import { gqlType } from "@hifieng/common";
import { MapEvent } from "../types/EventMap";

function isEventNotification(
  item:
    | gqlType.EventNotification
    | gqlType.PigRun
    | gqlType.PigRunUpdate
    | gqlType.TrainRun
    | gqlType.TrainRunUpdate
): item is gqlType.EventNotification {
  return Boolean(
    (item as gqlType.EventNotification).referenceId &&
      (item as gqlType.EventNotification).archived !== undefined
  );
}
function isPigRun(
  item: gqlType.EventNotification | gqlType.PigRun | gqlType.PigRunUpdate
): item is gqlType.PigRun {
  return Boolean(
    ((item as gqlType.PigRun).updates || (item as gqlType.PigRun).latest) &&
      (item as gqlType.EventNotification).archived === undefined
  );
}

export type PigUpdateWithPipelineRunId = gqlType.PigRunUpdate & {
  runId: string;
  pipeline: gqlType.PipelineSummary;
  pigCompleted: boolean;
};

function isTrainRun(
  item:
    | gqlType.EventNotification
    | gqlType.PigRun
    | gqlType.PigRunUpdate
    | gqlType.TrainRun
    | gqlType.TrainRunUpdate
): item is gqlType.TrainRun {
  return Boolean(
    ((item as gqlType.TrainRun).updates || (item as gqlType.TrainRun).latest) &&
      (item as gqlType.EventNotification).archived === undefined &&
      (item as gqlType.TrainRun).railId !== undefined
  );
}

function isTrainRunUpdate(
  item:
    | gqlType.EventNotification
    | gqlType.PigRun
    | gqlType.PigRunUpdate
    | gqlType.TrainRunUpdate
): item is gqlType.TrainRunUpdate {
  return Boolean(
    (item as gqlType.TrainRunUpdate) &&
      (item as TrainUpdateWithPipelineRunId).trainCompleted !== undefined
  );
}

export type TrainUpdateWithPipelineRunId = gqlType.TrainRunUpdate & {
  runId: string;
  pipeline: gqlType.PipelineSummary;
  trainCompleted: boolean;
};

const formatEventNotification = (
  event: gqlType.EventNotification
): MapEvent => {
  const {
    id,
    referenceId,
    post,
    type,
    coordinates,
    pipeline,
    eventMeta
  } = event;

  return {
    pipeline,
    id,
    referenceId,
    post,
    type: eventMeta ? eventMeta.status : type,
    coordinates,
    status: eventMeta ? eventMeta.status : undefined,
    category: type === "pig" ? "pig" : type === "train" ? "train" : "event",
    pulse: false
  };
};

const formatPigRun = (event: gqlType.PigRun): MapEvent => {
  const {
    runId,
    latest,
    updates,
    startLocation,
    endLocation,
    id,
    type,
    ...rest
  } = event;
  const update = latest ? latest : updates[0];
  const { segmentId, post, status, ...otherUpdates } = update;

  return {
    ...rest,
    ...otherUpdates,
    referenceId: id,
    id: runId,
    type: status,
    status,
    category: "pig",
    post,
    pulse: false
  };
};

const formatPigRunUpdate = (event: PigUpdateWithPipelineRunId): MapEvent => {
  const {
    id,
    runId,
    coordinates,
    post,
    timestamp,
    speed,
    status,
    pigCompleted
  } = event;
  return {
    id: runId,
    referenceId: id,
    post,
    type: status,
    coordinates,
    pipeline: event.pipeline,
    timestamp,
    speed,
    status,
    pulse: !pigCompleted,
    category: "pig"
  };
};

const formatTrainRun = (event: gqlType.TrainRun): MapEvent => {
  const {
    runId,
    latest,
    updates,
    startLocation,
    endLocation,
    id,
    type,
    ...rest
  } = event;
  const update = latest ? latest : updates[0];
  const { segmentId, post, status, ...otherUpdates } = update;

  return {
    ...rest,
    ...otherUpdates,
    referenceId: id,
    id: runId,
    type: status,
    status,
    category: "train",
    post,
    pulse: false
  };
};

const formatTrainRunUpdate = (
  event: TrainUpdateWithPipelineRunId
): MapEvent => {
  const {
    id,
    runId,
    coordinates,
    post,
    timestamp,
    speed,
    status,
    trainCompleted
  } = event;
  return {
    id: runId,
    referenceId: id,
    post,
    type: status,
    coordinates,
    pipeline: event.pipeline,
    timestamp,
    speed,
    status,
    pulse: !trainCompleted,
    category: "train"
  };
};

export const formatMapEvent = (
  event:
    | gqlType.PigRun
    | gqlType.TrainRun
    | gqlType.EventNotification
    | PigUpdateWithPipelineRunId
    | TrainUpdateWithPipelineRunId
): MapEvent => {
  if (isEventNotification(event)) return formatEventNotification(event);
  if (isTrainRun(event)) return formatTrainRun(event);
  if (isTrainRunUpdate(event)) return formatTrainRunUpdate(event);
  if (isPigRun(event)) return formatPigRun(event);
  return formatPigRunUpdate(event);
};
