import React from "react";

import { LogoCircle } from "../Icons";
import { Heading, Text } from "../Type";

import styles from "./index.module.scss";

const Loading = ({ heading }: { heading?: string }) => {
  return (
    <div className={styles.LoadingPage}>
      <div className={styles.Content}>
        <div className={styles.Logo}>
          <LogoCircle size={24} />
        </div>
        <Heading className={styles.Title} size="h3">
          {heading ? heading : "Initializing"}
        </Heading>
        <Text size="large" dark>
          Connecting to the Hifi system
        </Text>
      </div>
    </div>
  );
};

export default Loading;
