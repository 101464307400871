import React from "react";

import { Auth0Provider } from "../../contexts/AuthProvider";
import AuthGuard from "./AuthGuard";
import { getEnvVariable } from "../../helpers/getEnvVariable";

type PropsType = {
  children: React.ReactNode;
};

/*
 *  Component combines coupled components Auth0Provider and AuthGuard.
 *  Children will not be rendered until the auth0 provider is initialized.
 */

const domain: string = getEnvVariable("REACT_APP_AUTH_DOMAIN");
const clientId: string = getEnvVariable("REACT_APP_AUTH_CLIENT_ID");

const Auth = (props: PropsType) => {
  return (
    <Auth0Provider
      domain={domain}
      client_id={clientId}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
      redirect_uri={decodeURI(window.location.origin)}
      leeway={21600} // Allow the time to be incorrect by up to 6 hours
      useRefreshTokens
    >
      <AuthGuard>{props.children}</AuthGuard>
    </Auth0Provider>
  );
};

export default Auth;
