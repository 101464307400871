export const POLL_INTERVAL_MS = 10000;
export const QUICK_POLL_INTERVAL_MS = 5000;
export const MILLISECONDS_IN_SECONDS = 1000;
export const SECONDS_PER_DAY = 86400;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;
export const ISO_STR_HOUR_INDEX = 11;
export const ISO_STR_SECOND_END_INDEX = 19;
export const METERS_IN_KM = 1000;
export const JS_TO_UNIX_MULTIPLIER = 1000;
export const ONE_MINUTE_IN_MS = 60000;
export const MAX_ANALYSIS_CHANNEL_QUERY_RANGE = 20;
