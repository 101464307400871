import React from "react";

const DEFAULT_SIZE = 8;

type PropsType = {
  size?: number;
};

const Door = ({ size = DEFAULT_SIZE }: PropsType) => {
  size;
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 13 9">
      <g transform="scale(0.025) translate(-20 -100)">
        <path
          stroke="black"
          strokeWidth="17.63158"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default Door;
