import Logger from "logdna";
import { getEnvVariable } from "./getEnvVariable";

const loggerOptions = {
  app: "hifi-ui",
  // eslint-disable-next-line @typescript-eslint/camelcase
  with_credentials: false,
  // eslint-disable-next-line @typescript-eslint/camelcase
  index_meta: true
};

const logger = Logger.createLogger(
  getEnvVariable("REACT_APP_LOGDNA_KEY"),
  loggerOptions
);

export const logErrorToLogdna = (
  code: string,
  description: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorData: any = undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: any
) => {
  let message: string | undefined;
  let stack: string | undefined;
  if (typeof errorData === "string") {
    message = errorData;
  }
  if (typeof errorData === "object") {
    message = errorData.message;
    stack = errorData.stack;
  }
  logger.error(description, {
    meta: {
      error: {
        code,
        message,
        stack
      },
      context
    }
  });
};
