import React from "react";
const DEFAULT_SIZE = 22;
type PropsType = {
  size?: number;
};
const File = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg height={`${size}px`} viewBox="0 0 16 22">
      <g transform="translate(0 .344)">
        <path
          fill="currentColor"
          d="M15.275 5.86L10.025.72A.69.69 0 009.5.5H2C1.172.5.5 1.157.5 1.969V19.59c0 .811.672 1.469 1.5 1.469h12c.828 0 1.5-.658 1.5-1.469V6.374a.662.662 0 00-.225-.514zM9.5 2.3l4.2 4.2H9.5V2.3zM14 19.591H2V1.97h6v4.405c0 .811.672 1.469 1.5 1.469H14V19.59z"
        />
      </g>
    </svg>
  );
};
export default File;
