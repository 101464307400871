import { useEffect } from "react";
import gql from "graphql-tag";
import { gqlType } from "@hifieng/common";
import { useQuery } from "@apollo/react-hooks";
import { ApolloError } from "apollo-boost";

import { useOrganizationContext } from "../../../contexts/OrganizationProvider";
import { logErrorToLogdna } from "../../../helpers/logdna";
import {
  trainRunFragment,
  trainUpdatesFragment,
  trainsQueryComposer,
  ACTIVE_TRAINS_SUBSCRIPTION
} from "../../../helpers/trainQueryComposer";

const fragment = gql`
  fragment TrainsFragment on PaginatedTrainsAndTotal {
    trains {
      ...TrainRunFragment
      latest {
        ...TrainUpdateFragment
      }
    }
    active
    completed
  }
  ${trainRunFragment}
  ${trainUpdatesFragment}
`;

const ACTIVE_TRAINS_QUERY = trainsQueryComposer(fragment);

export const useActiveTrains = () => {
  const { activeOrg } = useOrganizationContext();

  const { subscribeToMore, loading, data } = useQuery(ACTIVE_TRAINS_QUERY, {
    variables: {
      organizationId: activeOrg ? activeOrg.id : undefined,
      filters: { completed: false }
    },
    fetchPolicy: "network-only",
    skip: !activeOrg,
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        "GETTING_ACTIVE_TRAINS",
        "Apollo error while getting active trains.",
        err
      );
      throw err;
    }
  });

  useEffect(() => {
    if (activeOrg) {
      subscribeToMore({
        document: ACTIVE_TRAINS_SUBSCRIPTION,
        variables: {
          organizationId: activeOrg.id
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          } else {
            const newTrain = subscriptionData.data.trackTrain;
            const prevRuns = prev.trains.trains;

            const existingTrain = prevRuns.find(
              (train: gqlType.TrainRun) => train.id === newTrain.id
            );
            const isNewNotificationCompleted =
              newTrain.latest.status === gqlType.TrainStatus.Completed;

            if (existingTrain) {
              const updatedRuns = prevRuns.map(
                (train: gqlType.TrainRun, i: number) => {
                  if (i === prevRuns.indexOf(existingTrain)) {
                    return newTrain;
                  }
                  return train;
                }
              );
              return Object.assign({}, prev, {
                trains: {
                  ...prev.trains,
                  trains: updatedRuns
                }
              });
            }
            const prevActiveRuns: number = prev.trains.active;
            const newActiveRuns: number = isNewNotificationCompleted
              ? prevActiveRuns
              : prevActiveRuns + 1;

            return Object.assign({}, prev, {
              trains: {
                ...prev.trains,
                trains: [newTrain, ...prevRuns].sort(
                  (a, b) => b.latest.timestamp - a.latest.timestamp
                ),
                active: newActiveRuns
              }
            });
          }
        }
      });
    }

    //suppressing hook dependency because `subscribeToMore` changes on every render
    // and the documentation suggest to only call it on component mount
    // https://www.apollographql.com/docs/react/data/subscriptions/#subscribing-to-updates-for-a-query
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrg]);

  return {
    loading,
    trainRuns: !loading && data ? data.trains : undefined
  };
};
