import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Intrusion = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 12 15">
      <g transform="translate(0 1)">
        <path
          d="M6,13.5 C6,13.5 11.3181818,10.9 11.3181818,7 L11.3181818,3.24765228 C11.3181818,3.01413167 11.1806575,2.80251443 10.9672636,2.70767273 L6.23999091,0.606662627 C6.08720229,0.538756575 5.91279771,0.538756575 5.76000909,0.606662627 L1.03273636,2.70767273 C0.819342535,2.80251443 0.681818182,3.01413167 0.681818182,3.24765228 L0.681818182,7 L0.681818182,7 C0.681818182,10.9 6,13.5 6,13.5 Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.125"
          fillRule="evenodd"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default Intrusion;
