import React, { useEffect } from "react";

import { useRouteMatch, useHistory } from "react-router-dom";
import ListDetailsSection from "../ListDetailsSection";
import EventsList from "../EventsList";
import EventMap from "../EventMap";
import { offset, eventsLimit } from "../../helpers/pagination";
import { useQueryParams } from "../../hooks/useQueryParams";

import { useEvents } from "../../hooks/useEvents";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import { useLiveStatusContext } from "../../contexts/LiveStatusProvider";
import { ITabContent } from "../TabbedContent";

const AlertsPage = ({ onActivated }: ITabContent) => {
  const match = useRouteMatch();
  const slugMatch = useRouteMatch<{ referenceId?: string }>(
    `${match.url}/:referenceId`
  );
  const history = useHistory();
  const { appStatus } = useLiveStatusContext();

  const { queryParams } = useQueryParams();
  const page = parseInt(queryParams.page, 10);
  const { activeOrg } = useOrganizationContext();
  const pipelines = (activeOrg && activeOrg.pipelines) || [];
  const pipelineIds =
    pipelines && pipelines.map((pipeline: { id: string }) => pipeline.id);

  const { currentLiveEvents, reFetchQuery } = useEvents({
    queryName: "liveEvents",
    filters: {
      pipelines: pipelineIds,
      archived: false,
      limit: eventsLimit,
      offset: location.pathname.includes("/alerts")
        ? offset(page, eventsLimit)
        : 0
    }
  });

  useEffect(() => {
    if (appStatus.needUpdate) {
      reFetchQuery();
    }
  }, [appStatus.needUpdate, reFetchQuery]);

  const selectedEvent =
    slugMatch && slugMatch.params ? slugMatch.params.referenceId : undefined;

  const selectEvent = (referenceId: string) => {
    history.push(`${match.url}/${referenceId}${history.location.search}`);
  };

  if (onActivated) onActivated({ title: "active" });

  return (
    <ListDetailsSection
      emptyStateText="No Alerts"
      totalEvents={currentLiveEvents.totalEvents}
      loading={currentLiveEvents.status === "loading" || !activeOrg}
      selectedEvent={selectedEvent}
      listComponent={
        <EventsList
          events={currentLiveEvents.events}
          selectedEvent={selectedEvent}
          setSelectedEvent={selectEvent}
        />
      }
      mapComponent={
        <EventMap
          canSearchOnMapMove={false}
          events={currentLiveEvents.events}
          scrollZoom
        />
      }
    />
  );
};

export default AlertsPage;
