import React from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { Heading, Meta, Text } from "../../Type";
import DateTime from "../../DateTime";
import Select from "../../Dropdown/Select";
import TrainUpdates from "../TrainUpdates";
import RunNotes from "../RunNotes";

import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";
import { trainTypeOptions } from "../../../helpers/filterOptions";
import { secondsToHms } from "../../../helpers/formatDate";

import styles from "./index.module.scss";

type Props = {
  trainRun: gqlType.TrainRun;
};

const SET_TRAIN_TYPE = gql`
  mutation($setTrainTypeInput: SetTrainTypeInput!) {
    setTrainType(setTrainTypeInput: $setTrainTypeInput)
  }
`;

const TrainSummary = ({ trainRun }: Props) => {
  const [setTrainType] = useMutation(SET_TRAIN_TYPE);
  return (
    <>
      <div className={styles.Wrapper}>
        <Heading className={styles.Title} size="h6">
          Run Details
        </Heading>
        <div className={cx(styles.TypeGroup, styles.SelectGroup)}>
          <Meta size="small" dark className={styles.Label}>
            TRAIN TYPE
          </Meta>
          <div>
            <Select
              small
              label="TRAIN TYPE"
              hideLabel
              items={trainTypeOptions}
              initial={
                trainTypeOptions.find(
                  option => option.value === trainRun.type
                ) || {
                  label: "Select Option",
                  value: ""
                }
              }
              onChange={selectedItem =>
                setTrainType({
                  variables: {
                    setTrainTypeInput: {
                      type: selectedItem.value,
                      id: trainRun.id
                    }
                  }
                })
              }
            />
          </div>
        </div>
        <div className={styles.TypeGroup}>
          <Meta size="small" dark className={styles.Label}>
            START
          </Meta>
          <div>
            <Text dark className={styles.Text}>
              <DateTime
                timestamp={trainRun.startTime * MILLISECONDS_IN_SECONDS}
                length="short"
              />
            </Text>
            <Text size="small" className={styles.SmallText}>
              {trainRun.startLocation.latitude},{" "}
              {trainRun.startLocation.longitude}
            </Text>
          </div>
        </div>
        {trainRun.endTime && trainRun.endLocation && (
          <>
            <div className={styles.TypeGroup}>
              <Meta size="small" dark className={styles.Label}>
                END
              </Meta>
              <div>
                <Text dark className={styles.Text}>
                  <DateTime
                    timestamp={trainRun.endTime * MILLISECONDS_IN_SECONDS}
                    length="short"
                  />
                </Text>
                <Text size="small" className={styles.SmallText}>
                  {trainRun.endLocation.latitude},{" "}
                  {trainRun.endLocation.longitude}
                </Text>
              </div>
            </div>
            <div className={styles.TypeGroup}>
              <Meta size="small" dark className={styles.Label}>
                DURATION
              </Meta>
              <div>
                <Text dark className={styles.Text}>
                  {secondsToHms(trainRun.endTime - trainRun.startTime)}
                </Text>
              </div>
            </div>
          </>
        )}
        <div className={styles.Notes}>
          <RunNotes logs={trainRun.auditLogs} id={trainRun.id} />
        </div>
      </div>
      <div className={styles.Wrapper}>
        <Heading className={styles.TitleUpdates} size="h6">
          Update History
        </Heading>
      </div>
      <TrainUpdates updates={trainRun.updates} />
    </>
  );
};

export default TrainSummary;
