import React from "react";
import cx from "classnames";
import { OptionType } from "../../../types/GeneralTypes";

import { Text } from "../../Type";

import styles from "./index.module.scss";

type PropsType = {
  option: OptionType;
  checked: boolean; // Whether this checkbox is currently checked
  highlighted?: boolean; // Whether this input is focused by keyboard input
  small?: boolean;
};

/*
 * This component is a simple checkbox input.
 * It does not use an onChange function, as it is intended to be used with downshift.
 */

const CheckboxInput = ({ option, checked, highlighted, small }: PropsType) => {
  return (
    <fieldset>
      <div className={styles.CheckboxWrapper}>
        <label
          className={cx(
            styles.CheckboxItem,
            highlighted && styles.Highlighted,
            small && styles.CheckboxItemSmall
          )}
          key={option.value.toString()}
        >
          {checked ? (
            <CheckboxSelected small={small} />
          ) : (
            <Checkbox small={small} />
          )}
          <input
            className={styles.CheckboxInput}
            name={option.label}
            type="checkbox"
            value={option.value.toString()}
            aria-checked={checked}
            checked={checked}
            onChange={() => null}
          />

          <Text
            component="span"
            size={small ? "small" : "regular"}
            className={cx(
              styles.CheckboxLabel,
              option.altText && styles.CheckboxLabelWithAltText
            )}
          >
            {option.label}
          </Text>

          {option.altText && (
            <div className={styles.AltTextWrapper}>
              <Text
                component="span"
                size={small ? "small" : "regular"}
                className={styles.DropdownAltText}
                dark
              >
                {option.altText}
              </Text>
            </div>
          )}
        </label>
      </div>
    </fieldset>
  );
};

export default CheckboxInput;

const CheckboxSelected = ({ small }: { small?: boolean }) => {
  return (
    <svg
      width={small ? "15" : "18"}
      height={small ? "15" : "18"}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <rect
          stroke="#FFF"
          strokeWidth="2.5"
          fill="#FFF"
          x="1"
          y="1"
          width="16"
          height="16"
          rx="1"
        />
        <g transform="translate(3 5)">
          <path
            stroke="#161616"
            strokeWidth=".8"
            fill="#000"
            d="M4.667 6.6L1.133 3.067.4 3.733l3.533 3.534.734.733L11.733.933 11 .2z"
          />
        </g>
      </g>
    </svg>
  );
};

const Checkbox = ({ small }: { small?: boolean }) => {
  return (
    <svg
      width={small ? "15" : "18"}
      height={small ? "15" : "18"}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="16"
        height="16"
        rx="1"
        stroke="#FFF"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};
