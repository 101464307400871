import { gqlType } from "@hifieng/common";
import { EventPriorityType } from "../types/EventTypes";

const DEFAULT_PRIORITY_LEVEL = "low";
const HIGH_PRIORITY_EVENTS = [
  "leak",
  gqlType.PigStatus.Stopped,
  gqlType.TrainStatus.Stopped
];
const MEDIUM_PRIORITY_EVENTS = [
  "intrusion",
  "unclassified",
  "flow restriction",
  gqlType.PigStatus.NotDetected,
  gqlType.PigStatus.ApproachingPoi,
  gqlType.TrainStatus.ApproachingStation
];

const getPriorityLevel = (eventType: string) => {
  const event = eventType;
  let priority: EventPriorityType = DEFAULT_PRIORITY_LEVEL;

  if (MEDIUM_PRIORITY_EVENTS.includes(event)) {
    priority = "medium";
  }

  if (HIGH_PRIORITY_EVENTS.includes(event)) {
    priority = "high";
  }

  return priority;
};

export default getPriorityLevel;
