import React, { useState, useRef, useEffect } from "react";
import { isEqual } from "lodash";

import {
  DayPickerRangeController,
  isInclusivelyBeforeDay,
  DayPickerRangeControllerShape,
  FocusedInputShape
} from "react-dates";

import cx from "classnames";
import { Primary, TextLink } from "../Button";
import { Text } from "../Type";
import { ChevronLeft, ChevronRight } from "../Icons";

import "./overrides.scss";
// Moment isn't exporting the `Moment` interface correctly.
// This is a known issue: https://github.com/moment/moment/issues/4877
import moment, { Moment } from "moment"; // eslint-disable-line
import { breakpoints } from "../../styles/variables";
import styles from "./index.module.scss";
import DropdownButton from "../Dropdown/DropdownButton";

export type DateRange = {
  startDate: Moment | null;
  endDate: Moment | null;
};

// We're adding a `maxDate` prop and we're changing `focusedInput` and
// `onFocusChange` to be optional because we have a some default behaviors
// that make implementation a little easier/smoother
interface IDayPickerRangeControllerShape
  extends Omit<
    DayPickerRangeControllerShape,
    "focusedInput" | "onFocusChange"
  > {
  maxDate?: Moment;
  focusedInput?: FocusedInputShape;
  onFocusChange?: (arg: FocusedInputShape | null) => void;
  rangeSelect?: boolean;
  onApply?: (args: DateRange) => void;
  dateFormat?: string;
  defaultText?: string;
  text?: string;
  clearText?: string;
  hideLabel?: boolean;
  onClearAll?: () => void;
}

// Extend the DayPickerRangeController props type to include maxDate
const ExtendedDayPickerRangeController = DayPickerRangeController as React.ClassicComponentClass<
  IDayPickerRangeControllerShape
>;

const DatePicker = ({
  startDate,
  endDate,
  navNext,
  navPrev,
  maxDate,
  numberOfMonths,
  initialVisibleMonth,
  isOutsideRange,
  minimumNights,
  onDatesChange, // pass an empty function () => {} to apply changes only onApply
  onApply,
  focusedInput,
  onFocusChange,
  rangeSelect,
  dateFormat = "MMM DD, YYYY",
  defaultText = "All",
  text,
  clearText = "Clear All",
  hideLabel,
  onClearAll,
  ...props
}: IDayPickerRangeControllerShape) => {
  const formatDateRange = (start: Moment | null, end: Moment | null) => {
    let output = String(text || defaultText);

    if (start || end) {
      if (start) {
        output = start.format(dateFormat);
      }
      if (rangeSelect && end && !isEqual(start, end)) {
        output += ` - ${end.format(dateFormat)}`;
      }
    }

    return output;
  };

  const [open, setOpen] = useState(false);

  const [textValue, setTextValue] = useState(
    formatDateRange(startDate, endDate)
  );

  const [focusedDateRangeInput, setFocusedDateRangeInput] = React.useState<
    "startDate" | "endDate" | null
  >("startDate");

  const doubleCalendar = 2;
  const isDoubleCalendar =
    (!numberOfMonths || numberOfMonths < doubleCalendar) &&
    window.innerWidth > breakpoints.medium;

  const datePickerDiv = useRef<HTMLDivElement>(null);

  const defaultInitialVisibleMonth = () =>
    moment().subtract(isDoubleCalendar ? 1 : 0, "month");

  const defaultOnFocusChange = (focused: "startDate" | "endDate" | null) => {
    if (rangeSelect) {
      setFocusedDateRangeInput(focused ? focused : "startDate");
    } else {
      setFocusedDateRangeInput("startDate");
    }
  };

  // If this isn't a multi-range selector then the focused input can only ever be the start date.
  // otherwise we default to the start date but allow the user to override this if the need.
  const defaultFocusedInput = !rangeSelect
    ? "startDate"
    : focusedDateRangeInput
    ? focusedDateRangeInput
    : "startDate";

  // Trying to keep the signature the same as `DayPickerRangeController` which uses type `any`.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isOutsideRangeDefault = (day: any) =>
    !isInclusivelyBeforeDay(day, moment());

  const changeDates = ({ startDate, endDate }: DateRange) => {
    const values = {
      startDate,
      endDate: rangeSelect ? endDate : startDate
    };

    onDatesChange(values);

    if (!onApply) {
      setTextValue(formatDateRange(values.startDate, values.endDate));
    }
  };

  const applyChanges = () => {
    if (onApply) {
      const values = {
        startDate,
        endDate
      };
      onApply(values);
    }
    setOpen(false);
  };

  const handleClearButtonClick = () => {
    changeDates({ startDate: null, endDate: null });
    setFocusedDateRangeInput("startDate");
    setTextValue(defaultText);
    if (onClearAll) onClearAll();
  };

  const handleVisibilityToggle = () => {
    open ? applyChanges() : null;
    setOpen(!open);
  };

  // Handles closing the `DatePicker` when it's open and you click outside of it.
  useEffect(() => {
    const clickListener = (e: MouseEvent) => {
      const target = e.target;
      if (
        target &&
        datePickerDiv &&
        datePickerDiv.current &&
        datePickerDiv.current.className.match(styles.FilterOpen) &&
        !datePickerDiv.current.contains(target as Node)
      ) {
        applyChanges();
      }
    };

    const escapeListener = (e: KeyboardEvent) => {
      if (
        datePickerDiv &&
        datePickerDiv.current &&
        datePickerDiv.current.className.match(styles.FilterOpen) &&
        e.key.toLowerCase() === "escape"
      ) {
        applyChanges();
      }
    };

    window.addEventListener("click", clickListener);
    window.addEventListener("keydown", escapeListener);

    return () => {
      window.removeEventListener("click", clickListener);
      window.removeEventListener("keydown", escapeListener);
    };
  });

  useEffect(() => {
    if (startDate && endDate) {
      setTextValue(formatDateRange(startDate, endDate));
    }
    if (!startDate && !endDate) {
      setTextValue("All");
      setFocusedDateRangeInput("startDate");
    }
    // disabling hooks dependency warning because
    // formatDateRange doesn't change
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div className={styles.DatePickerWrapper}>
      {!hideLabel && (
        <Text size="small" className={styles.DatePickerLabel} component="label">
          Date{`${rangeSelect ? "s" : ""}`}
        </Text>
      )}
      <div
        ref={datePickerDiv}
        className={cx(styles.FilterWrapper, { [styles.FilterOpen]: open })}
      >
        <span className={styles.DatePickerToggle}>
          <DropdownButton
            open={open}
            selectedItem={{ value: textValue, label: textValue }}
            onClick={handleVisibilityToggle}
          />
        </span>
        <div className={cx(styles.Filter, { [styles.FilterOpen]: open })}>
          <div className={styles.Wrapper}>
            <ExtendedDayPickerRangeController
              startDate={startDate}
              endDate={endDate}
              noBorder
              hideKeyboardShortcutsPanel
              focusedInput={focusedInput || defaultFocusedInput}
              onFocusChange={onFocusChange || defaultOnFocusChange}
              minimumNights={minimumNights || 0}
              isOutsideRange={isOutsideRange || isOutsideRangeDefault}
              initialVisibleMonth={
                initialVisibleMonth || defaultInitialVisibleMonth
              }
              // Use 2 months on desktop and 1 mobile/tablet
              numberOfMonths={
                numberOfMonths || isDoubleCalendar ? doubleCalendar : 1
              }
              navNext={
                navNext || (
                  <div className={styles.NavIconWrapperNext}>
                    <ChevronRight />
                  </div>
                )
              }
              navPrev={
                navPrev || (
                  <div className={styles.NavIconWrapperPrev}>
                    <ChevronLeft />
                  </div>
                )
              }
              // Prevents navigation to future months.
              maxDate={maxDate || moment().endOf("month")}
              onDatesChange={changeDates}
              {...props}
            />
          </div>
          <div className={styles.Buttons}>
            <TextLink
              onClick={handleClearButtonClick}
              linkStyle="secondary"
              buttonSize="small"
            >
              {clearText}
            </TextLink>
            {onApply && (
              <Primary onClick={applyChanges} buttonSize="small">
                Apply
              </Primary>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
