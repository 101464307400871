import { useMemo } from "react";

import moment from "moment";
import { MILLISECONDS_IN_SECONDS } from "../helpers/constants";

export const useMemoizedTimes = (
  start: string,
  end: string,
  utcOffset: string | undefined
) => {
  return useMemo(() => {
    if (start && end) {
      let offsetStartDate = moment(
        parseInt(start, 10) * MILLISECONDS_IN_SECONDS
      );

      if (utcOffset) {
        offsetStartDate = offsetStartDate.utcOffset(utcOffset, true);
      }
      const adjustedStartDate = offsetStartDate.startOf("day");

      let offsetEndDate = moment(parseInt(end, 10) * MILLISECONDS_IN_SECONDS);

      if (utcOffset) {
        offsetEndDate = offsetEndDate.utcOffset(utcOffset, true);
      }
      const adjustedEndDate = offsetEndDate.startOf("day").add(1, "day");

      const output = {
        start: adjustedStartDate.unix(),
        end: adjustedEndDate.unix()
      };
      return output;
    } else {
      return undefined;
    }
  }, [start, end, utcOffset]);
};
