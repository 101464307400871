import React, { useState, useMemo } from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";
import { NavLink, useRouteMatch } from "react-router-dom";
import qs from "qs";

import { Text } from "../../Type";
import DateTime from "../../DateTime";
import { TextLink } from "../../Button";
import { Train } from "../../Icons";

import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";
import { getStatusText } from "../../../helpers/trainStatus";
import { formatTrainUpdates } from "./formatUpdates";
import getPriorityLevel from "../../../helpers/getPriorityLevel";

import styles from "./index.module.scss";
import { useSelectedTrain } from "../useSelectedTrain";
import { useQueryParams } from "../../../hooks/useQueryParams";

type Props = {
  updates: Array<gqlType.TrainRunUpdate>;
};

type UpdateItemProps = {
  update: gqlType.TrainRunUpdate;
  withIcon?: boolean;
  pulse?: boolean;
};

const UpdateItem = ({ update, withIcon, pulse }: UpdateItemProps) => {
  const { updateId } = useSelectedTrain();
  const { queryParams } = useQueryParams();
  const priority = getPriorityLevel(update.status);
  const match = useRouteMatch();
  const showPulse =
    (pulse || false) && update.status !== gqlType.TrainStatus.Completed;
  return (
    <NavLink
      to={{
        pathname: `${match.url}/${update.id}`,
        search: `?${qs.stringify(queryParams, {
          arrayFormat: "comma",
          encode: false
        })}`
      }}
      className={cx(
        styles.LogEntry,
        withIcon && styles.LogEntryWithIcon,
        updateId === update.id && styles.LogEntrySelected
      )}
    >
      {withIcon ? (
        <span
          className={cx(styles.TrainIcon, {
            [styles.TrainIconLow]: priority === "low" && !showPulse,
            [styles.TrainIconMedium]: priority === "medium" && !showPulse,
            [styles.TrainIconHigh]: priority === "high" && !showPulse,
            [styles.TrainIconLowPulse]: priority === "low" && showPulse,
            [styles.TrainIconMediumPulse]: priority === "medium" && showPulse,
            [styles.TrainIconHighPulse]: priority === "high" && showPulse
          })}
        >
          <Train size={10} />
        </span>
      ) : (
        <span
          className={cx(styles.LogEntryIcon, {
            [styles.LogEntryIconLow]: priority === "low",
            [styles.LogEntryIconMedium]: priority === "medium",
            [styles.LogEntryIconHigh]: priority === "high"
          })}
        />
      )}
      <Text className={styles.Text}>
        {getStatusText(update.status)} - {update.coordinates.latitude},{" "}
        {update.coordinates.longitude}
      </Text>
      <Text size="small" component="span" dark className={styles.SmallText}>
        Update:{" "}
        <DateTime
          timestamp={update.timestamp * MILLISECONDS_IN_SECONDS}
          length="short"
        />
      </Text>
      <Text size="small" component="span" dark className={styles.SmallText}>
        ETA:{" "}
        {update.nextStationEta ? (
          <DateTime
            timestamp={update.nextStationEta * MILLISECONDS_IN_SECONDS}
            length="short"
          />
        ) : (
          "TBD"
        )}
      </Text>
    </NavLink>
  );
};

const NestedUpdate = ({ updates }: Props) => {
  const [flattened, setFlattened] = useState(false);
  return !flattened ? (
    <div className={styles.LogCollapsed}>
      <Text className={styles.Text}>
        {`${updates.length} other ${getStatusText(
          updates[0].status
        ).toLowerCase()} update${updates.length > 1 ? "s" : ""}`}
      </Text>
      <TextLink
        onClick={() => setFlattened(true)}
        className={styles.ToggleCollapsedButton}
      >
        View More
      </TextLink>
    </div>
  ) : (
    <>
      {updates.map(update => (
        <UpdateItem update={update} key={update.id} />
      ))}
      <TextLink
        onClick={() => setFlattened(false)}
        className={styles.ViewLessButton}
      >
        View Less
      </TextLink>
    </>
  );
};

const TrainUpdates = ({ updates }: Props) => {
  const formatted = useMemo(() => {
    return formatTrainUpdates(updates);
  }, [updates]);

  return (
    <div className={styles.LogList}>
      {formatted.map((item, i) =>
        Array.isArray(item) ? (
          <NestedUpdate updates={item} key={`nested-${item[0].id}`} />
        ) : i === 0 ? (
          <UpdateItem update={item} key={item.id} withIcon pulse />
        ) : (
          <UpdateItem update={item} key={item.id} withIcon />
        )
      )}
    </div>
  );
};

export default TrainUpdates;
