import { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { gqlType } from "@hifieng/common";
import useDeepCompareEffect from "use-deep-compare-effect";
import { IEvents } from "../types/EventTypes";
import formatEvent from "../helpers/formatEvent";
import { logErrorToLogdna } from "../helpers/logdna";
import { ApolloError } from "apollo-boost";

type PropsType = {
  queryName?: string;
  queryFragment?: unknown;
  filters: gqlType.EventFiltersInput;
  pollInterval?: number;
  initialValue?: IEvents;
};

const DEFAULT_QUERY_NAME = "events";
const ERROR_CODES = {
  gettingEvents: "GETTING_EVENTS"
};

export const INITIAL_EVENTS_STATE: IEvents = {
  status: "loading",
  events: [],
  totalEvents: 0
};

export function eventQueryComposer(fragment?: unknown, name?: string) {
  const eventFragment = fragment
    ? fragment
    : gql`
        fragment EventFragments on EventNotification {
          id
          referenceId
          pipeline {
            id
            name
            __typename
          }
          segmentId
          type
          post
          coordinates {
            latitude
            longitude
            __typename
          }
          channel
          archived
          archivedDate
          startTime
          endTime
          eventMeta {
            status
            runId
            railId
            nextStationName
            nextStationEta
            nextPoi {
              post
              type
            }
          }
          __typename
        }
      `;

  const queryName = name ? name : DEFAULT_QUERY_NAME;

  return gql`
    query ${queryName}($filters: EventFiltersInput) {
      events(filters: $filters) {
        events {
          ...EventFragments
        }
        totalEvents
      }
    }
    ${eventFragment}
  `;
}

export const useEvents = ({
  queryName,
  queryFragment,
  filters,
  pollInterval,
  initialValue
}: PropsType) => {
  const [eventsData, setEventsData] = useState<IEvents>(
    initialValue || INITIAL_EVENTS_STATE
  );

  const query = eventQueryComposer(queryFragment, queryName);

  const variables: {
    filters: gqlType.EventFiltersInput;
  } = { filters };

  const { loading, error, data, refetch } = useQuery(query, {
    fetchPolicy: "cache-and-network",
    pollInterval,
    variables,
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        ERROR_CODES.gettingEvents,
        "Apollo error while getting events.",
        error
      );
      return err;
    }
  });

  // If the filters change, set the loading status
  useDeepCompareEffect(() => {
    setEventsData({ ...eventsData, status: "loading" });
  }, [filters]);

  // Update Events
  useEffect(() => {
    if (error) {
      setEventsData({ status: "error", events: [], totalEvents: 0 });
    } else if (!loading && data && data.events) {
      setEventsData({
        status: "connected",
        events: data.events.events.map(formatEvent),
        totalEvents: data.events.totalEvents
      });
    } else if (loading) {
      setEventsData({
        events: [],
        totalEvents: 0,
        status: "loading"
      });
    }
  }, [data, error, loading]);

  return {
    currentLiveEvents: eventsData,
    reFetchQuery: refetch
  };
};
