import gql from "graphql-tag";

export const trainRunFragment = gql`
  fragment TrainRunFragment on TrainRun {
    id
    runId
    railId
    pipeline {
      name
      id
    }
    startTime
    endTime
    type
  }
`;

export const trainUpdatesFragment = gql`
  fragment TrainUpdateFragment on TrainRunUpdate {
    id
    timestamp
    post
    coordinates {
      latitude
      longitude
    }
    speed
    status
    nextStationName
    nextStationEta
  }
`;

export const ACTIVE_TRAINS_SUBSCRIPTION = gql`
  subscription trackTrain($organizationId: String!) {
    trackTrain(organizationId: $organizationId) {
      ...TrainRunFragment
      latest {
        ...TrainUpdateFragment
      }
      updates {
        ...TrainUpdateFragment
      }
    }
  }
  ${trainRunFragment}
  ${trainUpdatesFragment}
`;

export const TRAIN_COUNT_SUBSCRIPTION = gql`
  subscription trackTrain($organizationId: String!) {
    trackTrain(organizationId: $organizationId) {
      id
      completed
    }
  }
`;

export const COMPLETED_TRAINS_SUBSCRIPTION = gql`
  subscription trackTrain($organizationId: String!) {
    trackTrain(organizationId: $organizationId) {
      ...TrainRunFragment
      startLocation {
        latitude
        longitude
      }
      endLocation {
        latitude
        longitude
      }
      startPost
      endPost
      completed
      numberOfUpdates
      numberOfCriticalEvents
      auditLogs {
        id
      }
    }
  }
  ${trainRunFragment}
`;

export function trainsQueryComposer(fragment?: unknown) {
  const queryFragment =
    fragment ||
    gql`
      fragment TrainsFragment on PaginatedTrainsAndTotal {
        trains {
          ...TrainRunFragment
        }
      }
    `;

  return gql`
    query trains($organizationId: String!, $filters: TrainsFiltersInput) {
      trains(organizationId: $organizationId, filters: $filters) {
        ...TrainsFragment
      }
    }
    ${queryFragment}
  `;
}
