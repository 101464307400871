// taken from https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
const MB_MULTIPLIER = 1024;
const DEFAULT_DECIMALS = 1;

export function formatBytes(a: number, b = DEFAULT_DECIMALS) {
  if (a === 0) return "0 B ";
  const c = b < 0 ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(MB_MULTIPLIER));
  return `${parseFloat((a / Math.pow(MB_MULTIPLIER, d)).toFixed(c))} ${
    ["B ", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  }`;
}
// taken from https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
/* eslint-disable no-magic-numbers */
/* cspell:disable */
export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/* cspell:enable */
/* eslint-enable no-magic-numbers */
