import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Construction = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 12 13">
      <path
        fill="currentColor"
        d="M4.33354.00002a4.20444 4.20444 0 00-2.3166.68643l2.7456 2.74561a.9009.9009 0 01.0858 1.287.9009.9009 0 01-1.287-.0858L.72993 1.88767a4.22138 4.22138 0 00-.7293 2.4453c.0094 2.3891 1.94381 4.32352 4.33291 4.33292a4.67612 4.67612 0 001.1154-.1287l2.87432 2.87431c.8411.84111 2.2048.84111 3.04591 0 .8411-.8411.8411-2.2048 0-3.0459L8.49486 5.49126a4.67612 4.67612 0 00.1287-1.1154 4.29002 4.29002 0 00-1.22577-3.08912A4.29014 4.29014 0 004.33354.00002z"
      />
    </svg>
  );
};

export default Construction;
