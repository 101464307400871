import React from "react";
import ErrorScreen from "../../ErrorScreen";
import Report from "../ReportRow";
import EmptyListState from "../../EmptyListState";
import styles from "./index.module.scss";
import { gqlType } from "@hifieng/common";
import { canPreview } from "../previewTypes";

type PropsType = {
  reports: Array<gqlType.Report>;
  loading: boolean;
  error?: string;
};

const ReportsPage = ({ reports, loading, error }: PropsType) => {
  return (
    <div className={styles.Content}>
      {reports.length < 1 || loading ? (
        <div className={styles.Empty}>
          <EmptyListState loading={loading} text="No Reports" />
        </div>
      ) : (
        <div className={styles.List}>
          {!loading && !error
            ? reports.map((report: gqlType.Report) => {
                const preview = canPreview(report.contentType);

                return (
                  <div key={report.id} className={styles.ListItem}>
                    <Report
                      report={report}
                      showPreviewButton={preview}
                      showCloseButton={false}
                    />
                  </div>
                );
              })
            : null}
          {error ? (
            <ErrorScreen
              title="Something went wrong fetching reports."
              subtitle="Please reload the page to try again."
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ReportsPage;
