import React from "react";
import DatePicker from "../../DatePicker";
import moment from "moment";
import GenericFilter from "../../GenericFilter";
import { useHistoryFiltersContext } from "../HistoryFiltersProvider";
import styles from "./index.module.scss";
import Select from "../../Dropdown/Select";
import { OptionType } from "../../../types/GeneralTypes";
import ResultsCount from "../../ResultsCount";
import { eventsLimit } from "../../../helpers/pagination";

const DesktopFilters = ({
  eventsCount,
  totalEvents
}: {
  eventsCount: number;
  totalEvents: number;
}) => {
  const {
    dateRange,
    updateDateRange,
    applyDateRange,
    pipelineOptions,
    selectedPipelines,
    setSelectedPipelines,
    eventTypeOptions,
    selectedEventTypes,
    setSelectedEventTypes,
    sortByOptions,
    sortBy,
    setSortBy,
    page
  } = useHistoryFiltersContext();

  return (
    <div className={styles.Wrapper}>
      <DatePicker
        initialVisibleMonth={() => moment().subtract(1, "month")}
        rangeSelect
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        onApply={applyDateRange}
        onDatesChange={updateDateRange}
        onClearAll={() => updateDateRange({ startDate: null, endDate: null })}
      />

      {pipelineOptions.length > 1 ? (
        <GenericFilter
          label={"Pipelines"}
          options={pipelineOptions}
          selectedItems={selectedPipelines}
          setSelectedItems={setSelectedPipelines}
        />
      ) : null}

      <GenericFilter
        label={"Events"}
        options={eventTypeOptions}
        selectedItems={selectedEventTypes}
        setSelectedItems={setSelectedEventTypes}
      />

      <div className={styles.ResponsiveGap} />

      <ResultsCount
        totalEvents={totalEvents}
        eventsCount={eventsCount}
        page={page}
        className={styles.ResultsCount}
        limit={eventsLimit}
      />

      <Select
        label={"Sort By:"}
        items={sortByOptions}
        onChange={(sort: OptionType) => setSortBy(sort.value)}
        initial={
          sortByOptions.find(option => sortBy === option.value) || {
            value: "",
            label: ""
          }
        }
      />
    </div>
  );
};

export default DesktopFilters;
