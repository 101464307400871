import React, { ReactNode } from "react";

type PropsType = {
  children: ReactNode;
  size: number;
  childSize: number;
  iconColor: string;
  showPulse?: boolean;
};

const HALF = 0.5;
const ONE_THIRD = 0.3;

const IconCircleWrapper = ({
  children,
  size,
  childSize,
  iconColor,
  showPulse
}: PropsType) => {
  const circleIconPadding = (size - childSize) * HALF;
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {showPulse && (
        <style>{`
          .pulse {
            background: mix(white, $color, 40%);animation: pulse 2.5s infinite;
            transform-origin: center;
          }
          @keyframes pulse {
            0% {
              transform: scale(1); opacity: 0.5;
            } 85% {
              opacity: 0;
            } 100% {
              opacity: 0; transform: scale(2.2);
            }
          }
        `}</style>
      )}
      <g>
        <g fill={iconColor} fillRule="evenodd">
          <circle
            r={size * (showPulse || false ? ONE_THIRD : HALF)}
            cx={size * HALF}
            cy={size * HALF}
          />

          {showPulse && (
            <circle
              r={size * ONE_THIRD}
              cx={size * HALF}
              cy={size * HALF}
              className="pulse"
            />
          )}
        </g>

        <g
          fill="none"
          color="white"
          fillRule="evenodd"
          transform={`translate(${circleIconPadding}, ${circleIconPadding})`}
        >
          {children}
        </g>
      </g>
    </svg>
  );
};

export default IconCircleWrapper;
