import React from "react";

const ChevronRight = () => {
  return (
    <svg width="16" height="10">
      <path
        fill="currentColor"
        transform="rotate(-90 8 5)"
        d="M8 9.275l-7.5-7.5L1.55.725 8 7.175l6.45-6.45 1.05 1.05z"
      />
    </svg>
  );
};

export default ChevronRight;
