import React from "react";
import { Meta } from "../Type";
import styles from "./index.module.scss";

type BasePropsType = {
  children: React.ReactNode;
};

interface ICellProps extends React.HTMLAttributes<HTMLTableCellElement> {
  children: React.ReactNode;
}

interface IHeaderProps extends React.HTMLAttributes<HTMLHeadElement> {
  children: React.ReactNode;
}

interface IRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  children: Array<React.ReactElement<ICellProps | IHeaderProps>>;
}

type TablePropsType = {
  caption: string;
  children: Array<React.ReactElement<IRowProps>>;
};

export const Cell = ({ children }: BasePropsType) => {
  return <td className={styles.TableCell}>{children}</td>;
};

export const Head = ({ children }: BasePropsType) => (
  <th className={styles.TableHeader} scope="col">
    <Meta component="span" dark>
      {children}
    </Meta>
  </th>
);

export const Row = ({ children, onClick }: IRowProps) => {
  return (
    <tr className={styles.TableRow} onClick={onClick}>
      {children}
    </tr>
  );
};

export const Table = ({ caption, children }: TablePropsType) => {
  return (
    <div className={styles.TableWrapper}>
      <table className={styles.Table}>
        <caption className={styles.TableCaption}>{caption}</caption>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};
