import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Archive = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20,4 L20,18 C20,19.0543618 19.1841222,19.9181651 18.1492623,19.9945143 L18,20 L2,20 C0.945638205,20 0.0818348781,19.1841222 0.00548573643,18.1492623 L0,18 L0,4 L20,4 Z M10,8.52185059 L6.39984576,11.2994356 L7.57541626,12.9174696 L8.99984576,11.8818506 L9,15 L11,15 L10.9998458,11.9458506 L12.3439666,12.959362 L13.5475967,11.362091 L10.0138458,8.55785059 L10,8.52185059 Z M20,0 L20,2 L0,2 L0,0 L20,0 Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Archive;
