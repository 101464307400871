import React from "react";

import { useAuth } from "../../contexts/AuthProvider";

import Loading from "../Loading";

type PropsType = {
  children: React.ReactNode;
};

const AuthGuard = (props: PropsType) => {
  const { isAuthenticated, loading, loginWithRedirect } = useAuth();

  if (loading) {
    return <Loading />;
  }

  if (!loading && !isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  return <>{props.children}</>;
};

export default AuthGuard;
