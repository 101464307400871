import React, { useState, useEffect, useCallback } from "react";
import { gqlType } from "@hifieng/common";
import { OptionType } from "../../types/GeneralTypes";
import PipelineSelect from "./PipelineSelect";
import { useAuth } from "../../contexts/AuthProvider";
import { useQueryParams } from "../../hooks/useQueryParams";
import RecentAreas, { getSearchHistory, setSearchHistory } from "./RecentAreas";
import { SearchRange } from "../../types/AnalysisTypes";

import {
  getAnalysisRangeUnits,
  calculateMaxKPRange,
  getPipelineChKpMap,
  getRangeIndexes
} from "../../helpers/analysisFunctions";

import RangeSelector, {
  constrainRangeStart,
  constrainRangeEnd
} from "./RangeSelector";
import { kpMpRatio } from "../AnalysisCharts/BaseChart";

import styles from "./index.module.scss";
import { MAX_ANALYSIS_CHANNEL_QUERY_RANGE } from "../../helpers/constants";
import { clamp } from "../../helpers/math";

type Props = {
  pipelines: Array<gqlType.Pipeline>;
  pipeline: gqlType.Pipeline;
  onPipelineChange?: (pipeline: gqlType.Pipeline) => void;
  fiber: gqlType.ChannelKPostMap;
  onFiberChange?: (fiber: gqlType.ChannelKPostMap) => void;
  changePipelineURI?: string;
  setMapRange?: (range: SearchRange) => void;
  KPRange?: SearchRange;
};

const AnalysisFilters = ({
  pipelines,
  pipeline,
  onPipelineChange,
  fiber,
  onFiberChange,
  changePipelineURI,
  setMapRange,
  KPRange
}: Props) => {
  const { queryParams } = useQueryParams();

  const analysisRangeUnits = getAnalysisRangeUnits();
  const [rangeUnit, setRangeUnit] = useState(
    clamp(Number(queryParams.unit) || 0, 0, analysisRangeUnits.length - 1)
  );

  // range must be a clone of `fiber.ch` not a reference
  let range = [...fiber.ch];
  let precision = 0;
  let rangeStep = 1;

  const { permissions } = useAuth();
  const imperialFlag =
    (permissions.user.imperial ? true : false) ||
    fiber.id === "enbridge_line_3" ||
    fiber.id === "enbridge_line_4";

  // user has selected the Kilometer Posts unit type.
  if (!rangeUnit) {
    if (imperialFlag) {
      range = fiber.kp.map(function(kpValue) {
        return kpValue * kpMpRatio;
      });
    } else {
      range = fiber.kp;
    }
    precision = fiber.precision;
    rangeStep = fiber.step;
  }

  // Fiber is backwards, we'll have to reverse it
  if (range[0] > range[range.length - 1]) {
    range.sort();
  }

  const minRange = 0;
  const rangeStart = range[0];
  const rangeEnd = range[range.length - 1];
  const maxKpRange = calculateMaxKPRange(fiber);
  const maxRange = !rangeUnit
    ? imperialFlag
      ? maxKpRange * kpMpRatio
      : maxKpRange
    : MAX_ANALYSIS_CHANNEL_QUERY_RANGE;

  const constrainStartValue = useCallback(
    (value: number) => {
      return constrainRangeStart(
        value,
        rangeStart,
        rangeEnd,
        minRange,
        rangeStep,
        precision
      );
    },
    [rangeStart, rangeEnd, minRange, rangeStep, precision]
  );

  const constrainEndValue = useCallback(
    (value: number, start: number) => {
      return constrainRangeEnd(
        value,
        start,
        rangeEnd,
        minRange,
        maxRange,
        rangeStep,
        precision
      );
    },
    [rangeEnd, minRange, maxRange, rangeStep, precision]
  );

  const [startValue, setStartValue] = useState(
    constrainStartValue(Number(queryParams.start) || rangeStart)
  );

  const [endValue, setEndValue] = useState(
    constrainEndValue(
      Number(queryParams.end) || startValue + maxRange,
      startValue
    )
  );

  // We need to re-calculate/constrain the start and end values if any of the
  // following values change we might end up with a rage of a pipeline selected
  // that doesn't exist on the current pipeline or fiber.
  useEffect(() => {
    const start = constrainStartValue(rangeStart);
    const end = constrainEndValue(start + maxRange, start);
    setStartValue(start);
    setEndValue(end);
  }, [
    constrainStartValue,
    constrainEndValue,
    pipeline,
    fiber.id,
    precision,
    rangeStep,
    rangeUnit,
    rangeEnd,
    rangeStart,
    maxRange
  ]);

  // When the `queryParams` change the user has either clicked "search" or
  // they've clicked one of the "recent areas". We'll want to change the
  // `units` as well as the `start` and `end` values to match the ones being
  // passed in from the URL.
  useEffect(() => {
    const unitValue = Number(queryParams.unit) || 0;
    setRangeUnit(clamp(unitValue, 0, analysisRangeUnits.length - 1));
    if (queryParams.start) setStartValue(Number(queryParams.start));
    if (queryParams.end) setEndValue(Number(queryParams.end));
  }, [
    queryParams.start,
    queryParams.end,
    queryParams.unit,
    analysisRangeUnits.length
  ]);

  const handlePipelineChange = (selectedItem: OptionType) => {
    const pipeline = pipelines.find(
      pipeline => pipeline.id === selectedItem.value
    );

    if (pipeline && onPipelineChange) {
      onPipelineChange(pipeline);
    }
  };

  const handleFiberChange = (selectedItem: OptionType) => {
    if (onFiberChange) {
      onFiberChange(getPipelineChKpMap(pipeline, selectedItem.value));
    }
  };

  const handleRangeUnitChange = (value: number) => {
    setRangeUnit(value);
  };

  const handleRangeChange = (start: number, end: number) => {
    setStartValue(start);
    setEndValue(end);

    if (setMapRange) {
      setMapRange(getRangeIndexes(pipeline, fiber, { start, end }, rangeUnit));
    }
  };

  const [recentAreas, setRecentAreas] = useState(getSearchHistory());

  useEffect(() => {
    setSearchHistory(recentAreas);
  }, [recentAreas]);

  useEffect(() => {
    if (setMapRange && !KPRange) {
      setMapRange(
        getRangeIndexes(
          pipeline,
          fiber,
          { start: startValue, end: endValue },
          rangeUnit
        )
      );
    }
  }, [setMapRange, KPRange, pipeline, fiber, startValue, endValue, rangeUnit]);
  return (
    <>
      <div className={styles.FilterAttribute}>
        <PipelineSelect
          pipelines={pipelines.filter(
            pipeline =>
              pipeline.interfaceOptions.hideHeartbeat !== true &&
              pipeline.kmPosts.length !== 0
          )}
          selectedPipelineId={pipeline.id}
          fiber={fiber}
          onPipelineChange={handlePipelineChange}
          onFiberChange={handleFiberChange}
          changePipelineURI={changePipelineURI}
        />
      </div>
      <hr className={styles.FiltersPanelSeparator} />
      <div className={styles.FilterAttribute}>
        <RangeSelector
          key="range"
          pipeline={pipeline}
          fiber={fiber}
          precision={precision}
          rangeUnit={rangeUnit}
          minRange={minRange}
          maxRange={maxRange}
          rangeStep={rangeStep}
          rangeStart={rangeStart}
          rangeEnd={rangeEnd}
          startValue={startValue}
          endValue={endValue}
          onChange={handleRangeChange}
          onUnitChange={handleRangeUnitChange}
          onSearch={setRecentAreas}
        />
      </div>
      <hr className={styles.FiltersPanelSeparator} />
      <div className={styles.FilterAttribute}>
        <RecentAreas />
      </div>
    </>
  );
};

export default AnalysisFilters;
