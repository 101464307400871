import React from "react";
import { pipelineColorMap } from "../../../styles/colors";

const DEFAULT_SIZE = 18;
const DEFAULT_COLOR = pipelineColorMap.blue;

type PropsType = {
  color?: string;
  className?: string;
  size?: number;
};

const PipelineIcon = ({
  className,
  color = DEFAULT_COLOR,
  size = DEFAULT_SIZE
}: PropsType) => {
  return (
    <svg
      height={size}
      width={size}
      className={className}
      style={{
        stroke: pipelineColorMap[color] || pipelineColorMap.blue
      }}
    >
      <line x1="0" y1="9" x2="18" y2="9" />
    </svg>
  );
};

export default PipelineIcon;
