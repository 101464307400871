import { HeartbeatStatusType } from "../types/HeartbeatTypes";
import { MILLISECONDS_IN_SECONDS } from "../helpers/constants";
export const isSegmentConnected = (latestHeartbeat: number) => {
  const CONNECTION_TIMEOUT_MS = 20000;
  const nowSeconds: number = Math.floor(Date.now() / MILLISECONDS_IN_SECONDS);

  const isConnected =
    nowSeconds - CONNECTION_TIMEOUT_MS / MILLISECONDS_IN_SECONDS <
    latestHeartbeat;

  return isConnected;
};

export const getCurrentHeartbeatStatus = ({
  error,
  loading,
  connectedCount,
  disconnectedCount
}: {
  error: boolean;
  loading: boolean;
  connectedCount: number;
  disconnectedCount: number;
}): HeartbeatStatusType => {
  let currentStatus: HeartbeatStatusType = "error";

  if (error) {
    currentStatus = "error";
  } else if (loading && connectedCount === 0 && disconnectedCount === 0) {
    currentStatus = "loading";
  } else if (connectedCount === 0) {
    currentStatus = "disconnected";
  } else if (disconnectedCount > 0) {
    currentStatus = "partially-connected";
  } else if (connectedCount > 0) {
    currentStatus = "connected";
  }

  return currentStatus;
};
