import React from "react";

const DEFAULT_SIZE = 24;

type PropsType = {
  size?: number;
};

const Icon = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path stroke="#FFF" d="M.5.5h23v23H.5z" />
        <g transform="translate(8.25 6)">
          <path
            transform="rotate(90 3.75 6)"
            fill="#FFF"
            d="M3.75 9.206l-5.625-5.625.788-.787L3.75 7.63l4.838-4.837.787.787z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Icon;
