import { IHeartbeat } from "../types/HeartbeatTypes";
import { gqlType } from "@hifieng/common";
import { getCurrentHeartbeatStatus } from "./heartbeats";

type HeartBeatsByIdType = {
  [pipelineId: string]: {
    [segmentId: string]: boolean;
  };
};

type PropsType = {
  pipelines: Array<gqlType.Pipeline>;
  dataStatus: "error" | "loading" | "completed";
  liveHeartbeat: Array<gqlType.HeartbeatNotification>;
};

export const INITIAL_HEARTBEAT_STATE: IHeartbeat = {
  status: "loading",
  data: [],
  connectedCount: 0,
  disconnectedCount: 0
};

export const derivedHeartbeatInfo = ({
  pipelines,
  dataStatus,
  liveHeartbeat
}: PropsType) => {
  const isError = dataStatus === "error";
  const isLoading = dataStatus === "loading";

  const heartbeatsById: HeartBeatsByIdType = {};
  let connectedCount = 0;
  let disconnectedCount = 0;

  liveHeartbeat.forEach((heartbeat: gqlType.HeartbeatNotification) => {
    if (!heartbeatsById[heartbeat.pipelineId]) {
      heartbeatsById[heartbeat.pipelineId] = {};
    }

    heartbeatsById[heartbeat.pipelineId][heartbeat.segmentId] =
      heartbeat.isConnected;
  });

  // Pipelines with interfaceOptions.hideHeartbeat set to true are removed from all Heartbeat components
  const filteredPipelines = pipelines.filter(
    pipeline => !pipeline.interfaceOptions.hideHeartbeat
  );
  const heartbeatData = filteredPipelines.map(pipeline => {
    return {
      ...pipeline,
      segments: pipeline.segments.map(segment => {
        const latestHeartbeat =
          heartbeatsById[pipeline.id] && heartbeatsById[pipeline.id][segment.id]
            ? heartbeatsById[pipeline.id][segment.id]
            : null;

        const isConnected = latestHeartbeat;

        if (isConnected) connectedCount = connectedCount + 1;
        if (!isConnected) disconnectedCount = disconnectedCount + 1;

        return {
          ...segment,
          connected: !!isConnected
        };
      })
    };
  });

  const heartbeatStatus = getCurrentHeartbeatStatus({
    error: isError,
    loading: isLoading,
    connectedCount,
    disconnectedCount
  });

  return {
    status: heartbeatStatus,
    data: heartbeatData,
    connectedCount,
    disconnectedCount
  };
};
