import React, { useState } from "react";
import styles from "./index.module.scss";
import Select from "../../Dropdown/Select";
import RecentSearchItem from "../RecentSearchItem";
import { SearchItem } from "../../../types/AnalysisTypes";
import { Heading } from "../../Type";
import EmptyListState from "../../EmptyListState";
import { OptionType } from "../../../types/GeneralTypes";

export const SEARCH_HISTORY_LENGTH = 10;

export const getSearchHistory = (): Array<SearchItem> => {
  const lsOrg = localStorage.getItem("hifi:activeOrg");

  if (lsOrg) {
    const recentAreas = localStorage.getItem(`hifi:${lsOrg}:searchHistory`);
    if (recentAreas) {
      // uses `filter` to remove entries with no uri because that causes the whole page to crash
      return JSON.parse(recentAreas).filter((s: SearchItem) => s.uri);
    }
  }

  return [];
};

export const setSearchHistory = (searchHistory: Array<SearchItem>) => {
  const lsOrg = localStorage.getItem("hifi:activeOrg");
  if (lsOrg) {
    localStorage.setItem(
      `hifi:${lsOrg}:searchHistory`,
      JSON.stringify(searchHistory)
    );
  }
};

export const addSearchHistory = (searchItem: SearchItem) => {
  let searches = getSearchHistory();

  if (searches.length) {
    const existingSearchIndex = searches.findIndex(
      s => s.uri.toLowerCase() === searchItem.uri.toLowerCase()
    );
    // changes timestamp of an identical search (if exists) to the current time, otherwise adds new search
    existingSearchIndex > -1
      ? (searches[existingSearchIndex].timestamp = Date.now())
      : searches.unshift(searchItem);
  } else {
    searches = [searchItem];
  }

  return searches.slice(0, SEARCH_HISTORY_LENGTH);
};

const RecentAreas = () => {
  const areas: Array<SearchItem> = getSearchHistory();

  const updateLocalStorage = () => setSearchHistory(areas);

  const sortOptions: Array<OptionType> = [
    { value: "recent", label: "Recently Used" },
    { value: "asc", label: "Ascending Start" },
    { value: "desc", label: "Descending Start" }
  ];
  const [currentSort, setCurrentSort] = useState<OptionType>(sortOptions[0]);

  const sortAreas = (sort: OptionType, items: Array<SearchItem>) => {
    if (sort.value === "asc") {
      return items.sort((a, b) => a.rangeMin - b.rangeMin);
    } else if (sort.value === "desc") {
      return items.sort((a, b) => b.rangeMin - a.rangeMin);
    } else {
      // sort.value === "recent", or otherwise
      return items.sort((a, b) => b.timestamp - a.timestamp);
    }
  };

  const sortedAreas = sortAreas(currentSort, areas);

  return sortedAreas && sortedAreas.length ? (
    <>
      <div className={styles.resultsHeading}>
        <Heading size="h6">Recent Areas</Heading>
        <Select
          small
          label="Recently Used"
          hideLabel
          items={sortOptions}
          initial={currentSort}
          onChange={setCurrentSort}
        />
      </div>
      {sortedAreas.map((item: SearchItem, index: number) => {
        return (
          <RecentSearchItem
            to={item.uri}
            key={index}
            searchItem={item}
            active={!index}
            updateLocalStorage={updateLocalStorage}
          />
        );
      })}
    </>
  ) : (
    <EmptyListState
      loading={false}
      text="No Recent Searches"
      description={
        "Run a search to see a list of recently\nsearched areas to make it easy to\nreturn to areas of interest"
      }
    />
  );
};

export default RecentAreas;
