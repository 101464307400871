import React from "react";

const DEFAULT_SIZE = 20;

type PropsType = {
  size?: number;
};

const Arrow = ({ size = DEFAULT_SIZE }: PropsType) => (
  <svg
    width={`${size}px`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 13.563l-6.25-6.25.875-.875L10 11.813l5.375-5.376.875.875-6.25 6.25z"
      fill="currentColor"
    />
  </svg>
);

export default Arrow;
