import React from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";
import { Secondary } from "../../../Button";
import { Heading, Text, Meta } from "../../../Type";
import { File } from "../../../Icons";
import SignatureCard from "../SignatureCard";
import DateTime from "../../../DateTime";
import { analytics } from "../../../../analytics";
import styles from "./index.module.scss";

const EventSignatureField = ({
  label,
  children
}: {
  label: string;
  children: React.ReactNode;
}) => {
  return (
    <div className={styles.SignatureField}>
      <Meta component="h5" size="small" dark>
        {label}
      </Meta>
      <Text className={styles.SignatureFieldValue}>{children}</Text>
    </div>
  );
};

const EventSignatureFile = ({
  eventSignature
}: {
  eventSignature: gqlType.EventSignature;
}) => {
  return (
    <SignatureCard border>
      <div className={styles.Content}>
        <div className={cx(styles.SignatureCardGroup, styles.FileNameGroup)}>
          <File />
          <Heading size="h7" className={styles.FileName}>
            {eventSignature.name}
          </Heading>
        </div>
        <div className={cx(styles.SignatureCardGroup, styles.FileDetailsGroup)}>
          <EventSignatureField label="DATE">
            <DateTime timestamp={eventSignature.timestamp} length="short" />
          </EventSignatureField>
          <EventSignatureField label="FILE">
            {eventSignature.type}
          </EventSignatureField>
        </div>
        <div
          className={cx(styles.SignatureCardGroup, styles.DownloadButtonGroup)}
        >
          <a
            className={styles.DownloadWrapper}
            href={eventSignature.dataFileUri}
            download
            onClick={() => {
              analytics.eventFileSignatureDownloaded({
                eventId: eventSignature.eventId,
                signatureId: eventSignature.id
              });
            }}
          >
            <Secondary
              className={styles.DownloadButton}
              type="button"
              component="div"
            >
              Download
            </Secondary>
          </a>
        </div>
      </div>
    </SignatureCard>
  );
};

export default EventSignatureFile;
