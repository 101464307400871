import React from "react";
import { File } from "../../Icons";
import { Heading, Text, Meta } from "../../Type";
import { formatBytes } from "../../../helpers/reports";
import styles from "./index.module.scss";
import DateTime from "../../DateTime";

type PropsType = {
  file: { title: string; sizeInByte: number; contentType: string };
};

const FileDetails = ({ file }: PropsType) => {
  const UNIX_MULTIPLIER = 1000;
  const ts = Math.round(new Date().getTime() / UNIX_MULTIPLIER);

  const { title, contentType, sizeInByte } = file;
  return (
    <div className={styles.FileDetails}>
      <div className={styles.File}>
        <File size={24} />
      </div>
      <div>
        <Heading size="h7" className={styles.FileTitle}>
          {title}
        </Heading>
        <div className={styles.Details}>
          <div className={styles.Date}>
            <Meta dark size="small">
              Date
            </Meta>
            <Text className={styles.Text}>
              <DateTime timestamp={ts} length="short" />
            </Text>
          </div>
          <div>
            <Meta dark size="small">
              File
            </Meta>
            <Text className={styles.Text}>
              {contentType} - {formatBytes(sizeInByte)}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileDetails;
