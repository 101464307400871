import React, { useState } from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";
import { NavLink } from "react-router-dom";

import UpdateArchivedStatusButton from "./UpdateArchiveStatusButton";
import ShareDialog from "../../ShareDialog";
import {
  Close,
  IconByType,
  IconCircleWrapper,
  Share,
  Trash,
  Ellipses,
  PigNav
} from "../../Icons";
import { Primary, Secondary, Tertiary, Important } from "../../Button";
import { Text, Heading } from "../../Type";
import { analytics } from "../../../analytics";
import MobileMenu from "./MobileMenu";

import { useEventDetailContext } from "../../../contexts/eventDetailProvider";

import getPriorityLevel from "../../../helpers/getPriorityLevel";
import { getStatusText } from "../../../helpers/pigStatus";

import { priorityColorMap } from "../../../styles/colors";
import { useAuth } from "../../../contexts/AuthProvider";

import styles from "./index.module.scss";

type PropsType = {
  eventDetailsRef: React.RefObject<HTMLDivElement>;
  returnUrl: string;
  openDeleteModal: (event: gqlType.EventNotification) => void;
};

const PigTrackingLink = ({ event }: { event: gqlType.EventNotification }) => {
  if (
    event &&
    event.type === "pig" &&
    event.eventMeta &&
    event.eventMeta.runId
  ) {
    return (
      <NavLink
        to={`/pig-tracking/active/${event.pipeline.id}_${event.eventMeta.runId}`}
        className={styles.ButtonTracking}
      >
        <div className={styles.DropdownMainContent}>
          <div className={styles.IconWrapper}>
            <PigNav size={20} />
          </div>
          <Text component="span" className={styles.DropdownLabel}>
            View Live Tracking
          </Text>
        </div>
        <Primary
          component="div"
          buttonSize="small"
          className={styles.PigLinkButton}
        >
          View Live
        </Primary>
      </NavLink>
    );
  }
  return null;
};

const Header = ({ eventDetailsRef, returnUrl, openDeleteModal }: PropsType) => {
  const [shareOpen, setShareOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { event } = useEventDetailContext();
  const eventType = event ? event.type : "";
  const eventStatus = event && event.eventMeta ? event.eventMeta.status : "";
  const eventPriority = getPriorityLevel(eventStatus || eventType);
  const eventPriorityColor = priorityColorMap[eventPriority];
  const { permissions } = useAuth();

  const toggleShare = () => {
    if (event) {
      analytics.eventShareDialogueOpen(event.id);
      setShareOpen(open => {
        return !open;
      });
    }
  };

  return (
    <div className={styles.Header}>
      <div className={styles.TitleGroup}>
        {event && (
          <>
            <div className={styles.TitleWrapper}>
              <span className={styles.Icon}>
                <IconCircleWrapper
                  size={24}
                  childSize={12}
                  iconColor={eventPriorityColor}
                >
                  <IconByType type={event.type} size={12} />
                </IconCircleWrapper>
              </span>
              <Heading className={styles.Type} size="h6">
                {event.type} {eventStatus ? getStatusText(eventStatus) : ""}
              </Heading>
            </div>
            <div className={styles.TitleWrapper}>
              <Text size="small" className={styles.EventId} disambiguation>
                {event.referenceId}
              </Text>
            </div>
          </>
        )}
      </div>
      <div className={styles.ButtonGroup}>
        <button
          aria-label="Menu"
          type="button"
          className={styles.ButtonMenu}
          onClick={() => event && setMobileMenuOpen(!mobileMenuOpen)}
        >
          <Ellipses size={24} />
        </button>
        {event && (
          <MobileMenu
            items={[
              {
                label: "Delete",
                icon: <Trash size={20} />,
                onClick: () => event && openDeleteModal(event),
                hide: !permissions.user.admin
              },
              {
                component: (
                  <UpdateArchivedStatusButton
                    className={cx(styles.Button, styles.ButtonArchive)}
                    mobileMenu
                    key={`archive-${event.referenceId}`}
                    referenceId={event.referenceId}
                  />
                )
              },
              {
                component:
                  event.type === "pig" ? (
                    <PigTrackingLink
                      event={event}
                      key={`link-${event.referenceId}`}
                    />
                  ) : null
              }
            ]}
            referenceId={event.referenceId}
            className={cx(
              styles.MobileMenu,
              mobileMenuOpen && styles.MobileMenuOpen
            )}
          />
        )}

        <div
          className={cx(
            styles.SecondaryButtons,
            mobileMenuOpen && styles.SecondaryButtonsOpen
          )}
        >
          {event && (
            <>
              <PigTrackingLink event={event}></PigTrackingLink>
              <UpdateArchivedStatusButton
                className={cx(styles.Button, styles.ButtonArchive)}
                referenceId={event.referenceId}
              />
            </>
          )}
          {permissions.user["view-admin-event"] ? (
            <Important
              title="Delete"
              buttonSize="small"
              className={cx(styles.Button, styles.ButtonDelete)}
              onClick={() => {
                if (event) {
                  openDeleteModal(event);
                }
              }}
              text="Delete"
              icon={<Trash />}
              square
            />
          ) : null}

          <Secondary
            className={cx(styles.Button, styles.ButtonShare)}
            buttonSize="small"
            onClick={toggleShare}
            title="Share"
            text="Share"
            icon={<Share />}
            square
          />
        </div>
        <NavLink to={returnUrl}>
          <Tertiary
            title="Close"
            buttonSize="small"
            className={cx(styles.Button, styles.ButtonClose)}
            component="span"
            text="Close"
            square
            icon={<Close />}
          />
        </NavLink>
      </div>
      {shareOpen && event && (
        <ShareDialog
          eventDetailsRef={eventDetailsRef}
          referenceId={event.referenceId}
          eventId={event.id}
          onClose={() => setShareOpen(false)}
          label="Event URL"
        />
      )}
    </div>
  );
};

export default Header;
