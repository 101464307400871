import React, { useMemo } from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";
import { useHistory, useRouteMatch } from "react-router-dom";

import TrainSummary from "./TrainSummary";
import EmptyListState from "../EmptyListState";
import EventMap from "../EventMap";
import TrainUpdateDetails from "../TrainUpdateDetails";
import DetailsModal from "../DetailsModal";

import styles from "./index.module.scss";
import { useSelectedTrain } from "./useSelectedTrain";

type PropsType = {
  trainRun?: gqlType.TrainRun;
  loading: boolean;
  completed: boolean;
};

const TrainDetailsSection = ({ trainRun, loading, completed }: PropsType) => {
  const { updateId } = useSelectedTrain();
  const match = useRouteMatch();
  const history = useHistory();

  const updatesWithPipelineRunId = useMemo(() => {
    if (trainRun) {
      const updates = completed ? trainRun.updates : [trainRun.updates[0]];
      return updates.map(update => ({
        ...update,
        runId: trainRun.runId,
        pipeline: trainRun.pipeline,
        trainCompleted: completed
      }));
    }
    return [];
  }, [completed, trainRun]);

  const toUrlWithSearch = `${match.url}${history.location.search}`;

  return (
    <div
      className={cx(styles.Container, {
        [styles.ContainerLoading]: !trainRun || loading
      })}
    >
      <div className={styles.ListMapWrapper}>
        <div className={styles.List}>
          {!trainRun || loading ? (
            <EmptyListState loading={loading} text="Loading..." />
          ) : (
            <TrainSummary trainRun={trainRun} />
          )}
        </div>
        <div className={styles.MapModalWrapper}>
          <div className={styles.MapContainer}>
            {trainRun && (
              <EventMap
                canSearchOnMapMove={false}
                events={updatesWithPipelineRunId}
                scrollZoom
                focusPipelines={[trainRun.pipeline.id]}
                trainMode
              />
            )}
          </div>
          {updateId && trainRun ? (
            <DetailsModal id={updateId} returnUrl={toUrlWithSearch}>
              <TrainUpdateDetails
                updateId={updateId}
                train={trainRun}
                returnUrl={toUrlWithSearch}
              />
            </DetailsModal>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TrainDetailsSection;
