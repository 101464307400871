import { DecodedTokenType, IPermissions } from "../types/AuthTypes";

export const parseTokenPermissions = (decodedToken: DecodedTokenType) => {
  const permissions: IPermissions = {
    user: {},
    org: {}
  };

  if (
    decodedToken.hasOwnProperty("permissions") &&
    Array.isArray(decodedToken.permissions)
  ) {
    decodedToken.permissions.map(permission => {
      const permissionElements = permission.split(":");
      const permissionCategory = permissionElements[0] as "user" | "org";
      const permissionName = permissionElements.splice(1).join(":"); // Covers case where the permission value includes a ":" character. (ie "category:permission:value")

      permissions[permissionCategory] = {
        ...permissions[permissionCategory],
        [permissionName]: true
      };
    });
  }

  return permissions;
};
