import React from "react";

const DEFAULT_SIZE = 20;

type PropsType = {
  size?: number;
};

const Eye = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 16"
    >
      <g transform="translate(0.508 0.417)" fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M9.75.433a10.849 10.849 0 019.711 6.93.65.65 0 010 .441 10.849 10.849 0 01-9.711 6.93 10.849 10.849 0 01-9.711-6.93.65.65 0 010-.442A10.849 10.849 0 019.75.433zm0 1.3c-3.445 0-7.085 2.555-8.405 5.85 1.32 3.296 4.96 5.85 8.405 5.85 3.445 0 7.085-2.554 8.405-5.85-1.32-3.295-4.96-5.85-8.405-5.85zm0 1.95a3.9 3.9 0 110 7.8 3.9 3.9 0 010-7.8zm0 1.3a2.6 2.6 0 100 5.2 2.6 2.6 0 000-5.2z"
        />
      </g>
    </svg>
  );
};

export default Eye;
