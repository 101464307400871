import React from "react";
import { MultiSelect, IMultiSelectProps } from "../MultiSelect";
import { OptionType } from "../../types/GeneralTypes";

// This component is a wrapper around the MultiSelect component, enabling it to
// be used in Contexts that store the selected items as an array of Strings.
// If possible, using a Context that stores selected items as an array of OptionType
// will enable you to just use MultiSelect instead of this GenericFilter.

interface IGenericFilterProps
  extends Omit<IMultiSelectProps, "selections" | "setSelections"> {
  selectedItems: Array<string>;
  setSelectedItems: (selectedItems: Array<string>) => void;
}

const GenericFilter = ({
  options,
  selectedItems,
  setSelectedItems,
  ...props
}: IGenericFilterProps) => {
  const selections = options.filter(option =>
    selectedItems.includes(option.value)
  );

  const setSelections = (selections: Array<OptionType>) => {
    const selectedValues = selections.map(option => option.value);
    return setSelectedItems(selectedValues);
  };

  return (
    <MultiSelect
      options={options}
      selections={selections}
      setSelections={setSelections}
      {...props}
    />
  );
};

export default GenericFilter;
