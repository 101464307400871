import React, { useEffect } from "react";
import moment from "moment";
import { Secondary, Primary, TextLink } from "../../Button";
import Modal from "../../Modal";
import { OptionType } from "../../../types/GeneralTypes";

import { Close } from "../../Icons";
import { useModalContext } from "../../../contexts/ModalProvider";
import { useHistoryFiltersContext } from "../HistoryFiltersProvider";
import PipelineFilter from "../MobilePipelineFilter";
import EventTypesFilter from "../MobileEventTypesFilter";
import DatePicker from "../../DatePicker";

import { breakpoints } from "../../../styles/variables";
import styles from "./index.module.scss";
import cx from "classnames";
import { analytics } from "../../../analytics";
import ResultsCount from "../../ResultsCount";
import Select from "../../Dropdown/Select";
import { eventsLimit } from "../../../helpers/pagination";

const MobileFilters = ({
  eventsCount,
  totalEvents
}: {
  eventsCount: number;
  totalEvents: number;
}) => {
  const { setOpenModal, openModal } = useModalContext();
  const {
    selectedPipelines,
    selectedEventTypes,
    resetAllFilters,
    pipelineOptions,
    startDate,
    endDate,
    dateRange,
    applyDateRange,
    updateDateRange,
    sortByOptions,
    sortBy,
    setSortBy,
    page
  } = useHistoryFiltersContext();
  const modalName = "history-filters";

  const activeFiltersCount = React.useMemo(() => {
    return (
      selectedPipelines.length +
      selectedEventTypes.length +
      (startDate && endDate ? 1 : 0)
    );
  }, [selectedPipelines, selectedEventTypes, startDate, endDate]);

  const resetFilters = () => {
    resetAllFilters();
  };

  useEffect(() => {
    const handleResize = () => {
      if (openModal === modalName && window.screen.width > breakpoints.large) {
        setOpenModal("");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setOpenModal, openModal]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.MobileFilterBar}>
        <Secondary
          className={cx(styles.FilterButton, {
            [styles.FilterButtonHasActive]: activeFiltersCount > 0
          })}
          onClick={() => {
            setOpenModal(modalName);
            analytics.historyMobileFiltersOpened();
          }}
        >
          <span className={styles.FilterButtonContent}>
            Filters{" "}
            <span className={styles.FiltersCount}>{activeFiltersCount}</span>
          </span>
        </Secondary>

        <ResultsCount
          totalEvents={totalEvents}
          eventsCount={eventsCount}
          page={page}
          className={styles.ResultsCount}
          limit={eventsLimit}
        />
        <Select
          label={"Sort By:"}
          hideLabel
          items={sortByOptions}
          onChange={(sort: OptionType) => setSortBy(sort.value)}
          initial={
            sortByOptions.find(option => sortBy === option.value) || {
              value: "",
              label: ""
            }
          }
        />
      </div>

      <Modal overlayClassName={styles.Overlay} isOpen={openModal === modalName}>
        <div className={styles.ModalControls}>
          <TextLink
            type="button"
            onClick={() => {
              resetFilters();
            }}
            linkStyle="secondary"
          >
            Clear All
          </TextLink>
          <button
            className={styles.Close}
            type="button"
            onClick={() => setOpenModal("")}
          >
            <Close />
          </button>
        </div>
        <div className={styles.FiltersList}>
          <div className={styles.Filter}>
            <DatePicker
              initialVisibleMonth={() => moment().subtract(1, "month")}
              rangeSelect
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onDatesChange={range => {
                updateDateRange(range);
                if (range.endDate && range.startDate) {
                  applyDateRange(range);
                }
              }}
            />
          </div>

          {pipelineOptions.length > 1 ? (
            <div className={styles.Filter}>
              <PipelineFilter />
            </div>
          ) : null}

          <div className={styles.Filter}>
            {" "}
            <EventTypesFilter />
          </div>

          <Primary
            onClick={() => setOpenModal("")}
            disabled={eventsCount === 0}
            className={styles.ShowButton}
          >
            {eventsCount > 0
              ? `Show ${eventsCount} archived events`
              : "0 filter results"}
          </Primary>
        </div>
      </Modal>
    </div>
  );
};

export default MobileFilters;
