import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Seismic = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 16 12">
      <path
        fill="currentColor"
        d="M13.34249.03v.03c2.92841 3.46816 2.81587 8.57423-.2625 11.91l-1.1025-1.05c2.56348-2.77975 2.65671-7.0331.2175-9.9225L13.34249.03zM2.6575.03l1.1475.9675c-2.43921 2.8894-2.34598 7.14275.2175 9.9225l-1.1025 1.05C-.15836 8.63423-.2709 3.52816 2.65751.06V.03zM8 2.6965c1.82447 0 3.3035 1.47903 3.3035 3.3035S9.82446 9.3035 8 9.3035 4.6965 7.82446 4.6965 6 6.17554 2.6965 8 2.6965z"
      />
    </svg>
  );
};

export default Seismic;
