/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from "react";
import { gqlType } from "@hifieng/common";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { useOrganizationContext } from "../../../contexts/OrganizationProvider";
import { CSVLink } from "react-csv";

import styles from "./index.module.scss";
import { Table, Row, Head, Cell } from "../../Table";
import { TextLink, Primary } from "../../Button";
import { ChevronRight, Download } from "../../Icons";
import Completed from "../PigRun/Completed";
import EmptyListState from "../../EmptyListState";
import PaginationButtons from "../../PaginationButtons";

import formatCompletedRuns from "./formatCompletedRuns";
import { useCompletedPigs } from "./useCompletedPigs";
import DesktopFilters from "./DesktopFilters";
import MobileFilters from "./MobileFilters";

import { usePigFiltersContext } from "../PigFiltersProvider";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { pigsLimit } from "../../../helpers/pagination";
import { useMemoizedTimes } from "../../../hooks/useMemorizedTimes";
import LoadingWrapper from "../../LoadingSpinnerWrapper";
import { ITabContent } from "../../TabbedContent";

interface ICompletedPigsTabContent extends ITabContent {
  loading: boolean;
  empty?: boolean;
}

const TABLE_HEADERS = [
  "name",
  "pig type",
  "start",
  "end",
  "updates",
  "critical events"
];

const CompletedRuns = ({ empty, onActivated }: ICompletedPigsTabContent) => {
  const { activeOrg } = useOrganizationContext();
  const { setQueryParams, queryParams } = useQueryParams();

  const history = useHistory();
  const [exporting, setExporting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const {
    sortBy,
    selectedPigTypes,
    selectedUpdatesTypes,
    resetAllFilters,
    page,
    selectedPipelines
  } = usePigFiltersContext();
  const updatesType = selectedUpdatesTypes.length
    ? (selectedUpdatesTypes as Array<gqlType.UpdatesType>)
    : undefined;
  const types = selectedPigTypes.length
    ? (selectedPigTypes as Array<gqlType.PigType>)
    : undefined;
  const { start, end } = queryParams;

  const timeRange = useMemoizedTimes(
    start,
    end,
    activeOrg && activeOrg.utcOffset
  );

  const csvExportLimit = parseInt(process.env.CSV_EXPORT_LIMIT || "0", 0);

  const filters = {
    updatesType,
    types,
    timeRange,
    sortBy: (sortBy as gqlType.SortByTypes) || gqlType.SortByTypes.TimeDesc,
    limit: exporting ? csvExportLimit : pigsLimit,
    offset: exporting ? 0 : (page - 1) * pigsLimit,
    pipelines: selectedPipelines.length ? selectedPipelines : undefined
  };
  const { pigRuns, loading, total } = useCompletedPigs(filters);
  const match = useRouteMatch();

  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  useEffect(() => {
    if (
      exporting &&
      pigRuns &&
      activeOrg &&
      !loading &&
      !downloading &&
      pigRuns.pigs.length === total
    ) {
      setDownloading(true);
    }
  }, [exporting, pigRuns, activeOrg, loading, total, downloading]);

  useEffect(() => {
    if (csvLink && csvLink.current && downloading) {
      csvLink.current.link.click();
      setExporting(false);
      setDownloading(false);
    }
  }, [downloading]);

  if (!activeOrg || !pigRuns) {
    return <LoadingWrapper loading />;
  }

  const runs: Array<gqlType.PigRun> = pigRuns ? pigRuns.pigs : [];

  const mappedPigs = formatCompletedRuns(runs, {
    timezoneAbbr: activeOrg.timezoneAbbr,
    utcOffset: activeOrg.utcOffset
  });

  const csvData = mappedPigs.map(pig => [...pig.data, pig.auditLogs]);

  const noMatch = pigRuns && runs.length === 0;

  const handleRowClick = (url: string) => {
    history.push(url);
  };

  const getCSVData = () => {
    if (!exporting) {
      setExporting(true);
      return false;
    } else {
      return downloading;
    }
  };

  if (onActivated) onActivated({ title: "completed" });

  if (empty) {
    return (
      <EmptyListState
        loading={loading}
        text="No Completed Runs to Review"
        description={`Completed runs will show up once a pig has been\nthrough the tracked segment of the pipeline.`}
      />
    );
  }

  return (
    <>
      <DesktopFilters totalEvents={total} eventsCount={runs.length} />
      <MobileFilters eventsCount={runs.length} totalEvents={total} />
      {noMatch ? (
        <div className={styles.NoMatchWrapper}>
          <EmptyListState
            loading={loading}
            text="No Matches"
            description={`We couldn't find any completed pig runs that\nmatch your selected filters.`}
            cta={<Primary onClick={resetAllFilters}>Clear Filters</Primary>}
          />
        </div>
      ) : (
        <div className={styles.TableWrapperDesktop}>
          <Table caption="Completed Pig Runs">
            <Row>
              {[...TABLE_HEADERS, ""].map(header => (
                <Head key={header}>{header}</Head>
              ))}
            </Row>
            <>
              {mappedPigs.map(pig => (
                <Row
                  key={`row_${pig.id}`}
                  onClick={() => handleRowClick(`${match.url}/${pig.id}`)}
                >
                  <>
                    {pig.data.map((data, i) => (
                      <Cell key={`${TABLE_HEADERS[i]}_${pig.id}`}>{data}</Cell>
                    ))}
                  </>
                  <Cell>
                    <NavLink
                      to={`${match.url}/${pig.id}`}
                      className={styles.LinkWrapper}
                    >
                      <TextLink
                        component="span"
                        icon={<ChevronRight />}
                        linkStyle="secondary"
                      >
                        {`${pig.numAuditLogs} Notes`}
                      </TextLink>
                    </NavLink>
                  </Cell>
                </Row>
              ))}
            </>
          </Table>
        </div>
      )}
      <div className={styles.TableWrapperMobile}>
        {runs.map(run => (
          <Completed pigRun={run} key={run.id} url={`${match.url}/${run.id}`} />
        ))}
      </div>
      {!noMatch && (
        <div>
          <PaginationButtons
            updatePage={page => setQueryParams({ page }, "push")}
            currentPage={page || 1}
            totalEvents={total}
            limit={pigsLimit}
          />
          <Primary
            component="div"
            icon={<Download />}
            className={styles.DownloadLink}
          >
            <CSVLink
              data={csvData}
              headers={[...TABLE_HEADERS, "notes"]}
              filename="filtered-completed-pig-runs.csv"
              onClick={getCSVData}
              ref={csvLink}
            >
              Export Filtered Data
            </CSVLink>
          </Primary>
        </div>
      )}
    </>
  );
};

export default CompletedRuns;
