import React, { useEffect, useState } from "react";

import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import { googleMapStyles } from "./googleMapStyles";

import "./overrides.scss";
import { gqlType } from "@hifieng/common";

export type LatLngBoundsLiteral = google.maps.LatLngBoundsLiteral;

export type MapRenderProps = {
  getMapBounds: () => gqlType.GeoBoundsInput | null;
  Point: (x: number, y: number) => google.maps.Point;
  LatLng: (x: number, y: number) => google.maps.LatLng;
  zoomLevel: number;
};

type PropsType = {
  googleMapURL: string;
  loadingElement: React.ReactElement;
  containerElement: React.ReactElement;
  mapElement: React.ReactElement;
  children: (props: MapRenderProps) => React.ReactNode | Array<React.ReactNode>;
  defaultCenter?: {
    lat: number;
    lng: number;
  };
  fitBounds?: LatLngBoundsLiteral;
  onBoundsChanged?: (arg0: gqlType.GeoBoundsInput | null) => void;
  scrollZoom?: boolean;
};

const DEFAULT_ZOOM = 10;

const Map = withScriptjs(
  withGoogleMap(
    ({
      children,
      fitBounds,
      onBoundsChanged,
      defaultCenter = { lat: 0, lng: 0 },
      scrollZoom
    }: PropsType) => {
      const [zoomLevel, setZoomLevel] = useState<number>(DEFAULT_ZOOM);
      const zoomControlPosition = window.google.maps.ControlPosition.RIGHT_TOP;
      const mapTypeControlPosition =
        window.google.maps.ControlPosition.TOP_RIGHT;
      const mapTypeControlStyle =
        window.google.maps.MapTypeControlStyle.DEFAULT;

      // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
      const defaultOptions = {
        styles: googleMapStyles,
        fullscreenControl: false,
        scaleControl: true,
        streetViewControl: false,
        mapTypeControlOptions: {
          position: mapTypeControlPosition,
          style: mapTypeControlStyle
        },
        zoomControlOptions: {
          position: zoomControlPosition
        },
        tilt: 0, // Disables 3d mode
        gestureHandling: scrollZoom
          ? ("greedy" as google.maps.GestureHandlingOptions)
          : ("cooperative" as google.maps.GestureHandlingOptions)
      };
      const mapRef = React.useRef<GoogleMap>();

      const handleZoomChange = () => {
        if (mapRef && mapRef.current) {
          const zoom = mapRef.current.getZoom();
          setZoomLevel(zoom);
        }
      };

      const getMapBounds = (): gqlType.GeoBoundsInput | null => {
        if (mapRef && mapRef.current) {
          let bounds;
          if (mapRef.current.getBounds()) {
            bounds = mapRef.current.getBounds().toJSON();

            return {
              sw: {
                lat: bounds.south,
                long: bounds.west
              },
              ne: {
                lat: bounds.north,
                long: bounds.east
              }
            };
          }
        }
        return null;
      };

      const handleBoundsChange = () => {
        const bounds = getMapBounds();
        if (onBoundsChanged && bounds) {
          onBoundsChanged(bounds);
        }
      };

      useEffect(() => {
        if (mapRef && mapRef.current && fitBounds) {
          mapRef.current.fitBounds(fitBounds);
        }
      }, [fitBounds, mapRef]);

      return (
        <GoogleMap
          defaultZoom={zoomLevel}
          defaultCenter={defaultCenter}
          defaultOptions={defaultOptions}
          onZoomChanged={handleZoomChange}
          onBoundsChanged={handleBoundsChange}
          ref={map => {
            if (map) {
              mapRef.current = map;
            }
          }}
        >
          {children({
            getMapBounds,
            Point: (x: number, y: number) => new window.google.maps.Point(x, y),
            LatLng: (x: number, y: number) => {
              return new window.google.maps.LatLng(x, y);
            },
            zoomLevel
          })}
        </GoogleMap>
      );
    }
  )
);

export default Map;
