import React, { useState, useMemo, useRef } from "react";
import debounce from "lodash.debounce";
import { gqlType } from "@hifieng/common";
import { Polyline, Circle } from "react-google-maps";
import useDeepCompareEffect from "use-deep-compare-effect";
import Map, { LatLngBoundsLiteral, MapRenderProps } from "../Map";
import AssetMarkers from "./AssetMarkers";
import PostMarkers from "./PostMarkers";
import { PostMarkerData, CoordinatePoints } from "../../types/EventMap";
import EventMapButtons from "./EventMapButtons";
import CheckboxGroup from "../CheckboxGroup";
import Spiderfy from "./Spiderfy";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import {
  boundsToQueryParams,
  getGeoBounds,
  queryParamsToMapBounds
} from "../../helpers/eventMap";
import { getEnvVariable } from "../../helpers/getEnvVariable";
import { breakpoints } from "../../styles/variables";
import { color, pipelineColorMap } from "../../styles/colors";
import styles from "./index.module.scss";
import { analytics } from "../../analytics";
import PipelineLegend from "./PipelineLegend";
import {
  formatMapEvent,
  PigUpdateWithPipelineRunId,
  TrainUpdateWithPipelineRunId
} from "../../helpers/formatMapEvent";
import { SearchRange } from "../../types/AnalysisTypes";
import { HALF } from "../../helpers/math";
import { useAuth } from "../../contexts/AuthProvider";

type PropsType = {
  events: Array<
    | gqlType.EventNotification
    | gqlType.PigRun
    | PigUpdateWithPipelineRunId
    | gqlType.TrainRun
    | TrainUpdateWithPipelineRunId
  >;
  canSearchOnMapMove: boolean;
  isSearching?: boolean;
  scrollZoom?: boolean;
  focusPipelines?: [string];
  hideLegend?: boolean;
  disableEventIconSelection?: boolean;
  circledRange?: SearchRange; //start and end KPs of range to highlight,
  trainMode?: boolean;
};

type SelectedOptionsType = Array<string>;

const initialSelectedOptions = ["pipelines", "assets", "posts", "range"];

const MAX_ZOOM_LEVEL_ASSETS = 10;
const MAX_ZOOM_LEVEL_POSTS = 12;
const DEFAULT_ICON_SIZE = 40;
const searchDebounceTime = 1000;

const polylineOptions = {
  strokeWeight: 5,
  clickable: false
};

const searchLineOptions = {
  ...polylineOptions,
  strokeColor: pipelineColorMap.white
};

const searchCircleOptions = {
  strokeColor: pipelineColorMap.white,
  strokeWeight: 2,
  fillColor: pipelineColorMap.white,
  fillOpacity: 0.1
};

const EventMap = ({
  events,
  canSearchOnMapMove,
  isSearching,
  scrollZoom,
  focusPipelines,
  hideLegend,
  disableEventIconSelection,
  circledRange,
  trainMode
}: PropsType) => {
  const { setQueryParams, queryParams } = useQueryParams();

  const { activeOrg } = useOrganizationContext();
  const teckFlag = activeOrg !== undefined && activeOrg.id === "teck";
  const [searchOnMapMove, setSearchOnMapMove] = React.useState(true);
  const [fitBounds, setFitBounds] = useState<LatLngBoundsLiteral>();
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionsType>(
    initialSelectedOptions
  );
  const [mapChangeCountSinceReset, setMapChangeCountSinceReset] = useState(0);
  const [focusFlag, setFocusFlag] = useState(false);

  const pipelines = activeOrg ? activeOrg.pipelines : [];

  const mapChangedSinceReset = mapChangeCountSinceReset > 1;

  const rangeOption = {
    label: "Selected Range",
    value: "range"
  };

  const { permissions } = useAuth();

  let postLabel: string;
  if (permissions.user.imperial) {
    //enbridge case
    postLabel = "Mile Posts";
  } else if (activeOrg && activeOrg.id === "enbridge") {
    postLabel = "Posts";
  } else {
    postLabel = "KM Posts";
  }

  const defaultOptions = [
    {
      label: trainMode ? "Rails" : "Pipelines",
      value: "pipelines"
    },
    {
      label: "Assets",
      value: "assets"
    },
    {
      label: postLabel,
      value: "posts"
    }
  ];

  const mapOptions = circledRange
    ? [...defaultOptions, rangeOption]
    : defaultOptions;

  const setGeoBoundParams = (bounds: gqlType.GeoBoundsInput | undefined) => {
    if (canSearchOnMapMove && window.innerWidth > breakpoints.large) {
      if (bounds) {
        setQueryParams(boundsToQueryParams(bounds), "replace");
      } else {
        setQueryParams(
          {
            swLat: undefined,
            swLong: undefined,
            neLat: undefined,
            neLong: undefined
          },
          "replace"
        );
      }
    }
  };

  const toggleMapOption = (optionKey: string) => {
    setSelectedOptions(options => {
      const isSelected = options.includes(optionKey);
      const newSelectedOptions = isSelected
        ? options.filter(option => option !== optionKey)
        : [...options, optionKey];

      return newSelectedOptions;
    });
  };

  const resetPan = (pipelines: Array<gqlType.Pipeline>, focusFlag = false) => {
    // Array of points to be fit within the bounds of the pan/zoom action.
    const points: CoordinatePoints = [];
    pipelines;
    // const refetchPipelines = activeOrg ? activeOrg.pipelines : [];
    pipelines.forEach(pipeline => {
      if (!focusPipelines || focusPipelines.includes(pipeline.id)) {
        if (pipeline.kmPosts) {
          pipeline.kmPosts.forEach(post => points.push(post));
        }
      }
    });
    if (points.length) {
      const geoBounds = getGeoBounds(points);

      setFitBounds({
        north: geoBounds.ne.lat,
        east: geoBounds.ne.long,
        south: geoBounds.sw.lat,
        west: geoBounds.sw.long
      });
      setFocusFlag(focusFlag);
      setMapChangeCountSinceReset(0);
    }
  };

  // Debounced because we don't want to update the query params every time the map moves.
  // useRef because we want to make sure we use the most-recently-rendered debounced method,
  // otherwise we would call a newly created debounced method every time
  const onBoundsChanged = useRef(
    debounce((b: gqlType.GeoBoundsInput | null) => {
      if (b && searchOnMapMove) {
        setGeoBoundParams(b);
      }
      setMapChangeCountSinceReset(count => {
        return count + 1;
      });
    }, searchDebounceTime)
  );

  // Wait for pipelines to load. If there are query parameters,
  // use them to set the map bounds, otherwise use the bounds
  // generated by the pipeline points
  let initialFocusedPipeline: gqlType.Pipeline;
  pipelines.some(pipeline => {
    if (pipeline.interfaceOptions.initialFocus === true) {
      initialFocusedPipeline = pipeline;
      return true;
    }
    return false;
  });
  useDeepCompareEffect(() => {
    const { swLat, swLong, neLat, neLong } = queryParams;
    if (swLat && swLong && neLat && neLong) {
      setFitBounds(queryParamsToMapBounds({ swLat, swLong, neLat, neLong }));
      // setMapChangeCountSinceReset(0);
    } else if (pipelines.length) {
      if (initialFocusedPipeline !== undefined) {
        resetPan([initialFocusedPipeline], true);
      } else {
        resetPan(pipelines);
      }
    }
    // QueryParams is not a dependency for useEffect because the source of state
    // in this case is the map itself, not the URL. We should set that state when
    // the map loads or when the pipeline state changes but not when the query
    // parameters change, otherwise the application would be trying to sync the
    // two states, the map, and the URL. The URL should reflect the state, but
    // not try to keep the map in sync.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelines, focusPipelines]);

  // Calculate array of assets
  let assets: Array<gqlType.Asset> = [];
  if (pipelines.length) {
    pipelines.forEach(pipeline => {
      assets = [...assets, ...pipeline.assets];
    });
  }

  type PipelineCoordsType = {
    [pipelineId: string]: Array<{ lat: number; lng: number }>;
  };

  // Calculate array of all pipeline posts with injected post id and pipelineId
  const { posts, coords: pipelineCoords } = useMemo((): {
    posts: Array<PostMarkerData>;
    coords: PipelineCoordsType;
  } => {
    const posts: Array<PostMarkerData> = [];
    const coords: PipelineCoordsType = {};
    pipelines.forEach(pipeline => {
      pipeline.kmPosts.forEach(post => {
        posts.push({
          id: `${pipeline.id}-${post.post}`,
          pipelineId: pipeline.id,
          ...post
        });

        if (!coords[pipeline.id]) {
          coords[pipeline.id] = [];
        }

        coords[pipeline.id].push({
          lat: post.coordinates.latitude,
          lng: post.coordinates.longitude
        });
      });
    });
    return { posts, coords };
  }, [pipelines]);

  // computationally optimized Haversine formula
  const distanceFromLatLong = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) => {
    const p = 0.017453292519943295; // Math.PI / 180
    const c = Math.cos;
    const a =
      HALF -
      c((lat2 - lat1) * p) * HALF +
      c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p)) * HALF;
    const b = 12742000; // 2 * R * 1000m/km; R = 6371 km

    return b * Math.asin(Math.sqrt(a));
  };

  const getApproxSearchRadius = (
    pipeline: gqlType.Pipeline,
    startChannel: number,
    endChannel: number
  ) => {
    return pipelineCoords[pipeline.id][startChannel] !== undefined &&
      pipelineCoords[pipeline.id][endChannel] !== undefined
      ? HALF *
          distanceFromLatLong(
            pipelineCoords[pipeline.id][startChannel].lat,
            pipelineCoords[pipeline.id][startChannel].lng,
            pipelineCoords[pipeline.id][endChannel].lat,
            pipelineCoords[pipeline.id][endChannel].lng
          )
      : 0;
  };

  const getCenter = (
    pipeline: gqlType.Pipeline,
    startChannel: number,
    endChannel: number
  ) => {
    const coordsValid =
      pipelineCoords[pipeline.id][startChannel] &&
      pipelineCoords[pipeline.id][endChannel];
    const avg = (num1: number, num2: number) => {
      return HALF * (num1 + num2);
    };

    const centerLat = coordsValid
      ? avg(
          pipelineCoords[pipeline.id][startChannel].lat,
          pipelineCoords[pipeline.id][endChannel].lat
        )
      : 0;
    const centerLng = coordsValid
      ? avg(
          pipelineCoords[pipeline.id][startChannel].lng,
          pipelineCoords[pipeline.id][endChannel].lng
        )
      : 0;
    return { lat: centerLat, lng: centerLng };
  };

  const loadingStyle = {
    backgroundColor: color.darkMineShaft
  };

  const containerStyle = {
    backgroundColor: color.darkMineShaft
  };

  const mapEvents = useMemo(() => events.map(event => formatMapEvent(event)), [
    events
  ]);

  return (
    <>
      {pipelines.length ? (
        <Map
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${getEnvVariable(
            "REACT_APP_GOOGLE_MAPS_API_KEY"
          )}&v=3.40&libraries=geometry,drawing,places`}
          loadingElement={
            <div style={loadingStyle} className={styles.Loading} />
          }
          containerElement={
            <div style={containerStyle} className={styles.Container} />
          }
          mapElement={<div id="EventMap" className={styles.Map} />}
          fitBounds={fitBounds}
          onBoundsChanged={onBoundsChanged.current}
          scrollZoom={scrollZoom}
        >
          {({ zoomLevel, getMapBounds }: MapRenderProps) => {
            const updateQueryParams = () => {
              const bounds = getMapBounds();
              if (bounds) setGeoBoundParams(bounds);
            };

            return (
              <>
                <Spiderfy
                  zoomLevel={zoomLevel}
                  events={mapEvents}
                  setFitBounds={setFitBounds}
                  disableEventIconSelection={disableEventIconSelection}
                />
                {selectedOptions.includes("assets") &&
                  zoomLevel >= MAX_ZOOM_LEVEL_ASSETS &&
                  !teckFlag &&
                  assets && (
                    <AssetMarkers
                      assets={assets}
                      iconSize={DEFAULT_ICON_SIZE}
                    />
                  )}

                {selectedOptions.includes("posts") &&
                  posts &&
                  zoomLevel >= MAX_ZOOM_LEVEL_POSTS && (
                    <PostMarkers posts={posts} zoomLevel={zoomLevel} />
                  )}
                {selectedOptions.includes("pipelines") &&
                  pipelines.map(pipeline => {
                    return (
                      <Polyline
                        key={pipeline.id}
                        path={pipelineCoords[pipeline.id]}
                        options={{
                          strokeColor:
                            pipelineColorMap[pipeline.color] ||
                            pipelineColorMap.blue,
                          ...polylineOptions
                        }}
                      />
                    );
                  })}
                {circledRange &&
                  selectedOptions.includes("range") &&
                  focusPipelines &&
                  focusPipelines.length === 1 &&
                  pipelines.map(pipeline => {
                    return focusPipelines.includes(pipeline.id) ? (
                      <div key={pipeline.id}>
                        <Polyline
                          key={`${pipeline.id}-searchRange`}
                          path={pipelineCoords[pipeline.id].slice(
                            circledRange.start,
                            circledRange.end
                          )}
                          options={searchLineOptions}
                        />
                        <Circle
                          key={`${pipeline.id}-circle`}
                          center={getCenter(
                            pipeline,
                            circledRange.start,
                            circledRange.end
                          )}
                          radius={getApproxSearchRadius(
                            pipeline,
                            circledRange.start,
                            circledRange.end
                          )}
                          options={searchCircleOptions}
                        />
                      </div>
                    ) : null;
                  })}
                <div className={styles.MapOptions}>
                  <CheckboxGroup
                    small
                    name="MapOptions"
                    options={mapOptions}
                    checkboxValue={selectedOptions}
                    onChange={toggleMapOption}
                  />
                  {!hideLegend && (
                    <>
                      <hr className={styles.LegendDivider} />
                      <PipelineLegend
                        pipelines={pipelines}
                        resetPan={resetPan}
                        initialFocusedPipeline={
                          initialFocusedPipeline
                            ? initialFocusedPipeline.id
                            : ""
                        }
                        mapResetFlag={!mapChangedSinceReset && focusFlag}
                      />
                    </>
                  )}
                </div>
                <EventMapButtons
                  canSearchOnMapMove={canSearchOnMapMove}
                  searchOnMapMove={searchOnMapMove}
                  setSearchOnMapMove={shouldSearch => {
                    setSearchOnMapMove(shouldSearch);
                    analytics.mapAutoSearchToggled(shouldSearch ? "on" : "off");
                    if (shouldSearch) {
                      updateQueryParams();
                    }
                  }}
                  showResetMap={mapChangedSinceReset || focusFlag}
                  isSearching={isSearching}
                  onResetMap={() => {
                    resetPan(pipelines);
                    analytics.mapViewReset();
                    setSearchOnMapMove(true);
                    updateQueryParams();
                  }}
                />
              </>
            );
          }}
        </Map>
      ) : null}
    </>
  );
};

export default React.memo(EventMap);
