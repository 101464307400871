import React, { ChangeEvent, useState } from "react";
import { Text } from "../Type";
import styles from "./index.module.scss";
import cx from "classnames";
import { clamp, calculatePercent } from "../../helpers/math";

const Z_INDEX = 6;

interface IRangeSliderProps {
  dualRange?: boolean;
  min: number;
  max: number;
  onChange: Function;
  start: number;
  end?: number;
  step?: number;
  minRange?: number;
  maxRange?: number;
  pushHandles?: boolean;
  pullHandles?: boolean;
  debug?: boolean;
  className?: string;
}

const displayFloat = (value: number | string) => {
  return Number(value).toString();
};

const RangeSlider = ({
  dualRange,
  min,
  max,
  onChange,
  start,
  end,
  step,
  minRange,
  maxRange,
  pushHandles,
  pullHandles,
  debug,
  className
}: IRangeSliderProps) => {
  let minRangeValue = minRange !== undefined ? minRange : min;
  let maxRangeValue = maxRange && maxRange < max - min ? maxRange : max - min;

  const shouldPushHandles = dualRange ? pushHandles : true;
  const shouldPullHandles = dualRange ? pullHandles : true;

  if (!dualRange) {
    minRangeValue = 0;
    maxRangeValue = 0;
  }

  const startValue = clamp(start, min, max - minRangeValue);
  const endValue =
    end !== undefined
      ? clamp(
          end,
          startValue + minRangeValue,
          Math.min(startValue + maxRangeValue, max)
        )
      : startValue;

  const [leftZIndex, setLeftZIndex] = useState(Z_INDEX - 1);
  const [rightZIndex, setRightZIndex] = useState(Z_INDEX);

  // gets the number of decimal places of `step`, provided step is set and it
  // actually has decimal places.
  const decimalPlaces =
    step && Number(step) % 1 ? step.toString().split(".")[1].length || 0 : 0;

  const limitStyles = () => {
    return {
      left: `${Math.min(
        calculatePercent(startValue, min, max),
        calculatePercent(max - maxRangeValue, min, max)
      )}%`,
      width: `${calculatePercent(min + maxRangeValue, min, max)}%`
    };
  };

  const rangeStyles = () => {
    const minPercent = calculatePercent(startValue, min, max);
    return {
      left: `${minPercent}%`,
      width: `${calculatePercent(endValue, min, max) - minPercent}%`
    };
  };

  const onMinChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const values = { startValue, endValue };
    const value = shouldPushHandles
      ? clamp(Number(event.target.value), min, max - minRangeValue)
      : clamp(Number(event.target.value), min, endValue - minRangeValue);

    // react to the lower limit constraints
    if (value > endValue - minRangeValue) {
      // Push the max handle forward if necessary
      if (shouldPushHandles && endValue < max) {
        values.endValue = clamp(
          value + minRangeValue,
          min + minRangeValue,
          max
        );
      } else {
        event.preventDefault();
        return;
      }
      // react to the upper limit constraints
    } else if (endValue - value > maxRangeValue) {
      // pull the max handle backward if necessary
      if (shouldPullHandles) {
        values.endValue = clamp(
          value + maxRangeValue,
          min + minRangeValue,
          max
        );
      } else {
        event.preventDefault();
        return;
      }
    }

    // update with the recalculated values;
    onChange({
      ...values,
      startValue: value
    });
  };

  const onMaxChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const values = { startValue, endValue };
    const value = shouldPushHandles
      ? clamp(Number(event.target.value), min + minRangeValue, max)
      : clamp(Number(event.target.value), startValue + minRangeValue, max);

    // react to the lower limit constraints
    if (value < startValue + minRangeValue) {
      // push the min handle backward if necessary
      if (shouldPushHandles && startValue > min) {
        values.startValue = clamp(
          value - minRangeValue,
          min,
          max - minRangeValue
        );
      } else {
        event.preventDefault();
        return;
      }
      // react to the upper limit constraints
    } else if (value - startValue > maxRangeValue) {
      // pull the min handle forward if necessary
      if (shouldPullHandles) {
        values.startValue = clamp(
          value - maxRangeValue,
          min,
          max - minRangeValue
        );
      } else {
        event.preventDefault();
        return;
      }
    }

    // update with the recalculated values;
    onChange({
      ...values,
      endValue: value
    });
  };

  return (
    <div
      className={cx(
        styles.container,
        { [styles.single]: !dualRange },
        className
      )}
      data-debug={(debug && debug === true) || undefined}
    >
      <Text component="span" size="small" className={styles.minValue}>
        {displayFloat(decimalPlaces ? min.toFixed(decimalPlaces) : min)}
      </Text>
      <div className={styles.rangeInputs}>
        <input
          type="range"
          min={min}
          max={max}
          value={startValue}
          step={step}
          onChange={onMinChanged}
          className={cx(styles.thumb, styles.thumbLeft)}
          onMouseEnter={() => {
            setLeftZIndex(Z_INDEX);
            setRightZIndex(Z_INDEX - 1);
          }}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={endValue}
          step={step}
          tabIndex={!dualRange ? -1 : undefined} // disables tab selection when it's not a dual range slider
          onChange={onMaxChanged}
          className={cx(styles.thumb, styles.thumbRight)}
          onMouseEnter={() => {
            setLeftZIndex(Z_INDEX - 1);
            setRightZIndex(Z_INDEX);
          }}
        />
        <div className={styles.slider}>
          <div className={styles.sliderTrack}></div>
          {maxRangeValue < max - min ? (
            <div className={styles.sliderLimits} style={limitStyles()}></div>
          ) : null}
          <div className={styles.sliderRange} style={rangeStyles()}>
            <div className={cx(styles.handle, styles.handleLeft)}>
              <Text
                component="div"
                size="small"
                className={styles.sliderLeftValue}
                style={{
                  zIndex: leftZIndex
                }}
              >
                {displayFloat(
                  decimalPlaces ? startValue.toFixed(decimalPlaces) : startValue
                )}
              </Text>
            </div>
            <div className={cx(styles.handle, styles.handleRight)}>
              <Text
                component="div"
                size="small"
                className={styles.sliderRightValue}
                style={{
                  zIndex: rightZIndex
                }}
              >
                {displayFloat(
                  decimalPlaces ? endValue.toFixed(decimalPlaces) : endValue
                )}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Text component="span" size="small" className={styles.maxValue}>
        {displayFloat(decimalPlaces ? max.toFixed(decimalPlaces) : max)}
      </Text>
    </div>
  );
};

export default RangeSlider;
