import React from "react";
import cx from "classnames";

import DatePicker from "../../DatePicker";
import Select from "../../Dropdown/Select";
import GenericFilter from "../../GenericFilter";
import {
  trainTypeOptions,
  updatesTypeOptions,
  sortByOptions
} from "../../../helpers/filterOptions";
import { useTrainFiltersContext } from "../TrainFiltersProvider";

import styles from "./index.module.scss";
import ResultsCount from "../../ResultsCount";
import { trainsLimit } from "../../../helpers/pagination";

const TrainsFilter = ({
  eventsCount,
  totalEvents
}: {
  eventsCount: number;
  totalEvents: number;
}) => {
  const {
    sortBy,
    setSortBy,
    dateRange,
    updateDateRange,
    applyDateRange,
    selectedTrainTypes,
    setSelectedTrainTypes,
    setSelectedUpdatesTypes,
    pipelineOptions,
    selectedPipelines,
    setSelectedPipelines,
    selectedUpdatesTypes,
    page
  } = useTrainFiltersContext();

  const selectedSortBy = sortByOptions.find(item => item.value === sortBy);
  const widthType = pipelineOptions.length ? "Quarter" : "Third";

  return (
    <div className={styles.FilterBarWrapper}>
      <div className={styles.FilterBarInner}>
        <div
          className={cx(
            styles.FilterContainer,
            styles[`FilterContainer${widthType}`]
          )}
        >
          <DatePicker
            rangeSelect
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onApply={applyDateRange}
            onDatesChange={updateDateRange}
            onClearAll={() =>
              updateDateRange({ startDate: null, endDate: null })
            }
          />
        </div>
        {pipelineOptions.length && (
          <div
            className={cx(
              styles.FilterContainer,
              styles[`FilterContainer${widthType}`]
            )}
          >
            <GenericFilter
              label="Pipelines"
              options={pipelineOptions}
              selectedItems={selectedPipelines}
              setSelectedItems={setSelectedPipelines}
            />
          </div>
        )}
        <div
          className={cx(
            styles.FilterContainer,
            styles[`FilterContainer${widthType}`]
          )}
        >
          <GenericFilter
            label="Train Type"
            options={trainTypeOptions}
            selectedItems={selectedTrainTypes}
            setSelectedItems={setSelectedTrainTypes}
          />
        </div>
        <div
          className={cx(
            styles.FilterContainer,
            styles[`FilterContainer${widthType}`]
          )}
        >
          <GenericFilter
            label="Updates"
            options={updatesTypeOptions}
            selectedItems={selectedUpdatesTypes}
            setSelectedItems={setSelectedUpdatesTypes}
          />
        </div>
      </div>
      <div className={styles.FilterBarInner}>
        <ResultsCount
          totalEvents={totalEvents}
          eventsCount={eventsCount}
          page={page}
          className={styles.PaginationText}
          limit={trainsLimit}
        />
        <Select
          items={sortByOptions}
          label="Sort By"
          initial={selectedSortBy || sortByOptions[0]}
          onChange={selected => setSortBy(selected.value)}
        />
      </div>
    </div>
  );
};

export default TrainsFilter;
