import { gqlType } from "@hifieng/common";
import {
  MILLISECONDS_IN_SECONDS,
  SECONDS_PER_MINUTE,
  MINUTES_PER_HOUR,
  HOURS_PER_DAY
} from "./constants";

// converts a time of `unit` units to seconds.
export const timeFromUnitToSeconds = (
  time: number,
  unit: gqlType.TimeUnits
) => {
  let convertedTime = time;

  switch (unit) {
    case gqlType.TimeUnits.Minutes:
      convertedTime = time * SECONDS_PER_MINUTE;
      break;
    case gqlType.TimeUnits.Hours:
      convertedTime = time * SECONDS_PER_MINUTE * MINUTES_PER_HOUR;
      break;
    case gqlType.TimeUnits.Days:
      convertedTime =
        time * SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY;
      break;
  }

  return convertedTime;
};

// converts a time of `unit` units to milliseconds.
export const timeFromUnitToMs = (time: number, unit: gqlType.TimeUnits) => {
  return timeFromUnitToSeconds(time, unit) * MILLISECONDS_IN_SECONDS;
};
