import React, { useState } from "react";
import cx from "classnames";
import { Text } from "../Type";
import styles from "./index.module.scss";

interface IPropsType extends React.HTMLProps<HTMLInputElement> {
  value?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  errorMessage?: string;
  successMessage?: string;
  supportMessage?: string;
  label: string;
  constraintsLabel?: string;
  hideLabel?: boolean;
  disabled?: boolean;
  textSize?: "regular" | "small";
  min?: number;
  max?: number;
  step?: number;
}

const NumberInput = ({
  value,
  placeholder,
  errorMessage,
  successMessage,
  supportMessage,
  onChange,
  label,
  constraintsLabel,
  hideLabel,
  disabled,
  textSize = "regular",
  min,
  max,
  step,
  ...props
}: IPropsType) => {
  const [message, setMessage] = useState({
    text: errorMessage || successMessage || supportMessage,
    type: errorMessage
      ? "error"
      : successMessage
      ? "success"
      : supportMessage && "support"
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage({
      text: supportMessage,
      type: undefined
    });
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <label className={styles.Wrapper}>
      {!hideLabel ? (
        <Text
          size="small"
          className={cx(styles.Label, { [styles.Disabled]: disabled })}
        >
          {label}
          {constraintsLabel ? (
            <Text component="span" size="small" className={styles.Darker}>
              {`\u00A0${constraintsLabel}`}
            </Text>
          ) : null}
        </Text>
      ) : null}
      <input
        aria-label={hideLabel ? label : undefined}
        className={cx(styles.Input, styles.numberInput, {
          [styles.InputError]: message.type === "error",
          [styles.InputSuccess]: message.type === "success",
          [styles.InputSmall]: textSize === "small"
        })}
        type="number"
        placeholder={placeholder}
        onChange={handleChange}
        value={value !== undefined ? value.toString() : undefined}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        {...props}
      />
      {message.text && (
        <span
          className={cx(styles.Message, {
            [styles.MessageError]: message.type === "error",
            [styles.MessageSuccess]: message.type === "success"
          })}
        >
          {message.text}
        </span>
      )}
    </label>
  );
};

export default NumberInput;
