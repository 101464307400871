"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var types_1 = require("../../types");

function isNumber(item) {
  return typeof item === "number" && !isNaN(item);
}

function isString(item) {
  return typeof item === "string";
}

function isBaseNotification(item) {
  return Boolean(item) && isString(item.version) && isNumber(item.timestamp) && item.timestamp > 0 && isString(item.pipelineId) && isString(item.segmentId) && isString(item.id) && isString(item.method);
}

exports.isBaseNotification = isBaseNotification;

function isEventParameters(parameters) {
  return Boolean(parameters) && isString(parameters.type) && Boolean(parameters.location) && isNumber(parameters.location.post) && isNumber(parameters.location.latitude) && isNumber(parameters.location.longitude) && isString(parameters.location.channel) && isNumber(parameters.startTime) && parameters.startTime > 0 && (!parameters.endTime || isNumber(parameters.endTime) && Number(parameters.endTime) > 0);
}

function isHeartbeatNotification(item) {
  return isBaseNotification(item) && item.method.toLowerCase() === "heartbeat" && item.parameters && Object.keys(item.parameters).length === 0;
}

exports.isHeartbeatNotification = isHeartbeatNotification;

function isEventNotification(item) {
  return isBaseNotification(item) && item.method.toLowerCase() === "event" && Boolean(item.parameters) && isEventParameters(item.parameters);
}

exports.isEventNotification = isEventNotification;

function isBaseSignature(parameters) {
  return isString(parameters.name) && isString(parameters.eventId) && isString(parameters.type) && parameters.filePath && isString(parameters.filePath);
}

function isSignatureNotification(item) {
  return isBaseNotification(item) && item.method.toLowerCase() === "signature" && Boolean(item.parameters) && isBaseSignature(item.parameters);
}

exports.isSignatureNotification = isSignatureNotification;

function isLineChartSignature(item) {
  return isSignatureNotification(item) && item.parameters.type.toLowerCase() === "line-chart";
}

exports.isLineChartSignature = isLineChartSignature;

function isHeatMapSignature(item) {
  return isSignatureNotification(item) && item.parameters.type.toLowerCase() === "heat-map";
}

exports.isHeatMapSignature = isHeatMapSignature;

function isFileSignature(parameters, signatureType) {
  return isBaseSignature(parameters) && parameters.type.toLowerCase() === signatureType;
}

function isAudioSignature(item) {
  return isSignatureNotification(item) && isFileSignature(item, "audio");
}

exports.isAudioSignature = isAudioSignature;

function isImageSignature(item) {
  return isSignatureNotification(item) && isFileSignature(item, "image");
}

exports.isImageSignature = isImageSignature;

function isSummaryParameter(parameters) {
  return Boolean(parameters) && isString(parameters.filePath) && Boolean(parameters.headers) && Array.isArray(parameters.headers);
}

function isSummaryNotification(item) {
  return isBaseNotification(item) && item.method.toLowerCase() === "summary" && isSummaryParameter(item.parameters);
}

exports.isSummaryNotification = isSummaryNotification;

function isPigStatus(status) {
  return types_1.EdgeAgentPigStatusOptions.includes(status);
}

function isPigParameter(parameters) {
  return Boolean(parameters) && isNumber(parameters.speed) && isString(parameters.runId) && isPigStatus(parameters.status);
}

function isPigNotification(item) {
  return isBaseNotification(item) && item.method.toLowerCase() === "pig" && isPigParameter(item.parameters);
}

exports.isPigNotification = isPigNotification;

function isTrainStatus(status) {
  return types_1.EdgeAgentTrainStatusOptions.includes(status);
}

function isTrainParameter(parameters) {
  return Boolean(parameters) && isNumber(parameters.speed) && isString(parameters.runId) && isTrainStatus(parameters.status);
}

function isTrainNotification(item) {
  return isBaseNotification(item) && item.method.toLowerCase() === "train" && isTrainParameter(item.parameters);
}

exports.isTrainNotification = isTrainNotification;