export const eventsLimit = 20;
export const pigsLimit = 5;
export const trainsLimit = 5;

export const offset = (page: number, limit: number) => (page - 1) * limit;

export const paginationStart = (paginationOffset: number) =>
  paginationOffset + 1;

export const paginationEnd = (
  paginationOffset: number,
  eventCount: number,
  limit: number
) => {
  return paginationOffset + limit > paginationOffset + eventCount
    ? eventCount + paginationOffset
    : paginationOffset + limit;
};
