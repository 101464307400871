import React from "react";
import { InfoWindow } from "react-google-maps";
import cx from "classnames";
import { Text } from "../Type";
import DateTime from "../DateTime";
import { MILLISECONDS_IN_SECONDS } from "../../helpers/constants";

import styles from "./eventToolTip.module.scss";
import { MapEvent } from "../../types/EventMap";
import { getStatusText } from "../../helpers/pigStatus";
import getPriorityLevel from "../../helpers/getPriorityLevel";
import { kpToMp } from "../AnalysisCharts/BaseChart";
import { useAuth } from "../../contexts/AuthProvider";

type PropsType = {
  event: MapEvent;
};

const titleCaseAndFormat = (str: string) => {
  return str
    .toLowerCase()
    .split("_")
    .map(word => {
      // preserves capitalization of "POI"
      return word === "poi"
        ? "POI"
        : word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
};

const PigRunToolTip = ({ event }: PropsType) => {
  const priority = getPriorityLevel(event.type);
  return (
    <div className={styles.ToolTip}>
      <Text className={styles.ToolTipType} size="small" component="span">
        {event.category === "train" ? event.railId : event.pipeline.name} - Run{" "}
        {event.id}
      </Text>
      <Text size="small" component="span" dark className={styles.PigLocation}>
        {event.coordinates.latitude}, {event.coordinates.longitude}
      </Text>
      {event.timestamp && (
        <Text size="small" component="span" dark>
          <DateTime
            timestamp={event.timestamp * MILLISECONDS_IN_SECONDS}
            length="long"
          />
        </Text>
      )}
      {event.status && (
        <Text
          size="small"
          dark
          component="span"
          className={cx(styles.Icon, {
            [styles.IconHigh]: priority === "high",
            [styles.IconMedium]: priority === "medium"
          })}
        >
          {getStatusText(event.status)} - {event.speed}m/second
        </Text>
      )}
    </div>
  );
};

const EventToolTip = ({ event }: PropsType) => {
  const { timestamp, speed } = event;
  const { permissions } = useAuth();
  return (
    <InfoWindow>
      {timestamp && speed !== undefined ? (
        <PigRunToolTip event={event} />
      ) : (
        <div className={styles.ToolTip}>
          <Text className={styles.ToolTipType} size="small" component="span">
            {titleCaseAndFormat(event.type)}
          </Text>
          <Text size="small" component="span" dark>
            {event.referenceId}
          </Text>
          <Text size="small" component="span" dark>
            {permissions.user.imperial ||
            event.pipeline.id === "enbridge_line_3" ||
            event.pipeline.id === "enbridge_line_4"
              ? `MP ${kpToMp(event.post)}`
              : `KP ${event.post}`}
          </Text>
        </div>
      )}
    </InfoWindow>
  );
};

export default EventToolTip;
