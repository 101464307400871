import React from "react";
import AppError from "../../errors/AppError";
import { logErrorToLogdna } from "../../helpers/logdna";

const ERROR_CODES = {
  errorBoundary: "ERROR_BOUNDARY"
};

type PropsType = {
  errorComponent: React.ElementType<{ title: string; subtitle: string }>;
  children: React.ReactNode;
};

type StateType = {
  hasError: boolean;
  title: string;
  subtitle: string;
};

class ErrorBoundary extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false, title: "", subtitle: "" };
  }

  componentDidCatch(error: Error) {
    console.error(error);

    logErrorToLogdna(ERROR_CODES.errorBoundary, error.message, error);

    if (error instanceof AppError) {
      this.setState({
        hasError: true,
        title: error.title,
        subtitle: error.subtitle
      });
    } else {
      this.setState({
        hasError: true,
        title: "An internal error occurred",
        subtitle: error.message
      });
    }
  }

  render() {
    const { errorComponent: ErrorComponent } = this.props;

    if (this.state.hasError) {
      return (
        <ErrorComponent
          title={this.state.title}
          subtitle={this.state.subtitle}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
