import React from "react";

const DEFAULT_WIDTH = 24;
const DEFAULT_HEIGHT = 24;
const SIZE_RATIO = DEFAULT_HEIGHT / DEFAULT_WIDTH;

type Props = {
  width?: number;
};

const ArrowRight = ({ width = DEFAULT_WIDTH }: Props) => {
  const height = width * SIZE_RATIO;

  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25,4.5l-1.057,1.058l5.685,5.692l-16.628,0l-0,1.5l16.628,0l-5.685,5.693l1.057,1.057l7.5,-7.5l-7.5,-7.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRight;
