import React from "react";
import PropTypes from "prop-types";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";
import { Marker } from "react-google-maps";
import EventToolTip from "../EventToolTip";
import { MapCluster } from "../../Icons";
import getEncodedIconByType, { encodeSvg } from "../getEncodedIconByType";
import getPriorityLevel from "../../../helpers/getPriorityLevel";
import { CoordinatePoints, MapEvent } from "../../../types/EventMap";
import { color } from "../../../styles/colors";
import styles from "./BaseSpiderfy.module.scss";
import { EventPriorityType } from "../../../types/EventTypes";

type SpiderMarkerData = MapEvent & {
  position: {
    lat: number;
    lng: number;
  };
  icon: { url: string; anchor: google.maps.Point };
  zIndex: number;
};

type MarkerInstance = google.maps.Marker & {
  referenceId?: string;
};

type Cluster = {
  getMarkers: () => Array<MarkerInstance>;
  remove: () => void;
};

type PropsType = {
  zoomLevel: number;
  events: Array<MapEvent>;
  onZoom: (Points: CoordinatePoints) => void;
  onEventSelection?: (referenceId: string) => void;
};

type StateType = {
  focusedEvent: string | undefined;
  shouldUpdateMarkerPositions: boolean;
  spiderMarkers: Array<SpiderMarkerData>;
};

type ContextType = {
  [MAP: string]: google.maps.Map;
};

const MAP = `__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;
const MARKER = `__SECRET_MARKER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

const EVENT_ICON_SIZE = 40;
const FOCUSED_EVENT_ICON_SIZE = 48;
const LOW_PRIORITY_Z_INDEX = 12;
const MEDIUM_PRIORITY_Z_INDEX = 13;
const HIGH_PRIORITY_Z_INDEX = 14;
const HALF = 0.5;
const ONE_SECOND = 1000;

type ExtendedMarkerInstance = MarkerInstance & { priority: EventPriorityType };

// The calculator is used to determine the text and style for the cluster. The returned "index" is
// the index from the styles array (plus 1) that should be used. In our case, we use different
// styles to visualize the highest priority event in the cluster.

// Docs for the calculator are somewhat hard to find. This was based off of the original
// implementation here:
// https://github.com/mahnunchik/markerclustererplus/blob/736b0e3a7d916fbeb2ee5007494f17a5329b11a8/src/markerclusterer.js#L1591-L1618
function clusterCalculator(
  markers: Array<ExtendedMarkerInstance>,
  numStyles: number
) {
  let priorityIndex = 1;
  for (const marker of markers) {
    if (marker.priority === "medium") {
      priorityIndex = 2; // eslint-disable-line no-magic-numbers
    }
    if (marker.priority === "high") {
      priorityIndex = 3; // eslint-disable-line no-magic-numbers
      break;
    }
  }

  // The "index" returned is the index of the styles array that you want to use for the cluster
  // Math.min is used, in case the number of styles doesn't have an index for the priority level
  const retVal = {
    text: markers.length.toString(),
    index: Math.min(priorityIndex, numStyles),
    title: ""
  };
  return retVal;
}

const clusterIconSize = 40;
const lowPriorityIcon = encodeSvg(
  <MapCluster size={clusterIconSize} priority="low" />
);
const mediumPriorityIcon = encodeSvg(
  <MapCluster size={clusterIconSize} priority="medium" />
);
const highPriorityIcon = encodeSvg(
  <MapCluster size={clusterIconSize} priority="high" />
);

// The styles type is defined as "any" in react-google-maps 🙄
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let memoStyles: Array<any>;
const generateClusterStyles = () => {
  if (!memoStyles) {
    memoStyles = [
      // Low priority cluster style
      {
        textColor: color.black,
        url: lowPriorityIcon,
        height: 50,
        width: 50,
        textSize: 14,
        anchorText: [-5, -5], //eslint-disable-line no-magic-numbers
        anchor: new window.google.maps.Point(
          clusterIconSize * HALF,
          clusterIconSize * HALF
        )
      },
      // Medium priority cluster style
      {
        textColor: color.white,
        url: mediumPriorityIcon,
        height: 50,
        width: 50,
        textSize: 14,
        anchorText: [-5, -5], //eslint-disable-line no-magic-numbers
        anchor: new window.google.maps.Point(
          clusterIconSize * HALF,
          clusterIconSize * HALF
        )
      },
      // High priority cluster style
      {
        textColor: color.white,
        url: highPriorityIcon,
        height: 50,
        width: 50,
        textSize: 14,
        anchorText: [-5, -5], //eslint-disable-line no-magic-numbers
        anchor: new window.google.maps.Point(
          clusterIconSize * HALF,
          clusterIconSize * HALF
        )
      }
    ];
  }

  return memoStyles;
};

class BaseSpiderfy extends React.PureComponent<PropsType, StateType> {
  static contextTypes = {
    [MAP]: PropTypes.object
  };

  map: google.maps.Map;
  oms?: OverlappingMarkerSpiderfier;
  maxZoom = 14; //eslint-disable-line no-magic-numbers

  spiderfierOptions = {
    markersWontMove: true,
    markersWontHide: true,
    basicFormatEvents: true,
    nearbyDistance: 50,
    keepSpiderfied: true,
    circleSpiralSwitchover: 25,
    circleFootSeparation: 65,
    spiralFootSeparation: 70,
    spiralLengthStart: 50,
    spiralLengthFactor: 5
  };

  constructor(props: PropsType, context: ContextType) {
    super(props, context);

    this.state = {
      focusedEvent: undefined,
      shouldUpdateMarkerPositions: true,
      spiderMarkers: []
    };

    this.map = this.context[MAP];
  }

  componentDidMount() {
    // Initialize Spiderfier
    this.oms = new OverlappingMarkerSpiderfier(
      this.map,
      this.spiderfierOptions
    );

    // set spider leg colors
    const mti = window.google.maps.MapTypeId;
    this.oms.legColors.usual = {
      [mti.SATELLITE]: color.emperor,
      [mti.ROADMAP]: color.emperor,
      [mti.TERRAIN]: color.emperor,
      [mti.HYBRID]: color.emperor
    };
    this.oms.legColors.highlighted = {
      [mti.SATELLITE]: color.white,
      [mti.ROADMAP]: color.white,
      [mti.TERRAIN]: color.white,
      [mti.HYBRID]: color.white
    };

    this.oms.addListener("spiderfy", this.spiderfyListener);
    this.oms.addListener("unspiderfy", this.unspiderfyListener);
  }

  componentWillUnmount() {
    if (this.oms) {
      this.oms.removeListener("spiderfy", this.spiderfyListener);
      this.oms.removeListener("unspiderfy", this.unspiderfyListener);
    }
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    // Don't use this code anywhere else, this is bad code - Adam
    // The cluster takes two renders to recalculate the cluster, because the google map hasn't
    // actually finished zooming in by the time the render is complete. This triggers a second
    // render allowing the zoom/events change to complete, an then re-rendering.
    // We could achieve the same result by making this not a PureComponent, but that does the same
    // thing, with more unnecessary renders.
    if (
      this.props.zoomLevel !== prevProps.zoomLevel ||
      this.props.events !== prevProps.events
    ) {
      setTimeout(() => {
        this.forceUpdate();
      }, ONE_SECOND);
    }

    const { spiderMarkers } = this.state;
    let isEventUpdate = false;
    if (spiderMarkers.length !== this.props.events.length) {
      isEventUpdate = true;
    } else if (this.props.events !== prevProps.events) {
      this.props.events.forEach((event, idx) => {
        if (!spiderMarkers[idx]) {
          isEventUpdate = true;
        } else if (
          event.id !== spiderMarkers[idx].id ||
          event.referenceId !== spiderMarkers[idx].referenceId ||
          event.type !== spiderMarkers[idx].type ||
          event.post !== spiderMarkers[idx].post
        ) {
          isEventUpdate = true;
        }
      });
    }

    if (isEventUpdate || prevState.focusedEvent !== this.state.focusedEvent) {
      const spiderMarkers: Array<SpiderMarkerData> = this.props.events.map(
        event => {
          const isFocusedEvent =
            this.state.focusedEvent &&
            this.state.focusedEvent === event.referenceId;
          const iconSize = isFocusedEvent
            ? FOCUSED_EVENT_ICON_SIZE
            : EVENT_ICON_SIZE;
          const encodedIcon = getEncodedIconByType(
            iconSize,
            event.type,
            event.category,
            event.pulse || false
          );
          const icon = {
            url: encodedIcon,
            anchor: new window.google.maps.Point(
              iconSize * HALF,
              iconSize * HALF
            )
          };

          const eventPriority = getPriorityLevel(event.type);
          let priorityZIndex = LOW_PRIORITY_Z_INDEX;
          if (eventPriority === "medium")
            priorityZIndex = MEDIUM_PRIORITY_Z_INDEX;
          if (eventPriority === "high") priorityZIndex = HIGH_PRIORITY_Z_INDEX;

          return {
            ...event,
            referenceId: event.referenceId,
            position: {
              lat: event.coordinates.latitude,
              lng: event.coordinates.longitude
            },
            icon,
            zIndex: priorityZIndex
          };
        }
      );

      if (isEventUpdate) {
        this.unspiderfyAll();
      }

      this.setState(state => {
        return {
          spiderMarkers,
          shouldUpdateMarkerPositions: isEventUpdate
            ? true
            : state.shouldUpdateMarkerPositions
        };
      });
    }
  }

  spiderfyListener = () => {
    // Force update to immediately clear cluster icon from map
    this.forceUpdate();
  };

  unspiderfyListener = () => {
    if (this.oms) {
      this.oms.removeAllMarkers();
    }

    this.setState({
      shouldUpdateMarkerPositions: true,
      focusedEvent: undefined
    });
  };

  zoomToCluster = (cluster: Cluster) => {
    const clusterMarkers = cluster.getMarkers();
    const points = clusterMarkers.map(marker => {
      const position = marker.getPosition();
      return {
        coordinates: {
          latitude: position ? position.lat() : 0,
          longitude: position ? position.lng() : 0
        }
      };
    });

    this.props.onZoom(points);
  };

  spiderfyCluster = (cluster: Cluster) => {
    this.setState(
      { shouldUpdateMarkerPositions: false, focusedEvent: undefined },
      () => {
        const clusterMarkers = cluster.getMarkers();

        clusterMarkers.forEach((marker: google.maps.Marker, idx: number) => {
          // Add spiderfiable markers
          if (this.oms) {
            this.oms.addMarker(marker, () => null);
          }
          // On last marker trigger click event to spiderfy markers in cluster
          if (idx === clusterMarkers.length - 1) {
            window.google.maps.event.trigger(marker, "click", {});
          }
        });
        // Remove the cluster icon
        cluster.remove();
      }
    );
  };

  unspiderfyAll = () => {
    this.setState({ shouldUpdateMarkerPositions: true });
    if (this.oms) {
      this.oms.removeAllMarkers();
    }
  };

  eventIconHover = (isOver: boolean, eventId: string) => {
    this.setState(({ focusedEvent }) => {
      if (isOver) {
        return { focusedEvent: eventId };
      } else {
        if (focusedEvent === eventId) {
          return { focusedEvent: undefined };
        } else {
          return { focusedEvent };
        }
      }
    });
  };

  render() {
    const { spiderMarkers, shouldUpdateMarkerPositions } = this.state;
    const shouldZoomOnClick = this.props.zoomLevel < this.maxZoom;
    const clusteredMarkers: Array<MarkerInstance> = this.oms
      ? this.oms.getMarkers()
      : [];

    return (
      <MarkerClusterer
        zoomOnClick={false}
        averageCenter
        enableRetinaIcons
        gridSize={50}
        ignoreHidden
        clusterClass={styles.Cluster}
        styles={generateClusterStyles()}
        calculator={clusterCalculator}
        onClick={(cluster: Cluster) => {
          if (shouldZoomOnClick) {
            this.zoomToCluster(cluster);
          } else {
            this.spiderfyCluster(cluster);
          }
        }}
      >
        {spiderMarkers.map(marker => {
          const isFocusedEvent =
            this.state.focusedEvent &&
            this.state.focusedEvent === marker.referenceId;

          let position: google.maps.LatLng | google.maps.LatLngLiteral = {
            lat: marker.position.lat,
            lng: marker.position.lng
          };

          let isClusteredMarker = false;

          if (!shouldUpdateMarkerPositions) {
            const markerInstance = clusteredMarkers.find(
              (clusteredMarker: MarkerInstance) =>
                clusteredMarker.referenceId === marker.referenceId
            );
            if (markerInstance) {
              isClusteredMarker = true;
              const currentPosition = markerInstance.getPosition();
              if (currentPosition) {
                position = currentPosition;
              }
            }
          }

          const hideMarker = clusteredMarkers.length > 0 && !isClusteredMarker;

          return (
            // Marker component does not accept onFocus or onBlur events, thus eslint rule disabled
            <Marker //eslint-disable-line jsx-a11y/mouse-events-have-key-events
              key={marker.referenceId}
              ref={(ref: Marker | null) => {
                const markerInstance =
                  ref && ref.state
                    ? (ref.state as { [MARKER: string]: MarkerInstance })[
                        MARKER
                      ]
                    : undefined;

                if (markerInstance) {
                  // These properties are passed with the marker instance elsewhere
                  // Eg, the mouse events and the cluster calculator
                  markerInstance.setValues({
                    referenceId: marker.referenceId,
                    priority: getPriorityLevel(marker.type)
                  });
                }
              }}
              position={position}
              icon={marker.icon}
              zIndex={marker.zIndex}
              visible={!hideMarker}
              onClick={() => {
                if (isFocusedEvent && this.props.onEventSelection) {
                  this.props.onEventSelection(marker.referenceId);
                }
              }}
              onMouseOver={() => this.eventIconHover(true, marker.referenceId)}
              onMouseOut={() => this.eventIconHover(false, marker.referenceId)}
            >
              {isFocusedEvent &&
                this.state.focusedEvent === marker.referenceId && (
                  <EventToolTip event={marker} />
                )}
            </Marker>
          );
        })}
      </MarkerClusterer>
    );
  }
}

export default BaseSpiderfy;
