import React, { useContext, useState, useEffect } from "react";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { ApolloError } from "apollo-boost";

import {
  trainsQueryComposer,
  TRAIN_COUNT_SUBSCRIPTION
} from "../../helpers/trainQueryComposer";
import { logErrorToLogdna } from "../../helpers/logdna";

type PropsType = {
  children: React.ReactNode;
};

export type TrainCount = {
  active: number;
  completed: number;
};

interface ITrainHeaderContext {
  trainCount: TrainCount;
  loading?: boolean;
}
const initialTrainContext: ITrainHeaderContext = {
  trainCount: {
    active: 0,
    completed: 0
  }
};

const fragment = gql`
  fragment TrainsFragment on PaginatedTrainsAndTotal {
    active
    completed
  }
`;

export const TrainHeaderContext = React.createContext(initialTrainContext);
export const useTrainHeaderContext = () => useContext(TrainHeaderContext);

const TRAIN_COUNT_QUERY = trainsQueryComposer(fragment);

export const TrainHeaderProvider = ({ children }: PropsType) => {
  const [trainCount, setTrainCount] = useState<TrainCount>(
    initialTrainContext.trainCount
  );
  const { activeOrg } = useOrganizationContext();

  const { data, loading, subscribeToMore, refetch } = useQuery(
    TRAIN_COUNT_QUERY,
    {
      variables: {
        organizationId: activeOrg ? activeOrg.id : undefined
      },
      skip: !activeOrg,
      onError: (err: ApolloError) => {
        logErrorToLogdna(
          "GETTING_ACTIVE_TRAINS",
          "Apollo error while getting active trains.",
          err
        );
        throw err;
      }
    }
  );

  useEffect(() => {
    if (data) {
      const { active, completed } = data.trains;
      setTrainCount({ active, completed });
    }
  }, [data]);

  useEffect(() => {
    if (!activeOrg) {
      return;
    }
    subscribeToMore({
      document: TRAIN_COUNT_SUBSCRIPTION,
      variables: {
        organizationId: activeOrg.id
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        refetch();
      }
    });
    //suppressing hook dependency because `subscribeToMore` changes on every render
    // and the documentation suggest to only call it on component mount
    // https://www.apollographql.com/docs/react/data/subscriptions/#subscribing-to-updates-for-a-query
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrg]);

  return (
    <TrainHeaderContext.Provider
      value={{ trainCount, loading: loading || !activeOrg }}
    >
      {children}
    </TrainHeaderContext.Provider>
  );
};

export default TrainHeaderProvider;
