"use strict";

function __export(m) {
  for (var p in m) {
    if (!exports.hasOwnProperty(p)) exports[p] = m[p];
  }
}

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var utils = __importStar(require("./utils"));

exports.utils = utils;

var gqlSchema = __importStar(require("./graphql/graphql.json"));

function getGraphQLSchema() {
  return JSON.parse(JSON.stringify(gqlSchema));
}

exports.getGraphQLSchema = getGraphQLSchema;

__export(require("./types"));

__export(require("./validators"));