export const breakpoints = {
  xsmall: 320,
  small: 375,
  medium: 768,
  large: 1025,
  xlarge: 1200,
  xxlarge: 1440,
  xxxlarge: 1680,
  xxxxlarge: 1920
};
