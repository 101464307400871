import React from "react";
import styles from "./index.module.scss";
import { getAnalysisRangeUnits } from "../../../helpers/analysisFunctions";
import ToggleSwitch from "../../ToggleSwitch";
import { useAuth } from "../../../contexts/AuthProvider";

type Props = {
  rangeUnit?: number;
  onClick?: (value: number) => void;
};

const RangeUnitToggle = ({ rangeUnit = 0, onClick }: Props) => {
  const nextUnit = () => {
    return (rangeUnit + 1) % getAnalysisRangeUnits().length;
  };

  const { permissions } = useAuth();
  const imperialFlag = permissions.user.imperial ? true : false;

  return (
    <>
      <div className={styles.FilterRow}>
        {onClick && (
          <ToggleSwitch
            setActive={() => {
              onClick(nextUnit());
            }}
            active={!nextUnit()}
            inactiveLabel={imperialFlag ? "MP" : "KP"}
            activeLabel="Ch"
          />
        )}
      </div>
    </>
  );
};

export default RangeUnitToggle;
