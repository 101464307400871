import React, { useState, useEffect, useCallback } from "react";
import { gqlType } from "@hifieng/common";
import { OptionType } from "../../types/GeneralTypes";
import PipelineSelect from "./PipelineSelect";
import LeakStrengthSelect from "./LeakStrengthSelect";
import { useAuth } from "../../contexts/AuthProvider";
import { useQueryParams } from "../../hooks/useQueryParams";
import RecentAreas, { getSearchHistory, setSearchHistory } from "./RecentAreas";
import { SearchRange } from "../../types/AnalysisTypes";
import { Text } from "../Type";
import { formatTime } from "../../helpers/formatDate";

import {
  getAnalysisRangeUnits,
  calculateMaxKPRange,
  getPipelineChKpMap,
  getRangeIndexes
} from "../../helpers/analysisFunctions";

import RangeSelector, {
  constrainRangeStart,
  constrainRangeEnd
} from "./RangeSelector";
import { kpMpRatio } from "../AnalysisCharts/BaseChart";

import styles from "./index.module.scss";
import { MAX_ANALYSIS_CHANNEL_QUERY_RANGE } from "../../helpers/constants";
import { clamp } from "../../helpers/math";
import gql from "graphql-tag";
import { ApolloError } from "apollo-boost";
import { logErrorToLogdna } from "../../helpers/logdna";
import { useMutation } from "@apollo/react-hooks";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import LoadingWrapper from "../LoadingSpinnerWrapper";
import GlobalTestSelect from "./GlobalTestSelect";

const FAKE_LEAK = gql`
  mutation(
    $strength: String!
    $channel: Int!
    $pipelineId: String!
    $organizationId: String!
    $ultimateTest: String!
  ) {
    sendFakeLeak(
      strength: $strength
      channel: $channel
      pipelineId: $pipelineId
      organizationId: $organizationId
      ultimateTest: $ultimateTest
    )
  }
`;

type Props = {
  pipelines: Array<gqlType.Pipeline>;
  pipeline: gqlType.Pipeline;
  onPipelineChange?: (pipeline: gqlType.Pipeline) => void;
  fiber: gqlType.ChannelKPostMap;
  onFiberChange?: (fiber: gqlType.ChannelKPostMap) => void;
  onLeakStrengthChange?: (leakStrength: string) => void;
  leakStrength?: string;
  onGlobalTestFlagChange?: (globalTestFlag: string) => void;
  globalTestFlag?: string;
  changePipelineURI?: string;
  setMapRange?: (range: SearchRange) => void;
  KPRange?: SearchRange;
  singleChannelFlag?: boolean;
};

const AnalysisFilters = ({
  pipelines,
  pipeline,
  onPipelineChange,
  fiber,
  onFiberChange,
  onLeakStrengthChange,
  leakStrength,
  onGlobalTestFlagChange,
  globalTestFlag,
  changePipelineURI,
  setMapRange,
  KPRange,
  singleChannelFlag
}: Props) => {
  const { queryParams } = useQueryParams();

  const analysisRangeUnits = getAnalysisRangeUnits();
  const [rangeUnit, setRangeUnit] = useState(
    clamp(Number(queryParams.unit) || 0, 0, analysisRangeUnits.length - 1)
  );

  // range must be a clone of `fiber.ch` not a reference
  let range = [...fiber.ch];
  let precision = 0;
  let rangeStep = 1;

  const { permissions } = useAuth();
  const imperialFlag =
    (permissions.user.imperial ? true : false) ||
    fiber.id === "enbridge_line_3" ||
    fiber.id === "enbridge_line_4";
  // user has selected the Kilometer Posts unit type.
  if (!rangeUnit) {
    if (imperialFlag) {
      range = fiber.kp.map(function(kpValue) {
        return kpValue * kpMpRatio;
      });
    } else {
      range = fiber.kp;
    }
    precision = fiber.precision;
    rangeStep = fiber.step;
  }

  // Fiber is backwards, we'll have to reverse it
  if (range[0] > range[range.length - 1]) {
    range.sort();
  }

  const minRange = 0;
  const rangeStart = range[0];
  const rangeEnd = range[range.length - 1];
  const maxKpRange = calculateMaxKPRange(fiber);
  const maxRange = singleChannelFlag
    ? 1
    : !rangeUnit
    ? imperialFlag
      ? maxKpRange * kpMpRatio
      : maxKpRange
    : MAX_ANALYSIS_CHANNEL_QUERY_RANGE;

  const constrainStartValue = useCallback(
    (value: number) => {
      return constrainRangeStart(
        value,
        rangeStart,
        rangeEnd,
        minRange,
        rangeStep,
        precision
      );
    },
    [rangeStart, rangeEnd, minRange, rangeStep, precision]
  );

  const constrainEndValue = useCallback(
    (value: number, start: number) => {
      return constrainRangeEnd(
        value,
        start,
        rangeEnd,
        minRange,
        maxRange,
        rangeStep,
        precision
      );
    },
    [rangeEnd, minRange, maxRange, rangeStep, precision]
  );

  const [startValue, setStartValue] = useState(
    constrainStartValue(Number(queryParams.start) || rangeStart)
  );

  const [endValue, setEndValue] = useState(
    constrainEndValue(
      Number(queryParams.end) || startValue + maxRange,
      startValue
    )
  );

  // We need to re-calculate/constrain the start and end values if any of the
  // following values change we might end up with a rage of a pipeline selected
  // that doesn't exist on the current pipeline or fiber.
  useEffect(() => {
    const start = constrainStartValue(rangeStart);
    const end = constrainEndValue(start + maxRange, start);
    setStartValue(start);
    setEndValue(end);
  }, [
    constrainStartValue,
    constrainEndValue,
    pipeline,
    fiber.id,
    precision,
    rangeStep,
    rangeUnit,
    rangeEnd,
    rangeStart,
    maxRange
  ]);

  // When the `queryParams` change the user has either clicked "search" or
  // they've clicked one of the "recent areas". We'll want to change the
  // `units` as well as the `start` and `end` values to match the ones being
  // passed in from the URL.
  useEffect(() => {
    const unitValue = Number(queryParams.unit) || 0;
    setRangeUnit(clamp(unitValue, 0, analysisRangeUnits.length - 1));
    if (queryParams.start) setStartValue(Number(queryParams.start));
    if (queryParams.end) setEndValue(Number(queryParams.end));
  }, [
    queryParams.start,
    queryParams.end,
    queryParams.unit,
    analysisRangeUnits.length
  ]);

  const { activeOrg } = useOrganizationContext();

  const TEST_MS = 300000;
  const getLeakCooldown = () => {
    const leakStartTime = parseInt(
      localStorage.getItem("hifi:fakeLeakStart") || "0",
      10
    );
    return Date.now() - leakStartTime <= TEST_MS;
  };
  const [sending, setSending] = useState(false);
  const initialLeakCooldown = getLeakCooldown();
  const [leakCooldown, setLeakCooldown] = useState(initialLeakCooldown);
  const [leakTime, setLeakTime] = useState(0);
  const [sendFakeLeak] = useMutation(FAKE_LEAK, {
    variables: {
      strength: leakStrength,
      pipelineId: pipeline.id,
      channel: parseInt(String(startValue), 10),
      organizationId: activeOrg ? activeOrg.id : "",
      ultimateTest: globalTestFlag
    },
    refetchQueries: ["reports"],
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        "SENDING_FAKE_LEAK",
        "Apollo error while sending fake leak.",
        err
      );
      throw err;
    }
  });

  // const MINUTE_MS = 60000;
  useEffect(() => {
    console.log(leakTime);
  }, [leakTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout(() => {
        const leakStartTime = parseInt(
          localStorage.getItem("hifi:fakeLeakStart") || "0",
          10
        );
        console.log(Date.now());
        console.log(leakStartTime);
        if (Date.now() - leakStartTime <= TEST_MS || leakStartTime === 0) {
          console.log("leak cool down is on");
        } else {
          setLeakCooldown(false);
          console.log("leak cool down is off");
        }
      }, TEST_MS);
    }, TEST_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [leakTime]);

  const LEAK_SEND_DELAY = 5000;
  const processFakeLeak = () => {
    setSending(true);
    setLeakTime(Date.now());
    sendFakeLeak().then(() => {
      setTimeout(() => {
        setLeakCooldown(true);
        setSending(false);
      }, LEAK_SEND_DELAY);
    });

    // save fake leak information and timestamp in local storage
    localStorage.setItem(`hifi:fakeLeakStart`, String(Date.now()));
    // save fake leak information and timestamp in local storage
    localStorage.setItem(`hifi:fakeLeakLocation`, String(startValue));
    // save fake leak information and timestamp in local storage
    localStorage.setItem(
      `hifi:fakeLeakStrength`,
      String(leakStrength ? leakStrength.toUpperCase() : "")
    );
  };

  const handlePipelineChange = (selectedItem: OptionType) => {
    const pipeline = pipelines.find(
      pipeline => pipeline.id === selectedItem.value
    );

    if (pipeline && onPipelineChange) {
      onPipelineChange(pipeline);
    }
  };

  const handleFiberChange = (selectedItem: OptionType) => {
    if (onFiberChange) {
      onFiberChange(getPipelineChKpMap(pipeline, selectedItem.value));
    }
  };

  const handleLeakStrengthChange = (selectedItem: OptionType) => {
    if (onLeakStrengthChange) {
      onLeakStrengthChange(selectedItem.value);
    }
  };

  const handleGlobalTestFlagChange = (selectedItem: OptionType) => {
    if (onGlobalTestFlagChange) {
      onGlobalTestFlagChange(selectedItem.value);
    }
  };

  const handleRangeUnitChange = (value: number) => {
    setRangeUnit(value);
  };

  const handleRangeChange = (start: number, end: number) => {
    setStartValue(start);
    setEndValue(end);

    if (setMapRange) {
      setMapRange(getRangeIndexes(pipeline, fiber, { start, end }, rangeUnit));
    }
  };

  const [recentAreas, setRecentAreas] = useState(getSearchHistory());

  useEffect(() => {
    setSearchHistory(recentAreas);
  }, [recentAreas]);

  useEffect(() => {
    if (setMapRange && !KPRange) {
      setMapRange(
        getRangeIndexes(
          pipeline,
          fiber,
          { start: startValue, end: endValue },
          rangeUnit
        )
      );
    }
  }, [setMapRange, KPRange, pipeline, fiber, startValue, endValue, rangeUnit]);

  const time = formatTime(
    parseInt(String(localStorage.getItem("hifi:fakeLeakStart")), 10),
    {
      timezoneAbbr: activeOrg ? activeOrg.timezoneAbbr : "",
      utcOffset: activeOrg ? activeOrg.utcOffset : ""
    }
  );
  return (
    <>
      {leakCooldown ? (
        <div className={styles.FilterAttribute}>
          <Text component="span" size="regular">
            {`A fake leak request has been submitted with the following specifications.`}
          </Text>
          <br />
          <Text component="span" size="small">
            {` - Location:  KP ${localStorage.getItem(
              "hifi:fakeLeakLocation"
            )}`}
          </Text>
          <br />
          <Text component="span" size="small">
            {` -Strength:  ${localStorage.getItem("hifi:fakeLeakStrength")}`}
          </Text>
          <br />
          <Text component="span" size="small">
            {` -Start Time:  ${time}`}
          </Text>
          <br />
          <br />
          <Text component="span" size="regular">
            {`New requests can be submitted 5 minutes after the start time indicated for the current fake leak.`}
          </Text>
        </div>
      ) : (
        <div>
          {sending ? (
            <LoadingWrapper loading />
          ) : (
            <div>
              <div className={styles.FilterAttribute}>
                <PipelineSelect
                  pipelines={pipelines.filter(
                    pipeline => pipeline.interfaceOptions.hideHeartbeat !== true
                  )}
                  selectedPipelineId={pipeline.id}
                  fiber={fiber}
                  onPipelineChange={handlePipelineChange}
                  onFiberChange={handleFiberChange}
                  changePipelineURI={changePipelineURI}
                />
              </div>
              <hr className={styles.FiltersPanelSeparator} />
              <div className={styles.FilterAttribute}>
                <RangeSelector
                  key="range"
                  pipeline={pipeline}
                  fiber={fiber}
                  precision={precision}
                  rangeUnit={rangeUnit}
                  minRange={minRange}
                  maxRange={maxRange}
                  rangeStep={rangeStep}
                  rangeStart={rangeStart}
                  rangeEnd={rangeEnd}
                  startValue={startValue}
                  endValue={endValue}
                  onChange={handleRangeChange}
                  onUnitChange={handleRangeUnitChange}
                  onSearch={setRecentAreas}
                  onFakeLeakInitiate={processFakeLeak}
                  singleChannelFlag={singleChannelFlag}
                />
              </div>
              <hr className={styles.FiltersPanelSeparator} />
              <div className={styles.FilterAttribute}>
                <LeakStrengthSelect
                  onStrengthChange={handleLeakStrengthChange}
                />
              </div>
              <hr className={styles.FiltersPanelSeparator} />
              {permissions.user["fake-leak-ultimate-test"] ? (
                <div className={styles.FilterAttribute}>
                  <GlobalTestSelect onFlagChange={handleGlobalTestFlagChange} />
                </div>
              ) : (
                undefined
              )}
            </div>
          )}{" "}
        </div>
      )}
      <hr className={styles.FiltersPanelSeparator} />
      {singleChannelFlag ? (
        undefined
      ) : (
        <div className={styles.FilterAttribute}>
          <RecentAreas />
        </div>
      )}
    </>
  );
};

export default AnalysisFilters;
