import { gqlType } from "@hifieng/common";
import { SchematicMapDataPointType } from "../components/SchematicMap";

export const getSchematicMapInputs = (
  pipelines?: Array<gqlType.Pipeline>,
  pipelineId?: string
): { assets: Array<SchematicMapDataPointType>; domain: Array<number> } => {
  let assets: Array<SchematicMapDataPointType> = [];
  const domain: Array<number> = [0, 0];

  if (!pipelines || !pipelineId) return { assets, domain };
  const pipeline = pipelines.find(pipeline => pipeline.id === pipelineId);
  if (pipeline) {
    assets = pipeline.assets.map(asset => {
      return {
        id: asset.id,
        category: "asset",
        type: asset.type,
        post: asset.post
      };
    });

    if (pipeline.kmPosts && pipeline.kmPosts.length) {
      domain[0] = pipeline.kmPosts[0].post;
      domain[1] = pipeline.kmPosts[pipeline.kmPosts.length - 1].post;
    } else {
      domain[0] = 0;
      domain[1] = 1;
    }
  }

  return { assets, domain };
};
