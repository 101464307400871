import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Strain = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 14 14">
      <path
        transform="translate(0 -1)"
        fill="currentColor"
        d="M1.07339 9.5h4.9255c.13808 0 .25.11193.25.25v.0011l-.02004 4.54747c-.00122.27614.22165.50098.49779.5022a.5.5 0 00.40124-.19872l6.26328-8.29565c.16639-.22038.12262-.53392-.09776-.70031a.5.5 0 00-.30382-.10096l-4.98699.02533c-.13807.0007-.25056-.11065-.25126-.24872a.25.25 0 010-.00259l.01918-3.64984c.00145-.27614-.22123-.50117-.49737-.50262a.5.5 0 00-.38539.17829L.69063 8.6783c-.17768.21139-.15034.52679.06105.70446a.5.5 0 00.3217.11724z"
      />
    </svg>
  );
};

export default Strain;
