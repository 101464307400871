import React from "react";
import styles from "./index.module.scss";
import cx from "classnames";

type PropsType = {
  headerComponent: React.ReactNode;
  filterComponent: React.ReactNode;
  contentComponent: React.ReactNode;
  focusContentOnMobile?: boolean;
};

const FilterContentsSection = ({
  focusContentOnMobile,
  headerComponent,
  filterComponent,
  contentComponent
}: PropsType) => {
  return (
    <div className={styles.Container}>
      <div className={styles.HeaderWrapper}>{headerComponent}</div>
      <div
        className={cx(styles.FilterContentWrapper, {
          [styles.MobileFocusContent]: focusContentOnMobile
        })}
      >
        <div className={styles.Filter}>
          <div className={styles.FiltersPanel}>{filterComponent}</div>
        </div>
        <div className={styles.ContentModalWrapper}>
          <div className={styles.ContentContainer}>{contentComponent}</div>
        </div>
      </div>
    </div>
  );
};

export default FilterContentsSection;
