import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import TabbedContent, { IActivatedCallBack } from "../TabbedContent";
import ScreenLayout from "../ScreenLayout";
import ActiveRuns from "./ActiveRuns";
import CompletedRuns from "./CompletedRuns";
import { useAuth } from "../../contexts/AuthProvider";

import MobilePageTitle from "../MobilePageTitle";
import TrainHeaderProvider, {
  useTrainHeaderContext
} from "./TrainHeaderProvider";
import TrainFiltersProvider from "./TrainFiltersProvider";
import LoadingWrapper from "../LoadingSpinnerWrapper";

export const ACTIVE_URL = "/train-tracking/active";
export const COMPLETED_URL = "/train-tracking/history";

const TrainTracking = () => {
  const { permissions } = useAuth();
  const { trainCount, loading } = useTrainHeaderContext();

  const [currentTab, setCurrentTab] = useState("active");
  const [subTitle, setSubTitle] = useState(
    `${trainCount.active} ${currentTab} runs`
  );

  useEffect(() => {
    const count =
      currentTab === "active" ? trainCount.active : trainCount.completed;
    setSubTitle(`${count} ${currentTab} runs`);
  }, [trainCount, currentTab]);

  const onActivated = ({ title }: IActivatedCallBack) => {
    setCurrentTab(title);
  };

  const activeTab = {
    url: ACTIVE_URL,
    title: `Active Runs (${trainCount.active})`,
    content: (
      <ActiveRuns
        hideEmptyCta={trainCount.active <= 0 && trainCount.completed <= 0}
        emptyUrl={COMPLETED_URL}
        onActivated={onActivated}
      />
    )
  };

  const completedTab = {
    url: COMPLETED_URL,
    title: `Completed Runs (${trainCount.completed})`,
    content: (
      <TrainFiltersProvider>
        <CompletedRuns
          loading={loading || false}
          empty={trainCount.completed === 0}
          onActivated={onActivated}
        />
      </TrainFiltersProvider>
    )
  };
  const tabs = [activeTab, completedTab];

  if (loading) {
    return <LoadingWrapper loading />;
  } else if (permissions.user["train-tracking"]) {
    return (
      <ScreenLayout title="Train Tracking" subtitle={subTitle}>
        <MobilePageTitle page="Train Tracking" subtitle={subTitle} />
        <TabbedContent tabs={tabs} title="Train Tracking Navigation" />
      </ScreenLayout>
    );
  } else {
    return <Redirect to="/" />;
  }
};

const TrainTrackingPage = () => (
  <TrainHeaderProvider>
    <TrainTracking />
  </TrainHeaderProvider>
);

export default TrainTrackingPage;
