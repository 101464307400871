import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Valve = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 13 9">
      <g
        fill="none"
        stroke="currentColor"
        strokeWidth=".63158"
        fillRule="evenodd"
        transform="translate(.5 0.6)"
      >
        <path d="M6.24198 4.45885L.78314 8.31504V.71795zM6.24199 4.44729L11.70083.5911v7.59708z" />
      </g>
    </svg>
  );
};

export default Valve;
