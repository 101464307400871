export const priorityColorMap = {
  low: "#0062ff", // blue-60
  medium: "#f37113", // orange-60
  high: "#da1e28" // red-60
};

export const color = {
  white: "#ffffff", // white
  black: "#000000", // black
  silverChalice: "#a8a8a8", // gray-40
  gray: "#8d8d8d", // gray-50
  doveGrey: "#6f6f6f", // gray-60
  emperor: "#525252", // gray-70
  mineShaft: "#393939", // gray-80
  darkMineShaft: "#262626", // gray-90
  codGray: "#161616", // gray-100
  success: "#1baa55", // Success
  viking: "#60cadf",
  cornflowerLilac: "#ffa4a9", //red 30
  valentineRed: "#e34d55", //red 40
  alizarinCrimson: "#da1e28", // red-60
  emerald: "#56d679", //green 30
  forestGreen: "#1a8038", // green-60
  blueRibbon: "#0062ff", // blue-60
  denim: "#1B6AE1",
  oceanBlue: "#0630ad", // blue-80
  catalinaBlue: "#061f81", // blue-90
  macaroniAndCheese: "#ffba80", //orange 30
  christine: "#f37113", // orange-60
  mauve: "#d0b0ff", //purple 30
  electricViolet: "#8a3ffd" //purple 60
};

export const pipelineColorMap: { [id: string]: string } = {
  blue: color.viking,
  "red-60": color.alizarinCrimson,
  "green-60": color.forestGreen,
  "orange-60": color.christine,
  "purple-60": color.electricViolet,
  "red-40": color.valentineRed,
  "red-30": color.cornflowerLilac,
  "green-30": color.emerald,
  "orange-30": color.macaroniAndCheese,
  "purple-30": color.mauve,
  white: color.white
};
