import React from "react";
import { gqlType } from "@hifieng/common";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Heading, Text } from "../../Type";
import { useModalContext } from "../../../contexts/ModalProvider";
import Modal from "../../Modal";
import { Secondary, Important } from "../../Button";
import FileDetails from "../FileDetails";
import Alert from "../../Alert";
import { useOrganizationContext } from "../../../contexts/OrganizationProvider";
import styles from "./index.module.scss";
import { analytics } from "../../../analytics";
import { useHistory } from "react-router";
import { ApolloError } from "apollo-boost";
import { logErrorToLogdna } from "../../../helpers/logdna";

const ERROR_CODES = {
  deletingReport: "DELETING_REPORT",
  deletingReportAnalytics: "DELETING_REPORT_ANALYTICS"
};

const MARK_AS_DELETED = gql`
  mutation($name: String!, $organizationId: String!) {
    deleteReport(name: $name, organizationId: $organizationId)
  }
`;

type PropsType = {
  report?: gqlType.Report;
  onDelete: () => void;
};

const DeleteModal = ({ report, onDelete }: PropsType) => {
  const history = useHistory();
  const { activeOrg } = useOrganizationContext();
  const [deleteReport] = useMutation(MARK_AS_DELETED, {
    variables: {
      name: report ? report.name : "",
      organizationId: (activeOrg && activeOrg.id) || ""
    },
    refetchQueries: ["reports"],
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        ERROR_CODES.deletingReport,
        "Apollo error while deleting report.",
        err
      );
      throw err;
    }
  });

  const { setOpenModal, openModal } = useModalContext();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  if (!report) {
    return null;
  }

  const onDeleteClick = () => {
    analytics.reportDeleted(report.title, report.contentType);
    deleteReport()
      .then(() => {
        history.push("/reports");
        setSuccess(true);
        onDelete();
      })
      .catch(e => {
        setError(e);
      });
  };

  return (
    <Modal
      className={styles.DeleteModal}
      isOpen={openModal === "delete-report"}
    >
      <div>
        <Heading size="h6" className={styles.ModalTitle}>
          Delete Report
        </Heading>
        <Text className={styles.Confirm}>
          Are you sure you want to delete this file?
        </Text>
        <FileDetails
          file={{
            title: report.title,
            sizeInByte: report.sizeInByte,
            contentType: report.contentType
          }}
        />
        {error ? (
          <Alert
            className={styles.Alert}
            type="error"
            message="File not deleted. Try again."
          />
        ) : null}
        <div className={styles.Buttons}>
          <Secondary
            buttonSize="small"
            className={styles.Button}
            onClick={() => {
              setError(false);
              setOpenModal("");
            }}
          >
            {success ? "Close" : "Cancel"}
          </Secondary>
          {!success ? (
            <Important
              buttonSize="small"
              className={styles.Button}
              onClick={onDeleteClick}
            >
              Delete
            </Important>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
