import React from "react";

type PropsType = {
  className: string;
};

const NextLine = ({ className }: PropsType) => {
  return (
    <svg
      width="5px"
      height="9px"
      viewBox="0 0 5 9"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="currentColor" strokeWidth="1" strokeLinecap="square">
        <line x1="0.5" y1="0.5" x2="0.5" y2="8.5"></line>
        <line x1="4.5" y1="8.5" x2="0.5" y2="8.5"></line>
      </g>
    </svg>
  );
};

export default NextLine;
