import React from "react";
import { Text } from "../Type";
import { eventsLimit } from "../../helpers/pagination";

type PropsType = {
  total: number;
  page: number;
  type?: string;
};

const PaginationPageDisplay = ({ total, page, type = "events" }: PropsType) => {
  if (total > eventsLimit) {
    const page0 = Math.max(0, page - 1);
    const start = page0 * eventsLimit + 1;
    const end = Math.min(total, page0 * eventsLimit + eventsLimit);

    return (
      <Text component="span" dark>
        {`${start}-${end} of ${total} ${type}`}
      </Text>
    );
  }

  return null;
};

export default PaginationPageDisplay;
