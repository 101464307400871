import React from "react";
import { NavLink } from "react-router-dom";

import { gqlType } from "@hifieng/common";
import { Heading, Meta, Text } from "../../Type";
import styles from "./index.module.scss";
import DateTime from "../../DateTime";
import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";
import { ArrowPoint } from "../../Icons";
import { kpMpRatio } from "../../AnalysisCharts/BaseChart";
import { useAuth } from "../../../contexts/AuthProvider";

type PropsType = {
  pigRun: gqlType.PigRun;
  url: string;
};

const Completed = ({ pigRun, url }: PropsType) => {
  const { permissions } = useAuth();
  return (
    <NavLink to={url} className={styles.CompletedCard}>
      <div className={styles.Header}>
        <div className={styles.TitleGroup}>
          <Heading className={styles.Type} size="h7">
            {pigRun.pipeline.name} - Run {pigRun.runId}
          </Heading>
          <Heading className={styles.TypeDark} size="h7">
            {pigRun.type ? pigRun.type.toLocaleLowerCase() : "Unknown"} pig
          </Heading>
          <div className={styles.InfoGroupWrapper}>
            <div className={styles.InfoGroup}>
              <Meta dark>START</Meta>
              <Text className={styles.Type}>
                <DateTime
                  timestamp={pigRun.startTime * MILLISECONDS_IN_SECONDS}
                  length="long"
                />
                <br />
                {(permissions.user.imperial ? true : false) ||
                pigRun.pipelineId === "enbridge_line_3" ||
                pigRun.pipelineId === "enbridge_line_4"
                  ? `MP ${pigRun.startPost * kpMpRatio}`
                  : `KP ${pigRun.startPost}`}
              </Text>
            </div>
            <div className={styles.IconContainer}>
              <ArrowPoint />
            </div>
            {pigRun.endPost && pigRun.endTime && (
              <div className={styles.InfoGroup}>
                <Meta dark>END</Meta>
                <Text className={styles.Type}>
                  <DateTime
                    timestamp={pigRun.endTime * MILLISECONDS_IN_SECONDS}
                    length="long"
                  />
                  <br />
                  {(permissions.user.imperial ? true : false) ||
                  pigRun.pipelineId === "enbridge_line_3" ||
                  pigRun.pipelineId === "enbridge_line_4"
                    ? `MP ${pigRun.endPost * kpMpRatio}`
                    : `KP ${pigRun.endPost}`}
                </Text>
              </div>
            )}
          </div>
        </div>
        <div className={styles.Updates}>
          <Text className={styles.Type}>
            {pigRun.numberOfUpdates} Updates - {pigRun.numberOfCriticalEvents}{" "}
            Critical Events
          </Text>
          <Text
            className={styles.Type}
          >{`${pigRun.auditLogs.length} Notes`}</Text>
        </div>
      </div>
    </NavLink>
  );
};

export default Completed;
