import React from "react";

import { gqlType } from "@hifieng/common";
import { IPlotlyTrace } from "../../types/AnalysisTypes";
import HeatMap from "./HeatMap";
import ScatterLines from "./ScatterLines";
import { color } from "../../styles/colors";

export { default as ScatterLines } from "./ScatterLines";
export { default as HeatMap } from "./HeatMap";

export enum ChartTypes {
  ScatterLines,
  HeatMap
}

export interface IChartProps {
  type?: ChartTypes;
  windowSize: number;
  loading?: boolean;
  title: string;
  subTitle: string;
  timeRange: Array<number>;
  postRange: Array<number>;
  unit: number;
  fiber: gqlType.ChannelKPostMap;
  data: Array<IPlotlyTrace>;
  rawData: Array<gqlType.SummaryData>;
  connected: boolean;
  live: boolean;
  onPauseToggle?: React.MouseEventHandler<HTMLButtonElement>;
  onTypeChange?: Function;
  onShadingChange?: Function;
  minVal?: number;
  maxVal?: number;
}

// Plotly creates a ton of spelling issues so we're going to turn off the spell
// checker for a moment
/* cSpell:disable */
export const axisDefaults = {
  gridcolor: color.mineShaft,
  ticklabeloverflow: "allow",
  ticks: true,
  ticklen: 5,
  tickcolor: color.doveGrey,
  showline: true
};
/* cSpell:enable */

const AnalysisChart = (props: IChartProps) => {
  switch (props.type) {
    case ChartTypes.HeatMap:
      return <HeatMap {...props} />;
    default:
      return <ScatterLines {...props} />;
  }
};

export default AnalysisChart;
