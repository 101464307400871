import { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { gqlType } from "@hifieng/common";

import { ApolloError } from "apollo-boost";
import { logErrorToLogdna } from "../../../helpers/logdna";
import { useOrganizationContext } from "../../../contexts/OrganizationProvider";
import {
  pigRunFragment,
  pigsQueryComposer,
  COMPLETED_PIGS_SUBSCRIPTION
} from "../../../helpers/pigQueryComposer";

export const fragment = gql`
  fragment PigsFragment on PaginatedPigsAndTotal {
    pigs {
      ...PigRunFragment
      startLocation {
        latitude
        longitude
      }
      endLocation {
        latitude
        longitude
      }
      startPost
      endPost
      numberOfUpdates
      numberOfCriticalEvents
      auditLogs {
        id
        comment {
          message
        }
      }
    }
    resultCount
  }
  ${pigRunFragment}
`;

const COMPLETED_PIGS_QUERY = pigsQueryComposer(fragment);

export const useCompletedPigs = (filters: gqlType.PigsFiltersInput) => {
  const { activeOrg } = useOrganizationContext();
  const [pigRuns, setPigRuns] = useState();

  const { subscribeToMore, loading, data } = useQuery(COMPLETED_PIGS_QUERY, {
    variables: {
      organizationId: activeOrg ? activeOrg.id : undefined,
      skip: !activeOrg,
      filters: {
        ...filters,
        completed: true
      }
    },
    fetchPolicy: "cache-and-network",
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        "GETTING_completed_PIGS",
        "Apollo error while getting completed pigs.",
        err
      );
      throw err;
    }
  });

  useEffect(() => {
    if (!activeOrg) {
      return;
    }
    subscribeToMore({
      document: COMPLETED_PIGS_SUBSCRIPTION,
      variables: {
        organizationId: activeOrg.id
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        } else {
          const subscribed = subscriptionData.data.trackPig;
          const prevIds = prev.pigs.pigs.map((pig: gqlType.PigRun) => pig.id);
          if (subscribed.completed && !prevIds.includes(subscribed.id)) {
            const prevTotal: number = prev.pigs.resultCount;
            return Object.assign({}, prev, {
              pigs: {
                ...prev.pigs,
                pigs: [subscribed, ...prev.pigs.pigs].sort(
                  (a, b) => b.endTime - a.endTime
                ),
                resultCount: prevTotal + 1
              }
            });
          }
          return prev;
        }
      }
    });
    //suppressing hook dependency because `subscribeToMore` changes on every render
    // and the documentation suggest to only call it on component mount
    // https://www.apollographql.com/docs/react/data/subscriptions/#subscribing-to-updates-for-a-query
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrg]);

  useEffect(() => {
    if (data && data.pigs) setPigRuns(data.pigs);
  }, [data]);

  return {
    loading,
    pigRuns,
    total: data ? data.pigs.resultCount : 0
  };
};
