import React from "react";
import cx from "classnames";
import moment from "moment";
import { NavLink, useRouteMatch } from "react-router-dom";
import { gqlType } from "@hifieng/common";

import { Heading, Text } from "../../Type";
import styles from "./index.module.scss";
import DateTime from "../../DateTime";
import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";
import { getStatusText } from "../../../helpers/pigStatus";
import getPriorityLevel from "../../../helpers/getPriorityLevel";
import { coordWeldMap } from "../../../helpers/coordWeldMap";

type PropsType = {
  pigRun: gqlType.PigRun;
};

const DISPLAY_COORDINATE_DIGITS = 5;

const PigRun = ({ pigRun }: PropsType) => {
  const { status, timestamp, coordinates, speed, nextPoi } = pigRun.latest;

  const priority = getPriorityLevel(status);
  moment.locale("en-short", {
    parentLocale: "en",
    relativeTime: {
      m: "%d min",
      mm: "%d mins"
    }
  });
  let lastUpdated = moment.unix(timestamp).fromNow();
  if (lastUpdated === "a few seconds ago") lastUpdated = "secs ago";

  const statusText =
    status === gqlType.PigStatus.ApproachingPoi && nextPoi
      ? getStatusText(status).replace("POI", `${nextPoi.type} ${nextPoi.post}`)
      : getStatusText(status);
  const match = useRouteMatch();
  const COORDINATE_DIGITS = 6;
  const coordinatesString = `${coordinates.latitude.toFixed(
    COORDINATE_DIGITS
  )},${coordinates.longitude.toFixed(COORDINATE_DIGITS)}`;

  return (
    <NavLink
      to={`${match.url}/${pigRun.id}`}
      className={cx(styles.Card, {
        [styles.CardMedium]: priority === "medium",
        [styles.CardHigh]: priority === "high"
      })}
    >
      <div className={styles.Header}>
        <div className={styles.TitleGroup}>
          <Heading className={styles.Type} size="h7">
            {pigRun.pipeline.name} - Run {pigRun.runId}
          </Heading>
          <Heading className={styles.TypeDark} size="h7">
            {pigRun.type ? pigRun.type.toLowerCase() : "Unknown"} pig
          </Heading>
        </div>
        <div className={styles.InfoGroup}>
          <Text className={styles.Type}>
            {`${statusText}${
              pigRun.pipeline.id === "suncor_icp"
                ? ` (${coordWeldMap(coordinatesString)})`
                : ""
            }`}
          </Text>
          <Text className={cx(styles.Type, styles.TypeDark)}>
            <DateTime
              timestamp={timestamp * MILLISECONDS_IN_SECONDS}
              length="short"
            />
            {", "}
            {coordinates.latitude.toFixed(DISPLAY_COORDINATE_DIGITS)},{" "}
            {coordinates.longitude.toFixed(DISPLAY_COORDINATE_DIGITS)}
          </Text>
        </div>
        <Text
          size="small"
          className={cx(styles.PigStatus, styles.Type, {
            [styles.PigStatusYellow]: priority === "medium",
            [styles.PigStatusRed]: priority === "high"
          })}
        >
          {statusText} - {speed}m/second
          <Text size="small" dark component="span">
            Last Update - {lastUpdated}
          </Text>
        </Text>
      </div>
    </NavLink>
  );
};

export default PigRun;
