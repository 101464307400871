import React from "react";
import cx from "classnames";
import { Text } from "../../Type";
import { ResetCircle, Loading } from "../../Icons";
import CheckboxGroup from "../../CheckboxGroup";

import styles from "./index.module.scss";

type PropsType = {
  showResetMap: boolean;
  isSearching?: boolean;
  onResetMap?: () => void;
  searchOnMapMove: boolean;
  setSearchOnMapMove: (searchOnMove: boolean) => void;
  canSearchOnMapMove: boolean;
};

const EventMapButtons = ({
  showResetMap,
  isSearching,
  onResetMap,
  searchOnMapMove,
  setSearchOnMapMove,
  canSearchOnMapMove
}: PropsType) => {
  return (
    <>
      {onResetMap && (
        <button
          className={cx(styles.Button, styles.Reset, {
            [styles.ResetShow]: showResetMap
          })}
          type="button"
          onClick={onResetMap}
        >
          <Text className={styles.Text} component="div" size="small">
            Reset map view
          </Text>
          <ResetCircle />
        </button>
      )}

      {canSearchOnMapMove ? (
        <div className={cx(styles.Button, styles.SearchButton)}>
          <CheckboxGroup
            small
            noPad
            noCapitalize
            onChange={() => {
              const newState = !searchOnMapMove;
              setSearchOnMapMove(newState);
            }}
            checkboxValue={[searchOnMapMove.toString()]}
            name="searchOnMapMove"
            options={[{ value: "true", label: "Search as map moves" }]}
          />
          {isSearching && (
            <span className={styles.SearchButtonLoading}>
              <Loading />
            </span>
          )}
        </div>
      ) : null}
    </>
  );
};

export default EventMapButtons;
