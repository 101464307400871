import React, { useContext, useEffect } from "react";
// https://github.com/moment/moment/issues/4877
// eslint-disable-next-line
import { Moment } from "moment";
import { useQueryParams, IQueryParamsType } from "../../hooks/useQueryParams";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import { OptionType } from "../../types/GeneralTypes";
import { gqlType } from "@hifieng/common";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { sortByKey } from "../../helpers/sortByKey";
import { logErrorToLogdna } from "../../helpers/logdna";
import { ApolloError } from "apollo-boost";
import {
  getQueryParamDateRange,
  DateRange
} from "../../helpers/getDateFromQueryParams";

interface IContextType {
  startDate: Moment | null;
  endDate: Moment | null;
  dateRange: DateRange;
  updateDateRange: (arg0: DateRange) => void;
  applyDateRange: (arg0: DateRange) => void;
  focusedDateRangeInput: "startDate" | "endDate" | null;
  setFocusedDateRangeInput: (arg0: "startDate" | "endDate" | null) => void;
  selectedPipelines: Array<string>;
  pipelineOptions: Array<OptionType>;
  setSelectedPipelines: (arg0: Array<string>) => void;
  selectedEventTypes: Array<string>;
  eventTypeOptions: Array<OptionType>;
  setSelectedEventTypes: (arg0: Array<string>) => void;
  sortBy: string;
  sortByOptions: Array<OptionType>;
  setSortBy: (arg0: string) => void;
  page?: number;
  setPage: (arg0?: number) => void;
  resetAllFilters: () => void;
}

const ERROR_CODES = {
  updatingHistoryFiltersProvider: "UPDATING_HISTORY_FILTERS_PROVIDER"
};

const notInitializedError = () => {
  throw new Error("History Filters context has not yet been initialized.");
};

const INITIAL_CONTEXT: IContextType = {
  startDate: null,
  endDate: null,
  dateRange: {
    startDate: null,
    endDate: null
  },
  updateDateRange: () => {
    notInitializedError();
  },
  applyDateRange: () => {
    notInitializedError();
  },
  focusedDateRangeInput: "startDate",
  setFocusedDateRangeInput: () => {
    notInitializedError();
  },
  selectedPipelines: [],
  pipelineOptions: [],
  setSelectedPipelines: () => {
    notInitializedError();
  },
  selectedEventTypes: [],
  eventTypeOptions: [],
  setSelectedEventTypes: () => {
    notInitializedError();
  },
  sortBy: "TIME_DESC",
  sortByOptions: [],
  setSortBy: () => {
    notInitializedError();
  },
  page: 1,
  setPage: () => {
    notInitializedError();
  },
  resetAllFilters: () => {
    notInitializedError();
  }
};

export const HistoryFiltersContext = React.createContext(INITIAL_CONTEXT);
export const useHistoryFiltersContext = () => useContext(HistoryFiltersContext);

export const dateFilterName = "date-filter";
export const pipelineFilterName = "pipeline-filter";
export const eventTypeFilterName = "event-type-filter";

type PropsType = {
  children: React.ReactNode;
};

export const HistoryFiltersProvider = ({ children }: PropsType) => {
  const { setQueryParams, queryParams } = useQueryParams();

  const queryParamDateRange = getQueryParamDateRange(queryParams);
  const [dateRange, setDateRange] = React.useState<DateRange>(
    queryParamDateRange
  );
  const sortByOptions = [
    { value: "TIME_DESC", label: "Newest" },
    { value: "TIME_ASC", label: "Oldest" }
  ];

  const [focusedDateRangeInput, setFocusedDateRangeInput] = React.useState<
    "startDate" | "endDate" | null
  >("startDate");

  const [eventTypeOptions, setEventTypeOptions] = React.useState<
    Array<OptionType>
  >([]);

  const [pipelineOptions, setPipelineOptions] = React.useState<
    Array<OptionType>
  >([]);

  const { activeOrg } = useOrganizationContext();

  const [getEventTypes, eventListResponse] = useLazyQuery(
    gql`
      query eventTypes($filters: EventFiltersInput) {
        events(filters: $filters) {
          eventTypes
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      onError: (err: ApolloError) => {
        logErrorToLogdna(
          ERROR_CODES.updatingHistoryFiltersProvider,
          "Apollo error while adding comment.",
          err
        );
        return err;
      }
    }
  );

  React.useEffect(() => {
    if (activeOrg) {
      setPipelineOptions(
        activeOrg.pipelines
          .filter(pipeline => pipeline.segments.length > 0)
          .map((pipeline: gqlType.Pipeline) => ({
            value: pipeline.id,
            label: pipeline.name
          }))
          .sort(sortByKey("label"))
      );
    }
  }, [activeOrg, setPipelineOptions]);

  React.useEffect(() => {
    if (activeOrg) {
      getEventTypes({
        variables: {
          filters: { pipelines: activeOrg.pipelines.map(p => p.id) }
        }
      });
    }
  }, [activeOrg, getEventTypes]);

  React.useEffect(() => {
    if (!eventListResponse.loading && eventListResponse.data) {
      const currentOptions: Array<OptionType> = eventListResponse.data.events.eventTypes
        .map((e: string) => ({
          label: e.charAt(0).toUpperCase() + e.slice(1),
          value: e
        }))
        .sort(sortByKey<OptionType, "label">("label"));

      if (JSON.stringify(currentOptions) !== JSON.stringify(eventTypeOptions)) {
        setEventTypeOptions(currentOptions);
      }
    }
  }, [eventTypeOptions, eventListResponse]);

  const setNewFilter = (filters: IQueryParamsType) => {
    setQueryParams({ ...filters, page: undefined }, "push");
  };

  const resetAllFilters = () => {
    setDateRange(INITIAL_CONTEXT.dateRange);
    setQueryParams(
      {
        pipelines: undefined,
        start: undefined,
        end: undefined,
        types: undefined,
        sortBy: undefined,
        page: undefined
      },
      "push"
    );
  };

  const setSelectedEventTypes = (newEventTypes: Array<string>) => {
    if (Array.isArray(newEventTypes) && newEventTypes.length === 0) {
      setNewFilter({ types: undefined });
    } else {
      setNewFilter({ types: newEventTypes });
    }
  };

  const setSelectedPipelines = (newPipelines: Array<string>) => {
    if (Array.isArray(newPipelines) && newPipelines.length === 0) {
      setNewFilter({ pipelines: undefined });
    } else {
      setNewFilter({ pipelines: newPipelines });
    }
  };

  const setSortBy = (newSortBy: string) => {
    if (!newSortBy) {
      setNewFilter({ sortBy: undefined });
    } else {
      setNewFilter({ sortBy: newSortBy });
    }
  };

  const updateDateRange = (dateRange: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setDateRange({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate
    });
  };
  const setPage = (page?: number) => {
    if (page) {
      setQueryParams({ page }, "push");
    } else {
      setQueryParams({ page: undefined }, "push");
    }
  };

  const applyDateRange = (dateRangeArg: DateRange) => {
    if (dateRangeArg.startDate && dateRangeArg.endDate) {
      setNewFilter({
        start: dateRangeArg.startDate.unix().toString(),
        end: dateRangeArg.endDate.unix().toString()
      });
    }
    if (!dateRange.startDate && !dateRange.endDate) {
      setNewFilter({
        start: undefined,
        end: undefined
      });
    }
  };
  useEffect(() => {
    const range = getQueryParamDateRange(queryParams);
    setDateRange(range);
  }, [queryParams]);

  return (
    <HistoryFiltersContext.Provider
      value={{
        startDate: queryParamDateRange.startDate,
        endDate: queryParamDateRange.endDate,
        dateRange,
        updateDateRange,
        applyDateRange,
        focusedDateRangeInput,
        setFocusedDateRangeInput,
        selectedPipelines:
          queryParams.pipelines && queryParams.pipelines.length
            ? queryParams.pipelines.split(",")
            : [],
        pipelineOptions,
        setSelectedPipelines,
        selectedEventTypes:
          queryParams.types && queryParams.types.length
            ? queryParams.types.split(",")
            : [],
        eventTypeOptions,
        setSelectedEventTypes,
        sortBy: queryParams.sortBy ? queryParams.sortBy : "TIME_DESC",
        sortByOptions,
        setSortBy,
        page: queryParams.page ? queryParams.page : undefined,
        setPage,
        resetAllFilters
      }}
    >
      {children}
    </HistoryFiltersContext.Provider>
  );
};

export default HistoryFiltersProvider;
