import React, { useState, useMemo } from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";
import { NavLink, useRouteMatch } from "react-router-dom";
import qs from "qs";

import { Text } from "../../Type";
import DateTime from "../../DateTime";
import { TextLink } from "../../Button";
import { Pig } from "../../Icons";

import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";
import { getStatusText } from "../../../helpers/pigStatus";
import { coordWeldMap } from "../../../helpers/coordWeldMap";
import { formatPigUpdates } from "./formatUpdates";
import getPriorityLevel from "../../../helpers/getPriorityLevel";

import styles from "./index.module.scss";
import { useSelectedPig } from "../useSelectedPig";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { useOrganizationContext } from "../../../contexts/OrganizationProvider";

type Props = {
  updates: Array<gqlType.PigRunUpdate>;
};

type UpdateItemProps = {
  update: gqlType.PigRunUpdate;
  withIcon?: boolean;
  pulse?: boolean;
};

const UpdateItem = ({ update, withIcon, pulse }: UpdateItemProps) => {
  const { updateId } = useSelectedPig();
  const { queryParams } = useQueryParams();
  const priority = getPriorityLevel(update.status);
  const match = useRouteMatch();
  const showPulse =
    (pulse || false) && update.status !== gqlType.PigStatus.Completed;

  const COORDINATE_DIGITS = 6;
  const coordinatesString = `${update.coordinates.latitude.toFixed(
    COORDINATE_DIGITS
  )},${update.coordinates.longitude.toFixed(COORDINATE_DIGITS)}`;
  const { activeOrg } = useOrganizationContext();
  const weldFlag = activeOrg && activeOrg.id === "suncor";

  return (
    <NavLink
      to={{
        pathname: `${match.url}/${update.id}`,
        search: `?${qs.stringify(queryParams, {
          arrayFormat: "comma",
          encode: false
        })}`
      }}
      className={cx(
        styles.LogEntry,
        withIcon && styles.LogEntryWithIcon,
        updateId === update.id && styles.LogEntrySelected
      )}
    >
      {withIcon ? (
        <span
          className={cx(styles.PigIcon, {
            [styles.PigIconLow]: priority === "low" && !showPulse,
            [styles.PigIconMedium]: priority === "medium" && !showPulse,
            [styles.PigIconHigh]: priority === "high" && !showPulse,
            [styles.PigIconLowPulse]: priority === "low" && showPulse,
            [styles.PigIconMediumPulse]: priority === "medium" && showPulse,
            [styles.PigIconHighPulse]: priority === "high" && showPulse
          })}
        >
          <Pig size={10} />
        </span>
      ) : (
        <span
          className={cx(styles.LogEntryIcon, {
            [styles.LogEntryIconLow]: priority === "low",
            [styles.LogEntryIconMedium]: priority === "medium",
            [styles.LogEntryIconHigh]: priority === "high"
          })}
        />
      )}
      <Text className={styles.Text}>
        {update.status === gqlType.PigStatus.ApproachingPoi && update.nextPoi
          ? getStatusText(update.status).replace(
              "POI",
              `${update.nextPoi.type} ${update.nextPoi.post}`
            )
          : getStatusText(update.status)}{" "}
        - {update.coordinates.latitude}, {update.coordinates.longitude}
      </Text>
      <Text size="small" component="span" dark className={styles.SmallText}>
        <DateTime
          timestamp={update.timestamp * MILLISECONDS_IN_SECONDS}
          length="short"
        />
        {weldFlag ? ` (${coordWeldMap(coordinatesString)})` : ""}
      </Text>
    </NavLink>
  );
};

const NestedUpdate = ({ updates }: Props) => {
  const [flattened, setFlattened] = useState(false);
  return !flattened ? (
    <div className={styles.LogCollapsed}>
      <Text className={styles.Text}>
        {`${updates.length} other ${getStatusText(
          updates[0].status
        ).toLowerCase()} update${updates.length > 1 ? "s" : ""}`}
      </Text>
      <TextLink
        onClick={() => setFlattened(true)}
        className={styles.ToggleCollapsedButton}
      >
        View More
      </TextLink>
    </div>
  ) : (
    <>
      {updates.map(update => (
        <UpdateItem update={update} key={update.id} />
      ))}
      <TextLink
        onClick={() => setFlattened(false)}
        className={styles.ViewLessButton}
      >
        View Less
      </TextLink>
    </>
  );
};

const PigUpdates = ({ updates }: Props) => {
  const formatted = useMemo(() => {
    return formatPigUpdates(updates);
  }, [updates]);

  return (
    <div className={styles.LogList}>
      {formatted.map((item, i) =>
        Array.isArray(item) ? (
          <NestedUpdate updates={item} key={`nested-${item[0].id}`} />
        ) : i === 0 ? (
          <UpdateItem update={item} key={item.id} withIcon pulse />
        ) : (
          <UpdateItem update={item} key={item.id} withIcon />
        )
      )}
    </div>
  );
};

export default PigUpdates;
