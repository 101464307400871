import React from "react";

import EventModal from "./EventModal";
import PaginationButtons from "../PaginationButtons";
import EmptyListState from "../EmptyListState";

import { useQueryParams } from "../../hooks/useQueryParams";
import { eventsLimit } from "../../helpers/pagination";

import styles from "./index.module.scss";
import LoadingWrapper from "../LoadingSpinnerWrapper";
import PaginationPageDisplay from "../PaginationPageDisplay";

type PropsType = {
  totalEvents: number;
  loading: boolean;
  listComponent: React.ReactNode;
  mapComponent: React.ReactNode;
  headerComponent?: React.ReactNode;
  emptyStateText: string;
  selectedEvent?: string;
  emptyCta?: React.ReactNode;
  emptyDescription?: string;
  paginate?: boolean;
};

const ListDetailsSection = ({
  mapComponent,
  loading,
  headerComponent,
  listComponent,
  totalEvents,
  emptyStateText,
  selectedEvent,
  emptyCta,
  emptyDescription,
  paginate = true
}: PropsType) => {
  const { queryParams, setQueryParams } = useQueryParams();
  let { page } = queryParams;
  if (!page) {
    page = 1;
  } else {
    page = parseInt(page, 10);
  }

  return (
    <div className={styles.Container}>
      <div className={styles.HeaderWrapper}>{headerComponent}</div>
      <div className={styles.ListMapWrapper}>
        <div className={styles.List}>
          {loading ? (
            <LoadingWrapper loading />
          ) : totalEvents < 1 && !loading ? (
            <EmptyListState
              loading={loading}
              text={emptyStateText}
              cta={emptyCta}
              description={emptyDescription}
            />
          ) : (
            <>
              {paginate && totalEvents > eventsLimit && (
                <div className={styles.PaginationHeading}>
                  <PaginationPageDisplay total={totalEvents} page={page} />
                </div>
              )}
              {listComponent}
              {paginate && (
                <PaginationButtons
                  updatePage={page => setQueryParams({ page }, "push")}
                  currentPage={page}
                  totalEvents={totalEvents}
                  limit={eventsLimit}
                />
              )}
            </>
          )}
        </div>
        <div className={styles.MapModalWrapper}>
          <div className={styles.MapContainer}>{mapComponent}</div>
          {selectedEvent && <EventModal referenceId={selectedEvent} />}
        </div>
      </div>
    </div>
  );
};

export default ListDetailsSection;
