class AppError extends Error {
  title: string;
  subtitle: string;

  constructor(title: string, subtitle?: string) {
    super(`${title}: ${subtitle}`);
    this.name = "AppError";
    this.title = title;
    this.subtitle = subtitle || "";
  }
}

export default AppError;
