import { useRouteMatch } from "react-router-dom";

export const useSelectedPig = () => {
  const match = useRouteMatch();
  const slugMatch = useRouteMatch<{
    updateId?: string;
  }>(`${match.url}/:updateId`);
  const updateId =
    slugMatch && slugMatch.params ? slugMatch.params.updateId : undefined;

  return {
    updateId
  };
};
