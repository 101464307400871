import React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";

import ErrorBoundary from "../ErrorBoundary";
import ErrorScreen from "../ErrorScreen";
import { LogoCircle } from "../Icons";
import PageTitle from "../PageTitle";
import { Text } from "../Type";
import { useLiveStatusContext } from "../../contexts/LiveStatusProvider";

import styles from "./index.module.scss";

import { HeartbeatStatusType } from "../../types/HeartbeatTypes";
import HeartbeatStatus from "../HeartbeatStatus";
type PropsType = {
  title?: React.ReactNode;
  children?: React.ReactNode;
  subtitle?: React.ReactNode;
  breadCrumbs?: {
    text: string;
    url: string;
  };
};

const formatStatusText = (status: HeartbeatStatusType) => {
  if (status === "error") {
    return "Network error";
  }

  if (status === "disconnected") {
    return "Disconnected";
  }
  return status.replace("-", " ");
};

const ScreenLayout = ({
  title,
  children,
  subtitle,
  breadCrumbs
}: PropsType) => {
  const { liveHeartbeat } = useLiveStatusContext();

  const displayConnected =
    liveHeartbeat.status !== "loading" && liveHeartbeat.status !== "error"
      ? liveHeartbeat.connectedCount
      : undefined;

  const displayDisconnected =
    liveHeartbeat.status !== "loading" && liveHeartbeat.status !== "error"
      ? liveHeartbeat.disconnectedCount
      : undefined;

  return (
    <div className={styles.Screen}>
      <div className={styles.HeaderWrapper}>
        <div
          className={cx(styles.Header, {
            [styles.HeaderWithLink]: breadCrumbs
          })}
        >
          <div
            className={cx(styles.DesktopHeader, {
              [styles.DesktopHeaderWithLink]: breadCrumbs
            })}
          >
            <PageTitle
              title={title}
              subtitle={subtitle}
              breadCrumbs={breadCrumbs}
            />
          </div>
          <div className={styles.HeartbeatWrapper}>
            <div className={styles.Heartbeat}>
              <span className={styles.Logo}>
                <LogoCircle />
              </span>
              <NavLink className={styles.HeartbeatLink} to={"/heartbeats"}>
                <Text component="span" className={cx(styles.StatusText)}>
                  {formatStatusText(liveHeartbeat.status)}
                </Text>
                <HeartbeatStatus
                  pulse
                  status={liveHeartbeat.status}
                  connected={displayConnected}
                  disconnected={displayDisconnected}
                />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Content}>
        <div className={styles.ContentContainer}>
          <ErrorBoundary errorComponent={ErrorScreen}>{children}</ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default ScreenLayout;
