import gql from "graphql-tag";

export const pigRunFragment = gql`
  fragment PigRunFragment on PigRun {
    id
    runId
    pipeline {
      name
      id
    }
    startTime
    endTime
    type
  }
`;

export const pigUpdatesFragment = gql`
  fragment PigUpdateFragment on PigRunUpdate {
    id
    timestamp
    post
    coordinates {
      latitude
      longitude
    }
    speed
    status
    nextPoi {
      post
      type
    }
  }
`;

export const ACTIVE_PIGS_SUBSCRIPTION = gql`
  subscription trackPig($organizationId: String!) {
    trackPig(organizationId: $organizationId) {
      ...PigRunFragment
      latest {
        ...PigUpdateFragment
      }
      updates {
        ...PigUpdateFragment
        nextPoi {
          post
          type
        }
      }
    }
  }
  ${pigRunFragment}
  ${pigUpdatesFragment}
`;

export const PIG_COUNT_SUBSCRIPTION = gql`
  subscription trackPig($organizationId: String!) {
    trackPig(organizationId: $organizationId) {
      id
      completed
    }
  }
`;

export const COMPLETED_PIGS_SUBSCRIPTION = gql`
  subscription trackPig($organizationId: String!) {
    trackPig(organizationId: $organizationId) {
      ...PigRunFragment
      startLocation {
        latitude
        longitude
      }
      endLocation {
        latitude
        longitude
      }
      startPost
      endPost
      completed
      numberOfUpdates
      numberOfCriticalEvents
      auditLogs {
        id
      }
    }
  }
  ${pigRunFragment}
`;

export function pigsQueryComposer(fragment?: unknown) {
  const queryFragment =
    fragment ||
    gql`
      fragment PigsFragment on PaginatedPigsAndTotal {
        pigs {
          ...PigRunFragment
        }
      }
    `;

  return gql`
    query pigs($organizationId: String!, $filters: PigsFiltersInput) {
      pigs(organizationId: $organizationId, filters: $filters) {
        ...PigsFragment
      }
    }
    ${queryFragment}
  `;
}
