import React, { useContext, useEffect } from "react";
import gql from "graphql-tag";
import { gqlType } from "@hifieng/common";

import { useEvents } from "../hooks/useEvents";
import { useOrganizationContext } from "./OrganizationProvider";
import { IOrganizations } from "../types/OrganizationTypes";
import { useLiveStatusContext } from "./LiveStatusProvider";
import formatEvent from "../helpers/formatEvent";

interface IEventDetailContext {
  event?: gqlType.EventNotification;
  orgData?: IOrganizations;
}

const initialContext: IEventDetailContext = {
  event: undefined,
  orgData: undefined
};

const eventDetailContext = React.createContext(initialContext);
const ContextProvider = eventDetailContext.Provider;

type PropsType = {
  referenceId: string;
  children: React.ReactNode;
};

const EVENT_DETAIL_FRAGMENT = gql`
  fragment EventFragments on EventNotification {
    id
    referenceId
    pipeline {
      id
      name
      __typename
    }
    segmentId
    type
    post
    coordinates {
      latitude
      longitude
      __typename
    }
    archived
    archivedDate
    startTime
    endTime
    channel
    signatures {
      id
      name
      type
      dataFileUri
      eventId
      timestamp
      createdAt
      updatedAt
      __typename
    }
    auditLogs {
      id
      eventId
      createdAt
      comment {
        author
        type
        message
        systemCreated
        __typename
      }
      __typename
    }
    eventMeta {
      status
      runId
      railId
      nextPoi {
        post
        type
      }
      timestamp
      speed
    }
    __typename
  }
`;

export const EventDetailProvider = (props: PropsType) => {
  const orgData = useOrganizationContext();
  const { appStatus } = useLiveStatusContext();

  const {
    currentLiveEvents: { status, events },
    reFetchQuery
  } = useEvents({
    queryName: "eventDetails",
    queryFragment: EVENT_DETAIL_FRAGMENT,
    filters: { referenceId: props.referenceId }
  });

  useEffect(() => {
    if (appStatus.needUpdate) {
      reFetchQuery();
    }
  }, [appStatus.needUpdate, props.referenceId, reFetchQuery]);

  const event =
    events && events.length > 0 && status === "connected"
      ? events[0]
      : initialContext.event;

  return (
    <ContextProvider
      value={{
        orgData,
        event: event ? formatEvent(event) : undefined
      }}
    >
      {props.children}
    </ContextProvider>
  );
};

export const useEventDetailContext = () => useContext(eventDetailContext);
