import React from "react";

import { Heading, Text } from "../Type";
import styles from "./index.module.scss";

type Props = {
  loading: boolean;
  text: string;
  description?: string;
  cta?: React.ReactNode;
};

const EmptyListState = ({ loading, text, cta, description }: Props) => {
  return (
    <div className={styles.Empty}>
      <Heading size="h5">{loading ? "Loading..." : text}</Heading>
      {!loading && (
        <>
          {description && (
            <Text size="large" className={styles.EmptyDesc}>
              {description}
            </Text>
          )}
          {cta}
        </>
      )}
    </div>
  );
};

export default EmptyListState;
