import { SchematicMapDataPointType, SchematicMapCategoryType } from "../index";

export type EnrichedClusterPoint = SchematicMapDataPointType & {
  xPosition: number;
};

export type SchematicMapClusterPointType = SchematicMapDataPointType & {
  clusterPoints?: Array<EnrichedClusterPoint>;
};

export type ClusteredPointsType = Array<SchematicMapClusterPointType>;

type AxisContextType = {
  xScale: d3.ScaleLinear<number, number>;
  xDomain: Array<number>;
  xAxisWidth: number;
  category: SchematicMapCategoryType;
  iconSize: number;
  teckFlag?: boolean;
};

const initActiveCluster: {
  startPoint?: number;
  post?: number;
  endPoint?: number;
} = {
  startPoint: undefined,
  post: undefined,
  endPoint: undefined
};

const DOUBLE = 2;

export const clusterPoints = (
  points: Array<SchematicMapDataPointType>,
  axisContext: AxisContextType
): ClusteredPointsType => {
  const {
    category,
    iconSize,
    xAxisWidth,
    xDomain,
    xScale,
    teckFlag
  } = axisContext;
  const enableClustering = teckFlag === false;
  const clusterWidth =
    xAxisWidth !== 0
      ? xScale(((iconSize * DOUBLE) / xAxisWidth) * (xDomain[1] - xDomain[0]))
      : 0;

  const clusteredPointsById: {
    [pointId: string]: boolean;
  } = {};
  const clusteredPoints: ClusteredPointsType = [];
  const axisPoints = points.map(point => {
    return {
      ...point,
      xPosition: xScale(point.post)
    };
  });

  axisPoints.forEach((point, idx) => {
    if (clusteredPointsById[point.id]) {
      return;
    }
    //if not in cluster and not part of cluster future cluster
    const isFirstInCluster =
      enableClustering &&
      !clusteredPointsById[point.id] &&
      axisPoints[idx + 1] &&
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      axisPoints[idx + 1].xPosition < point.xPosition + clusterWidth; // ie is next point close enough
    let activeCluster = initActiveCluster;

    if (!isFirstInCluster) {
      clusteredPoints.push(point);
    } else {
      // add to start and endpoint to active cluster
      activeCluster.startPoint = point.xPosition;
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      activeCluster.endPoint = point.xPosition + clusterWidth;
      activeCluster.post = point.post;

      // add all points to cluster
      const evalPoint = (
        i: number,
        clusterPoints: Array<EnrichedClusterPoint>
      ) => {
        const currentPoint = axisPoints[i];

        if (
          currentPoint &&
          !clusteredPointsById[currentPoint.id] &&
          activeCluster.endPoint &&
          currentPoint.xPosition < activeCluster.endPoint &&
          enableClustering
        ) {
          clusterPoints.push(currentPoint);
          clusteredPointsById[currentPoint.id] = true;
          if (i < axisPoints.length) evalPoint(i + 1, clusterPoints);
        }

        return clusterPoints;
      };

      const clusterPoints = evalPoint(idx, []);

      const clusterRange = clusterPoints.reduce<{
        sumPost: number;
        meanPost: number;
      }>(
        (acc, cur, idx) => {
          acc.sumPost = acc.sumPost + cur.post;
          acc.meanPost = acc.sumPost / (idx + 1);
          return acc;
        },
        {
          sumPost: 0,
          meanPost: 0
        }
      );

      // Add activeCluster to clusteredPoints
      clusteredPoints.push({
        id: idx.toString(),
        category,
        type: "cluster",
        post: clusterRange.meanPost,
        clusterPoints
      });

      // reset active cluster
      activeCluster = initActiveCluster;
    }
  });

  return clusteredPoints;
};
