import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Comment = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 15 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="currentColor"
          d="M0 0h15v1.38889H0V0zm0 4.16667h11.53846v1.38889H0v-1.3889zM0 8.6111h6.92308V10H0V8.61111z"
        />
      </g>
    </svg>
  );
};

export default Comment;
