import React, { useState, useEffect } from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";

import { Heading, Text } from "../../Type";
import { Tertiary } from "../../Button";
import { TextInput } from "../../Inputs";
import DateTime from "../../DateTime";
import { Arrow } from "../../Icons";

import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";

import styles from "./index.module.scss";
import { useAuditLogs } from "../../../hooks/useAuditLogs";
import { logErrorToLogdna } from "../../../helpers/logdna";
import { useTrainDetails } from "../../TrainDetailsPage/useTrainDetails";

const MIN_LOG_ENTRY_CHARACTERS = 3;
const MAX_LOG_ENTRY_CHARACTERS = 200;
const ERROR_CODES = {
  savingComment: "SAVING_COMMENT"
};
const validateLogEntry = (log: string): string => {
  let errorMessage = "";
  if (log.length < MIN_LOG_ENTRY_CHARACTERS) {
    errorMessage = `Please enter at least ${MIN_LOG_ENTRY_CHARACTERS} characters`;
  }

  if (log.length > MAX_LOG_ENTRY_CHARACTERS) {
    errorMessage = "Log entry too large";
  }

  return errorMessage;
};
type Props = {
  id: string;
  logs: Array<gqlType.TrainAuditLog>;
};

const RunNotes = ({ logs, id }: Props) => {
  const { saveLog, saveLogLoading, saveLogError } = useAuditLogs("train");
  const { refetch } = useTrainDetails(id);

  const [logInput, setLogInput] = useState("");
  const [notesVisible, setNotesVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const attemptSaveLog = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // clear previous error message
    setErrorMessage("");

    const errorMessage = validateLogEntry(logInput);
    if (errorMessage) {
      setErrorMessage(errorMessage);
    } else {
      saveLog(logInput, id);
    }
  };

  const handleLogInput = (value: string) => {
    setLogInput(value);
    setErrorMessage("");
  };

  // Handle save log state changes
  useEffect(() => {
    // on save failure
    if (saveLogError && !saveLogLoading) {
      const message = "Error saving comment. Please try again.";
      setErrorMessage(message);
      logErrorToLogdna(ERROR_CODES.savingComment, message);
    }

    // on save success
    if (!saveLogError && !saveLogLoading) {
      setLogInput("");
      refetch();
    }
  }, [saveLogLoading, saveLogError, refetch]);
  return (
    <>
      <button
        className={styles.ToggleButton}
        type="button"
        onClick={() => setNotesVisible(!notesVisible)}
      >
        <Heading size="h7" className={styles.ButtonText}>
          {notesVisible ? "Hide Notes" : "View Notes"} ({logs.length})
        </Heading>
        <span
          className={cx(styles.ToggleIcon, {
            [styles.ToggleIconOpen]: notesVisible
          })}
        >
          <Arrow />
        </span>
      </button>
      <div
        className={cx(styles.Notes, {
          [styles.NotesOpen]: notesVisible
        })}
      >
        <form className={styles.LogForm} onSubmit={attemptSaveLog}>
          <div className={styles.InputWrapper}>
            <TextInput
              onChange={handleLogInput}
              value={logInput}
              errorMessage={errorMessage}
              label="Add a comment"
              hideLabel
              size="small"
              maxLength={200}
            />
          </div>
          <Tertiary
            buttonSize="small"
            className={styles.SendButton}
            type="submit"
          >
            Submit
          </Tertiary>
        </form>

        {logs ? (
          logs.map(log => (
            <div className={styles.LogInputText} key={log.id}>
              <Text size="small" component="span">
                {log.comment.message}
              </Text>
              <br />
              <Text size="small" component="span" dark>
                {log.comment.author} -{" "}
                <DateTime
                  timestamp={log.createdAt * MILLISECONDS_IN_SECONDS}
                  length="short"
                />
              </Text>
            </div>
          ))
        ) : (
          <Text className={styles.EmptyText} size="small">
            Add a note to capture additional details about the train run, such
            as launch or retrieval points
          </Text>
        )}
      </div>
    </>
  );
};

export default RunNotes;
