// https://github.com/moment/moment/issues/4877
// eslint-disable-next-line
import moment, { Moment } from "moment";
import { JS_TO_UNIX_MULTIPLIER } from "./constants";
import { IQueryParamsType } from "../hooks/useQueryParams";

export type DateRange = {
  startDate: Moment | null;
  endDate: Moment | null;
};

export const getQueryParamDateRange = (
  queryParams: IQueryParamsType
): DateRange => {
  if (queryParams) {
    return {
      startDate: queryParams.start
        ? moment(
            parseInt(queryParams.start.toString(), 10) * JS_TO_UNIX_MULTIPLIER
          )
        : null,
      endDate: queryParams.end
        ? moment(
            parseInt(queryParams.end.toString(), 10) * JS_TO_UNIX_MULTIPLIER
          )
        : null
    };
  }
  return {
    startDate: null,
    endDate: null
  };
};
