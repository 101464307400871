import React from "react";
import cx from "classnames";
import { Heading } from "../Type";
import { Exclamation, Check } from "../Icons";

import styles from "./index.module.scss";

type PropsType = {
  type: "error" | "success" | "warning";
  message: string;
  className?: string;
};

const Alert = ({ type, message, className = "" }: PropsType) => {
  return (
    <div
      className={cx(styles.Alert, className, {
        [styles.AlertSuccess]: type === "success"
      })}
    >
      {type === "success" ? <Check /> : <Exclamation />}
      <Heading className={styles.AlertText} size="h7">
        {message}
      </Heading>
    </div>
  );
};

export default Alert;
