import React from "react";

const Volume = () => {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-1 -.993)">
        <path
          fill="currentColor"
          d="M20.37 6.06l-1.148.968a7.5 7.5 0 01-.217 9.922L20.108 18a9 9 0 00.262-11.91v-.03zM16.185 9a4.5 4.5 0 01-.135 5.955l1.102 1.02a6 6 0 00.173-7.942L16.185 9zM13.5 22.5a.75.75 0 01-.533-.225L7.252 16.5H2.25a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h5.003l5.714-5.775a.75.75 0 011.058 0 .75.75 0 01.225.525v19.5a.75.75 0 01-.75.75zM3 15h4.56c.2 0 .392.082.532.225l4.658 4.702V4.073L8.092 8.774A.75.75 0 017.56 9H3v6z"
        />
      </g>
    </svg>
  );
};

export default Volume;
