import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// Needed to initialize react-dates to use our styles see README: https://github.com/airbnb/react-dates/blob/master/README.md
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import AppErrorBoundary from "./AppErrorBoundary";
import AnalysisPage from "../AnalysisPage";
import FakeLeakPage from "../FakeLeakPage";
import AnalysisDetailsPage from "../AnalysisDetailsPage";
import HeartbeatPage from "../HeartbeatPage";
import ReportsRoute from "../ReportsRoute";
import OrganizationsPage from "../OrganizationsPage";
import PigTrackingPage from "../PigTrackingPage";
import TrainTrackingPage from "../TrainTrackingPage";
import MonitorPage from "../MonitoringPage";
import PageLayout from "../PageLayout";
import Loading from "../../components/Loading";

import { useAnalytics } from "../../hooks/useAnalytics";
import { usePageTracker } from "../../hooks/usePageTracker";
import useAlertSound from "../../hooks/useAlertSound";
import PigDetailsPage from "../PigDetailsPage";
import TrainDetailsPage from "../TrainDetailsPage";

const App = () => {
  useAnalytics();
  usePageTracker();
  useAlertSound();

  return (
    <PageLayout>
      <AppErrorBoundary>
        <Suspense fallback={<Loading heading="Loading" />}>
          <Switch>
            <Route path="/(alerts|history)" component={MonitorPage} />
            <Route path="/reports" component={ReportsRoute} />
            <Route path="/heartbeats" component={HeartbeatPage} />
            <Route path="/analysis/:id" component={AnalysisDetailsPage} />
            <Route path="/analysis" component={AnalysisPage} />
            <Route path="/fake-leak" component={FakeLeakPage} />

            <Route
              path="/pig-tracking/(active|history)/:id"
              component={PigDetailsPage}
            />
            <Route
              path="/pig-tracking/(active|history)"
              component={PigTrackingPage}
            />
            <Redirect from="/pig-tracking" to="/pig-tracking/active" exact />

            <Route
              path="/train-tracking/(active|history)/:id"
              component={TrainDetailsPage}
            />
            <Route
              path="/train-tracking/(active|history)"
              component={TrainTrackingPage}
            />
            <Redirect
              from="/train-tracking"
              to="/train-tracking/active"
              exact
            />

            <Route exact path="/organizations" component={OrganizationsPage} />
            <Redirect from="/" to="/alerts" />
          </Switch>
        </Suspense>
      </AppErrorBoundary>
    </PageLayout>
  );
};

export default App;
