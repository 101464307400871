import React from "react";
import CheckboxGroup from "../../CheckboxGroup";
import { useHistoryFiltersContext } from "../HistoryFiltersProvider";
import { analytics } from "../../../analytics";

const PipelineFilter = () => {
  const {
    pipelineOptions,
    selectedPipelines,
    setSelectedPipelines
  } = useHistoryFiltersContext();

  const updatePipelines = (pipelineName: string) => {
    let newPipelines = [];
    if (selectedPipelines.includes(pipelineName)) {
      newPipelines = selectedPipelines.filter(
        pipeline => pipeline !== pipelineName
      );
    } else {
      newPipelines = [...selectedPipelines, pipelineName];
    }
    setSelectedPipelines(newPipelines);
  };

  return (
    <CheckboxGroup
      label="Pipelines"
      checkboxValue={selectedPipelines}
      onChange={(pipelineName: string) => {
        updatePipelines(pipelineName);
        analytics.historyAddFilter({ type: "pipeline", value: pipelineName });
      }}
      name="pipeline-filter"
      options={pipelineOptions}
    />
  );
};

export default PipelineFilter;
