import React from "react";

import { useRouteMatch, useHistory } from "react-router";
import EventDetails from "../../EventDetails";
import DetailsModal from "../../DetailsModal";
import { EventDetailProvider } from "../../../contexts/eventDetailProvider";

type PropsType = {
  referenceId?: string;
};
const EventModal = ({ referenceId }: PropsType) => {
  const match = useRouteMatch();
  const history = useHistory();

  const toUrl = referenceId ? match.url.replace(`/${referenceId}`, "") : "/";

  const toUrlWithSearch = `${toUrl}${history.location.search}`;

  if (!referenceId) {
    return null;
  }

  return (
    <DetailsModal id={referenceId} returnUrl={toUrlWithSearch}>
      <EventDetailProvider referenceId={referenceId}>
        <EventDetails returnUrl={toUrlWithSearch} />
      </EventDetailProvider>
    </DetailsModal>
  );
};

export default EventModal;
