import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Archive = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.667 6v9.334c0 .736-.597 1.333-1.334 1.333H4.668a1.333 1.333 0 01-1.333-1.334V6h13.333zm-1.334 1.333H4.668v8h10.667v-8zm-3.333 2v1.334H8V9.334h4zm4.667-6v1.334H3.333V3.334h13.334z"
        fill="#fff"
      />
    </svg>
  );
};

export default Archive;
