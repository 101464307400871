import React, { useRef } from "react";
import { gqlType } from "@hifieng/common";

import TrainInfo from "./TrainInfo";
import Header from "./Header";
import DetailsSection from "../DetailsSection";
import EventMap from "../EventMap";
import SchematicMap, { SchematicMapDataPointType } from "../SchematicMap";

import { SCREENSHOT_REMOVE_SECTION_CLASS } from "../ShareDialog";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import { getStatusText } from "../../helpers/trainStatus";
import { getSchematicMapInputs } from "../../helpers/getSchematicMapInputs";

import styles from "./index.module.scss";
import { useAuth } from "../../contexts/AuthProvider";

type PropsType = {
  updateId: string;
  train: gqlType.TrainRun;
  returnUrl: string;
};

const TrainUpdateDetails = ({ returnUrl, updateId, train }: PropsType) => {
  const trainDetailsRef = useRef<HTMLDivElement>(null);
  const targetUpdate = train.updates.find(
    (update: gqlType.TrainRunUpdate) => update.id === updateId
  );
  const { permissions } = useAuth();
  const trainWithTargetUpdate = {
    ...train,
    updates: targetUpdate ? [targetUpdate] : []
  };

  const { activeOrg } = useOrganizationContext();

  if (!activeOrg || !targetUpdate) {
    return <div className={styles.Wrapper}></div>;
  }

  const { pipelines } = activeOrg;
  const { post, status } = targetUpdate;

  const featuredUpdateData: SchematicMapDataPointType | undefined = {
    id: updateId,
    category: "train",
    isFeatured: true,
    type: status,
    post
  };

  const { assets, domain } = getSchematicMapInputs(
    pipelines,
    train.pipeline.id
  );

  let estimatedArrival;

  return (
    <div ref={trainDetailsRef} id="train-details" className={styles.Content}>
      <div className={styles.Fixed}>
        <Header
          returnUrl={returnUrl}
          trainDetailsRef={trainDetailsRef}
          updateId={updateId}
          trainId={train.id}
          status={status}
        />
      </div>
      <div className={styles.Scroll}>
        <DetailsSection>
          <TrainInfo
            train={train}
            update={targetUpdate}
            estimatedArrival={estimatedArrival}
          />
        </DetailsSection>
        <DetailsSection
          title="Schematic Map"
          className={SCREENSHOT_REMOVE_SECTION_CLASS}
        >
          <div className={styles.SchematicMapWrapper}>
            {pipelines && featuredUpdateData && (
              <SchematicMap
                key={updateId}
                featuredEvent={featuredUpdateData}
                events={[]}
                assets={assets}
                pipelineDomain={domain}
                iconLabel={getStatusText(status)}
                imperialFlag={
                  (permissions.user.imperial ? true : false) ||
                  train.pipelineId === "enbridge_line_3" ||
                  train.pipelineId === "enbridge_line_4"
                }
              />
            )}
          </div>
        </DetailsSection>
        <DetailsSection title="Event Map" last>
          <div className={styles.MapWrapper}>
            <EventMap
              events={[trainWithTargetUpdate]}
              canSearchOnMapMove={false}
              isSearching={false}
              focusPipelines={[train.pipeline.id]}
              trainMode
              hideLegend
              disableEventIconSelection
            />
          </div>
        </DetailsSection>
      </div>
    </div>
  );
};

export default TrainUpdateDetails;
