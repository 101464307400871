import React from "react";

const DEFAULT_SIZE = 24;

type PropsType = {
  size?: number;
};

const Train = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 12 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <path
          d="M8.85714286,0.5 C10.4350993,0.5 11.7142857,1.77918643 11.7142857,3.35714286 L11.7142857,3.35714286 L11.7142857,9.78571429 C11.708878,11.2181806 10.6435517,12.4252246 9.22285714,12.6085714 L9.22285714,12.6085714 L10,15.5 L8.81714286,15.5 L8.05142857,12.6428571 L3.94857143,12.6428571 L3.18285714,15.5 L2,15.5 L2.77714286,12.6085714 C1.35644834,12.4252246 0.29112201,11.2181806 0.285714286,9.78571429 L0.285714286,9.78571429 L0.285714286,3.35714286 C0.285714286,1.77918643 1.56490071,0.5 3.14285714,0.5 L3.14285714,0.5 Z M3.29974455,8.20671785 C2.60938861,8.20671785 2.04974455,8.76636192 2.04974455,9.45671785 C2.04974455,10.1470738 2.60938861,10.7067179 3.29974455,10.7067179 C3.99010048,10.7067179 4.54974455,10.1470738 4.54974455,9.45671785 C4.54974455,8.76636192 3.99010048,8.20671785 3.29974455,8.20671785 Z M8.79974455,8.20671785 C8.10938861,8.20671785 7.54974455,8.76636192 7.54974455,9.45671785 C7.54974455,10.1470738 8.10938861,10.7067179 8.79974455,10.7067179 C9.49010048,10.7067179 10.0497445,10.1470738 10.0497445,9.45671785 C10.0497445,8.76636192 9.49010048,8.20671785 8.79974455,8.20671785 Z M8.85714286,1.87210519 L3.14285714,1.87210519 C2.41814005,1.87414197 1.77299903,2.33168879 1.53142857,3.01496233 L1.53142857,3.01496233 L1.53142857,4.5 L10.4685714,4.5 L10.4685714,3.01496233 L10.4149724,2.8813854 C10.1406285,2.27270704 9.53354548,1.87400618 8.85714286,1.87210519 L8.85714286,1.87210519 Z"
          id="path-1"
        ></path>
      </g>
    </svg>
  );
};

export default Train;
