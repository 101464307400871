import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import WaveSurfer from "wavesurfer.js";
import { Primary, Secondary } from "../../../../Button";
import { Text } from "../../../../Type";
import moment from "moment";
import { Play, Pause, ResetAudio } from "../../../../Icons";
import { analytics } from "../../../../../analytics";

const WaveSurferWrapper = ({
  url,
  analyticsData
}: {
  url: string;
  analyticsData?: {
    eventId: string;
    signatureId: string;
  };
}) => {
  const formatElapsedTime = (timeInSeconds: number) => {
    const duration = moment.duration(timeInSeconds, "seconds").asMilliseconds();
    // https://stackoverflow.com/questions/54111963/moment-js-format-duration
    const formattedElapsedTime = moment.utc(duration).format("HH:mm:ss");
    return formattedElapsedTime;
  };

  const waveSurfer = useRef<WaveSurfer | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const playAnalyticsSent = useRef<boolean>(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      // If the url has changed, we can't use the existing instance anymore
      if (waveSurfer.current) {
        waveSurfer.current.destroy();
      }
      // Create a new instance of wavesurfer
      const waveSurferInstance = WaveSurfer.create({
        container: containerRef.current,
        barWidth: 1,
        barGap: 1,
        waveColor: "white",
        progressColor: "grey",
        responsive: true
      });
      waveSurferInstance.load(url);
      waveSurfer.current = waveSurferInstance;
      waveSurfer.current.on("seek", (progress: number) => {
        if (waveSurfer.current) {
          setElapsedTime(progress * waveSurfer.current.getDuration());
        }
      });

      waveSurfer.current.on("ready", () => {
        setIsReady(true);
      });

      // Reset the time and playing status, if Wavesurfer is reinitialized
      setElapsedTime(0);
      setIsPlaying(waveSurfer.current.isPlaying());
    }

    return () => {
      if (waveSurfer.current) {
        waveSurfer.current.destroy();
      }
    };
  }, [url]);

  useEffect(() => {
    const handleAudioProcess = (progress: number) => {
      if (Math.floor(progress) !== Math.floor(elapsedTime)) {
        setElapsedTime(Math.floor(progress));
      }
    };
    if (waveSurfer.current) {
      waveSurfer.current.on("audioprocess", handleAudioProcess);
    }
    return () => {
      if (waveSurfer.current) {
        waveSurfer.current.un("audioprocess", handleAudioProcess);
      }
    };
  }, [elapsedTime]);

  const playPause = () => {
    if (waveSurfer.current && isReady) {
      if (!playAnalyticsSent.current === true && analyticsData) {
        analytics.eventAudioSignaturePlayed(analyticsData);
        playAnalyticsSent.current = true;
      }
      waveSurfer.current.playPause();
      setIsPlaying(waveSurfer.current.isPlaying());
    }
  };

  const stop = () => {
    if (waveSurfer.current && isReady) {
      waveSurfer.current.stop();
      setIsPlaying(false);
    }
  };

  return (
    <div className={styles.WrapperContainer}>
      <div className={styles.ControlsRow}>
        <div className={styles.LeftControls}>
          <Primary className={styles.AudioControlButton} onClick={playPause}>
            {isPlaying ? <Pause /> : <Play />}
          </Primary>
          <Text className={styles.ElapsedTime}>
            {formatElapsedTime(elapsedTime)}
          </Text>
        </div>
        <Secondary className={styles.AudioControlButton} onClick={stop}>
          <ResetAudio />
        </Secondary>
      </div>
      <div ref={containerRef}></div>
    </div>
  );
};

export default WaveSurferWrapper;
