import React from "react";

const Play = () => {
  return (
    <svg width="13px" height="15px" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 .344)">
        <path
          fill="currentColor"
          d="M1.02040816,15.5102187 C0.682274643,15.5102187 0.408158419,15.2360927 0.408158419,14.8979592 L0.408158419,1.42857143 C0.407291425,1.20896852 0.524101515,1.00571896 0.714285714,0.895918367 C0.903714945,0.78655135 1.13710138,0.78655135 1.32653061,0.895918367 L12.9591837,7.63061224 C13.1510064,7.73932319 13.2695602,7.94277941 13.2695602,8.16326531 C13.2695602,8.3837512 13.1510064,8.58720742 12.9591837,8.69591837 L1.32653061,15.4306122 C1.23322271,15.4834982 1.12765901,15.5109448 1.02040816,15.5102187 Z M1.63265306,2.4877551 L1.63265306,13.8387755 L11.4285714,8.16326531 L1.63265306,2.4877551 Z"
        />
      </g>
    </svg>
  );
};

export default Play;
