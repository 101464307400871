import React from "react";
import AppError from "../../errors/AppError";

type PropsType = {
  children: React.ReactNode;
};

class AppErrorBoundary extends React.Component<PropsType> {
  componentDidCatch(error: Error) {
    if (error.message.includes("Loading chunk")) {
      throw new AppError("Network error", "Please reload the page.");
    } else {
      console.error(
        "New error caught in AppErrorBoundary",
        error.name,
        error.message,
        error.stack
      );
      throw error;
    }
  }

  render() {
    return this.props.children;
  }
}

export default AppErrorBoundary;
