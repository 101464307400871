import React from "react";
import CommentBox from "../../CommentBox";

import { useEventDetailContext } from "../../../contexts/eventDetailProvider";
import getPriorityLevel from "../../../helpers/getPriorityLevel";

const EventCommentBox = () => {
  const { event } = useEventDetailContext();

  return event ? (
    <CommentBox
      auditLogs={event.auditLogs}
      type={event.type}
      statusPriority={
        event.eventMeta ? getPriorityLevel(event.eventMeta.status) : undefined
      }
      id={event.referenceId}
    />
  ) : null;
};

export default EventCommentBox;
