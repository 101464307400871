import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Close = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        fill="currentColor"
        clipRule="evenodd"
        d="M-8.46581e-07 12.775L1.225 14L7 8.225L12.775 14L14 12.775L8.225 7L14 1.225L12.775 -1.07093e-07L7 5.775L1.225 -1.11683e-06L1.63152e-07 1.225L5.775 7L-8.46581e-07 12.775Z"
      />
    </svg>
  );
};

export default Close;
