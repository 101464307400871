import { gqlType } from "@hifieng/common";

export const sortByOptions = [
  { value: "TIME_DESC", label: "Newest" },
  { value: "TIME_ASC", label: "Oldest" }
];

export const pigTypeOptions = [
  { label: "Utility", value: gqlType.PigType.Utility },
  { label: "Inspection", value: gqlType.PigType.Inspection },
  { label: "Other", value: gqlType.PigType.Other }
];

export const trainTypeOptions = [
  { label: "Utility", value: gqlType.TrainType.Utility },
  { label: "Inspection", value: gqlType.TrainType.Inspection },
  { label: "Other", value: gqlType.TrainType.Other }
];

export const updatesTypeOptions = [
  { value: "NON_CRITICAL", label: "Non-Critical" },
  { value: "CRITICAL", label: "Critical" }
];
