import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { gqlType } from "@hifieng/common";
import { NetworkStatus, ApolloError } from "apollo-boost";

import { OrganizationContext } from "./OrganizationProvider";
import { useAuth } from "./AuthProvider";
import { IHeartbeat } from "../types/HeartbeatTypes";
import { analytics } from "../analytics";
import { MILLISECONDS_IN_SECONDS } from "../helpers/constants";
import { logErrorToLogdna } from "../helpers/logdna";
import {
  INITIAL_HEARTBEAT_STATE,
  derivedHeartbeatInfo
} from "../helpers/derivedHeartbeatInfo";

type PropsType = {
  children: React.ReactNode;
};

interface ILiveStatusContext {
  connectionTimeout: boolean;
  appStatus: {
    needUpdate: boolean;
    newEvent: boolean;
    activeEventCount: number;
  };
  liveHeartbeat: IHeartbeat;
  activeAlert: boolean;
  dismissAlert: () => void;
}

// Can't use gqlType.Status because events should be initialized to undefined, not a number
type LiveStatus = Omit<gqlType.Status, "events"> & {
  events: {
    lastUpdateTimestamp?: number;
    newEventTimestamp?: number;
    activeEventCount?: number;
  };
};

interface IErrorWithStatus extends Error {
  statusCode: number;
}

export const INITIAL_LIVE_STATUS: LiveStatus = {
  heartbeats: [],
  events: {
    lastUpdateTimestamp: undefined,
    newEventTimestamp: undefined,
    activeEventCount: undefined
  },
  requestedOn: 0
};

const initialState: ILiveStatusContext = {
  connectionTimeout: false,
  appStatus: { needUpdate: false, newEvent: false, activeEventCount: 0 },
  liveHeartbeat: INITIAL_HEARTBEAT_STATE,
  activeAlert: false,
  dismissAlert: () => {
    throw new Error("LiveStatus context has not yet been initialized.");
  }
};

const HEARTBEAT_TIME_OUT = 30;
const FETCH_EVERY_MS = 3000;
const ERROR_CODES = {
  unauthorizedLogout: "UNAUTHORIZED_LOGOUT",
  updatingLiveStatus: "UPDATING_LIVE_STATUS"
};

export const LIVE_STATUS_QUERY = {
  name: "liveStatus",
  query: gql`
    query liveStatus($activeOrgId: String!) {
      status(activeOrgId: $activeOrgId) {
        heartbeats {
          pipelineId
          segmentId
          isConnected
        }
        events {
          lastUpdateTimestamp
          newEventTimestamp
          activeEventCount
        }
        requestedOn
      }
    }
  `
};

const LiveStatusContext = React.createContext(initialState);

export const useLiveStatusContext = () => useContext(LiveStatusContext);

export const LiveStatusProvider = (props: PropsType) => {
  const [eventStatus, setEventStatus] = useState(INITIAL_LIVE_STATUS.events);
  const [appStatus, setAppStatus] = useState(initialState.appStatus);
  const [lastRequest, setLastRequest] = useState(0);
  const [liveHeartbeat, setLiveHeartbeat] = useState(INITIAL_HEARTBEAT_STATE);
  const [activeAlert, setActiveAlert] = useState<boolean>(false);

  const { activeOrg } = useContext(OrganizationContext);
  // Without useMemo, the newly defined array triggers the effects on every render causing excessive
  // renders until the activeOrg becomes defined.
  const pipelines = useMemo(() => (activeOrg && activeOrg.pipelines) || [], [
    activeOrg
  ]);
  const activeOrgId = (activeOrg && activeOrg.id) || "";
  const previousOrg = useRef<string>(activeOrgId);

  const { logout, user, permissions } = useAuth();

  const { data, networkStatus } = useQuery(LIVE_STATUS_QUERY.query, {
    variables: {
      activeOrgId
    },
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
    notifyOnNetworkStatusChange: true,
    pollInterval: FETCH_EVERY_MS,
    onError: (error: ApolloError) => {
      if (
        error &&
        error.networkError &&
        // eslint-disable-next-line no-magic-numbers
        (error.networkError as IErrorWithStatus).statusCode === 401
      ) {
        logErrorToLogdna(
          ERROR_CODES.unauthorizedLogout,
          `User's token is unauthorized has been logged out, ${
            user
              ? `User Email: ${user.email}, User Permissions: ${JSON.stringify(
                  permissions
                )}`
              : ""
          }`,
          error,
          networkStatus
        );
        logout();
      } else {
        logErrorToLogdna(
          ERROR_CODES.updatingLiveStatus,
          `Apollo error while updating live status., ${
            user
              ? `User Email: ${user.email}, User Permissions: ${JSON.stringify(
                  permissions
                )}`
              : ""
          }
          `,
          error,
          networkStatus
        );
      }

      return error;
    }
  });

  const currTime = Math.floor(Date.now() / MILLISECONDS_IN_SECONDS);
  const lostConnection = currTime - lastRequest > HEARTBEAT_TIME_OUT;

  // If the network connection goes offline, for long enough, show on the UI that the network is offline
  useEffect(() => {
    if (networkStatus === NetworkStatus.error && lostConnection) {
      const latestHeartbeat = derivedHeartbeatInfo({
        pipelines,
        dataStatus: "error",
        liveHeartbeat: []
      });

      if (latestHeartbeat.status !== liveHeartbeat.status) {
        setLiveHeartbeat(latestHeartbeat);
      }
    }
  }, [lostConnection, liveHeartbeat.status, networkStatus, pipelines]);

  // When the latest heartbeat data arrives, store it
  useEffect(() => {
    if (networkStatus === NetworkStatus.ready && data && activeOrg) {
      const latestHeartbeat = derivedHeartbeatInfo({
        pipelines,
        dataStatus: "completed",
        liveHeartbeat: data.status.heartbeats
      });

      if (latestHeartbeat.status !== liveHeartbeat.status) {
        analytics.heartbeatChange(liveHeartbeat.status, latestHeartbeat.status);
      }

      setLiveHeartbeat(latestHeartbeat);
    }
  }, [data, liveHeartbeat.status, networkStatus, pipelines, activeOrg]);

  // When the event data arrives, store the new event data
  useEffect(() => {
    if (networkStatus === NetworkStatus.ready && data) {
      const currStatus = data.status.events;

      if (
        eventStatus.newEventTimestamp !== undefined &&
        eventStatus.newEventTimestamp < currStatus.newEventTimestamp &&
        previousOrg.current === activeOrgId
      ) {
        setActiveAlert(true);
      }
      setAppStatus({
        needUpdate:
          eventStatus.lastUpdateTimestamp === undefined ||
          eventStatus.lastUpdateTimestamp < currStatus.lastUpdateTimestamp,
        newEvent:
          eventStatus.newEventTimestamp === undefined ||
          eventStatus.newEventTimestamp < currStatus.newEventTimestamp,
        activeEventCount: activeOrgId ? currStatus.activeEventCount : 0
      });
      setEventStatus(currStatus);
      setLastRequest(data.status.requestedOn);
      previousOrg.current = activeOrgId;
    }
  }, [data, eventStatus, networkStatus, pipelines, activeOrgId]);

  return (
    <LiveStatusContext.Provider
      value={{
        connectionTimeout: false,
        appStatus,
        liveHeartbeat,
        activeAlert,
        dismissAlert: () => setActiveAlert(false)
      }}
    >
      {props.children}
    </LiveStatusContext.Provider>
  );
};
