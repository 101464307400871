import React from "react";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import {
  formatDate,
  formatTime,
  shortDate,
  longDate
} from "../../helpers/formatDate";

type PropsType = {
  length: "dateOnly" | "timeOnly" | "short" | "long";
  timestamp: number;
};

const DateTime = ({ length, timestamp }: PropsType): JSX.Element => {
  const { activeOrg } = useOrganizationContext();
  const timeOptions = activeOrg && {
    timezoneAbbr: activeOrg.timezoneAbbr,
    utcOffset: activeOrg.utcOffset
  };
  if (length === "dateOnly") {
    return <>{timeOptions ? formatDate(timestamp, timeOptions) : null}</>;
  } else if (length === "timeOnly") {
    return <>{timeOptions ? formatTime(timestamp, timeOptions) : null}</>;
  } else if (length === "short") {
    return <>{timeOptions ? shortDate(timestamp, timeOptions) : null}</>;
  } else {
    return <>{timeOptions ? longDate(timestamp, timeOptions) : null}</>;
  }
};

export default DateTime;
