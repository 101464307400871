import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Pump = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 12 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1.25 1)"
        fill="#161616"
        stroke="currentColor"
        strokeWidth=".63158"
        fillRule="evenodd"
      >
        <path d="M4.36866 0h6.47489v4.28047H4.36866zM4.44273 5.93251l4.29459 4.85323H0z" />
        <circle cx="4.36866" cy="4.28047" r="4.28047" />
      </g>
    </svg>
  );
};

export default Pump;
