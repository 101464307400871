import React, { ReactNode } from "react";
import cx from "classnames";
import { Heading } from "../Type";

import styles from "./index.module.scss";

type PropsType = {
  title?: string;
  last?: boolean;
  className?: string;
  children?: ReactNode;
};

const DetailsSection = ({ title, last, className, children }: PropsType) => {
  return (
    <section
      className={cx(styles.Section, className, { [styles.SectionLast]: last })}
    >
      {title && (
        <Heading size="h7" className={styles.Title}>
          {title}
        </Heading>
      )}
      {children}
    </section>
  );
};

export default DetailsSection;
