export const sortByKey = <ValueType, KeyType extends keyof ValueType>(
  key: KeyType,
  invert?: boolean
) => (a: ValueType, b: ValueType) => {
  if (a[key] > b[key]) {
    return invert ? -1 : 1;
  } else if (a[key] < b[key]) {
    return invert ? 1 : -1;
  } else {
    return 0;
  }
};
