import React from "react";

const DEFAULT_SIZE = 22;

type PropsType = {
  size?: number;
};

const ResetCircle = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 22 22">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 0)">
          <circle fill="currentColor" cx="11" cy="11" r="11" />
        </g>
        <g transform="translate(4.5 4.5)">
          <path
            fill="#000"
            d="M4.66667 3.66667h-2.61C3.38473 1.62563 5.89682.70084 8.23128 1.39356c2.33447.69272 3.93554 2.83803 3.93539 5.2731h1C13.16949 3.92694 11.4541 1.47958 8.87775.5476 6.3014-.38437 3.41728.39917 1.66667 2.50667v-1.84h-1v4h4v-1zm4 6h2.61c-1.32807 2.04103-3.84016 2.96582-6.17462 2.2731-2.33446-.69271-3.93554-2.83803-3.93539-5.2731h-1c-.00281 2.73974 1.71256 5.1871 4.28892 6.11906 2.57635.93197 5.46047.14843 7.21108-1.95906v1.84h1v-4h-4v1z"
          />
        </g>
      </g>
    </svg>
  );
};

export default ResetCircle;
