import React, { useContext, useEffect } from "react";
// https://github.com/moment/moment/issues/4877
// eslint-disable-next-line
import { Moment } from "moment";
import { useQueryParams, IQueryParamsType } from "../../hooks/useQueryParams";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import { OptionType } from "../../types/GeneralTypes";
import { gqlType } from "@hifieng/common";
import { sortByKey } from "../../helpers/sortByKey";
import {
  sortByOptions,
  pigTypeOptions,
  updatesTypeOptions
} from "../../helpers/filterOptions";
import {
  getQueryParamDateRange,
  DateRange
} from "../../helpers/getDateFromQueryParams";

interface IContextType {
  startDate: Moment | null;
  endDate: Moment | null;
  dateRange: DateRange;
  updateDateRange: (arg0: DateRange) => void;
  applyDateRange: (arg0: DateRange) => void;
  selectedPipelines: Array<string>;
  pipelineOptions: Array<OptionType>;
  setSelectedPipelines: (arg0: Array<string>) => void;
  selectedPigTypes: Array<string>;
  pigTypeOptions: Array<OptionType>;
  setSelectedPigTypes: (arg0: Array<string>) => void;
  sortBy: string;
  sortByOptions: Array<OptionType>;
  setSortBy: (arg0: string) => void;
  updatesTypeOptions: Array<OptionType>;
  selectedUpdatesTypes: Array<string>;
  setSelectedUpdatesTypes: (arg0: Array<string>) => void;
  page: number;
  setPage: (arg0?: number) => void;
  resetAllFilters: () => void;
}

const notInitializedError = () => {
  throw new Error("History Filters context has not yet been initialized.");
};

const INITIAL_CONTEXT: IContextType = {
  startDate: null,
  endDate: null,
  dateRange: {
    startDate: null,
    endDate: null
  },
  updateDateRange: () => {
    notInitializedError();
  },
  applyDateRange: () => {
    notInitializedError();
  },
  selectedPipelines: [],
  pipelineOptions: [],
  setSelectedPipelines: () => {
    notInitializedError();
  },
  selectedPigTypes: [],
  pigTypeOptions: [],
  setSelectedPigTypes: () => {
    notInitializedError();
  },
  sortBy: "TIME_DESC",
  sortByOptions: [],
  setSortBy: () => {
    notInitializedError();
  },
  selectedUpdatesTypes: [],
  updatesTypeOptions: [],
  setSelectedUpdatesTypes: () => {
    notInitializedError();
  },
  page: 1,
  setPage: () => {
    notInitializedError();
  },
  resetAllFilters: () => {
    notInitializedError();
  }
};

export const PigFiltersContext = React.createContext(INITIAL_CONTEXT);
export const usePigFiltersContext = () => useContext(PigFiltersContext);

type PropsType = {
  children: React.ReactNode;
};

export const PigFiltersProvider = ({ children }: PropsType) => {
  const { setQueryParams, queryParams } = useQueryParams();

  const [pipelineOptions, setPipelineOptions] = React.useState<
    Array<OptionType>
  >([]);
  const queryParamDateRange = getQueryParamDateRange(queryParams);

  const [dateRange, setDateRange] = React.useState<DateRange>(
    queryParamDateRange
  );

  const { activeOrg } = useOrganizationContext();
  const numericPage = parseInt(queryParams.page, 10);
  const page = Number.isInteger(numericPage) ? numericPage : 1;
  useEffect(() => {
    if (activeOrg) {
      setPipelineOptions(
        activeOrg.pipelines
          .filter((pipeline: gqlType.Pipeline) => pipeline.segments.length > 0)
          .map((pipeline: gqlType.Pipeline) => ({
            value: pipeline.id,
            label: pipeline.name
          }))
          .sort(sortByKey("label"))
      );
    }
  }, [activeOrg, setPipelineOptions]);

  const setNewFilter = (filters: IQueryParamsType) => {
    setQueryParams({ ...filters, page: undefined }, "push");
  };

  const getSelectedValues = (key: "pipelines" | "type" | "updateType") =>
    queryParams[key] && queryParams[key].length
      ? queryParams[key].split(",")
      : [];

  const setSelected = (
    values: Array<string>,
    key: "pipelines" | "type" | "updateType"
  ) => {
    if (Array.isArray(values) && values.length === 0) {
      setNewFilter({ [key]: undefined });
    } else {
      setNewFilter({ [key]: values });
    }
  };

  const setSortBy = (newSortBy: string) => {
    if (!newSortBy) {
      setNewFilter({ sortBy: undefined });
    } else {
      setNewFilter({ sortBy: newSortBy });
    }
  };

  const updateDateRange = (dateRange: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setDateRange({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate
    });
  };

  const applyDateRange = (dateRangeArg: DateRange) => {
    if (dateRangeArg.startDate && dateRangeArg.endDate) {
      setNewFilter({
        start: dateRangeArg.startDate.unix().toString(),
        end: dateRangeArg.endDate.unix().toString()
      });
    }
    if (!dateRange.startDate && !dateRange.endDate) {
      setNewFilter({
        start: undefined,
        end: undefined
      });
    }
  };

  const setPage = (page?: number) => {
    if (page) {
      setQueryParams({ page }, "push");
    } else {
      setQueryParams({ page: undefined }, "push");
    }
  };

  const resetAllFilters = () => {
    setDateRange(INITIAL_CONTEXT.dateRange);
    setQueryParams(
      {
        pipelines: undefined,
        start: undefined,
        end: undefined,
        type: undefined,
        updateType: undefined
      },
      "push"
    );
  };

  useEffect(() => {
    const range = getQueryParamDateRange(queryParams);
    setDateRange(range);
  }, [queryParams]);

  return (
    <PigFiltersContext.Provider
      value={{
        startDate: queryParamDateRange.startDate,
        endDate: queryParamDateRange.endDate,
        dateRange,
        updateDateRange,
        selectedPipelines: getSelectedValues("pipelines"),
        pipelineOptions,
        setSelectedPipelines: values => setSelected(values, "pipelines"),
        selectedPigTypes: getSelectedValues("type"),
        pigTypeOptions,
        setSelectedPigTypes: values => setSelected(values, "type"),
        selectedUpdatesTypes: getSelectedValues("updateType"),
        updatesTypeOptions,
        setSelectedUpdatesTypes: values => setSelected(values, "updateType"),
        sortBy: queryParams.sortBy ? queryParams.sortBy : "TIME_DESC",
        sortByOptions,
        setSortBy,
        page,
        setPage,
        resetAllFilters,
        applyDateRange
      }}
    >
      {children}
    </PigFiltersContext.Provider>
  );
};

export default PigFiltersProvider;
