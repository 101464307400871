import React from "react";

const Download = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.875 9.312l-.97-.969-5.218 5.211V.375H8.313v13.18L3.094 8.343l-.969.97L9 16.186l6.875-6.874zm0 6.188v2.75H2.125V15.5H.75v2.75c0 .76.616 1.375 1.375 1.375h13.75c.76 0 1.375-.616 1.375-1.375V15.5h-1.375z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Download;
