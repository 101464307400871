import React from "react";
import cx from "classnames";
import styles from "./index.module.scss";

interface IButtonProps extends React.HTMLProps<HTMLButtonElement> {
  component?: React.ElementType;
  type?: "button" | "submit" | "reset";
  text?: React.ReactNode;
  buttonSize?: "small" | "regular" | "large";
  icon?: React.ReactNode;
  square?: boolean;
  responsive?: boolean;
  visuallyDisabled?: boolean;
  linkStyle?: "primary" | "secondary";
  underline?: boolean;
}

export const Primary = ({ ...props }: IButtonProps) => {
  return <BaseButton {...props} buttonStyle={styles.primary}></BaseButton>;
};

export const Secondary = ({ ...props }: IButtonProps) => {
  return <BaseButton {...props} buttonStyle={styles.secondary}></BaseButton>;
};

export const Tertiary = ({ ...props }: IButtonProps) => {
  return <BaseButton {...props} buttonStyle={styles.tertiary}></BaseButton>;
};

export const Important = ({ ...props }: IButtonProps) => {
  return <BaseButton {...props} buttonStyle={styles.important}></BaseButton>;
};

export const TextLink = ({ linkStyle, underline, ...props }: IButtonProps) => {
  return (
    <BaseButton
      {...props}
      buttonStyle={cx(styles.textLink, {
        [styles.textLinkSecondary]: linkStyle === "secondary",
        [styles.textLinkUnderline]: underline
      })}
    ></BaseButton>
  );
};

interface IBaseButtonProps extends IButtonProps {
  buttonStyle: string;
}

const BaseButton = ({
  component: Component = "button",
  buttonStyle,
  children,
  className,
  buttonSize = "regular",
  square = false,
  responsive = false,
  visuallyDisabled = false,
  text,
  icon,
  type = "button",
  ...props
}: IBaseButtonProps) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <Component
      {...props}
      type={type}
      className={cx(className, styles.button, buttonStyle, styles[buttonSize], {
        [styles.withIcon]: icon,
        [styles[`withIcon${buttonSize}`]]: icon,
        [styles.square]: square,
        [styles[`square${buttonSize}`]]: square,
        [styles.responsive]: responsive,
        [styles.visuallyDisabled]: visuallyDisabled
      })}
      aria-label={text}
      title={text}
    >
      {children}
      {icon && (
        <div
          className={cx(styles.IconWrapper, styles[`IconWrapper${buttonSize}`])}
        >
          {icon}
        </div>
      )}
    </Component>
  );
};
