import React from "react";
import Select from "../../Dropdown/Select";
import { OptionType } from "../../../types/GeneralTypes";
import PipelineIcon from "../../Icons/Pipeline";

type ILeakStrengthSelectProps = {
  onStrengthChange: (selectedItem: OptionType) => void;
};

const LeakStrengthSelect = ({ onStrengthChange }: ILeakStrengthSelectProps) => {
  return (
    <>
      <Select
        label="Leak Strength"
        items={[
          {
            value: "weak",
            label: "Weak",
            icon: <PipelineIcon color="black" />
          },
          {
            value: "medium",
            label: "Medium",
            icon: <PipelineIcon color="black" />
          },
          {
            value: "strong",
            label: "Strong",
            icon: <PipelineIcon color="black" />
          }
        ]}
        initial={{
          value: "medium",
          label: "Medium",
          icon: <PipelineIcon color="yellow" />
        }}
        onChange={onStrengthChange}
      />
    </>
  );
};

export default LeakStrengthSelect;
