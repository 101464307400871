import React, { useState } from "react";
import cx from "classnames";
import RangeSlider from "../../RangeSlider";
import { Tertiary } from "../../Button";
import styles from "./index.module.scss";

type Props = {
  precision: number;
  minRange: number;
  maxRange: number;
  rangeStep: number;
  rangeStart: number;
  rangeEnd: number;
  startValue: number;
  endValue: number;
  onShadingChange: (start: number, end: number, resetFlag?: boolean) => void;
};

enum ShadingText {
  Auto = "Auto Color Scale",
  Manual = "Reset Color Scale"
}

const ShadingSelector = ({
  precision,
  minRange,
  maxRange,
  rangeStep,
  rangeStart,
  rangeEnd,
  startValue,
  endValue,
  onShadingChange
}: Props) => {
  const [resetShadingActive, setResetShadingActive] = useState(false);
  const [shadingText, setShadingText] = useState(ShadingText.Auto);

  const handleRangeSliderChange = (values: {
    startValue: number;
    endValue: number;
  }) => {
    setResetShadingActive(true);
    setShadingText(ShadingText.Manual);
    onShadingChange(
      Number(values.startValue.toFixed(precision)),
      Number(values.endValue.toFixed(precision))
    );
  };

  const resetShading = () => {
    setResetShadingActive(false);
    setShadingText(ShadingText.Auto);
    onShadingChange(0, 0, true);
  };

  return (
    <div className={styles.Container}>
      <Tertiary
        className={
          resetShadingActive
            ? styles.ResetButton
            : cx(styles.ResetButton, styles.InactiveResetButton)
        }
        // linkStyle="secondary"
        // underline={resetShadingActive}
        onClick={resetShading}
        disabled={!resetShadingActive}
      >
        {shadingText}
      </Tertiary>
      <RangeSlider
        dualRange
        min={rangeStart}
        max={rangeEnd}
        onChange={handleRangeSliderChange}
        start={startValue}
        end={endValue}
        step={rangeStep}
        minRange={minRange}
        maxRange={maxRange}
        pushHandles
        pullHandles
        className={styles.KPRangeSlider}
      />
    </div>
  );
};

export default ShadingSelector;
