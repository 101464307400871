import React from "react";
import styles from "./index.module.scss";
import { gqlType } from "@hifieng/common";
import { Heading } from "../../../Type";
import SignatureCard from "../SignatureCard";

const EventSignatureImage = ({
  eventSignature
}: {
  eventSignature: gqlType.EventSignature;
}) => {
  return (
    <SignatureCard border>
      <div className={styles.Content}>
        <Heading size="h7">{eventSignature.name}</Heading>
        <img
          className={styles.SignatureImage}
          src={eventSignature.dataFileUri}
          alt={`Contents for signature "${eventSignature.name}"`}
          crossOrigin="anonymous"
        />
      </div>
    </SignatureCard>
  );
};

export default EventSignatureImage;
