import useDeepCompareEffect from "use-deep-compare-effect";
import { useAuth } from "../contexts/AuthProvider";
import { analytics } from "../analytics";

export const useAnalytics = () => {
  const { user, permissions } = useAuth();

  //Set analytics user
  useDeepCompareEffect(() => {
    // wait for the app to load and set the active org before
    // we set the user properties
    analytics.setUserContext({
      ...user,
      permissions
    });
  }, [user, permissions]);
};
