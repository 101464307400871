import React from "react";

const DEFAULT_SIZE = 24;

type PropsType = {
  size?: number;
};

const ArrowPoint = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.25 4.5l-1.057 1.057 5.685 5.692H2.25v1.5h16.628l-5.685 5.693L14.25 19.5l7.5-7.5-7.5-7.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default ArrowPoint;
