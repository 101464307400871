import React from "react";
import { gqlType } from "@hifieng/common";
import Select from "../../Dropdown/Select";
import { OptionType } from "../../../types/GeneralTypes";
import PipelineIcon from "../../Icons/Pipeline";
import SinglePipeline from "../SinglePipeline";

import styles from "./index.module.scss";

type IPipelineSelectProps = {
  pipelines: Array<gqlType.Pipeline>;
  onPipelineChange: (selectedItem: OptionType) => void;
  onFiberChange: (selectedItem: OptionType) => void;
  selectedPipelineId?: string;
  fiber: gqlType.ChannelKPostMap;
  changePipelineURI?: string;
};

const PipelineSelect = ({
  pipelines,
  selectedPipelineId,
  onPipelineChange,
  fiber,
  onFiberChange,
  changePipelineURI
}: IPipelineSelectProps) => {
  let pipeline: gqlType.Pipeline = pipelines[0];
  let fibers: Array<gqlType.ChannelKPostMap> = [];

  // if a selectedPipelineId was specified and we have more than 1 pipeline
  // then we'll find and set that pipeline now.
  if (pipelines.length > 1 && selectedPipelineId) {
    pipelines.forEach((item: gqlType.Pipeline) => {
      if (selectedPipelineId === item.id) {
        pipeline = item;
      }
    });
  }

  if (pipeline.chKpMaps && pipeline.chKpMaps.length) {
    fibers = pipeline.chKpMaps;
  }

  return (pipelines.length > 1 || fibers.length > 1) && !changePipelineURI ? (
    <>
      <Select
        label="Pipeline"
        items={pipelines.map((pipeline: gqlType.Pipeline) => {
          return {
            value: pipeline.id,
            label: pipeline.name,
            icon: <PipelineIcon color={pipeline.color} />
          };
        })}
        initial={{
          value: pipeline.id,
          label: pipeline.name,
          icon: <PipelineIcon color={pipeline.color} />
        }}
        onChange={onPipelineChange}
      />
      {fibers.length > 1 && (
        <div className={styles.FiberSelect}>
          <Select
            hideLabel
            label="Fiber"
            items={fibers.map((item: gqlType.ChannelKPostMap) => {
              return {
                value: item.id,
                label: item.name,
                icon: <PipelineIcon color={pipeline.color} />
              };
            })}
            forceSelectedItem={{
              value: fiber.id,
              label: fiber.name,
              icon: <PipelineIcon color={pipeline.color} />
            }}
            initial={{
              value: fiber.id,
              label: fiber.name,
              icon: <PipelineIcon color={pipeline.color} />
            }}
            onChange={onFiberChange}
          />
        </div>
      )}
    </>
  ) : (
    <SinglePipeline
      pipelineName={fiber.name}
      pipelineColor={pipeline.color}
      changeURL={changePipelineURI || undefined}
    />
  );
};

export default PipelineSelect;
