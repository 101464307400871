import React, { useEffect, useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import cx from "classnames";
import { useLiveStatusContext } from "../../contexts/LiveStatusProvider";
import { useAuth } from "../../contexts/AuthProvider";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import Avatar from "../../components/Avatar";
import { Logo, Clock, Volume, Help, File, PigNav, Train, Leak } from "../Icons";
import EventCount from "../EventCount";
import { Text } from "../Type";
import CloseButton from "./closeButton";
import styles from "./index.module.scss";

type LinkType = {
  icon: ({ size }: { size?: number }) => JSX.Element;
  destination: string;
  text: string;
  permission?: string;
  uris?: Array<string>;
};

type PropsType = {
  location: { pathname: string };
};

const FileIcon = () => <File />;

// Primary links are visible on Mobile and Desktop.
const primaryLinks: Array<LinkType> = [
  {
    icon: Volume,
    destination: "/",
    text: "Monitoring",
    uris: ["/alerts", "/history"]
  },
  {
    icon: Clock,
    destination: "/analysis",
    text: "Analysis",
    permission: "view-summary"
  },
  {
    icon: PigNav,
    destination: "/pig-tracking",
    text: "Pig Tracking",
    permission: "pig-tracking"
  },
  {
    icon: Train,
    destination: "/train-tracking",
    text: "Train Tracking",
    permission: "train-tracking"
  },
  {
    icon: Leak,
    destination: "/fake-leak",
    text: "Deep Fake Leak",
    permission: "fake-leak"
  },
  {
    icon: FileIcon,
    destination: "/reports",
    text: "Reports"
  }
];

// Secondary links are collapsed on Mobile
const secondaryLinks: Array<LinkType> = [];

const Sidebar = (props: PropsType) => {
  const { activeOrg } = useOrganizationContext();
  const { user, permissions } = useAuth();
  const { appStatus, activeAlert } = useLiveStatusContext();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [isPrimaryPath, setIsPrimaryPath] = useState(false);
  const { pathname } = props.location;
  const filteredLinks = primaryLinks.filter(link => {
    if (link.permission) {
      return permissions.user[link.permission];
    }
    return link;
  });

  useEffect(() => {
    const primaryMatch = filteredLinks.find(
      link => link.destination === pathname
    );
    setIsPrimaryPath(!!primaryMatch);
  }, [pathname, filteredLinks]);

  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  return (
    <section className={styles.SidebarWrapper}>
      <div className={styles.Sidebar}>
        <div className={styles.Header}>
          <NavLink to="/" className={styles.Logo}>
            <Logo />
          </NavLink>
        </div>
        {activeOrg && (
          <>
            <nav className={styles.Menu}>
              {filteredLinks.map(link => (
                <NavLink
                  key={link.text}
                  to={link.destination}
                  className={styles.Link}
                  activeClassName={styles.LinkSelected}
                  isActive={(match, location) => {
                    if (link.uris) {
                      return link.uris.some(uri =>
                        location.pathname.startsWith(uri)
                      );
                    }
                    return Boolean(match);
                  }}
                >
                  <div className={styles.LinkIcon}>
                    <link.icon />
                    {link.text === "Monitoring" &&
                      !!appStatus.activeEventCount && (
                        <EventCount
                          activeAlert={activeAlert}
                          className={styles.LinkIconCount}
                          count={appStatus.activeEventCount}
                        />
                      )}
                  </div>
                  <div className={styles.LinkText}> {link.text}</div>
                </NavLink>
              ))}
              <CloseButton
                isOpen={mobileDrawerOpen}
                isSelected={!isPrimaryPath && !mobileDrawerOpen}
                onToggle={toggleMobileDrawer}
              />
            </nav>
            <div
              className={cx(styles.MenuCollapsible, {
                [styles.MenuCollapsibleOpen]: mobileDrawerOpen
              })}
            >
              <nav className={cx(styles.Menu, styles.MenuSecondary)}>
                {secondaryLinks.map(link => (
                  <NavLink
                    key={link.text}
                    to={link.destination}
                    className={styles.Link}
                    activeClassName={styles.LinkSelected}
                    exact
                  >
                    <div className={styles.LinkIcon}>
                      <link.icon />
                    </div>
                    <div className={styles.LinkText}> {link.text}</div>
                  </NavLink>
                ))}
              </nav>

              <div className={styles.Footer}>
                <NavLink to="/organizations" className={styles.FooterAccount}>
                  <div className={styles.LinkIcon}>
                    <Avatar src={activeOrg.imageUrl} alt={activeOrg.name} />
                  </div>
                  <div>
                    <Text component="div" size="small">
                      {activeOrg.name}
                    </Text>
                    <Text component="div" size="small" dark>
                      {user && user.name ? user.name : ""}
                    </Text>
                  </div>
                </NavLink>
                <div className={styles.FooterSupport}>
                  <div className={styles.LinkIcon}>
                    <Help />
                  </div>

                  <div>
                    <Text component="div" size="small">
                      Support
                    </Text>
                    <Text component="div" dark size="small">
                      <a className={styles.Contact} href="tel:18884104434">
                        1-888-410-4434
                      </a>
                    </Text>
                    <Text component="div" dark size="small">
                      Tech Support (option 1)
                    </Text>
                    <Text component="div" dark size="small">
                      <a
                        className={styles.Contact}
                        href="mailto:pipelineops@hifieng.com"
                      >
                        pipelineops@hifieng.com
                      </a>
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default withRouter(Sidebar);
