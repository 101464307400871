import React from "react";
import styles from "./index.module.scss";
import { gqlType } from "@hifieng/common";
import { Heading } from "../../../Type";
import WaveSurferWrapper from "./WaveSurferWrapper";

import SignatureCard from "../SignatureCard";

const EventSignatureAcoustic = ({
  eventSignature
}: {
  eventSignature: gqlType.EventSignature;
}) => {
  return (
    <SignatureCard border>
      <div className={styles.Content}>
        <Heading size="h7" className={styles.Name}>
          Audio - {eventSignature.name}
        </Heading>
        <WaveSurferWrapper
          url={eventSignature.dataFileUri}
          analyticsData={{
            eventId: eventSignature.eventId,
            signatureId: eventSignature.id
          }}
        />
      </div>
    </SignatureCard>
  );
};

export default EventSignatureAcoustic;
