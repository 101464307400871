import React from "react";

import styles from "./index.module.scss";

type PropsType = {
  alt: string;
  src: string;
};

const Avatar = ({ alt, src }: PropsType) => {
  return <img className={styles.Avatar} alt={alt} src={src} />;
};

export default Avatar;
