import React from "react";

const Help = () => (
  <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill="#1B6AE1" cx="14" cy="14" r="14" />
      <g transform="translate(7 7)">
        <circle
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="7"
          cy="7"
          r="7"
        />
        <path
          d="M4.963 4.9a2.1 2.1 0 014.081.7c0 1.4-2.1 2.1-2.1 2.1"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle fill="currentColor" cx="7" cy="10.5" r="1" />
        <path
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7 10.5h0"
        />
      </g>
    </g>
  </svg>
);

export default Help;
