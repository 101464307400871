import React, { useEffect, useState } from "react";

import TabbedContent, { IActivatedCallBack } from "../TabbedContent";
import ScreenLayout from "../ScreenLayout";
import Alerts from "../AlertsPage";
import History from "../HistoryPage";

import { useLiveStatusContext } from "../../contexts/LiveStatusProvider";
import MobilePageTitle from "../MobilePageTitle";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import Loading from "../Loading";

type EventType = {
  id: string;
  archived: boolean;
};

// We need an initial event counts for the tabs
const EVENTS_QUERY = gql`
  query events($filters: EventFiltersInput) {
    events(filters: $filters) {
      events {
        id
        archived
      }
    }
  }
`;

const MonitoringPage = () => {
  const { activeOrg } = useOrganizationContext();
  const { appStatus } = useLiveStatusContext();

  const [subTitle, setSubTitle] = useState(`0 active events`);
  const [activeCount, setActiveCount] = useState(0);
  const [historyCount, setHistoryCount] = useState(0);

  const { data, loading, error, refetch } = useQuery(EVENTS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      filters: {
        pipelines:
          activeOrg && activeOrg.pipelines
            ? activeOrg.pipelines.map(p => p.id)
            : []
      }
    }
  });

  // When the appStatus changes we'll re-fetch events to get update counts
  useEffect(() => {
    refetch();
  }, [refetch, appStatus]);

  // Updates tab counts when the event counts change.
  useEffect(() => {
    if (!loading && !error && data && data.events && data.events.events) {
      setActiveCount(
        data.events.events.filter((e: EventType) => !e.archived).length
      );
      setHistoryCount(
        data.events.events.filter((e: EventType) => e.archived).length
      );
    }
  }, [data, loading, error]);

  // Each of these tabs will be able to update the page title and tab counts
  // from within. Because each tab is only updating its own count we have to
  // write the callbacks inline.

  const alertsTab = {
    url: "/alerts",
    title: `Alert Monitoring (${activeCount})`,
    content: (
      <Alerts
        onActivated={({ title }: IActivatedCallBack) => {
          setSubTitle(`${activeCount} ${title} events`);
        }}
      />
    )
  };

  const historyTab = {
    url: "/history",
    title: `Alert History (${historyCount})`,
    content: (
      <History
        onActivated={({ title }: IActivatedCallBack) => {
          setSubTitle(`${historyCount} ${title} events`);
        }}
      />
    )
  };

  const tabs = [alertsTab, historyTab];

  if (activeOrg && activeOrg.pipelines) {
    return (
      <ScreenLayout title="Monitoring" subtitle={subTitle}>
        <MobilePageTitle page="Monitoring" subtitle={subTitle} />
        <TabbedContent tabs={tabs} title="Monitoring Page Navigation" />
      </ScreenLayout>
    );
  }

  return <Loading />;
};

export default MonitoringPage;
