import React from "react";
import { Text } from "../Type";
import { offset } from "../../helpers/pagination";

interface IResultsProps extends React.HTMLProps<HTMLSpanElement> {
  totalEvents: number;
  eventsCount: number;
  limit: number;
  page?: number;
  className?: string;
}

const ResultsCount = ({
  totalEvents,
  eventsCount,
  page,
  className,
  limit
}: IResultsProps) => {
  const firstEventIndex: number = page ? offset(page, limit) + 1 : 1;
  const lastEventIndex = firstEventIndex + eventsCount - 1;

  return (
    <Text className={className}>
      {`${
        lastEventIndex === 0
          ? "No"
          : `${firstEventIndex}-${lastEventIndex} of ${totalEvents}`
      } results`}
    </Text>
  );
};

export default ResultsCount;
