import React from "react";
import cx from "classnames";

import styles from "./closeButton.module.scss";

type PropsType = {
  isOpen: boolean;
  isSelected: boolean;
  onToggle: () => void;
};

const CloseButton = ({ isOpen, isSelected, onToggle }: PropsType) => {
  return (
    <>
      <button
        type="button"
        className={cx(styles.ToggleOverlay, {
          [styles.ToggleOverlayOpen]: isOpen
        })}
        onClick={onToggle}
      />
      <button
        type="button"
        className={cx(styles.Toggle, {
          [styles.ToggleOpen]: isOpen,
          [styles.LinkSelected]: isSelected
        })}
        onClick={onToggle}
      >
        <span />
        <span />
        <span />
        <span />
      </button>
    </>
  );
};

export default CloseButton;
