import React from "react";
import { gqlType } from "@hifieng/common";
import EventCard from "./EventCard";
import { Heading, Text } from "../../Type";
import getPriorityLevel from "../../../helpers/getPriorityLevel";
import styles from "./index.module.scss";
import DateTime from "../../DateTime";
import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";
import { coordWeldMap } from "../../../helpers/coordWeldMap";
import { getStatusText } from "../../../helpers/pigStatus";
import { kpToMp } from "../../AnalysisCharts/BaseChart";
import { useAuth } from "../../../contexts/AuthProvider";

type PropsType = {
  event: gqlType.EventNotification;
  selected: boolean;
  onClick: () => void;
};

const EventSummary = ({ event, selected, onClick }: PropsType) => {
  const { startTime } = event;
  const priorityLevel = getPriorityLevel(
    (event.type === "pig" || event.type === "train") && event.eventMeta
      ? event.eventMeta.status
      : event.type
  );
  const { permissions } = useAuth();
  const mpPrecision = 2;

  const COORDINATE_DIGITS = 6;
  const coordinatesString = `${event.coordinates.latitude.toFixed(
    COORDINATE_DIGITS
  )},${event.coordinates.longitude.toFixed(COORDINATE_DIGITS)}`;
  // }
  return (
    <EventCard priority={priorityLevel} onClick={onClick} selected={selected}>
      <div className={styles.Header}>
        <div className={styles.TitleGroup}>
          <Heading className={styles.Type} size="h7">
            {event.type}{" "}
            {event.eventMeta ? getStatusText(event.eventMeta.status) : ""}
          </Heading>
          <Text size="small" className={styles.StartTime}>
            <DateTime
              timestamp={startTime * MILLISECONDS_IN_SECONDS}
              length="short"
            />
          </Text>
        </div>
        <div className={styles.InfoGroup}>
          <Text size="small" className={styles.Details}>
            {(event.type === "pig" || event.type === "train") && event.eventMeta
              ? `${
                  event.type === "train"
                    ? event.eventMeta.railId
                    : event.pipeline.name
                } - Run ${event.eventMeta.runId}`
              : event.pipeline.name}
          </Text>
          <Text size="small" dark className={styles.Id} disambiguation>
            {event.referenceId}
          </Text>
        </div>
        {event.pipeline.id === "suncor_icp" ? (
          <Text size="small" className={styles.Details}>
            {`${coordWeldMap(coordinatesString)} | ${coordinatesString}`}
          </Text>
        ) : (
          <Text size="small" className={styles.Details}>
            {(permissions.user.imperial ? true : false) ||
            event.pipeline.id === "enbridge_line_3" ||
            event.pipeline.id === "enbridge_line_4"
              ? `MP ${kpToMp(event.post, mpPrecision)}`
              : `KP ${event.post}`}
          </Text>
        )}
      </div>
    </EventCard>
  );
};

export default EventSummary;
