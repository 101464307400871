import React from "react";
import cx from "classnames";

import { Heading, Text } from "../Type";
import { NavLink } from "react-router-dom";

import { Arrow } from "../Icons";

import styles from "./index.module.scss";

type PropsType = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  className?: string;
  breadCrumbs?: {
    text: string;
    url: string;
  };
};

const PageTitle = ({ title, subtitle, breadCrumbs }: PropsType) => {
  return (
    <div
      className={cx(styles.Title, {
        [styles.TitleWithLink]: breadCrumbs
      })}
    >
      {breadCrumbs && (
        <NavLink className={styles.BreadCrumb} to={breadCrumbs.url}>
          <div className={styles.LinkIcon}>
            <Arrow />
          </div>
          <Text component="span" dark className={styles.LinkText}>
            {breadCrumbs.text}
          </Text>
        </NavLink>
      )}
      <Heading size="h6" className={styles.Text}>
        {title}
        <Heading size="h6" component="span" className={styles.Text} dark>
          {subtitle}
        </Heading>
      </Heading>
    </div>
  );
};

export default PageTitle;
