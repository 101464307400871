import React from "react";
import { Route, NavLink } from "react-router-dom";

import styles from "./index.module.scss";

export interface IActivatedCallBack {
  title: string;
  count?: number;
}
export interface ITabContent {
  onActivated?: ({ title, count }: IActivatedCallBack) => void;
}

type Tab = {
  url: string;
  title: string;
  content: string | React.ReactNode;
};

type PropsType = {
  tabs: Array<Tab>;
  title: string;
  exact?: boolean;
};

const TabbedContent = ({ tabs, title, exact }: PropsType) => {
  return (
    <div className={styles.TabWrapper}>
      <div role="tablist" aria-label={title} className={styles.TabbedContent}>
        <div className={styles.Tabs}>
          {tabs.map(tab => (
            <NavLink
              key={tab.url}
              to={tab.url}
              className={styles.TabButton}
              activeClassName={styles.TabButtonSelected}
            >
              {tab.title}
            </NavLink>
          ))}
        </div>
      </div>
      {tabs.map(tab => (
        <Route
          key={tab.url}
          path={tab.url}
          className={styles.TabPanel}
          exact={exact}
        >
          {tab.content}
        </Route>
      ))}
    </div>
  );
};

export default TabbedContent;
