import React from "react";

import Sidebar from "../Sidebar";

import styles from "./index.module.scss";

type PropsType = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PropsType) => {
  return (
    <div className={styles.Page}>
      <Sidebar />
      <main className={styles.Screen}>{children}</main>
    </div>
  );
};

export default PageLayout;
