import React from "react";
import cx from "classnames";
import { Text } from "../Type";
import { PaginationLeft, PaginationRight } from "../Icons";

import styles from "./index.module.scss";

type PropsType = {
  totalEvents: number;
  currentPage: number;
  updatePage: (page: number) => void;
  limit: number;
};

function generatePaginationButtons(pageCount: number, currentPage: number) {
  const buttons = [];
  for (let i = 1; i <= pageCount; i++) {
    if (
      i === 1 ||
      i === currentPage ||
      i === currentPage - 1 ||
      i === currentPage + 1 ||
      i === pageCount
    ) {
      buttons.push(i);
    } else {
      if (buttons[buttons.length - 1] !== "spacer") {
        buttons.push("spacer");
      }
    }
  }
  return buttons;
}

const PaginationButton = ({
  button,
  currentPage,
  updatePage
}: {
  button: string | number;
  currentPage: number;
  updatePage: (page: number) => void;
}) => {
  if (typeof button === "number") {
    return (
      <button
        className={cx(styles.Button, {
          [styles.ButtonCurrent]: button === currentPage
        })}
        type="button"
        onClick={() => {
          if (currentPage !== button) {
            updatePage(button);
          }
        }}
      >
        <Text className={styles.ButtonText}>{button}</Text>
      </button>
    );
  } else if (typeof button === "string") {
    if (button === "spacer") {
      return <span className={styles.Spacer}>&bull;&bull;&bull;</span>;
    }
  }
  return null;
};

const PaginationButtons = ({
  totalEvents,
  currentPage,
  updatePage,
  limit
}: PropsType) => {
  const pageCount = Math.floor((totalEvents + limit - 1) / limit);

  if (pageCount === 1) {
    return null;
  }

  const buttons: Array<number | string> = generatePaginationButtons(
    pageCount,
    currentPage
  );

  return (
    <div className={styles.Pagination}>
      <button
        disabled={currentPage === 1}
        className={cx(styles.ButtonIcon, {
          [styles.Disabled]: currentPage === 1
        })}
        type="button"
        onClick={() => {
          updatePage(currentPage === 1 ? currentPage : currentPage - 1);
        }}
      >
        <PaginationLeft />
      </button>
      {buttons.map((button, i) => (
        <PaginationButton
          updatePage={updatePage}
          currentPage={currentPage}
          key={i}
          button={button}
        />
      ))}
      <button
        className={styles.ButtonIcon}
        type="button"
        onClick={() => {
          updatePage(currentPage === pageCount ? currentPage : currentPage + 1);
        }}
      >
        <PaginationRight />
      </button>
    </div>
  );
};

export default PaginationButtons;
