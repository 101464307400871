import { gqlType } from "@hifieng/common";

const digitsOfPrecision = 7;
const formatEvent = (
  event: gqlType.EventNotification
): gqlType.EventNotification => ({
  ...event,
  coordinates: {
    ...event.coordinates,
    latitude: parseFloat(event.coordinates.latitude.toFixed(digitsOfPrecision)),
    longitude: parseFloat(
      event.coordinates.longitude.toFixed(digitsOfPrecision)
    )
  }
});

export default formatEvent;
