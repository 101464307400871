import { gqlType } from "@hifieng/common";
import AppError from "../errors/AppError";
import { MAX_ANALYSIS_CHANNEL_QUERY_RANGE } from "./constants";
import {
  AnalysisDetailsFilters,
  SearchRange,
  UnitDefinition
} from "../types/AnalysisTypes";

export const getAnalysisRangeUnits = (imperialFlag = false) => {
  return [
    {
      name: imperialFlag ? "MPs" : "KPs",
      title: imperialFlag ? "MP Range" : "KP Range",
      shortTitle: imperialFlag ? "MP" : "KP",
      description: `Range is limited to ${
        imperialFlag ? "0.31 Miles" : "0.5 KM"
      } of data at a single time to reduce load times.`,
      // eslint-disable-next-line no-magic-numbers
      maxRange: imperialFlag ? 0.31 : 0.5
    },
    {
      name: "Channels",
      title: "Channel Range",
      shortTitle: "CH",
      description:
        "Range is limited to 20 Channels of data at a single time to reduce load times.",
      maxRange: 20
    }
  ];
};

export const getUnitDefinition = (unit: number | string): UnitDefinition => {
  const analysisRangeUnits = getAnalysisRangeUnits();
  if (!analysisRangeUnits[Number(unit)]) {
    throw new AppError(
      `Index ${unit} is out of bounds for "analysisRangeUnits"`
    );
  }
  return analysisRangeUnits[Number(unit)];
};

// Finds a `chKpMap` (or "fiber") for a pipeline, if one can't be found then it
// creates one.
export const getPipelineChKpMap = (
  pipeline: gqlType.Pipeline,
  fiberId?: string
) => {
  // if this pipeline has any `ChKpMap` definition then we will try to give the
  // request one back.
  if (pipeline.chKpMaps && pipeline.chKpMaps.length) {
    const fiberIndex = Math.max(
      pipeline.chKpMaps.findIndex(
        (f: gqlType.ChannelKPostMap) =>
          fiberId && f.id.toLowerCase() === fiberId.toLowerCase()
      ),
      0
    );

    // send out the selected fiber if it exists otherwise we'll default.
    return pipeline.chKpMaps[fiberIndex];
  }

  // At this point we don't have any mappings defined so we will create one
  // from the `kmPosts` data on the pipeline. This will assume a 1:1, linear
  // mapping between channels and kilometer posts, it's not ideal but it's the
  // best we can do without the proper data. Hopefully this is never needed.
  const constructedFiber: gqlType.ChannelKPostMap = {
    id: pipeline.id,
    name: pipeline.name,
    ch: [],
    kp: [],
    precision: 3,
    step: 0.001
  };

  pipeline.kmPosts.forEach((kmPost: gqlType.Locations, i: number) => {
    constructedFiber.ch.push(i + 1);
    constructedFiber.kp.push(kmPost.post);
  });

  return constructedFiber;
};

export const KPostRangeToChannelRange = (
  fiber: gqlType.ChannelKPostMap,
  range: SearchRange
): SearchRange => {
  const kps = [...fiber.kp]; // cloning fiber.kp
  const chs = [...fiber.ch]; // cloning fiber.ch

  let { start, end } = range;

  // Fiber is backwards, we'll have to reverse everything
  if (kps[0] > kps[kps.length - 1]) {
    // kPosts are in descending order, this will sort them ascending
    kps.sort();
    // channels are in ascending order but we'll need them descending
    chs.reverse();
  }

  // find the corresponding start channel index
  const startIndex = kps.findIndex((kp: number) => kp >= start);

  // set the corresponding start channel value
  start = chs[Math.max(0, startIndex)];

  // find the corresponding end channel index
  const endIndex = kps.findIndex((kp: number) => kp >= end);

  // there really isn't any reason why this above code wouldn't have found an
  // index but let's just be safe.
  if (endIndex >= 0) {
    // set the corresponding end channel value
    end = chs[Math.min(chs.length - 1, endIndex)];
  } else {
    // Fallback that sets the end based on a max range from the start.
    end = Number(start) + MAX_ANALYSIS_CHANNEL_QUERY_RANGE;
  }

  // If the Fiber was reversed our start and end will be inverted, we'll use
  // `min` and `max` to figure out the correct order to return them in.
  return {
    start: Math.min(start, end),
    end: Math.max(start, end)
  };
};

// creates an `AnalysisDetailsFilters` object and corrects the channel range
// based on the requested unit.
export const createAnalysisDetailsFilters = (
  filters: AnalysisDetailsFilters
) => {
  const unitDef = getUnitDefinition(filters.unit);
  let range = {
    start: filters.start,
    end: filters.end
  };

  // if the unit is KPs then we'll have to convert start and end values to channels here
  if (unitDef.shortTitle.toLowerCase() === "kp") {
    range = KPostRangeToChannelRange(filters.fiber, range);
  }

  return {
    ...filters,
    ...range
  };
};

// finds the max allowed range of KPs bases on the given channel max.
export const calculateMaxKPRange = (
  fiber: gqlType.ChannelKPostMap,
  channelMax = MAX_ANALYSIS_CHANNEL_QUERY_RANGE
) => {
  // when the fiber is reversed we'll get the correct number but it will be
  // negative. This isn't really a huge deal because the range and number
  // inputs will invert it for us but we'll try to be more intentional
  // with it by only returning a positive integer.
  return Math.abs(
    fiber.kp[Math.min(channelMax, fiber.kp.length - 1)] - fiber.kp[0]
  );
};

export const channelToKPost = (
  fiber: gqlType.ChannelKPostMap,
  channel: number
) => {
  const index = fiber.ch.findIndex((ch: number) => ch === channel);
  if (index >= 0 && fiber.kp[index]) {
    return fiber.kp[index];
  }
  return null;
};

export const getKPostIndex = (
  pipeline: gqlType.Pipeline,
  fiber: gqlType.ChannelKPostMap,
  post: number,
  isChannel?: boolean
) => {
  const findPost = isChannel ? channelToKPost(fiber, post) : post;

  if (findPost !== null) {
    for (let i = 0; i < pipeline.kmPosts.length; i++) {
      if (pipeline.kmPosts[i].post >= findPost) {
        return i;
      }
    }
  }

  return 0;
};

export const getRangeIndexes = (
  pipeline: gqlType.Pipeline,
  fiber: gqlType.ChannelKPostMap,
  range: SearchRange,
  unit: number
) => {
  const isChannel =
    getUnitDefinition(unit).shortTitle.toLocaleLowerCase() === "ch";

  return {
    start: getKPostIndex(pipeline, fiber, range.start, isChannel),
    end: getKPostIndex(pipeline, fiber, range.end, isChannel)
  };
};
