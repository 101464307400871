import React from "react";

import EventSignatureImage from "./EventSignatureImage";
import EventSignatureAcoustic from "./EventSignatureAcoustic";
import EventSignatureFile from "./EventSignatureFile";
import DetailsSection from "../../DetailsSection";

import { useEventDetailContext } from "../../../contexts/eventDetailProvider";

import styles from "./index.module.scss";

import { gqlType } from "@hifieng/common";

const EventSignature = ({
  eventSignature
}: {
  eventSignature: gqlType.EventSignature;
}) => {
  switch (eventSignature.type) {
    case "image": {
      return <EventSignatureImage eventSignature={eventSignature} />;
    }
    case "acoustic": {
      return <EventSignatureAcoustic eventSignature={eventSignature} />;
    }
    default: {
      return <EventSignatureFile eventSignature={eventSignature} />;
    }
  }
};

const EventSignatures = () => {
  const { event } = useEventDetailContext();
  return event && event.signatures && event.signatures.length ? (
    <DetailsSection title="Signatures">
      <div className={styles.SignaturesContainer}>
        {event.signatures.map((eventSignature: gqlType.EventSignature) => {
          return (
            <EventSignature
              eventSignature={eventSignature}
              key={eventSignature.id}
            />
          );
        })}
      </div>
    </DetailsSection>
  ) : null;
};

export default EventSignatures;
