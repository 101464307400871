import React, { useState } from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";
import { NavLink } from "react-router-dom";

import ShareDialog from "../../ShareDialog";
import { Close, IconByType, IconCircleWrapper, Share } from "../../Icons";
import { Secondary, Tertiary } from "../../Button";
import { Text, Heading } from "../../Type";
import { analytics } from "../../../analytics";

import getPriorityLevel from "../../../helpers/getPriorityLevel";
import { getStatusText } from "../../../helpers/trainStatus";

import { priorityColorMap } from "../../../styles/colors";

import styles from "./index.module.scss";

type PropsType = {
  trainDetailsRef: React.RefObject<HTMLDivElement>;
  updateId: string;
  trainId: string;
  status: gqlType.TrainStatus;
  returnUrl: string;
};

const Header = ({
  status,
  updateId,
  trainId,
  trainDetailsRef,
  returnUrl
}: PropsType) => {
  const [shareOpen, setShareOpen] = useState(false);
  const priority = getPriorityLevel(status);
  const priorityColor = priorityColorMap[priority];
  const statusText = getStatusText(status);
  const toggleShare = () => {
    analytics.eventShareDialogueOpen(trainId);
    setShareOpen(open => {
      return !open;
    });
  };

  return (
    <div className={styles.Header}>
      <div
        className={cx(styles.TitleGroup, {
          [styles.TitleGroupLow]: priority === "low",
          [styles.TitleGroupMedium]: priority === "medium",
          [styles.TitleGroupHigh]: priority === "high"
        })}
      >
        <div className={styles.TitleWrapper}>
          <span className={styles.Icon}>
            <IconCircleWrapper
              size={24}
              childSize={12}
              iconColor={priorityColor}
            >
              <IconByType type="train" size={12} />
            </IconCircleWrapper>
          </span>
          <Heading className={styles.Type} size="h6">
            Train {statusText}
          </Heading>
        </div>
        <div className={styles.TitleWrapper}>
          <Text size="small" className={styles.Id} disambiguation>
            {updateId}
          </Text>
        </div>
      </div>
      <div className={styles.ButtonGroup}>
        <Secondary
          className={cx(styles.Button, styles.ButtonShare)}
          buttonSize="small"
          onClick={toggleShare}
          title="Share"
          text="Share"
          square
          icon={<Share />}
        />
        <NavLink to={returnUrl}>
          <Tertiary
            title="Close"
            buttonSize="small"
            className={cx(styles.Button, styles.ButtonClose)}
            component="span"
            text="Close"
            icon={<Close />}
            square
          />
        </NavLink>
      </div>
      {shareOpen && (
        <ShareDialog
          eventDetailsRef={trainDetailsRef}
          referenceId={updateId}
          eventId={trainId}
          onClose={() => setShareOpen(false)}
          label="Train Update Details URL"
        />
      )}
    </div>
  );
};

export default Header;
