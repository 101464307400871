import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Share = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 18 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.14963 2.46736c1.32563.34767 2.36095 1.38285 2.7088 2.70843.34784 1.32558-.0458 2.73574-1.0299 3.68951L9.8088 7.8456c.60909-.59166.85229-1.46552.63642-2.28677-.21587-.82125-.85733-1.46258-1.67863-1.67827-.8213-.21568-1.6951.0277-2.28663.63692L2.1314 8.86602c-.91923.91924-.91923 2.40961 0 3.32885.91924.91923 2.40961.91923 3.32885 0l1.0197 1.0197a3.78248 3.78248 0 01-2.68412 1.11059v-.00073C2.2608 14.32484.8767 13.4004.28912 11.98227c-.58758-1.41813-.26291-3.05058.82258-4.13596l4.34856-4.34855C6.4139 2.5135 7.824 2.11969 9.14963 2.46736zm7.7385-.68066c1.47137 1.47137 1.48402 3.85299.02837 5.3399l-4.34855 4.34855a3.7832 3.7832 0 01-2.68414 1.10987c-1.53551.0014-2.92047-.92288-3.50837-2.3414-.5879-1.4185-.26281-3.05152.82352-4.13673L8.21939 7.1266c-.59464.59464-.82687 1.46135-.60922 2.27364.21765.8123.85213 1.44677 1.66442 1.66442.8123.21766 1.679-.01458 2.27364-.60922L15.8968 6.1069c.59464-.59464.82687-1.46135.60922-2.27364-.21765-.8123-.85213-1.44677-1.66442-1.66443-.8123-.21765-1.679.01458-2.27364.60922l-1.01972-1.0197c1.48692-1.45565 3.86854-1.443 5.3399.02836z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default Share;
