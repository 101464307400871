import React, { useCallback } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import BaseSpiderfy from "./BaseSpiderfy";
import { getGeoBounds } from "../../../helpers/eventMap";
import { LatLngBoundsLiteral } from "../../Map";
import { MapEvent } from "../../../types/EventMap";

type PropsType = {
  zoomLevel: number;
  setFitBounds: (bounds: LatLngBoundsLiteral) => void;
  events: Array<MapEvent>;
  disableEventIconSelection?: boolean;
};

const METERS_PER_DEGREE_LATITUDE = 111111;
const MIN_CLUSTER_ZOOM_METERS = 200;
const HALF = 0.5;

const degreesToRadians = (degrees: number) => {
  const pi = Math.PI;
  return degrees * (pi / 180); //eslint-disable-line no-magic-numbers
};

const Spiderfy = ({
  zoomLevel,
  setFitBounds,
  events,
  disableEventIconSelection
}: PropsType) => {
  const history = useHistory();
  const match = useRouteMatch();

  const eventIconSelection = useCallback(
    (eventId: string) => {
      history.push(`${match.url}/${eventId}`);
    },
    [history, match.url]
  );

  const onZoom = useCallback(
    points => {
      // Get bounds of clustered markers
      const { ne, sw } = getGeoBounds(points);
      /*
       * Assuming the earth is perfectly spherical, we can say that there is ~ 111km between each degree of Latitude.
       * The distance between each degree of longitude is dependant on the degree of latitude. The ratio of the two distances is
       * equal to the cosine of the degrees latitude.
       * (https://gis.stackexchange.com/questions/2951/algorithm-for-offsetting-a-latitude-longitude-by-some-amount-of-meters)
       */

      const minNSDegrees = MIN_CLUSTER_ZOOM_METERS / METERS_PER_DEGREE_LATITUDE;
      const latToLongRatio = Math.cos(degreesToRadians(ne.lat));
      const minEWDegrees =
        MIN_CLUSTER_ZOOM_METERS / METERS_PER_DEGREE_LATITUDE / latToLongRatio;

      const spreadNS = ne.lat - sw.lat;
      const spreadEW = ne.long - sw.long;

      // Increase the north and south bounds evenly if the north-south spread is
      // not greater than the desired min distance (MIN_CLUSTER_ZOOM_METERS)
      if (spreadNS < minNSDegrees) {
        const diffNS = minNSDegrees - spreadNS;
        ne.lat = ne.lat + diffNS * HALF;
        sw.lat = sw.lat - diffNS * HALF;
      }
      // Increase the east and west bounds evenly if the east-west spread is
      // not greater than the desired min distance (MIN_CLUSTER_ZOOM_METERS)
      if (spreadEW < minEWDegrees) {
        const diffEW = minEWDegrees - spreadEW;
        ne.long = ne.long + diffEW * HALF;
        sw.long = sw.long - diffEW * HALF;
      }

      setFitBounds({
        north: ne.lat,
        east: ne.long,
        south: sw.lat,
        west: sw.long
      });
    },
    [setFitBounds]
  );

  return (
    <BaseSpiderfy
      zoomLevel={zoomLevel}
      onZoom={onZoom}
      onEventSelection={
        disableEventIconSelection ? undefined : eventIconSelection
      }
      events={events}
    />
  );
};

export default Spiderfy;
