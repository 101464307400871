import React from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";
import { Link } from "react-router-dom";
import { File, Download, Trash, Eye, Close } from "../../Icons";
import { Heading, Text, Meta } from "../../Type";
import { Primary, Secondary, Important, Tertiary } from "../../Button";
import { breakpoints } from "../../../styles/variables";
import { formatBytes } from "../../../helpers/reports";
import { useAuth } from "../../../contexts/AuthProvider";
import styles from "./index.module.scss";
import { analytics } from "../../../analytics";
import DateTime from "../../DateTime";
import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";
import { useModalContext } from "../../../contexts/ModalProvider";
import DeleteModal from "../DeleteModal";

type DetailsPropsType = {
  className?: string;
  date: number;
  sizeInByte: string;
  contentType: string;
};

const Details = ({
  className,
  date,
  sizeInByte,
  contentType
}: DetailsPropsType) => (
  <div className={cx(styles.Details, className)}>
    <div className={styles.Date}>
      <Meta size="small" dark>
        Date
      </Meta>
      <Text size="small" className={styles.Text}>
        <DateTime timestamp={date * MILLISECONDS_IN_SECONDS} length="long" />
      </Text>
    </div>
    <div className={styles.Type}>
      <Meta size="small" dark>
        File
      </Meta>
      <Text size="small" className={styles.Text}>
        {contentType} - {formatBytes(parseInt(sizeInByte, 10))}
      </Text>
    </div>
  </div>
);

const DeleteButton = ({
  setReportToDelete
}: {
  setReportToDelete: () => void;
}) => {
  const { permissions } = useAuth();

  if (permissions.user["write-reports"]) {
    return (
      <Important
        square
        className={styles.Button}
        onClick={() => {
          setReportToDelete();
        }}
        title="Delete"
      >
        <Trash size={20} />
      </Important>
    );
  } else return null;
};

type PropsType = {
  report: gqlType.Report;
  showCloseButton: boolean;
  showPreviewButton: boolean;
};
const ReportRow = ({
  report,
  showCloseButton,
  showPreviewButton
}: PropsType) => {
  const { title, name, uri, createdAt, sizeInByte, contentType } = report;

  const { setOpenModal } = useModalContext();
  const [reportToDelete, setReportToDelete] = React.useState<gqlType.Report>();

  const openDeleteModal = (report: gqlType.Report) => {
    setReportToDelete(report);
    setOpenModal("delete-report");
  };

  const onDelete = () => {
    setReportToDelete(undefined);
  };

  return (
    <div className={styles.Report}>
      <div className={styles.Main}>
        <File
          // eslint-disable-next-line no-magic-numbers
          size={window.innerWidth > breakpoints.medium ? 24 : 16}
        />
        <div className={styles.Name}>
          <Heading className={styles.Title} size="h2">
            {title}
          </Heading>
        </div>
        <Details
          date={createdAt}
          sizeInByte={sizeInByte.toString()}
          contentType={contentType}
          className={styles.DesktopDetails}
        />
        <div className={styles.Buttons}>
          {showPreviewButton ? (
            <Link to={`/reports/${encodeURIComponent(name)}`}>
              <Primary
                component="div"
                square
                className={styles.Button}
                title="Preview"
              >
                <Eye size={20} />
              </Primary>
            </Link>
          ) : null}
          <Secondary
            component="a"
            square
            className={styles.Button}
            href={uri}
            download={name}
            target="_blank"
            title="Download"
            onClick={() => {
              analytics.reportDownloaded(title, contentType);
            }}
          >
            <Download />
          </Secondary>
          <DeleteButton setReportToDelete={() => openDeleteModal(report)} />
          {showCloseButton ? (
            <Link to="/reports">
              <Tertiary
                component="div"
                square
                className={styles.Button}
                title="Close"
              >
                <Close size={20} />
              </Tertiary>
            </Link>
          ) : null}
        </div>
      </div>
      <Details
        date={createdAt}
        sizeInByte={sizeInByte.toString()}
        contentType={contentType}
        className={styles.MobileDetails}
      />
      <DeleteModal report={reportToDelete} onDelete={onDelete} />
    </div>
  );
};

export default ReportRow;
