import { gqlType } from "@hifieng/common";
import getPriorityLevel from "../../../helpers/getPriorityLevel";

type NestedUpdate = {
  [index: string]: Array<gqlType.PigRunUpdate>;
};

type GroupedUpdate = {
  low: Array<gqlType.PigRunUpdate>;
  high: Array<gqlType.PigRunUpdate>;
};

export type FormattedUpdates = Array<
  gqlType.PigRunUpdate | Array<gqlType.PigRunUpdate>
>;

export const formatPigUpdates = (
  updates: Array<gqlType.PigRunUpdate>
): FormattedUpdates => {
  const grouped = updates.reduce(
    (acc: GroupedUpdate, update, i, array) => {
      const priority = getPriorityLevel(update.status);
      const previousPriority = i > 0 && getPriorityLevel(array[i - 1].status);
      const nextPriority =
        i < updates.length - 1 && getPriorityLevel(array[i + 1].status);
      if (
        priority === "low" &&
        previousPriority === "low" &&
        i !== updates.length - 1 &&
        nextPriority === "low"
      ) {
        acc.low.push(update);
      } else {
        acc.high.push(update);
      }
      return acc;
    },
    {
      low: [],
      high: []
    }
  );
  let number = 0;
  const lowPriority = grouped.low.reduce(
    (acc: NestedUpdate, item, i, array) => {
      const itemIndex = updates.indexOf(item);
      const nextItemIndex =
        i < array.length ? updates.indexOf(array[i + 1]) : undefined;
      if (itemIndex + 1 === nextItemIndex || i + 1 === grouped.low.length) {
        if (acc[number].indexOf(item) < 0) {
          acc[number].push(item);
        }
        acc[number].push(array[i + 1]);
      } else if (i === 0 || itemIndex + 1 !== nextItemIndex) {
        acc[number + 1] = [];
        if (acc[number].indexOf(item) < 0) {
          acc[number].push(item);
        }
        number++;
        acc[number] = [];
      } else {
        number++;
        acc[number] = [];
      }
      return acc;
    },
    {
      "0": []
    }
  );
  const nestedUpdates = Object.keys(lowPriority)
    .map(key => {
      return lowPriority[key].filter(item => item);
    })
    .filter(update => update.length);
  const sorted = [...grouped.high, ...nestedUpdates].sort((a, b) => {
    const aIndex = Array.isArray(a)
      ? updates.indexOf(a[0])
      : updates.indexOf(a);
    const bIndex = Array.isArray(b)
      ? updates.indexOf(b[0])
      : updates.indexOf(b);
    return aIndex - bIndex;
  });
  return sorted.filter(item => item);
};
