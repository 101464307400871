import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Leak = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 10 14">
      <path
        fill="currentColor"
        d="M5.48739047,1.36979396 L7.89565558,4.41225321 L7.89565558,4.41225321 C9.3646718,5.8924061 10.5379858,8.11910391 9.74353478,10.0537823 C8.94908379,11.9884606 7.07682214,13.25 5,13.25 C2.92317786,13.25 1.05091621,11.9884606 0.256465215,10.0537823 C-0.537985781,8.11910391 0.635328197,5.8924061 2.10434442,4.41225321 L4.50686059,1.37032259 C4.72080256,1.09944132 5.11382945,1.0532829 5.38471072,1.26722487 C5.42280428,1.29731115 5.45726313,1.33173287 5.48739047,1.36979396 Z"
      />
    </svg>
  );
};

export default Leak;
