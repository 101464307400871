import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { NavLink } from "react-router-dom";
import cx from "classnames";

import { Heading, Text } from "../../Type";
import { useModalContext } from "../../../contexts/ModalProvider";
import Modal from "../../Modal";
import { Secondary, Important, Tertiary } from "../../Button";
import styles from "./index.module.scss";
import { gqlType } from "@hifieng/common";
import { analytics } from "../../../analytics";
import { logErrorToLogdna } from "../../../helpers/logdna";
import { ApolloError } from "apollo-boost";

const ERROR_CODES = {
  deletingEvent: "DELETING_EVENT",
  deletingEventAnalytics: "DELETING_EVENT_ANALYTICS"
};

const DELETE_EVENT = gql`
  mutation($id: String!) {
    deleteEvent(id: $id)
  }
`;

type PropsType = {
  event?: gqlType.EventNotification;
  returnUrl: string;
};

const DeleteModal = ({ event, returnUrl }: PropsType) => {
  const [deleteReport] = useMutation(DELETE_EVENT, {
    variables: {
      id: event && event.referenceId
    },
    refetchQueries: ["liveEvents", "historyEvents", "liveStatus"],
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        ERROR_CODES.deletingEvent,
        "Apollo error while deleting event.",
        err
      );
      throw err;
    }
  });

  const { setOpenModal, openModal } = useModalContext();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  if (!event) {
    return null;
  }

  return (
    <Modal
      className={cx(styles.DeleteModal, {
        [styles.DeleteModalSuccess]: success,
        [styles.DeleteModalError]: error
      })}
      isOpen={openModal === "delete-event"}
    >
      <div>
        <Heading size="h6" className={styles.ModalTitle}>
          Delete Event
        </Heading>
        <Text className={styles.Confirm}>
          Are you sure you want to delete this event?
        </Text>
        {error ? (
          <Heading className={styles.AlertText} size="h7">
            Event not deleted. Try again.
          </Heading>
        ) : null}
        {success ? (
          <Heading className={styles.AlertText} size="h7">
            Event deleted
          </Heading>
        ) : null}
        <div className={styles.Buttons}>
          {success ? (
            <NavLink
              to={returnUrl}
              onClick={() => {
                setError(false);
                setOpenModal("");
              }}
            >
              <Tertiary
                buttonSize="small"
                className={styles.Button}
                component="span"
              >
                Close
              </Tertiary>
            </NavLink>
          ) : (
            <Secondary
              buttonSize="small"
              className={styles.Button}
              onClick={() => {
                setError(false);
                setOpenModal("");
              }}
            >
              Cancel
            </Secondary>
          )}
          {!success ? (
            <Important
              buttonSize="small"
              className={styles.Button}
              onClick={() => {
                analytics.eventDeleted(event);
                deleteReport()
                  .then(() => {
                    setSuccess(true);
                    setError(false);
                  })
                  .catch(e => {
                    setError(e);
                  });
              }}
            >
              Delete
            </Important>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
