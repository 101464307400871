import React from "react";
import cx from "classnames";
import { Text } from "../Type";

import styles from "./index.module.scss";

type PropsType = {
  activeAlert?: boolean;
  small?: boolean;
  count: number;
  className?: string;
};

const threeSeconds = 3000;

const EventCount = ({ count, className, small, activeAlert }: PropsType) => {
  const [bounce, setBounce] = React.useState(false);
  React.useEffect(() => {
    if (activeAlert) {
      setBounce(true);
    } else {
      setTimeout(() => setBounce(false), threeSeconds);
    }
  }, [activeAlert]);

  return (
    <Text
      size={small ? "small" : "regular"}
      className={cx(styles.LinkIconCount, className, {
        [styles.Bounce]: bounce
      })}
    >
      {count}
    </Text>
  );
};

export default EventCount;
