import React from "react";
import { useRouteMatch } from "react-router-dom";

import TrainDetailsSection from "../TrainDetailsSection";
import ScreenLayout from "../ScreenLayout";
import MobilePageTitle from "../MobilePageTitle";
import { ACTIVE_URL, COMPLETED_URL } from "../TrainTrackingPage";

import { useTrainDetails } from "./useTrainDetails";

import styles from "./index.module.scss";
import LoadingWrapper from "../LoadingSpinnerWrapper";

const TrainDetailsPage = () => {
  const match = useRouteMatch<{ id: string }>();
  const { id } = match.params;
  const { trainData, status } = useTrainDetails(id, true);
  const completed = match.url.includes("history");

  const runDetails = trainData ? trainData.trainRun : undefined;

  if (!runDetails) return <LoadingWrapper loading />;

  const breadCrumbs = {
    text: completed ? "Completed Runs" : "Active Runs",
    url: completed ? COMPLETED_URL : ACTIVE_URL
  };
  const pageTitle = `${runDetails.railId} - Run ${runDetails.runId}`;

  return (
    <ScreenLayout title={pageTitle} breadCrumbs={breadCrumbs}>
      <div className={styles.HeaderWrapper}>
        <MobilePageTitle page={pageTitle} breadCrumbs={breadCrumbs} />
      </div>
      <TrainDetailsSection
        trainRun={runDetails}
        loading={status === "loading"}
        completed={completed}
      />
    </ScreenLayout>
  );
};

export default TrainDetailsPage;
