import React from "react";
import { gqlType } from "@hifieng/common";

import OrganizationCard from "./OrganizationCard";
import ScreenLayout from "../ScreenLayout";
import MobilePageTitle from "../MobilePageTitle";
import { Heading, Text } from "../Type";
import { Secondary } from "../Button";
import Avatar from "../Avatar";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import { useAuth } from "../../contexts/AuthProvider";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import ErrorScreen from "../ErrorScreen";
import { POLL_INTERVAL_MS } from "../../helpers/constants";

import styles from "./index.module.scss";
import { logErrorToLogdna } from "../../helpers/logdna";
import { ApolloError } from "apollo-boost";
import LoadingWrapper from "../LoadingSpinnerWrapper";

const ERROR_CODES = {
  updatingOrganizationsPage: "UPDATING_ORGANIZATIONS_PAGE"
};

export const GET_HEARTBEAT_DATA = gql`
  query {
    organizations {
      id
      name
      imageUrl
      heartbeats {
        pipelineId
        segmentId
        timestamp
        isConnected
      }
      eventsCount
      utcOffset
      timezoneAbbr
      pipelines {
        id
        interfaceOptions {
          hideHeartbeat
        }
        segments {
          id
        }
      }
    }
  }
`;

export type HeartbeatResponseType = {
  id: string;
  name: string;
  imageUrl: string;
  heartbeats: Array<gqlType.HeartbeatNotification>;
  eventsCount: number;
  pipelines: Array<gqlType.Pipeline>;
};

const OrganizationsPage = () => {
  const { loading, error, data } = useQuery(GET_HEARTBEAT_DATA, {
    pollInterval: POLL_INTERVAL_MS,
    onError: (error: ApolloError) => {
      logErrorToLogdna(
        ERROR_CODES.updatingOrganizationsPage,
        "Apollo error while updating organizations page.",
        error
      );
      return error;
    }
  });
  const { activeOrg, setActiveOrg } = useOrganizationContext();
  const { logout, user } = useAuth();

  if (!activeOrg) {
    return null;
  }

  if (error) {
    console.error(error);

    // We deliberately don't throw an error here because doing so would stop the app and show an
    // error screen. Instead, we let the app continue as normal, and it will show a "disconnected"
    // state instead.
  }

  return (
    <ScreenLayout
      title="Organizations"
      subtitle={data ? data.organizations.length : undefined}
    >
      <div className={styles.Content}>
        <MobilePageTitle
          page="Organizations"
          subtitle={data ? data.organizations.length : undefined}
        />
        <div className={styles.OrgList}>
          {loading && <LoadingWrapper loading />}
          {data && data.organizations.length < 1 ? (
            <ErrorScreen
              title="You are authorized to use this application, but are not authorized to access any organizations."
              subtitle="Please contact your administrator to resolve this issue"
            />
          ) : null}
          {data && data.organizations.length > 0
            ? data.organizations.map((org: HeartbeatResponseType) => {
                const isActive = activeOrg.id === org.id;
                return (
                  <OrganizationCard
                    error={!!error}
                    loading={loading}
                    key={org.id}
                    setActiveOrg={setActiveOrg}
                    isActive={isActive}
                    org={org}
                  />
                );
              })
            : null}
        </div>
        <div className={styles.CompanyLogout}>
          <div className={styles.CompanyLogoutContent}>
            <div className={styles.Avatar}>
              <Avatar src={activeOrg.imageUrl} alt={activeOrg.name} />
            </div>
            <div className={styles.CompanyInfo}>
              <Heading size="h6">{activeOrg.name}</Heading>
              <Text dark component="span">
                {user && user.name ? user.name : ""}
              </Text>
            </div>
            <Secondary className={styles.Logout} onClick={logout}>
              Logout
            </Secondary>
          </div>
        </div>
      </div>
    </ScreenLayout>
  );
};

export default OrganizationsPage;
