import React from "react";
import EventSummary from "./EventSummary";
import { gqlType } from "@hifieng/common";

const EventsList = ({
  events,
  selectedEvent,
  setSelectedEvent
}: {
  events?: Array<gqlType.EventNotification>;
  selectedEvent?: string;
  setSelectedEvent: (arg0: string) => void;
}) => {
  return (
    <>
      {events &&
        events.map((event: gqlType.EventNotification) => (
          <EventSummary
            key={event.referenceId}
            event={event}
            selected={selectedEvent === event.referenceId}
            onClick={() => setSelectedEvent(event.referenceId)}
          />
        ))}
    </>
  );
};

export default EventsList;
