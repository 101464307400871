import React, { useRef } from "react";

import CommentBox from "./EventCommentBox";
import EventInfo from "./EventInfo";
import Header from "./Header";
import EventSchematicMap from "./EventSchematicMap";
import EventDetailMap from "./EventDetailMap";
import EventSignatures from "./EventSignatures";
import DetailsSection from "../DetailsSection";
import DeleteModal from "./DeleteModal";

import { SCREENSHOT_REMOVE_SECTION_CLASS } from "../ShareDialog";

import styles from "./index.module.scss";
import { useModalContext } from "../../contexts/ModalProvider";
import { gqlType } from "@hifieng/common";

type PropsType = {
  returnUrl: string;
};

const EventDetails = ({ returnUrl }: PropsType) => {
  const { setOpenModal } = useModalContext();
  const [eventToDelete, setEventToDelete] = React.useState<
    gqlType.EventNotification
  >();
  const eventDetailsRef = useRef<HTMLDivElement>(null);

  const openDeleteModal = (event: gqlType.EventNotification) => {
    setEventToDelete(event);
    setOpenModal("delete-event");
  };

  return (
    <>
      <div ref={eventDetailsRef} id="event-details" className={styles.Content}>
        <div className={styles.Fixed}>
          <Header
            returnUrl={returnUrl}
            openDeleteModal={openDeleteModal}
            eventDetailsRef={eventDetailsRef}
          />
        </div>
        <div className={styles.Scroll}>
          <DetailsSection>
            <EventInfo />
            <CommentBox />
          </DetailsSection>
          <DetailsSection
            title="Schematic Map"
            className={SCREENSHOT_REMOVE_SECTION_CLASS}
          >
            <EventSchematicMap />
          </DetailsSection>
          <EventSignatures />
          <DetailsSection title="Event Map" last>
            <EventDetailMap />
          </DetailsSection>
        </div>
      </div>
      <DeleteModal event={eventToDelete} returnUrl={returnUrl} />
    </>
  );
};

export default EventDetails;
