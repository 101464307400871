import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import TabbedContent, { IActivatedCallBack } from "../TabbedContent";
import ScreenLayout from "../ScreenLayout";
import ActiveRuns from "./ActiveRuns";
import CompletedRuns from "./CompletedRuns";
import { useAuth } from "../../contexts/AuthProvider";

import MobilePageTitle from "../MobilePageTitle";
import PigHeaderProvider, { usePigHeaderContext } from "./PigHeaderProvider";
import PigFiltersProvider from "./PigFiltersProvider";
import LoadingWrapper from "../LoadingSpinnerWrapper";

export const ACTIVE_URL = "/pig-tracking/active";
export const COMPLETED_URL = "/pig-tracking/history";

const PigTracking = () => {
  const { permissions } = useAuth();
  const { pigCount, loading } = usePigHeaderContext();

  const [currentTab, setCurrentTab] = useState("active");
  const [subTitle, setSubTitle] = useState(
    `${pigCount.active} ${currentTab} runs`
  );

  useEffect(() => {
    const count =
      currentTab === "active" ? pigCount.active : pigCount.completed;
    setSubTitle(`${count} ${currentTab} runs`);
  }, [pigCount, currentTab]);

  const onActivated = ({ title }: IActivatedCallBack) => {
    setCurrentTab(title);
  };

  const activeTab = {
    url: ACTIVE_URL,
    title: `Active Runs (${pigCount.active})`,
    content: (
      <ActiveRuns
        hideEmptyCta={pigCount.active <= 0 && pigCount.completed <= 0}
        emptyUrl={COMPLETED_URL}
        onActivated={onActivated}
      />
    )
  };

  const completedTab = {
    url: COMPLETED_URL,
    title: `Completed Runs (${pigCount.completed})`,
    content: (
      <PigFiltersProvider>
        <CompletedRuns
          loading={loading || false}
          empty={pigCount.completed === 0}
          onActivated={onActivated}
        />
      </PigFiltersProvider>
    )
  };
  const tabs = [activeTab, completedTab];

  if (loading) {
    return <LoadingWrapper loading />;
  } else if (permissions.user["pig-tracking"]) {
    return (
      <ScreenLayout title="Pig Tracking" subtitle={subTitle}>
        <MobilePageTitle page="Pig Tracking" subtitle={subTitle} />
        <TabbedContent tabs={tabs} title="Pig Tracking Navigation" />
      </ScreenLayout>
    );
  } else {
    return <Redirect to="/" />;
  }
};

const PigTrackingPage = () => (
  <PigHeaderProvider>
    <PigTracking />
  </PigHeaderProvider>
);

export default PigTrackingPage;
