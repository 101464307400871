import React from "react";

import { Primary } from "../Button";
import { Heading, Text } from "../Type";

import { analytics } from "../../analytics";

import styles from "./index.module.scss";

const ErrorScreen = ({
  title,
  subtitle
}: {
  title: string;
  subtitle: string;
}) => {
  const handleReload = () => {
    location.reload();
  };

  analytics.appError(`${title}. ${subtitle}`);

  return (
    <div className={styles.Screen}>
      <div className={styles.Content}>
        <Heading className={styles.Title} size="h3">
          {title}
        </Heading>
        {subtitle && (
          <Text className={styles.Subtitle} size="large" dark>
            {subtitle}
          </Text>
        )}

        <Primary className={styles.Button} onClick={handleReload}>
          Reload
        </Primary>
      </div>
    </div>
  );
};

export default ErrorScreen;
