import React from "react";

const Loading = () => {
  return (
    <svg width="21" height="5" viewBox="0 0 21 5">
      <g
        transform="translate(.11133 .3125)"
        fill="currentColor"
        fillRule="evenodd"
      >
        <circle cx="10.1123" cy="2.19775" r="2" />
        <circle cx="18.1123" cy="2.19775" r="2" />
        <circle cx="2.1123" cy="2.19775" r="2" />
      </g>
    </svg>
  );
};

export default Loading;
