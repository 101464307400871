import React from "react";
import PipelineIcon from "../../Icons/Pipeline";
import { Heading } from "../../Type";
import { TextLink } from "../../Button";
import { NavLink } from "react-router-dom";

import styles from "./index.module.scss";

type Props = {
  pipelineName: string;
  pipelineColor: string;
  changeURL?: string;
};

const SinglePipeline = ({ pipelineName, pipelineColor, changeURL }: Props) => {
  return (
    <div className={styles.singlePipeline}>
      <PipelineIcon color={pipelineColor} />
      <Heading size="h7">{pipelineName}</Heading>
      {changeURL && (
        <NavLink to={changeURL} className={styles.changeButton}>
          <TextLink component="div">Change</TextLink>
        </NavLink>
      )}
    </div>
  );
};

export default SinglePipeline;
