import React from "react";
import useSound from "use-sound";
import { useLiveStatusContext } from "../contexts/LiveStatusProvider";
import { analytics } from "../analytics";

const useAlertSound = () => {
  const { activeAlert, dismissAlert } = useLiveStatusContext();

  const [playNewAlertTone] = useSound("/audio/notification.mp3");

  const mute = localStorage.getItem("hifi-mute") === "true";
  React.useEffect(() => {
    if (!mute && activeAlert) {
      // empty object to make TS happy despite PlayOptions being optional
      // https://github.com/joshwcomeau/use-sound/blob/master/src/types.ts
      playNewAlertTone({});
      analytics.newEventNotification();
      dismissAlert();
    }
  }, [activeAlert, dismissAlert, mute, playNewAlertTone]);
};

export default useAlertSound;
