import React, { useState, useEffect } from "react";
import { Text } from "../Type";
import styles from "./index.module.scss";

const DEFAULT_SIZE = 48;
const DEFAULT_TIMEOUT = 5;

export type LoadingSpinnerProps = {
  size?: number;
  progress?: number; // loading %, must be incremented by parent (should not use with autoIncrement)
  autoIncrement?: boolean; // whether to display a mock loading %, incremented locally
  timeToLoad?: number; // approximate time in seconds for page to load, affects autoIncrement speed
};

export const LoadingSpinner = ({
  size = DEFAULT_SIZE,
  progress,
  autoIncrement,
  timeToLoad = DEFAULT_TIMEOUT
}: LoadingSpinnerProps) => {
  const [mockProgress, setMockProgress] = useState(0);

  const FINAL_PERCENTAGE = 99;
  const INCREMENT = 1;
  const TIMING_CONST = 6;
  useEffect(() => {
    if (autoIncrement) {
      const updateProgress = () => setMockProgress(mockProgress + INCREMENT);
      if (mockProgress < FINAL_PERCENTAGE) {
        setTimeout(updateProgress, timeToLoad * (mockProgress / TIMING_CONST)); //this timing function simulates logarithmic progress
      }
    }
  }, [mockProgress, autoIncrement, timeToLoad]);

  const FONT_SIZE_RATIO = 4;

  return (
    <div
      className={styles.Wrapper}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <div
        className={styles.Spinner}
        aria-label="Loading..."
        role="alert"
        aria-busy="true"
      />
      <Text
        size="small"
        className={styles.Percentage}
        style={{ fontSize: `${size / FONT_SIZE_RATIO}px` }}
      >
        {progress && `${progress}%`}
        {autoIncrement && `${mockProgress}%`}
      </Text>
    </div>
  );
};
