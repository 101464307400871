import React from "react";
import Select from "../../Dropdown/Select";
import { OptionType } from "../../../types/GeneralTypes";
import PipelineIcon from "../../Icons/Pipeline";

type IGlobalTestSelectProps = {
  onFlagChange: (selectedItem: OptionType) => void;
};

const GlobalTestSelect = ({ onFlagChange }: IGlobalTestSelectProps) => {
  return (
    <>
      <Select
        label="Ultimate Test"
        items={[
          {
            value: "true",
            label: "True",
            icon: <PipelineIcon color="black" />
          },
          {
            value: "false",
            label: "False",
            icon: <PipelineIcon color="black" />
          }
        ]}
        initial={{
          value: "false",
          label: "False",
          icon: <PipelineIcon color="yellow" />
        }}
        onChange={onFlagChange}
      />
    </>
  );
};

export default GlobalTestSelect;
