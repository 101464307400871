import { IPermissions } from "../types/AuthTypes";

export const getAnalyticsPermissions = (permissions?: IPermissions) => {
  const analyticsPermissions: { org: Array<string>; user: Array<string> } = {
    org: [],
    user: []
  };
  if (permissions) {
    Object.entries(permissions.org).forEach(([key, value]) => {
      if (value) {
        analyticsPermissions.org.push(key);
      }
    });
    Object.entries(permissions.user).forEach(([key, value]) => {
      if (value) {
        analyticsPermissions.user.push(key);
      }
    });
  }

  return analyticsPermissions;
};
