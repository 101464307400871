import React, { useEffect, useRef, useState } from "react";
import htmlCanvas from "html2canvas";
import cx from "classnames";
import { analytics } from "../../analytics";
import { Text } from "../Type";
import { TextInput } from "../Inputs";

import { color } from "../../styles/colors";

import styles from "./index.module.scss";
import { Primary } from "../Button";

// In order for the ShareDialog to be positioned correctly,
// its parent element must have `position: relative;` applied.

type PropsType = {
  eventDetailsRef?: React.RefObject<HTMLDivElement>;
  referenceId?: string;
  eventId: string;
  onClose: () => void;
  label: string;
  disableScreenshot?: boolean;
};

export const SCREENSHOT_IGNORE_CLASS = "screenshot-ignore";
export const SCREENSHOT_REMOVE_SECTION_CLASS = "screenshot-remove-section";

const adjustForRemovedSections = (height: number) => {
  let adjustedHeight = height;
  const sectionElementList = document.querySelectorAll(
    `.${SCREENSHOT_REMOVE_SECTION_CLASS}`
  );

  sectionElementList.forEach(el => {
    adjustedHeight = adjustedHeight - el.clientHeight;
  });

  return adjustedHeight;
};

const ShareDialog = ({
  onClose,
  eventDetailsRef,
  referenceId,
  eventId,
  label,
  disableScreenshot
}: PropsType) => {
  const shareRef = useRef<HTMLDivElement>(null);
  const shareUrl = window.location.href;
  const [buttonText, setButtonText] = useState("Copy Link");
  const copyUrlLink = () => {
    navigator.clipboard.writeText(shareUrl);
    setButtonText("Copied!");
    analytics.eventUrlCopy(eventId);
  };

  const downloadScreenshot = () => {
    if (eventDetailsRef && !disableScreenshot && referenceId) {
      const eventDetailsEl = eventDetailsRef.current;

      if (eventDetailsEl) {
        const width = Math.max(
          eventDetailsEl.scrollWidth,
          eventDetailsEl.clientWidth
        );
        const height = Math.max(
          eventDetailsEl.scrollHeight,
          eventDetailsEl.clientHeight
        );

        htmlCanvas(eventDetailsEl, {
          height: adjustForRemovedSections(height), //remove the height of the schematicMapSectionEl as this section is being removed.
          width,
          backgroundColor: color.darkMineShaft,
          imageTimeout: 5000,
          useCORS: true, // Enables cors images
          ignoreElements: (el: Element) => {
            if (
              el &&
              el.className &&
              typeof el.className === "string" &&
              (el.className.includes(SCREENSHOT_IGNORE_CLASS) ||
                el.className.includes(SCREENSHOT_REMOVE_SECTION_CLASS))
            ) {
              return true;
            }

            return false;
          }
        })
          .then(canvas => {
            const imageUrl = canvas.toDataURL("image/jpeg", 1.0);
            const link = document.createElement("a");
            link.download = `hifi-event-${referenceId}.png`;
            link.href = imageUrl;
            link.click();
            link.remove();
            analytics.eventScreenshotDownload(eventId);
          })
          .catch((error: Error) => {
            console.error(error);
            onClose();
          });
      }
    }
  };

  useEffect(() => {
    const clickListener = (e: MouseEvent) => {
      const eventTarget = e.target;
      if (shareRef && shareRef.current) {
        const clickInShareDialog =
          eventTarget &&
          shareRef &&
          shareRef.current.contains(eventTarget as Node);

        if (!clickInShareDialog) {
          onClose();
        }
      }
    };
    window.addEventListener("click", clickListener);

    return () => {
      window.removeEventListener("click", clickListener);
    };
  }, [shareRef, onClose]);

  return (
    <div
      ref={shareRef}
      id="share-dialog"
      className={cx(styles.Share, "screenshot-ignore")}
    >
      <div className={styles.Row}>
        <Text component="span" size="small">
          {label}
        </Text>
      </div>
      <div className={styles.Row}>
        <div className={styles.InputWrapper}>
          <TextInput
            value={shareUrl}
            label="URL"
            hideLabel
            onFocus={e => e.target.select()}
          />{" "}
        </div>
        <Primary onClick={copyUrlLink}>{buttonText}</Primary>
      </div>
      {!disableScreenshot && (
        <div className={styles.Row}>
          <Text component="span" size="small">
            or you can also{" "}
            <button
              type="button"
              className={styles.ButtonScreenshot}
              onClick={downloadScreenshot}
            >
              <Text component="span" size="small">
                download a screenshot
              </Text>
            </button>
          </Text>
        </div>
      )}
    </div>
  );
};

export default ShareDialog;
