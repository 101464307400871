import { gqlType } from "@hifieng/common";
import { longDate } from "../../../helpers/formatDate";
import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";

const formatCompletedRuns = (
  runs: Array<gqlType.TrainRun>,
  timeOptions: {
    timezoneAbbr: string;
    utcOffset: string;
  }
) => {
  const start = (run: gqlType.TrainRun) =>
    `${
      timeOptions
        ? longDate(run.startTime * MILLISECONDS_IN_SECONDS, timeOptions)
        : ""
    }\n${run.startLocation.latitude}, ${run.startLocation.longitude} `;

  const end = (run: gqlType.TrainRun) =>
    run.endTime && run.endLocation
      ? `${
          timeOptions
            ? longDate(run.endTime * MILLISECONDS_IN_SECONDS, timeOptions)
            : timeOptions
        }\n${run.endLocation.latitude}, ${run.endLocation.longitude} `
      : "--";

  return runs.map(run => {
    return {
      id: run.id,
      data: [
        `${run.pipeline.name} - Run ${run.runId}`,
        run.type ? run.type.toLowerCase() : "--",
        start(run),
        end(run),
        `${run.numberOfUpdates}`,
        `${run.numberOfCriticalEvents}`
      ],
      numAuditLogs: run.auditLogs.length,
      auditLogs: run.auditLogs
        .map(log => {
          return log.comment.message;
        })
        .join(", ")
    };
  });
};

export default formatCompletedRuns;
