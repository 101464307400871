import React from "react";
import { gqlType } from "@hifieng/common";
import ScreenLayout from "../ScreenLayout";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import Loading from "../Loading";
import FilterContentsSection from "../FilterContentsSection";
import AnalysisFilters from "../AnalysisFilters";
import MobilePageTitle from "../MobilePageTitle";
import AnalysisDetails from "./AnalysisDetails";
import { Redirect, useRouteMatch } from "react-router-dom";
import { useQueryParams } from "../../hooks/useQueryParams";
import {
  getAnalysisRangeUnits,
  createAnalysisDetailsFilters,
  getPipelineChKpMap,
  getUnitDefinition
} from "../../helpers/analysisFunctions";
import { clamp } from "../../helpers/math";
import AppError from "../../errors/AppError";

const analysisPageURI = "/analysis";

const AnalysisDetailsPage = () => {
  const { activeOrg } = useOrganizationContext();
  const pipelines = activeOrg ? activeOrg.pipelines : [];

  const match = useRouteMatch<{ id: string }>();
  const { id } = match.params;

  const { queryParams } = useQueryParams();
  const { unit, start, end } = queryParams;

  // If we don't have all the required query vars then we'll redirect to the
  // main analysis page.
  // Note: These values will all be strings at this point so `start=0` won't
  // trigger this condition but `start=` will. That is the intended/preferred behavior.
  if (!unit || !start || !end) {
    return <Redirect to={analysisPageURI} />;
  }

  if (pipelines && pipelines.length) {
    const pipeline = pipelines.find(
      (pipeline: gqlType.Pipeline) => pipeline.id === id
    );

    if (pipeline) {
      const fiber = getPipelineChKpMap(
        pipeline,
        queryParams.fiber || undefined
      );

      const clampUnit = clamp(
        Number(unit),
        0,
        getAnalysisRangeUnits().length - 1
      );
      const unitDef = getUnitDefinition(clampUnit);

      return (
        <ScreenLayout title="Analysis">
          <FilterContentsSection
            focusContentOnMobile
            headerComponent={
              <MobilePageTitle
                page={pipeline.name}
                subtitle={`${unitDef.shortTitle} ${start} - ${end}`}
                breadCrumbs={{
                  text: "Back to Search",
                  url: analysisPageURI
                }}
              />
            }
            filterComponent={
              <AnalysisFilters
                pipelines={pipelines}
                pipeline={pipeline}
                fiber={fiber}
                changePipelineURI={analysisPageURI}
              />
            }
            contentComponent={
              activeOrg && (
                <AnalysisDetails
                  organization={activeOrg}
                  filters={createAnalysisDetailsFilters({
                    pipelineId: `${
                      // cspell:disable-next-line
                      id === "enbridge_line_4" ? "enbridge_line_3" : id
                    }`,
                    unit: clampUnit,
                    start: Number(start),
                    end: Number(end),
                    fiber
                  })}
                />
              )
            }
          />
        </ScreenLayout>
      );
    }

    throw new AppError(`pipeline "${id}" does not exist in this organization.`);
  }

  return <Loading />;
};

export default AnalysisDetailsPage;
