import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { analytics } from "../analytics";
import { logErrorToLogdna } from "../helpers/logdna";
import { ApolloError } from "apollo-boost";

interface ILogs {
  saveLog: (log: string, referenceId: string) => void;
  saveLogError: boolean;
  saveLogLoading: boolean;
}

const ERROR_CODES = {
  addComment: "ADDING_COMMENT"
};

const ADD_USER_AUDIT_LOG = gql`
  mutation addAuditLogComment(
    $referenceId: String!
    $comment: CommentInput!
    $eventType: String
  ) {
    addAuditLogComment(
      referenceId: $referenceId
      comment: $comment
      eventType: $eventType
    )
  }
`;

export const useAuditLogs = (eventType?: string): ILogs => {
  const type = eventType || "events";
  const [
    addAuditLog,
    { error: saveLogError, loading: saveLogLoading }
  ] = useMutation(ADD_USER_AUDIT_LOG, {
    refetchQueries: type === "events" ? ["eventDetails"] : undefined,
    awaitRefetchQueries: true,
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        ERROR_CODES.addComment,
        "Apollo error while adding comment.",
        err
      );
      return err;
    }
  });

  const saveLog = (message: string, referenceId: string) => {
    addAuditLog({
      variables: { referenceId, comment: { message }, eventType: type }
    });

    analytics.auditLog({
      isSuccess: true,
      referenceId
    });
  };

  return {
    saveLog,
    saveLogLoading,
    saveLogError: Boolean(saveLogError)
  };
};
