import { useEffect } from "react";
import gql from "graphql-tag";
import { gqlType } from "@hifieng/common";
import { useQuery } from "@apollo/react-hooks";
import { ApolloError } from "apollo-boost";

import { useOrganizationContext } from "../../../contexts/OrganizationProvider";
import { logErrorToLogdna } from "../../../helpers/logdna";
import {
  pigRunFragment,
  pigUpdatesFragment,
  pigsQueryComposer,
  ACTIVE_PIGS_SUBSCRIPTION
} from "../../../helpers/pigQueryComposer";

const fragment = gql`
  fragment PigsFragment on PaginatedPigsAndTotal {
    pigs {
      ...PigRunFragment
      latest {
        ...PigUpdateFragment
      }
    }
    active
    completed
  }
  ${pigRunFragment}
  ${pigUpdatesFragment}
`;

const ACTIVE_PIGS_QUERY = pigsQueryComposer(fragment);

export const useActivePigs = () => {
  const { activeOrg } = useOrganizationContext();

  const { subscribeToMore, loading, data } = useQuery(ACTIVE_PIGS_QUERY, {
    variables: {
      organizationId: activeOrg ? activeOrg.id : undefined,
      filters: { completed: false }
    },
    fetchPolicy: "network-only",
    skip: !activeOrg,
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        "GETTING_ACTIVE_PIGS",
        "Apollo error while getting active pigs.",
        err
      );
      throw err;
    }
  });

  useEffect(() => {
    if (activeOrg) {
      subscribeToMore({
        document: ACTIVE_PIGS_SUBSCRIPTION,
        variables: {
          organizationId: activeOrg.id
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          } else {
            const newPig = subscriptionData.data.trackPig;
            const prevRuns = prev.pigs.pigs;

            const existingPig = prevRuns.find(
              (pig: gqlType.PigRun) => pig.id === newPig.id
            );
            const isNewNotificationCompleted =
              newPig.latest.status === gqlType.PigStatus.Completed;

            if (existingPig) {
              const updatedRuns = prevRuns.map(
                (pig: gqlType.PigRun, i: number) => {
                  if (i === prevRuns.indexOf(existingPig)) {
                    return newPig;
                  }
                  return pig;
                }
              );
              return Object.assign({}, prev, {
                pigs: {
                  ...prev.pigs,
                  pigs: updatedRuns
                }
              });
            }
            const prevActiveRuns: number = prev.pigs.active;
            const newActiveRuns: number = isNewNotificationCompleted
              ? prevActiveRuns
              : prevActiveRuns + 1;

            return Object.assign({}, prev, {
              pigs: {
                ...prev.pigs,
                pigs: [newPig, ...prevRuns].sort(
                  (a, b) => b.latest.timestamp - a.latest.timestamp
                ),
                active: newActiveRuns
              }
            });
          }
        }
      });
    }

    //suppressing hook dependency because `subscribeToMore` changes on every render
    // and the documentation suggest to only call it on component mount
    // https://www.apollographql.com/docs/react/data/subscriptions/#subscribing-to-updates-for-a-query
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrg]);

  return {
    loading,
    pigRuns: !loading && data ? data.pigs : undefined
  };
};
