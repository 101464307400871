import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Check = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 18 12">
      <path
        fill="currentColor"
        d="M7 9.9L1.7 4.6l-1.1 1 5.3 5.3L7 12 17.6 1.4 16.5.3z"
      />
    </svg>
  );
};

export default Check;
