import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Exclamation = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 4 18">
      <path
        fill="currentColor"
        d="M1 .5h2v11H1V.5zm1 14c-.82843 0-1.5.67157-1.5 1.5s.67157 1.5 1.5 1.5 1.5-.67157 1.5-1.5-.67157-1.5-1.5-1.5z"
      />
    </svg>
  );
};

export default Exclamation;
