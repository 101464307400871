import React, { ReactNode } from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  border?: boolean;
  children: ReactNode;
};
const SignatureCard = ({ border, children }: PropsType) => {
  return (
    <div
      className={cx(styles.SignatureCard, {
        [styles.Border]: border
      })}
    >
      {children}
    </div>
  );
};

export default SignatureCard;
