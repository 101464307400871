import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Frequency = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M10.3236.875l-.12584.00701c-.41362.04776-.76354.3355-.90323.73814L6.106 10.834 4.83042 7.5818c-.1592-.41742-.55457-.70437-1.00562-.7068H.875v2.25l2.207-.001 2.05095 5.29186c.16011.41976.5555.7067 1.00656.70912l.12613-.007c.4145-.04775.76442-.33548.90412-.73813L10.279 5.51001l.8605 2.83171c.14564.46446.56098.78462 1.04082.78328H15.125v-2.25l-2.153-.001-1.62196-5.21954c-.12896-.4114-.47437-.71156-.89015-.7694l-.13628-.01005z"
      />
    </svg>
  );
};

export default Frequency;
