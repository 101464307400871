import React from "react";
import { gqlType } from "@hifieng/common";
import RangeSlider from "../../RangeSlider";
import { Primary } from "../../Button";
import { NumberInput } from "../../Inputs";
import { snap, clamp } from "../../../helpers/math";
import RangeUnitToggle from "../RangeUnitToggle";
import { NavLink } from "react-router-dom";
import { SearchItem } from "../../../types/AnalysisTypes";
import { getAnalysisRangeUnits } from "../../../helpers/analysisFunctions";
import { Text } from "../../Type";
import { useAuth } from "../../../contexts/AuthProvider";

import styles from "./index.module.scss";
import { setSearchHistory, addSearchHistory } from "../RecentAreas";
import { kpMpRatio } from "../../AnalysisCharts/BaseChart";

type Props = {
  pipeline: gqlType.Pipeline;
  fiber: gqlType.ChannelKPostMap;
  precision: number;
  rangeUnit: number;
  minRange: number;
  maxRange: number;
  rangeStep: number;
  rangeStart: number;
  rangeEnd: number;
  startValue: number;
  endValue: number;
  onChange: (start: number, end: number) => void;
  onUnitChange: (value: number) => void;
  onSearch: (searches: Array<SearchItem>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFakeLeakInitiate?: (fakeLeak: any) => void;
  singleChannelFlag?: boolean;
};

/*
 * Constrains the start value of a dual range slider
 *
 *   2    1              4    3
 *   |----V--------------|----|
 */
export const constrainRangeStart = (
  value: number, // 1: The value to constrain
  start: number, // 2: The start of the constraints scale
  end: number, // 3: The end of the constraints scale
  min: number, // 4: Min amount of padding from the end of the scale
  step: number, // Step increments to snap the final value to
  precision: number // Number of decimals to use in the output
) => {
  return Number(snap(clamp(value, start, end - min), step).toFixed(precision));
};

/*
 * Constrains the end value of a dual range slider using the starting value as
 * the anchor point to limit the maximum end value.
 *
 *        2  5   1   6        3
 *   |----S==|===V===|--------|
 */
export const constrainRangeEnd = (
  value: number, // 1: The value to constrain
  start: number, // 2: The start value to constraint to
  end: number, // 3: The end of the constraints scale
  min: number, // 4: Min amount of padding from the start value
  max: number, // 5: The max distance from the start value
  step: number, // Step increments to snap the final value to
  precision: number // Number of decimals to use in the output
) => {
  return Number(
    snap(clamp(value, start + min, Math.min(start + max, end)), step).toFixed(
      precision
    )
  );
};

export const buildSearchURI = (
  pipelineId: string,
  start: number,
  end: number,
  unit: string,
  fiberId: string
) =>
  `/analysis/${pipelineId}?${
    fiberId && fiberId !== pipelineId ? `fiber=${fiberId}&` : ""
  }start=${start}&end=${end}&unit=${unit}`;

const RangeSelector = ({
  pipeline,
  fiber,
  precision,
  rangeUnit,
  minRange,
  maxRange,
  rangeStep,
  rangeStart,
  rangeEnd,
  startValue,
  endValue,
  onChange,
  onUnitChange,
  onSearch,
  onFakeLeakInitiate,
  singleChannelFlag
}: Props) => {
  const constrainStart = (value: number) => {
    return constrainRangeStart(
      value,
      rangeStart,
      rangeEnd,
      minRange,
      rangeStep,
      precision
    );
  };

  const constrainEnd = (value: number, start: number) => {
    return constrainRangeEnd(
      value,
      start,
      rangeEnd,
      minRange,
      maxRange,
      rangeStep,
      precision
    );
  };

  const handleStartSliderChange = (values: {
    startValue: number;
    endValue: number;
  }) => {
    const start = Number(values.startValue.toFixed(precision));
    const range = Number((endValue - startValue).toFixed(precision));
    onChange(start, start + range);
  };

  const handleRangeSliderChange = (values: {
    startValue: number;
    endValue: number;
  }) => {
    const range = Number(values.endValue.toFixed(precision));
    onChange(startValue, Number((startValue + range).toFixed(precision)));
  };

  const onStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value), endValue);
  };

  const onEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(startValue, Number(event.target.value));
  };

  const handleStartInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const start = constrainStart(Number(event.target.value));
    const end = constrainEnd(endValue, start);
    onChange(start, end);
  };

  const handleEndInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(startValue, constrainEnd(Number(event.target.value), startValue));
  };

  const maxRangeDisplayValue = Number(
    Math.min(Number(startValue) + maxRange, rangeEnd).toFixed(precision)
  );

  const { permissions } = useAuth();
  const imperialFlag =
    (permissions.user.imperial ? true : false) ||
    fiber.id === "enbridge_line_3" ||
    fiber.id === "enbridge_line_4";
  const analysisRangeUnits = getAnalysisRangeUnits(imperialFlag);

  const unit = rangeUnit; // TODO: fix these range units and make them of enum type
  const currentSearch: SearchItem = {
    rangeUnit: String(unit),
    rangeMin: startValue,
    rangeMax: endValue,
    pipelineName: pipeline.name,
    km: Math.round(endValue - startValue),
    timestamp: Date.now(),
    uri: buildSearchURI(
      pipeline.id,
      imperialFlag ? startValue / kpMpRatio : startValue,
      imperialFlag ? endValue / kpMpRatio : endValue,
      String(unit),
      fiber.id
    )
  };
  const saveSearch = () => {
    const searches = addSearchHistory(currentSearch);
    onSearch(searches);
    setSearchHistory(searches);
  };

  return (
    <>
      <RangeUnitToggle rangeUnit={rangeUnit} onClick={onUnitChange} />
      <Text size="large" className={styles.FilterText}>
        Start {analysisRangeUnits[rangeUnit].shortTitle}
      </Text>
      <RangeSlider
        min={rangeStart}
        max={rangeEnd}
        onChange={handleStartSliderChange}
        start={startValue}
        end={endValue}
        step={rangeStep}
        className={styles.KPRangeSlider}
      />

      {!singleChannelFlag ? (
        <Text size="large" className={styles.FilterText}>
          {analysisRangeUnits[rangeUnit].title}
        </Text>
      ) : null}
      {!singleChannelFlag ? (
        <Text size="small" className={styles.FilterText}>
          {analysisRangeUnits[rangeUnit].description}
        </Text>
      ) : null}
      {!singleChannelFlag ? (
        <RangeSlider
          min={0}
          max={analysisRangeUnits[rangeUnit].maxRange}
          onChange={handleRangeSliderChange}
          start={endValue - startValue}
          step={rangeStep}
          className={styles.KPRangeSlider}
        />
      ) : null}

      <div className={styles.SearchRow}>
        <NumberInput
          label={singleChannelFlag ? "Selection" : "Start"}
          value={Number(startValue.toFixed(precision))}
          min={rangeStart}
          max={rangeEnd - minRange}
          step={rangeStep}
          onChange={onStartChange}
          onBlur={handleStartInputChange}
        />
        <div className={styles.KPTextInputSeparator} />
        {!singleChannelFlag ? (
          <NumberInput
            label="End"
            constraintsLabel={`(Max\u00A0${maxRangeDisplayValue})`}
            value={Number(endValue.toFixed(precision))}
            min={startValue + minRange}
            max={maxRangeDisplayValue}
            step={rangeStep}
            onChange={onEndChange}
            onBlur={handleEndInputChange}
          />
        ) : null}
        <NavLink
          className={styles.searchButton}
          to={singleChannelFlag ? "/fake-leak" : currentSearch.uri}
        >
          <Primary
            component="div"
            onClick={singleChannelFlag ? onFakeLeakInitiate : saveSearch}
          >
            {singleChannelFlag ? "Initiate" : "Start"}
          </Primary>
        </NavLink>
      </div>
    </>
  );
};

export default RangeSelector;
