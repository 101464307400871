import React, { ReactElement } from "react";
import ReactDOMServer from "react-dom/server";

import { IconByType, IconCircleWrapper } from "../Icons";
import getPriorityLevel from "../../helpers/getPriorityLevel";

import { priorityColorMap } from "../../styles/colors";

const HALF = 0.5;
const FOUR_TENTHS = 0.4;

// CAUTION: Stepping into this code is dangerous territory
// Encoding icon and rendering to static markup is error-prone, and could introduce hard-to-debug issues.
//
// This method should only be used to render icons that cannot be rendered normally via React, such
// as if the icons will be rendered onto Google Maps or an SVG.

export const encodeSvg = (reactElement: ReactElement): string => {
  return `data:image/svg+xml,${escape(
    ReactDOMServer.renderToStaticMarkup(reactElement)
  )}`;
};

const getEncodedIconByType = (
  size: number,
  type: string,
  category: "event" | "asset" | "pig" | "train",
  pulse?: boolean
): string => {
  let iconType = type;
  let iconColor = "";
  const showPulse = category === ("pig" || "train") && pulse;
  const innerIconSize = showPulse ? size * FOUR_TENTHS : size * HALF;
  const priority = getPriorityLevel(type);

  switch (category) {
    case "pig":
      iconType = "pig";
      iconColor = priorityColorMap[priority];
      break;
    case "train":
      iconType = "train";
      iconColor = priorityColorMap[priority];
      break;
    case "event":
      iconColor = priorityColorMap[priority];
      break;
    case "asset":
    default:
      iconColor = "#161616";
  }

  return encodeSvg(
    <IconCircleWrapper
      size={size}
      childSize={innerIconSize}
      iconColor={iconColor}
      showPulse={showPulse}
    >
      <IconByType type={iconType} size={innerIconSize} />
    </IconCircleWrapper>
  );
};

export default getEncodedIconByType;
