import React, { useContext } from "react";

import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { OrganizationContext } from "../../../contexts/OrganizationProvider";
import { Heading, Text } from "../../Type";
import { useModalContext } from "../../../contexts/ModalProvider";
import FileDetails from "../FileDetails";
import Modal from "../../Modal";
import { Secondary, Primary } from "../../Button";
import { TextInput } from "../../Inputs";
import Alert from "../../Alert";
import { uuid } from "../../../helpers/reports";
import styles from "./index.module.scss";
import { logErrorToLogdna } from "../../../helpers/logdna";
import { ApolloError } from "apollo-boost";

const ERROR_CODES = {
  updatingReport: "UPDATING_REPORT"
};

const UPLOAD_REPORT = gql`
  mutation uploadReport(
    $name: String!
    $title: String!
    $organizationId: String!
    $contentType: String!
  ) {
    uploadReport(
      name: $name
      title: $title
      organizationId: $organizationId
      contentType: $contentType
    ) {
      url
    }
  }
`;

type PropsType = {
  onSuccess: () => void;
};

const UploadModal = ({ onSuccess }: PropsType) => {
  const [uploadReport] = useMutation(UPLOAD_REPORT, {
    refetchQueries: ["reports"],
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        ERROR_CODES.updatingReport,
        "Apollo error while updating report.",
        err
      );
      throw err;
    }
  });

  const { setOpenModal, openModal } = useModalContext();
  const [fileTitle, setFileTitle] = React.useState("");
  const [uploading, setUploading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [fileToUpload, setFileToUpload] = React.useState<File | null>();
  const [error, setError] = React.useState(false);
  const { activeOrg } = useContext(OrganizationContext);

  if (!activeOrg) {
    return null;
  }

  const onFileSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (fileList) {
      const targetFile = fileList.item(0);
      if (targetFile) {
        setFileToUpload(targetFile);
        setFileTitle(targetFile.name);
      }
    }
  };

  const uploadFileHandler = () => {
    if (!fileToUpload) {
      return;
    }

    const options = {
      method: "PUT",
      body: fileToUpload
    };

    const name = `${uuid()}-${fileToUpload.name.replace(/\s/g, "-")}`;

    const variables = {
      name,
      contentType: fileToUpload.type,
      organizationId: activeOrg.id,
      title: fileTitle
    };

    setUploading(true);

    uploadReport({ variables })
      .then(async result => {
        if (result.data) {
          const response = await fetch(result.data.uploadReport.url, options);

          if (!response.ok) {
            setError(true);
          }

          setSuccess(true);
          onSuccess();
        }
      })
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <Modal
      className={styles.UploadModal}
      isOpen={openModal === "upload-report"}
    >
      <div>
        <Heading size="h6" className={styles.ModalTitle}>
          Upload Report
        </Heading>
        {success && fileToUpload ? (
          <FileDetails
            file={{
              title: fileTitle,
              sizeInByte: fileToUpload.size,
              contentType: fileToUpload.type
            }}
          />
        ) : (
          <div>
            <div className={styles.File}>
              <Text className={styles.FileText}>File</Text>
              <input
                className={styles.FileInput}
                type="file"
                onChange={onFileSelectHandler}
              />
            </div>
            {error ? (
              <Alert message="Failed to upload file. Try again." type="error" />
            ) : null}
            <div className={styles.Title}>
              <TextInput
                label="Title"
                placeholder="Add a title"
                value={fileTitle}
                onChange={setFileTitle}
                disabled={success || !fileTitle}
              />
            </div>
            <Text>
              {" "}
              {uploading ? "Uploading report, please wait..." : " "}{" "}
            </Text>
          </div>
        )}
        <div className={styles.Buttons}>
          <Secondary
            buttonSize="small"
            className={styles.Button}
            onClick={() => {
              setSuccess(false);
              setOpenModal("");
              setFileTitle("");
            }}
            disabled={uploading}
          >
            {success ? "Close" : "Cancel"}
          </Secondary>
          {!success ? (
            <Primary
              buttonSize="small"
              className={styles.Button}
              onClick={uploadFileHandler}
              disabled={uploading || success || !fileTitle}
            >
              Upload
            </Primary>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;
