import React from "react";

import PageTitle from "../PageTitle/";

import styles from "./index.module.scss";

type PropsType = {
  page: string;
  subtitle?: React.ReactNode;
  breadCrumbs?: {
    text: string;
    url: string;
  };
};

const MobilePageTitle = ({ page, subtitle, breadCrumbs }: PropsType) => {
  return (
    <div className={styles.MobilePageTitle}>
      <PageTitle title={page} subtitle={subtitle} breadCrumbs={breadCrumbs} />
    </div>
  );
};

export default MobilePageTitle;
