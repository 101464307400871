import React from "react";
import cx from "classnames";
import styles from "./index.module.scss";
import { Close } from "../Icons";
import { Text } from "../Type";
import { HeartbeatStatusType } from "../../types/HeartbeatTypes";

const PulsingIcon = ({
  status,
  pulse
}: {
  status: HeartbeatStatusType;
  pulse?: boolean;
}) => {
  if (["loading"].includes(status)) {
    return null;
  }
  return (
    <div
      className={cx(styles.StatusContainer, {
        [styles.StatusContainerConnected]:
          status === "loading" || status === "connected",
        [styles.StatusContainerPartial]: status === "partially-connected",
        [styles.StatusContainerDisconnected]: status === "disconnected",
        [styles.StatusContainerError]: status === "error"
      })}
    >
      <div className={cx(styles.StatusIcon)}>
        {(status === "disconnected" || status === "error") && (
          <div className={styles.StatusIconDisconnectedError}>
            <Close size={8} />
          </div>
        )}
      </div>
      {pulse ? (
        <>
          <div className={cx(styles.StatusPulse, styles.StatusPulseInner)} />
          <div className={cx(styles.StatusPulse, styles.StatusPulseOuter)} />
        </>
      ) : null}
    </div>
  );
};

type PropsType = {
  status: HeartbeatStatusType;
  connected?: number;
  disconnected?: number;
  pulse?: boolean;
  dark?: boolean;
};

const HeartbeatStatus = ({
  status,
  connected,
  disconnected,
  pulse = false,
  dark = false
}: PropsType) => {
  let connectedString = "- / -";
  if (connected !== undefined && disconnected !== undefined) {
    connectedString = `${connected} / ${connected + disconnected}`;
  }

  return (
    <div className={styles.Heartbeat}>
      <PulsingIcon pulse={pulse} status={status} />
      <Text dark={dark} component="span">
        {connectedString}
      </Text>
    </div>
  );
};

export default HeartbeatStatus;
