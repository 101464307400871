import { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { gqlType } from "@hifieng/common";

import { ApolloError } from "apollo-boost";
import { logErrorToLogdna } from "../../helpers/logdna";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import {
  pigRunFragment,
  pigUpdatesFragment,
  ACTIVE_PIGS_SUBSCRIPTION
} from "../../helpers/pigQueryComposer";

export const PIG_DETAILS = gql`
  query pigRun($id: String!) {
    pigRun(id: $id) {
      ...PigRunFragment
      startLocation {
        latitude
        longitude
      }
      endLocation {
        latitude
        longitude
      }
      startPost
      endPost
      updates {
        ...PigUpdateFragment
        nextPoi {
          post
          type
        }
      }
      auditLogs {
        id
        pigId
        createdAt
        comment {
          author
          message
        }
      }
    }
  }
  ${pigRunFragment}
  ${pigUpdatesFragment}
`;

export const usePigDetails = (id: string, subscribeToChange?: boolean) => {
  const { activeOrg } = useOrganizationContext();

  const { subscribeToMore, loading, error, data, refetch } = useQuery<{
    pigRun: gqlType.PigRun;
  }>(PIG_DETAILS, {
    variables: { id },
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        "GETTING_PIG_DETAILS",
        "Apollo error while getting pig run details.",
        err
      );
      return err;
    }
  });

  useEffect(() => {
    if (!activeOrg) {
      return;
    }
    if (subscribeToChange) {
      subscribeToMore({
        document: ACTIVE_PIGS_SUBSCRIPTION,
        variables: {
          organizationId: activeOrg.id
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          } else {
            // These types appear incorrect, so coerce them into the PigRun type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const subscribed: gqlType.PigRun = (subscriptionData.data as any)
              .trackPig;

            // If two pig updates arrive at the same time, the "latest" fetched value will be the
            // more recent of the two, resulting in the same update being processed twice.
            // This code ignores out any updates that already exist.
            const updateAlreadyExists =
              prev.pigRun.updates.findIndex(
                existingUpdate => existingUpdate.id === subscribed.latest.id
              ) !== -1;

            if (
              prev &&
              prev.pigRun.id === subscribed.id &&
              !updateAlreadyExists
            ) {
              return Object.assign({}, prev, {
                pigRun: {
                  ...prev.pigRun,
                  ...subscribed,
                  updates: [subscribed.latest, ...prev.pigRun.updates]
                }
              });
            }
          }
          return prev;
        }
      });
    }

    //suppressing hook dependency because `subscribeToMore` changes on every render
    // and the documentation suggest to only call it on component mount
    // https://www.apollographql.com/docs/react/data/subscriptions/#subscribing-to-updates-for-a-query
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrg]);

  return {
    error,
    status: loading && !data ? "loading" : "connected",
    pigData: !loading && data ? data : undefined,
    refetch
  };
};
