export const MAX_PERCENT = 100;
export const HALF = 0.5;

export const snap = (value: number, step: number) => {
  // A step value of 0 will return "Infinity", let's assume it was an error
  // and default to 1
  const nonZeroStep = step || 1;
  return Math.floor(value / nonZeroStep) * nonZeroStep;
};

export const clamp = (input: number, min: number, max: number) => {
  return Math.min(Math.max(min, input), max);
};

export const calculatePercent = (value: number, min: number, max: number) => {
  return Math.round(((value - min) / (max - min)) * MAX_PERCENT);
};

export const findPrecision = (
  input: number | string | Array<number | string>
) => {
  const numbers = input instanceof Array ? input : [input];
  let precision = 0;

  for (let i = 0; i < numbers.length; i++) {
    const numSplit = numbers[i].toString().split(".");

    if (numSplit.length > 1) {
      precision = Math.max(precision, numSplit[numSplit.length - 1].length);
    }
  }

  return precision;
};
