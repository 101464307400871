import React from "react";
import cx from "classnames";
import styles from "./index.module.scss";

import { SearchItem } from "../../../types/AnalysisTypes";
import { Text, Heading } from "../../Type";
import ArrowRight from "../../Icons/ArrowRight";
import { NavLink, NavLinkProps } from "react-router-dom";
import { formatElapsedTime } from "../../../helpers/formatDate";
import { getUnitDefinition } from "../../../helpers/analysisFunctions";
import { useAuth } from "../../../contexts/AuthProvider";
import { kpToMp } from "../../AnalysisCharts/BaseChart";

interface IProps extends NavLinkProps {
  searchItem: SearchItem;
  className?: string;
  active?: boolean;
  updateLocalStorage?: () => void;
}

const RecentSearchItem = ({
  searchItem,
  className,
  active,
  updateLocalStorage,
  ...props
}: IProps) => {
  const classNames = cx(
    styles.recentSearchItem,
    { className: className || false },
    { [styles.active]: active || false }
  );

  const elapsedTime = formatElapsedTime(searchItem.timestamp);
  const unit = getUnitDefinition(searchItem.rangeUnit);

  const { permissions } = useAuth();

  return (
    <NavLink
      className={classNames}
      onClick={() => {
        searchItem.timestamp = Date.now();
        updateLocalStorage && updateLocalStorage();
      }}
      {...props}
    >
      <div className={styles.textRow}>
        <Heading size="h7">
          {permissions.user.imperial
            ? `MP ${kpToMp(searchItem.rangeMin)}`
            : `${unit.shortTitle} ${searchItem.rangeMin}`}
          <span className={styles.arrowRight}>
            <ArrowRight width={20} />
          </span>
          {permissions.user.imperial
            ? kpToMp(searchItem.rangeMax)
            : searchItem.rangeMax}
        </Heading>
        <Text component="span">
          {permissions.user.imperial
            ? `${kpToMp(searchItem.km, 1)}miles`
            : `${searchItem.km}km`}
        </Text>
      </div>
      <div className={styles.textRow}>
        <Text dark size="small" component="span">
          {searchItem.pipelineName}
        </Text>
        <Text dark size="small" component="span">
          Last used {elapsedTime} ago
        </Text>
      </div>
    </NavLink>
  );
};

export default RecentSearchItem;
