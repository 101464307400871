import React from "react";
import cx from "classnames";
import moment from "moment";
import { NavLink, useRouteMatch } from "react-router-dom";
import { gqlType } from "@hifieng/common";

import { Heading, Text } from "../../Type";
import styles from "./index.module.scss";
import { getStatusText } from "../../../helpers/trainStatus";
import getPriorityLevel from "../../../helpers/getPriorityLevel";
import DateTime from "../../DateTime";

const UNIX_MULTIPLIER = 1000;

type PropsType = {
  trainRun: gqlType.TrainRun;
};

const TrainRun = ({ trainRun }: PropsType) => {
  const {
    status,
    timestamp,
    nextStationName,
    nextStationEta
  } = trainRun.latest;

  const priority = getPriorityLevel(status);
  moment.locale("en-short", {
    parentLocale: "en",
    relativeTime: {
      m: "%d min",
      mm: "%d mins"
    }
  });
  let lastUpdated = moment.unix(timestamp).fromNow();
  if (lastUpdated === "a few seconds ago") lastUpdated = "secs ago";

  const statusText = getStatusText(status);
  const match = useRouteMatch();

  return (
    <NavLink
      to={`${match.url}/${trainRun.id}`}
      className={cx(styles.Card, {
        [styles.CardMedium]: priority === "medium",
        [styles.CardHigh]: priority === "high"
      })}
    >
      <div className={styles.Header}>
        <div className={styles.TitleGroup}>
          <Heading className={styles.Type} size="h7">
            {trainRun.railId} - Run {trainRun.runId}
          </Heading>
          <Heading className={styles.TypeDark} size="h7">
            {nextStationName ? nextStationName : "TBD"}
          </Heading>
        </div>
        <div className={styles.InfoGroup}>
          <Text className={styles.Type}>{statusText}</Text>
          <Text className={cx(styles.Type, styles.TypeDark)}>
            Next Stop - {nextStationName ? nextStationName : "TBD"}
          </Text>
        </div>
        <Text
          size="small"
          className={cx(styles.TrainStatus, styles.Type, {
            [styles.TrainStatusYellow]: priority === "medium",
            [styles.TrainStatusRed]: priority === "high"
          })}
        >
          ETA -{" "}
          {nextStationEta ? (
            <DateTime
              timestamp={nextStationEta * UNIX_MULTIPLIER}
              length="long"
            />
          ) : (
            "TBD"
          )}
          <Text size="small" dark component="span">
            Last Update - {lastUpdated}
          </Text>
        </Text>
      </div>
    </NavLink>
  );
};

export default TrainRun;
