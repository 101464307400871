import React from "react";

import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import { formatDate, formatTime } from "../../helpers/formatDate";
import { Text } from "../Type";

import styles from "./index.module.scss";

type props = {
  startTimestamp: number;
  endTimestamp: number;
  isLive?: boolean;
};

const TimeTicker = ({ startTimestamp, endTimestamp, isLive }: props) => {
  const { activeOrg } = useOrganizationContext();

  // &nbsp; doesn't work for some reason so we use the unicode character instead
  let date = "\u00A0";
  let time = "\u00A0";

  if (startTimestamp && endTimestamp && activeOrg) {
    const timeZoneOptions = {
      utcOffset: activeOrg.utcOffset,
      timezoneAbbr: activeOrg.timezoneAbbr
    };

    const startDate = formatDate(startTimestamp, timeZoneOptions);
    const startTime = formatTime(startTimestamp, timeZoneOptions);
    const endDate = formatDate(endTimestamp, timeZoneOptions);
    let endTime = formatTime(endTimestamp, timeZoneOptions);

    if (startDate !== endDate) {
      endTime = `${endDate} ${endTime}`;
    }

    date = startDate;
    time = `${startTime} - ${isLive ? "Present" : endTime}`;
  }

  return (
    <Text size="small" dark className={styles.TimeTicker}>
      {date} {time}
    </Text>
  );
};

export default TimeTicker;
