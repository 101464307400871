import React from "react";
import { LoadingSpinner, LoadingSpinnerProps } from "../LoadingSpinner";
import styles from "./index.module.scss";
import cx from "classnames";

type Props = {
  loading: boolean;
  spinnerProps?: LoadingSpinnerProps;
  className?: string;
};

export default function LoadingWrapper({
  loading,
  spinnerProps,
  className
}: Props) {
  const classNames = cx(styles.Spinner, className, {
    [styles.SpinnerVisible]: loading
  });

  return (
    <div className={classNames}>
      <LoadingSpinner {...spinnerProps} />
    </div>
  );
}
