import React, { useMemo } from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";
import { useHistory, useRouteMatch } from "react-router-dom";

import PigSummary from "./PigSummary";
import EmptyListState from "../EmptyListState";
import EventMap from "../EventMap";
import PigUpdateDetails from "../PigUpdateDetails";
import DetailsModal from "../DetailsModal";

import styles from "./index.module.scss";
import { useSelectedPig } from "./useSelectedPig";

type PropsType = {
  pigRun?: gqlType.PigRun;
  loading: boolean;
  completed: boolean;
};

const PigDetailsSection = ({ pigRun, loading, completed }: PropsType) => {
  const { updateId } = useSelectedPig();
  const match = useRouteMatch();
  const history = useHistory();

  const updatesWithPipelineRunId = useMemo(() => {
    if (pigRun) {
      const updates = completed ? pigRun.updates : [pigRun.updates[0]];
      return updates.map(update => ({
        ...update,
        runId: pigRun.runId,
        pipeline: pigRun.pipeline,
        pigCompleted: completed
      }));
    }
    return [];
  }, [completed, pigRun]);

  const toUrlWithSearch = `${match.url}${history.location.search}`;

  return (
    <div
      className={cx(styles.Container, {
        [styles.ContainerLoading]: !pigRun || loading
      })}
    >
      <div className={styles.ListMapWrapper}>
        <div className={styles.List}>
          {!pigRun || loading ? (
            <EmptyListState loading={loading} text="Loading..." />
          ) : (
            <PigSummary pigRun={pigRun} />
          )}
        </div>
        <div className={styles.MapModalWrapper}>
          <div className={styles.MapContainer}>
            {pigRun && (
              <EventMap
                canSearchOnMapMove={false}
                events={updatesWithPipelineRunId}
                scrollZoom
                focusPipelines={[pigRun.pipeline.id]}
              />
            )}
          </div>
          {updateId && pigRun ? (
            <DetailsModal id={updateId} returnUrl={toUrlWithSearch}>
              <PigUpdateDetails
                updateId={updateId}
                pig={pigRun}
                returnUrl={toUrlWithSearch}
              />
            </DetailsModal>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PigDetailsSection;
