import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Acoustic = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 14 12">
      <path
        fill="currentColor"
        d="M4.90527.45v11.1h-1.5V.45h1.5zm5.51055 0v11.1h-1.5V.45h1.5zM2.15 2.05v7.9H.65v-7.9h1.5zm5.51055 0v7.9h-1.5v-7.9h1.5zm5.51054 0v7.9h-1.5v-7.9h1.5z"
      />
    </svg>
  );
};

export default Acoustic;
