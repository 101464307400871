import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Flow = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 14 15">
      <path
        fill="currentColor"
        d="M7 .51752c1.06107 0 1.95661.789 2.0903 1.8416.12034.94735-.41021 1.84222-1.26648 2.2051L7.714 4.605v1.954l3.46448.00014c.61022 0 1.11212.46376 1.17247 1.05805l.00608.1205L12.357 9.941l.10958.0416c.8087.34272 1.32686 1.15997 1.28057 2.04778l-.01408.15732c-.1337 1.05261-1.02924 1.8416-2.09031 1.8416-1.06107 0-1.95661-.78899-2.09031-1.8416-.12033-.94735.41022-1.84222 1.2665-2.2051l.10905-.0416V7.987H3.071v1.954l.11006.0416c.8087.34272 1.32686 1.15997 1.28057 2.04778l-.01408.15732c-.1337 1.05261-1.02924 1.8416-2.0903 1.8416-1.06108 0-1.95662-.78899-2.09032-1.8416-.12033-.94735.41022-1.84222 1.2665-2.2051L1.642 9.941l.00097-2.20331c0-.61022.46375-1.11211 1.05805-1.17247l.1205-.00608L6.285 6.559V4.605l-.10882-.04078c-.8087-.34272-1.32686-1.15996-1.28057-2.04777l.01408-.15733C5.0434 1.30652 5.93893.51752 7 .51752z"
      />
    </svg>
  );
};

export default Flow;
