import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Temperature = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 8 13">
      <path
        d="M6.22205 2.25468C6.22205 1.00946 5.2272 0 4 0 2.7728 0 1.77795 1.00946 1.77795 2.25468v3.3124C.31307 6.5602-.33888 8.40895.17253 10.11963.68393 11.8303 2.23823 13 4 13c1.76177 0 3.31607-1.16969 3.82747-2.88036.5114-1.71068-.14054-3.55943-1.60542-4.55257V2.25468z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Temperature;
