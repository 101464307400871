import React from "react";
import CheckboxGroup from "../../CheckboxGroup";
import { useHistoryFiltersContext } from "../HistoryFiltersProvider";
import { analytics } from "../../../analytics";

const PipelineFilter = () => {
  const {
    eventTypeOptions,
    selectedEventTypes,
    setSelectedEventTypes
  } = useHistoryFiltersContext();

  const updateEventType = (eventTypeName: string) => {
    let newEventTypes = [];
    if (selectedEventTypes.includes(eventTypeName)) {
      newEventTypes = selectedEventTypes.filter(
        event => event !== eventTypeName
      );
    } else {
      newEventTypes = [...selectedEventTypes, eventTypeName];
    }

    setSelectedEventTypes(newEventTypes);
  };

  return (
    <CheckboxGroup
      label="Event Types"
      checkboxValue={selectedEventTypes}
      onChange={(eventTypeName: string) => {
        updateEventType(eventTypeName);
        analytics.historyAddFilter({ type: "eventType", value: eventTypeName });
      }}
      name="events-filter"
      options={eventTypeOptions}
    />
  );
};

export default PipelineFilter;
