import React, { useState } from "react";
import { Primary } from "../../Button";
import DateTime from "../../DateTime";
import { Share } from "../../Icons";
import ShareDialog from "../../ShareDialog";
import { Text } from "../../Type";
import styles from "./index.module.scss";

type Props = {
  search: string;
  startTime: number;
  showTime?: boolean;
  subText?: string;
};

const TimelineInfoBar = ({ search, startTime, showTime, subText }: Props) => {
  const [shareOpen, setShareOpen] = useState(false);

  return (
    <div className={styles.TimelineInfoBar}>
      <Text component="span" className={styles.DateText}>
        <DateTime timestamp={startTime} length="dateOnly" />
      </Text>
      {showTime && (
        <Text dark component="span">
          <DateTime timestamp={startTime} length="timeOnly" /> - Present
        </Text>
      )}
      {subText && (
        <Text dark component="span">
          {subText}
        </Text>
      )}
      <span className={styles.StretchElement} />
      <Primary icon={<Share />} onClick={() => setShareOpen(!shareOpen)}>
        Share
      </Primary>
      {shareOpen && (
        <ShareDialog
          eventId={search}
          onClose={() => setShareOpen(false)}
          label="Search URL"
          disableScreenshot
        />
      )}
    </div>
  );
};

export default TimelineInfoBar;
