/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from "react";
import { gqlType } from "@hifieng/common";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { useOrganizationContext } from "../../../contexts/OrganizationProvider";
import { CSVLink } from "react-csv";

import styles from "./index.module.scss";
import { Table, Row, Head, Cell } from "../../Table";
import { TextLink, Primary } from "../../Button";
import { ChevronRight, Download } from "../../Icons";
import Completed from "../TrainRun/Completed";
import EmptyListState from "../../EmptyListState";
import PaginationButtons from "../../PaginationButtons";

import formatCompletedRuns from "./formatCompletedRuns";
import { useCompletedTrains } from "./useCompletedTrains";
import DesktopFilters from "./DesktopFilters";
import MobileFilters from "./MobileFilters";

import { useTrainFiltersContext } from "../TrainFiltersProvider";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { trainsLimit } from "../../../helpers/pagination";
import { useMemoizedTimes } from "../../../hooks/useMemorizedTimes";
import LoadingWrapper from "../../LoadingSpinnerWrapper";
import { ITabContent } from "../../TabbedContent";

interface ICompletedTrainsTabContent extends ITabContent {
  loading: boolean;
  empty?: boolean;
}

const TABLE_HEADERS = [
  "name",
  "train type",
  "start",
  "end",
  "updates",
  "critical events"
];

const CompletedRuns = ({ empty, onActivated }: ICompletedTrainsTabContent) => {
  const { activeOrg } = useOrganizationContext();
  const { setQueryParams, queryParams } = useQueryParams();

  const history = useHistory();
  const [exporting, setExporting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const {
    sortBy,
    selectedTrainTypes,
    selectedUpdatesTypes,
    resetAllFilters,
    page,
    selectedPipelines
  } = useTrainFiltersContext();
  const updatesType = selectedUpdatesTypes.length
    ? (selectedUpdatesTypes as Array<gqlType.UpdatesType>)
    : undefined;
  const types = selectedTrainTypes.length
    ? (selectedTrainTypes as Array<gqlType.TrainType>)
    : undefined;
  const { start, end } = queryParams;

  const timeRange = useMemoizedTimes(
    start,
    end,
    activeOrg && activeOrg.utcOffset
  );

  const csvExportLimit = parseInt(process.env.CSV_EXPORT_LIMIT || "0", 0);

  const filters = {
    updatesType,
    types,
    timeRange,
    sortBy: (sortBy as gqlType.SortByTypes) || gqlType.SortByTypes.TimeDesc,
    limit: exporting ? csvExportLimit : trainsLimit,
    offset: exporting ? 0 : (page - 1) * trainsLimit,
    pipelines: selectedPipelines.length ? selectedPipelines : undefined
  };
  const { trainRuns, loading, total } = useCompletedTrains(filters);
  const match = useRouteMatch();

  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  useEffect(() => {
    if (
      exporting &&
      trainRuns &&
      activeOrg &&
      !loading &&
      !downloading &&
      trainRuns.trains.length === total
    ) {
      setDownloading(true);
    }
  }, [exporting, trainRuns, activeOrg, loading, total, downloading]);

  useEffect(() => {
    if (csvLink && csvLink.current && downloading) {
      csvLink.current.link.click();
      setExporting(false);
      setDownloading(false);
    }
  }, [downloading]);

  if (!activeOrg || !trainRuns) {
    return <LoadingWrapper loading />;
  }

  const runs: Array<gqlType.TrainRun> = trainRuns ? trainRuns.trains : [];

  const mappedTrains = formatCompletedRuns(runs, {
    timezoneAbbr: activeOrg.timezoneAbbr,
    utcOffset: activeOrg.utcOffset
  });

  const csvData = mappedTrains.map(train => [...train.data, train.auditLogs]);

  const noMatch = trainRuns && runs.length === 0;

  const handleRowClick = (url: string) => {
    history.push(url);
  };

  const getCSVData = () => {
    if (!exporting) {
      setExporting(true);
      return false;
    } else {
      return downloading;
    }
  };

  if (onActivated) onActivated({ title: "completed" });

  if (empty) {
    return (
      <EmptyListState
        loading={loading}
        text="No Completed Runs to Review"
        description={`Completed runs will show up once a train has been\nthrough the tracked segment of the pipeline.`}
      />
    );
  }

  return (
    <>
      <DesktopFilters totalEvents={total} eventsCount={runs.length} />
      <MobileFilters eventsCount={runs.length} totalEvents={total} />
      {noMatch ? (
        <div className={styles.NoMatchWrapper}>
          <EmptyListState
            loading={loading}
            text="No Matches"
            description={`We couldn't find any completed train runs that\nmatch your selected filters.`}
            cta={<Primary onClick={resetAllFilters}>Clear Filters</Primary>}
          />
        </div>
      ) : (
        <div className={styles.TableWrapperDesktop}>
          <Table caption="Completed Train Runs">
            <Row>
              {[...TABLE_HEADERS, ""].map(header => (
                <Head key={header}>{header}</Head>
              ))}
            </Row>
            <>
              {mappedTrains.map(train => (
                <Row
                  key={`row_${train.id}`}
                  onClick={() => handleRowClick(`${match.url}/${train.id}`)}
                >
                  <>
                    {train.data.map((data, i) => (
                      <Cell key={`${TABLE_HEADERS[i]}_${train.id}`}>
                        {data}
                      </Cell>
                    ))}
                  </>
                  <Cell>
                    <NavLink
                      to={`${match.url}/${train.id}`}
                      className={styles.LinkWrapper}
                    >
                      <TextLink
                        component="span"
                        icon={<ChevronRight />}
                        linkStyle="secondary"
                      >
                        {`${train.numAuditLogs} Notes`}
                      </TextLink>
                    </NavLink>
                  </Cell>
                </Row>
              ))}
            </>
          </Table>
        </div>
      )}
      <div className={styles.TableWrapperMobile}>
        {runs.map(run => (
          <Completed
            trainRun={run}
            key={run.id}
            url={`${match.url}/${run.id}`}
          />
        ))}
      </div>
      {!noMatch && (
        <div>
          <PaginationButtons
            updatePage={page => setQueryParams({ page }, "push")}
            currentPage={page || 1}
            totalEvents={total}
            limit={trainsLimit}
          />
          <Primary
            component="div"
            icon={<Download />}
            className={styles.DownloadLink}
          >
            <CSVLink
              data={csvData}
              headers={[...TABLE_HEADERS, "notes"]}
              filename="filtered-completed-train-runs.csv"
              onClick={getCSVData}
              ref={csvLink}
            >
              Export Filtered Data
            </CSVLink>
          </Primary>
        </div>
      )}
    </>
  );
};

export default CompletedRuns;
