import React from "react";
import { NavLink } from "react-router-dom";
import { gqlType } from "@hifieng/common";

import ListDetailsSection from "../../ListDetailsSection";
import EventMap from "../../EventMap";
import { Primary as PrimaryButton } from "../../Button";
import PigRun from "../PigRun/Active";

import { useActivePigs } from "./useActivePigs";
import { ITabContent } from "../../TabbedContent";

interface IActivePigsTabContent extends ITabContent {
  emptyUrl: string;
  hideEmptyCta?: boolean;
}

const ActiveRuns = ({
  emptyUrl,
  hideEmptyCta,
  onActivated
}: IActivePigsTabContent) => {
  const { pigRuns, loading } = useActivePigs();

  const sortedRuns: Array<gqlType.PigRun> = pigRuns
    ? pigRuns.pigs.sort(
        (a: gqlType.PigRun, b: gqlType.PigRun) =>
          b.latest.timestamp - a.latest.timestamp
      )
    : undefined;

  if (!sortedRuns) return null;

  if (onActivated) onActivated({ title: "active" });

  return (
    <ListDetailsSection
      emptyStateText="No Pigs Detected"
      emptyCta={
        !hideEmptyCta && (
          <NavLink to={emptyUrl}>
            <PrimaryButton component="div">View Completed Runs</PrimaryButton>
          </NavLink>
        )
      }
      emptyDescription={`There are currently no active pigs in\n the pipelines displayed.`}
      totalEvents={sortedRuns.length}
      loading={loading}
      paginate={false}
      listComponent={
        <>
          {sortedRuns.map(run => (
            <PigRun pigRun={run} key={run.id} />
          ))}
        </>
      }
      mapComponent={
        <EventMap
          canSearchOnMapMove={false}
          events={sortedRuns || []}
          scrollZoom
        />
      }
    />
  );
};

export default ActiveRuns;
