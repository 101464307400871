import React from "react";
import SchematicMap, {
  SchematicMapDataPointType,
  SchematicMapCategoryType
} from "../../SchematicMap";

import { useOrganizationContext } from "../../../contexts/OrganizationProvider";
import { useEventDetailContext } from "../../../contexts/eventDetailProvider";

import styles from "./index.module.scss";
import { getSchematicMapInputs } from "../../../helpers/getSchematicMapInputs";
import { getStatusText } from "../../../helpers/pigStatus";
import { useAuth } from "../../../contexts/AuthProvider";

const EventSchematicMap = () => {
  const { event } = useEventDetailContext();
  const { activeOrg } = useOrganizationContext();

  const { permissions } = useAuth();

  if (!event || !activeOrg) {
    return <div className={styles.Wrapper}></div>;
  }

  const { pipelines } = activeOrg;

  let featuredEventCategory: SchematicMapCategoryType;
  if (event.type === "pig") {
    featuredEventCategory = "pig";
  } else if (event.type === "train") {
    featuredEventCategory = "train";
  } else {
    featuredEventCategory = "event";
  }

  const teckFlag = activeOrg !== undefined && activeOrg.id === "teck";

  const featuredEventData: SchematicMapDataPointType = {
    id: event.referenceId,
    category: featuredEventCategory,
    isFeatured: true,
    type:
      event.type === "pig" && event.eventMeta
        ? event.eventMeta.status
        : event.type,
    post: event.post
  };

  const pipelineId = event.pipeline.id;
  const { assets, domain } = getSchematicMapInputs(pipelines, pipelineId);

  return (
    <div className={styles.Wrapper}>
      {pipelines && featuredEventData && (
        <SchematicMap
          key={event.referenceId}
          featuredEvent={featuredEventData}
          events={[]}
          assets={assets}
          pipelineDomain={domain}
          iconLabel={
            event && event.eventMeta
              ? getStatusText(event.eventMeta.status)
              : undefined
          }
          imperialFlag={
            (permissions.user.imperial ? true : false) ||
            pipelineId === "enbridge_line_3" ||
            pipelineId === "enbridge_line_4"
          }
          teckFlag={teckFlag}
        />
      )}
    </div>
  );
};

export default EventSchematicMap;
