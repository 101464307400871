import React from "react";

const DEFAULT_WIDTH = 12.6;
const DEFAULT_HEIGHT = 14;

type PropsType = {
  size?: number;
};

const Trash = ({ size = DEFAULT_HEIGHT }: PropsType) => {
  return (
    <svg
      width={(size * DEFAULT_WIDTH) / DEFAULT_HEIGHT}
      height={size}
      viewBox="0 0 14 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.916 5.917h1.042v6.25H4.916v-6.25zm3.125 0h1.042v6.25H8.041v-6.25zM.75 2.792v1.041h1.041V14.25c0 .575.467 1.042 1.042 1.042h8.333c.576 0 1.042-.467 1.042-1.042V3.833h1.042V2.792H.75zM2.833 14.25V3.833h8.333V14.25H2.833zM4.916.708h4.167V1.75H4.916V.708z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Trash;
