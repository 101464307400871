import { gqlType } from "@hifieng/common";
import { CoordinatePoints } from "../types/EventMap";

export const queryParamsToMapBounds = (queryParams: {
  swLat: string;
  swLong: string;
  neLat: string;
  neLong: string;
}) => {
  return {
    north: parseFloat(queryParams.neLat),
    east: parseFloat(queryParams.neLong),
    south: parseFloat(queryParams.swLat),
    west: parseFloat(queryParams.swLong)
  };
};

export const boundsToQueryParams = (bounds: gqlType.GeoBoundsInput) => {
  const maxDigits = 7;
  return {
    swLat: bounds.sw.lat.toFixed(maxDigits).toString(),
    swLong: bounds.sw.long.toFixed(maxDigits).toString(),
    neLat: bounds.ne.lat.toFixed(maxDigits).toString(),
    neLong: bounds.ne.long.toFixed(maxDigits).toString()
  };
};

export const queryStringToQueryParams = (queryParams: {
  swLat: string;
  swLong: string;
  neLat: string;
  neLong: string;
}) => {
  const { swLat, swLong, neLat, neLong } = queryParams;
  return {
    sw: { lat: parseFloat(swLat), long: parseFloat(swLong) },
    ne: { lat: parseFloat(neLat), long: parseFloat(neLong) }
  };
};

export const getGeoBounds = (
  points: CoordinatePoints
): gqlType.GeoBoundsInput => {
  let minLat = points[0].coordinates.latitude;
  let maxLat = points[0].coordinates.latitude;
  let minLong = points[0].coordinates.longitude;
  let maxLong = points[0].coordinates.longitude;

  points.forEach(point => {
    const pointLat = point.coordinates.latitude;
    const pointLong = point.coordinates.longitude;
    if (pointLat < minLat) minLat = pointLat;
    if (pointLat > maxLat) maxLat = pointLat;
    if (pointLong < minLong) minLong = pointLong;
    if (pointLong > maxLong) maxLong = pointLong;
  });

  return {
    sw: {
      lat: minLat,
      long: minLong
    },
    ne: {
      lat: maxLat,
      long: maxLong
    }
  };
};
