import React, { useState, useEffect, useMemo } from "react";
import { gqlType } from "@hifieng/common";
import AnalysisChart, { ChartTypes } from "../../AnalysisCharts";
import useSummaryData from "../../../hooks/useSummaryData";
import { AnalysisDetailsFilters } from "../../../types/AnalysisTypes";
import TimelineInfoBar from "../TimelineInfoBar";
import {
  MILLISECONDS_IN_SECONDS,
  SECONDS_PER_MINUTE
} from "../../../helpers/constants";

import debounce from "lodash.debounce";
import { breakpoints } from "../../../styles/variables";
import { HALF } from "../../../helpers/math";
import { useAuth } from "../../../contexts/AuthProvider";

const FIFTEEN_MIN_IN_SEC = 900;
const FIVE_MIN_IN_SEC = 300;
const FIFTEEN_SEC_IN_MS = 15000;

// how fast the data should update in milliseconds.
const pollInterval = 3000;

// We will ask for data in `queryBufferInSeconds` length of time from `now()`
const queryBufferInSeconds = 180;

// Allow gaps of `gapToleranceMS` milliseconds in the chart, gaps greater than
// this value will cause the chart to "reconnect".
const gapToleranceMS = Math.max(
  (queryBufferInSeconds / HALF) * MILLISECONDS_IN_SECONDS,
  FIFTEEN_SEC_IN_MS
);

type Props = {
  organization: gqlType.Organization;
  filters: AnalysisDetailsFilters;
};

const AnalysisDetails = ({ organization, filters }: Props) => {
  const { start, end, unit, fiber, pipelineId } = filters;
  const postRange = useMemo(() => [start, end], [start, end]);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  // this should be in the same units as `filterDefaults.timeUnit`
  const initialTimeRange =
    windowSize < breakpoints.medium ||
    (windowSize >= breakpoints.large && windowSize <= breakpoints.xlarge)
      ? FIVE_MIN_IN_SEC
      : FIFTEEN_MIN_IN_SEC;

  // creates a window resize event listener that updates a `windowSize`
  // state which can be used to trigger re-renders of components in response
  // to the window size changing.
  useEffect(() => {
    const delayMS = 150;
    const resize = debounce(() => {
      setWindowSize(window.innerWidth);
    }, delayMS);

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const summaryFilterDefaults = {
    organizationId: organization.id,
    pollInterval,
    gapToleranceMS,
    unit,
    queryBufferInSeconds,
    initialTimeRange,
    windowSize,
    filters: {
      pipeline: pipelineId,
      channel: start,
      toChannel: end,
      timeUnit: gqlType.TimeUnits.Seconds,
      startTime: initialTimeRange,
      endTime: 0,
      limit: initialTimeRange * (end - start)
    }
  };

  const [strainChartType, setStrainChartType] = useState<ChartTypes>(
    ChartTypes.HeatMap
  );
  const strainProps = useSummaryData({
    ...summaryFilterDefaults,
    chartType: strainChartType,
    filters: {
      ...summaryFilterDefaults.filters,
      measures: [gqlType.Measures.StrainTemp]
    }
  });

  const [rmsChartType, setRMSChartType] = useState<ChartTypes>(
    ChartTypes.HeatMap
  );
  const rmsProps = useSummaryData({
    ...summaryFilterDefaults,
    chartType: rmsChartType,
    filters: {
      ...summaryFilterDefaults.filters,
      measures: [gqlType.Measures.Rms]
    }
  });

  const [bprmsChartType, setBPRMSChartType] = useState<ChartTypes>(
    ChartTypes.HeatMap
  );
  const bprmsProps = useSummaryData({
    ...summaryFilterDefaults,
    chartType: bprmsChartType,
    filters: {
      ...summaryFilterDefaults.filters,
      measures: [gqlType.Measures.Bprms]
    }
  });

  const { permissions } = useAuth();
  const imperialFlag =
    (permissions.user.imperial ? true : false) ||
    fiber.id === "enbridge_line_3" ||
    fiber.id === "enbridge_line_4";

  const subtitle =
    unit === 0 ? `${imperialFlag ? "Mile" : "Kilometer"} Posts` : "Channels";

  return (
    <>
      <TimelineInfoBar
        search={JSON.stringify(filters)}
        subText="Updates every 3 to 7 seconds"
        startTime={
          Date.now() -
          initialTimeRange * SECONDS_PER_MINUTE * MILLISECONDS_IN_SECONDS
        }
      />
      <AnalysisChart
        windowSize={windowSize}
        title="Strain/Temperature"
        subTitle={subtitle}
        postRange={postRange}
        unit={unit}
        fiber={fiber}
        type={strainChartType}
        onTypeChange={setStrainChartType}
        minVal={0}
        {...strainProps}
      />
      <AnalysisChart
        windowSize={windowSize}
        title="Acoustic 1 (Broadband)"
        subTitle={subtitle}
        postRange={postRange}
        unit={unit}
        fiber={fiber}
        type={rmsChartType}
        onTypeChange={setRMSChartType}
        minVal={0}
        {...rmsProps}
      />
      <AnalysisChart
        windowSize={windowSize}
        title="Acoustic 2 (Band-Pass)"
        subTitle={subtitle}
        postRange={postRange}
        unit={unit}
        fiber={fiber}
        type={bprmsChartType}
        onTypeChange={setBPRMSChartType}
        minVal={0}
        {...bprmsProps}
      />
    </>
  );
};

export default AnalysisDetails;
