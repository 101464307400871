import React from "react";
import { NavLink } from "react-router-dom";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  id: string;
  returnUrl: string;
  short?: boolean;
  children: React.ReactNode;
};
const DetailsModal = ({ id, returnUrl, short, children }: PropsType) => {
  return (
    <>
      <NavLink
        to={returnUrl}
        className={styles.DetailsModalMask}
        aria-label="close details"
      ></NavLink>

      {/* Key definition ensures Details modal scrolls to top when
       selecting new event. */}
      <div
        key={id}
        className={cx(styles.DetailsModal, short && styles.DetailsModalShort)}
      >
        {children}
      </div>
    </>
  );
};

export default DetailsModal;
