import React from "react";

const DEFAULT_SIZE = 20;

type PropsType = {
  size?: number;
};

const LogoCircle = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="M17.29 3.506c2.781 3.049 3.306 6.692 1.644 10.449-1.673 3.78-4.83 5.578-8.717 5.85a11.408 11.408 0 01-3.124-.48c-1.097-.324-1.173-.586-.36-1.398 2.376-2.372 4.742-4.754 7.174-7.068.708-.674.767-1.059.037-1.759-2.424-2.323-4.782-4.712-7.158-7.085-.803-.802-.737-1.074.35-1.378 3.971-1.112 7.435-.11 10.153 2.869zM5.195 2.775c2.082 2.165 4.232 4.268 6.397 6.353.644.62.611 1.023-.017 1.63a260.723 260.723 0 00-6.477 6.446c-.688.713-1.173.576-1.78-.002C1.274 15.248.2 12.869.123 10.047.17 7.18 1.256 4.768 3.327 2.777c.644-.62 1.143-.756 1.869-.002z"
      />
    </svg>
  );
};

export default LogoCircle;
