import React from "react";
import { Text } from "../Type";

import styles from "./index.module.scss";
import cx from "classnames";

interface IToggleSwitchProps {
  setActive: (value: boolean) => void;
  active?: boolean;
  inactiveLabel?: string;
  activeLabel?: string;
}

const ToggleSwitch = ({
  setActive,
  active,
  inactiveLabel = "Off",
  activeLabel = "On"
}: IToggleSwitchProps) => {
  return (
    <div className={styles.ToggleSwitch}>
      <Text component="span">{inactiveLabel}</Text>
      <button
        type="button"
        className={cx(styles.Switch, { [styles.active]: active })}
        onClick={() => {
          setActive(!active);
        }}
      ></button>
      <Text component="span">{activeLabel}</Text>
    </div>
  );
};

export default ToggleSwitch;
