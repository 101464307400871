import React, { useState } from "react";
import CheckboxInput from "./CheckboxInput";
import { Primary, TextLink } from "../Button";
import { OptionType } from "../../types/GeneralTypes";
import styles from "./index.module.scss";
import { Text } from "../Type";
import {
  useSelect,
  UseSelectState,
  UseSelectStateChangeOptions
} from "downshift";

import cx from "classnames";
import DropdownButton from "../Dropdown/DropdownButton";

export interface IMultiSelectProps {
  label: string;
  hideLabel?: boolean;
  options: Array<OptionType>;
  selections: Array<OptionType>;
  setSelections: (selections: Array<OptionType>) => void;
  maxWidth?: number;
  disabled?: boolean;
  small?: boolean;
}

export const MultiSelect = ({
  options,
  label,
  hideLabel,
  selections,
  setSelections,
  disabled,
  small,
  maxWidth
}: IMultiSelectProps) => {
  const [unsavedSelections, setUnsavedSelections] = useState<Array<OptionType>>(
    selections
  );

  const stateReducer = (
    state: UseSelectState<OptionType>,
    actionAndChanges: UseSelectStateChangeOptions<OptionType>
  ) => {
    const { changes, type } = actionAndChanges;
    switch (type) {
      case useSelect.stateChangeTypes.MenuKeyDownEnter:
      case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
      case useSelect.stateChangeTypes.ItemClick:
        return {
          ...changes,
          isOpen: true, // keep menu open after selection
          highlightedIndex: state.highlightedIndex
        };
      case useSelect.stateChangeTypes.ToggleButtonClick:
        if (!state.isOpen) {
          //resets (clears) unsaved changes on menu reopen
          setUnsavedSelections(selections);
        }
        return changes;
      default:
        return changes;
    }
  };

  const {
    isOpen,
    closeMenu,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect({
    items: options,
    stateReducer,
    selectedItem: null,
    onSelectedItemChange: changes => {
      let newSelections: Array<OptionType> = [];
      if (
        unsavedSelections.some(
          option =>
            changes.selectedItem && option.value === changes.selectedItem.value
        )
      ) {
        // deselects option if option has already been selected
        newSelections = unsavedSelections.filter(
          option =>
            changes.selectedItem && option.value !== changes.selectedItem.value
        );
      } else {
        changes.selectedItem &&
          (newSelections = [...unsavedSelections, changes.selectedItem]);
      }
      setUnsavedSelections(newSelections);
    }
  });

  const selectedOptionsLabels =
    selections.length === 0
      ? "All" // displays All if no options are selected
      : selections.map(s => s.label).join(", ");

  //reconstructed as an OptionType to satisfy the props for DropdownButton
  const selectedOptions: OptionType = {
    label: selectedOptionsLabels,
    value: selectedOptionsLabels
  };

  const handleApply = () => {
    setSelections(unsavedSelections);
    closeMenu();
  };

  const OptionList = () => {
    return (
      <>
        {options.map((option, index) => (
          <li
            key={`${option.value}`}
            {...getItemProps({
              item: option,
              index
            })}
          >
            <CheckboxInput
              option={option}
              small={small}
              checked={unsavedSelections.includes(option)}
              highlighted={highlightedIndex === index}
            />
          </li>
        ))}
      </>
    );
  };

  return (
    <div className={styles.DropdownWrapper}>
      {!hideLabel && (
        <Text
          size="small"
          className={styles.Label}
          component="label"
          {...getLabelProps()}
        >
          {label}
        </Text>
      )}
      <div className={styles.DropdownButtonWrapper}>
        <DropdownButton
          open={isOpen}
          small={small}
          selectedItem={selectedOptions}
          maxWidth={maxWidth}
          buttonProps={{ ...getToggleButtonProps(), disabled }}
          aria-label={hideLabel && label}
        />
      </div>
      <ul
        {...getMenuProps()}
        className={cx(styles.Dropdown, isOpen && styles.DropdownOpen)}
      >
        {isOpen && (
          <>
            <OptionList />
            <div className={cx(styles.Buttons, small && styles.ButtonsSmall)}>
              <TextLink
                onClick={() => {
                  setUnsavedSelections(
                    unsavedSelections.length
                      ? [] // clear all
                      : options // select all
                  );
                }}
                buttonSize="small"
                className={styles.TextLink}
                linkStyle="secondary"
              >
                {unsavedSelections.length ? "Clear all" : "Select all"}
              </TextLink>
              <Primary onClick={handleApply} buttonSize="small">
                Apply
              </Primary>
            </div>
          </>
        )}
      </ul>
    </div>
  );
};
