import React from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import Avatar from "../../Avatar";
import { Primary } from "../../Button";
import EventCount from "../../EventCount";
import { Heading, Meta } from "../../Type";
import HeartbeatStatus from "../../HeartbeatStatus";
import { Check, IconCircleWrapper } from "../../Icons";
import { HeartbeatResponseType } from "../index";

import { color } from "../../../styles/colors";
import styles from "./index.module.scss";
import { derivedHeartbeatInfo } from "../../../helpers/derivedHeartbeatInfo";
import { analytics } from "../../../analytics";

type PropsType = {
  error: boolean;
  loading: boolean;
  isActive: boolean;
  setActiveOrg: (orgId: string) => void;
  org: HeartbeatResponseType;
};

const OrganizationCard = ({
  isActive,
  org,
  setActiveOrg,
  error,
  loading
}: PropsType) => {
  const history = useHistory();

  const heartbeatStatus = derivedHeartbeatInfo({
    pipelines: org.pipelines,
    dataStatus: error ? "error" : loading ? "loading" : "completed",
    liveHeartbeat: org.heartbeats
  });

  // Pipelines without segments are purely visual, this is currently for avoiding a bug on the
  // husky_sgs pipeline which has a "lateral" segment
  const pipelinesCount = org.pipelines.filter(
    pipeline => pipeline.segments.length > 0
  ).length;

  return (
    <div className={cx(styles.Org, { [styles.OrgActive]: isActive })}>
      <div className={styles.Details}>
        <div className={styles.Summary}>
          <Avatar src={org.imageUrl} alt={org.name} />
          <div className={styles.OrgInfo}>
            <div className={styles.Headings}>
              <Heading
                className={cx({ [styles.OrgNameActive]: isActive })}
                component="h2"
                size="h6"
              >
                {org.name}
              </Heading>
              <Meta
                className={styles.PipelineCount}
                component="h5"
                size="small"
                dark
              >
                {pipelinesCount}{" "}
                {pipelinesCount === 1 ? "Pipeline" : "Pipelines"}
              </Meta>
            </div>
            <HeartbeatStatus
              dark={isActive}
              status={heartbeatStatus.status}
              connected={heartbeatStatus.connectedCount}
              disconnected={heartbeatStatus.disconnectedCount}
            />
          </div>
        </div>
        {org.eventsCount > 0 && (
          <EventCount className={styles.EventCount} count={org.eventsCount} />
        )}
      </div>
      <div className={styles.Status}>
        {!isActive ? (
          <Primary
            className={styles.Activate}
            onClick={() => {
              setActiveOrg(org.id);
              analytics.userChangeOrg(org.name);
              history.push(`/alerts`);
            }}
          >
            Switch organization
          </Primary>
        ) : (
          <div className={styles.ActiveStatus}>
            <Heading
              className={cx(styles.ActiveTitle, {
                [styles.OrgNameActive]: isActive
              })}
              component="h3"
              size="h6"
            >
              Active
            </Heading>
            <IconCircleWrapper
              childSize={18}
              size={32}
              iconColor={color.success}
            >
              <Check size={18} />
            </IconCircleWrapper>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationCard;
