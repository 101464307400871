import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Test = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 12 14">
      <path
        fill="currentColor"
        d="M10.5 1.62512H9v-.5c0-.55228-.44772-1-1-1H4c-.55228 0-1 .44772-1 1v.5H1.5c-.55228 0-1 .44772-1 1v9.5c0 .55229.44772 1 1 1h9c.55228 0 1-.44771 1-1v-9.5c0-.55228-.44772-1-1-1zm-6.5-.5h4v2H4v-2z"
      />
    </svg>
  );
};

export default Test;
