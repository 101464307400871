import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { Primary, Secondary } from "../../Button";
import { Text } from "../../Type";

import { useEventDetailContext } from "../../../contexts/eventDetailProvider";

import { analytics } from "../../../analytics";
import { Archive, Unarchive } from "../../Icons";
import { logErrorToLogdna } from "../../../helpers/logdna";
import { ApolloError } from "apollo-boost";
import styles from "./index.module.scss";

const ERROR_CODES = {
  updatingEventArchiveStatus: "UPDATING_EVENT_ARCHIVE_STATUS"
};

const UPDATE_ARCHIVED_STATUS = gql`
  mutation($id: String!, $isArchived: Boolean!) {
    updateArchivedStatus(id: $id, isArchived: $isArchived)
  }
`;

type PropsType = {
  referenceId: string;
  className?: string;
  mobileMenu?: boolean;
};

const UpdateArchivedStatusButton = ({
  referenceId,
  className,
  mobileMenu
}: PropsType) => {
  const [executing, setExecuting] = useState(false);
  const [updateArchivedStatus] = useMutation(UPDATE_ARCHIVED_STATUS, {
    refetchQueries: [
      "eventDetails",
      "liveEvents",
      "historyEvents",
      "liveStatus"
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setExecuting(false);
    },
    onError: (err: ApolloError) => {
      logErrorToLogdna(
        ERROR_CODES.updatingEventArchiveStatus,
        "Apollo error while updating event archive status.",
        err
      );
      return err;
    }
  });
  const { event } = useEventDetailContext();

  const attemptArchivedStatusUpdate = (archived: boolean) => {
    if (event && archived) {
      analytics.eventArchived(event);
    }
    if (event && !archived) {
      analytics.eventUnarchived(event);
    }

    updateArchivedStatus({
      variables: { id: referenceId, isArchived: archived }
    });
    setExecuting(true);
  };

  if (!event) {
    return null;
  }

  if (mobileMenu) {
    return (
      <button
        type="button"
        className={styles.DropdownButton}
        onClick={() =>
          attemptArchivedStatusUpdate(event.archived ? false : true)
        }
      >
        <div className={styles.DropdownMainContent}>
          <div className={styles.IconWrapper}>
            {event.archived ? <Unarchive size={20} /> : <Archive size={20} />}
          </div>
          <Text component="span" className={styles.DropdownLabel}>
            {event.archived ? "Unarchive" : "Archive"}
          </Text>
        </div>
      </button>
    );
  } else {
    if (event.archived) {
      return (
        <Secondary
          className={className}
          buttonSize="small"
          onClick={() => attemptArchivedStatusUpdate(false)}
          disabled={executing}
          title="Unarchive"
          text="Unarchive"
          square
          icon={<Unarchive />}
        />
      );
    } else {
      return (
        <Primary
          className={className}
          buttonSize="small"
          onClick={() => attemptArchivedStatusUpdate(true)}
          disabled={executing}
          title="Archive"
          text="Archive"
          square
          icon={<Archive />}
        />
      );
    }
  }
};

export default UpdateArchivedStatusButton;
