import React from "react";
import cx from "classnames";
import { gqlType } from "@hifieng/common";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { Heading, Meta, Text } from "../../Type";
import DateTime from "../../DateTime";
import Select from "../../Dropdown/Select";
import PigUpdates from "../PigUpdates";
import RunNotes from "../RunNotes";

import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";
import { pigTypeOptions } from "../../../helpers/filterOptions";
import { secondsToHms } from "../../../helpers/formatDate";

import styles from "./index.module.scss";

type Props = {
  pigRun: gqlType.PigRun;
};

const SET_PIG_TYPE = gql`
  mutation($setPigTypeInput: SetPigTypeInput!) {
    setPigType(setPigTypeInput: $setPigTypeInput)
  }
`;

const PigSummary = ({ pigRun }: Props) => {
  const [setPigType] = useMutation(SET_PIG_TYPE);
  return (
    <>
      <div className={styles.Wrapper}>
        <Heading className={styles.Title} size="h6">
          Run Details
        </Heading>
        <div className={cx(styles.TypeGroup, styles.SelectGroup)}>
          <Meta size="small" dark className={styles.Label}>
            PIG TYPE
          </Meta>
          <div>
            <Select
              small
              label="PIG TYPE"
              hideLabel
              items={pigTypeOptions}
              initial={
                pigTypeOptions.find(option => option.value === pigRun.type) || {
                  label: "Select Option",
                  value: ""
                }
              }
              onChange={selectedItem =>
                setPigType({
                  variables: {
                    setPigTypeInput: {
                      type: selectedItem.value,
                      id: pigRun.id
                    }
                  }
                })
              }
            />
          </div>
        </div>
        <div className={styles.TypeGroup}>
          <Meta size="small" dark className={styles.Label}>
            START
          </Meta>
          <div>
            <Text dark className={styles.Text}>
              <DateTime
                timestamp={pigRun.startTime * MILLISECONDS_IN_SECONDS}
                length="short"
              />
            </Text>
            <Text size="small" className={styles.SmallText}>
              {pigRun.startLocation.latitude}, {pigRun.startLocation.longitude}
            </Text>
          </div>
        </div>
        {pigRun.endTime && pigRun.endLocation && (
          <>
            <div className={styles.TypeGroup}>
              <Meta size="small" dark className={styles.Label}>
                END
              </Meta>
              <div>
                <Text dark className={styles.Text}>
                  <DateTime
                    timestamp={pigRun.endTime * MILLISECONDS_IN_SECONDS}
                    length="short"
                  />
                </Text>
                <Text size="small" className={styles.SmallText}>
                  {pigRun.endLocation.latitude}, {pigRun.endLocation.longitude}
                </Text>
              </div>
            </div>
            <div className={styles.TypeGroup}>
              <Meta size="small" dark className={styles.Label}>
                DURATION
              </Meta>
              <div>
                <Text dark className={styles.Text}>
                  {secondsToHms(pigRun.endTime - pigRun.startTime)}
                </Text>
              </div>
            </div>
          </>
        )}
        <div className={styles.Notes}>
          <RunNotes logs={pigRun.auditLogs} id={pigRun.id} />
        </div>
      </div>
      <div className={styles.Wrapper}>
        <Heading className={styles.TitleUpdates} size="h6">
          Update History
        </Heading>
      </div>
      <PigUpdates updates={pigRun.updates} />
    </>
  );
};

export default PigSummary;
