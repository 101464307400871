import React from "react";

const Clock = () => {
  return (
    <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        transform="translate(0 .017)"
        d="M14.443 15.5l-4.193-4.193V5.25h1.5v5.435l3.75 3.758-1.057 1.057zM11 .5a10.455 10.455 0 00-7.5 3.172V.5H2v6h6V5H4.31A9 9 0 112 11H.5c0 5.799 4.701 10.5 10.5 10.5S21.5 16.799 21.5 11 16.799.5 11 .5z"
      />
    </svg>
  );
};

export default Clock;
