import React from "react";
import cx from "classnames";
import { Text } from "../../Type";

import styles from "./index.module.scss";

type MenuItem = {
  label?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  component?: React.ReactNode;
  hide?: boolean;
};

type Props = {
  className?: string;
  referenceId?: string;
  items: Array<MenuItem>;
};

const MobileMenu = ({ items, referenceId, className }: Props) => {
  return (
    <ul className={cx(className, styles.Dropdown)}>
      {items.map((item, i) => {
        return (
          (item.component || item.label) && (
            <li
              className={cx(styles.DropdownItem)}
              key={`menu-${i}-${referenceId}`}
            >
              {item.component ? (
                item.component
              ) : (
                <button
                  type="button"
                  onClick={item.onClick}
                  className={styles.DropdownButton}
                >
                  <div className={styles.DropdownMainContent}>
                    <div className={styles.IconWrapper}>{item.icon}</div>
                    <Text component="span" className={styles.DropdownLabel}>
                      {item.label}
                    </Text>
                  </div>
                </button>
              )}
            </li>
          )
        );
      })}
    </ul>
  );
};

export default MobileMenu;
