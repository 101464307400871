// Plotly creates a ton of spelling issues so we're going to turn off the spell
// checker for the whole document.
/* cSpell:disable */

import React, { useState } from "react";
import { gqlType } from "@hifieng/common";
import { axisDefaults, IChartProps } from ".";
import { color } from "../../styles/colors";
import BaseChart, { kpToMp } from "./BaseChart";
import groupby from "lodash.groupby";
import orderby from "lodash.orderby";
import { MILLISECONDS_IN_SECONDS } from "../../helpers/constants";
import { channelToKPost } from "../../helpers/analysisFunctions";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import { formatTime } from "../../helpers/formatDate";
import { useAuth } from "../../contexts/AuthProvider";

interface IHeatMapChartProps extends Omit<IChartProps, "data"> {
  rawData: Array<gqlType.SummaryData>;
}

const mpPrecision = 3;

const HeatMap = ({
  rawData,
  timeRange,
  postRange,
  unit,
  fiber,
  ...props
}: IHeatMapChartProps) => {
  const { activeOrg } = useOrganizationContext();
  const { permissions } = useAuth();
  const imperialFlag =
    (permissions.user.imperial ? true : false) ||
    fiber.id === "enbridge_line_3" ||
    fiber.id === "enbridge_line_4";

  const formatChannelName = (channel: number) => {
    if (unit === 0) {
      const kp = channelToKPost(fiber, channel);
      if (imperialFlag) {
        if (kp) {
          return `MP:  ${kpToMp(kp, mpPrecision)}`;
        } else {
          return `MP: ${0}`;
        }
      } else {
        return `KP: ${channelToKPost(fiber, channel) || 0}`;
      }
    }
    return `Channel: ${channel}`;
  };

  const generateHoverText = (measure: gqlType.SummaryData) => {
    if (activeOrg) {
      const name = formatChannelName(measure.channel);
      const time = formatTime(measure.time * MILLISECONDS_IN_SECONDS, {
        timezoneAbbr: activeOrg.timezoneAbbr,
        utcOffset: activeOrg.utcOffset
      });
      return `${name}<br>Time: ${time}<br>Value: ${measure.measureValueDouble}`;
    }
    return "";
  };

  let channelAxis: Array<number> = [];
  let timeAxis: Array<number> = [];
  let magnitudes: Array<Array<number>> = [[]];
  let hoverTexts: Array<Array<string>> = [[]];

  if (rawData && rawData.length) {
    // First we order by Channel(asc) and Time(asc). Then we group by channel.
    const channels = groupby(
      orderby(rawData, ["channel", "time"], ["asc", "asc"]),
      "channel"
    );

    // Figure out the y axis: channels
    // This is the easiest array to figure out. `channels` is an object who's
    // keys are the grouped channels. We'll also need to convert them to numbers.
    channelAxis = Object.keys(channels).map(channel => Number(channel));

    // Figure out the x axis: time
    // Each channel SHOULD contain all the same timestamps so we'll trust that
    // the first one has all the data that we need.
    timeAxis = Object.values(channels)[0].map(
      measure => measure.time * MILLISECONDS_IN_SECONDS
    );

    // Now we distribute the values into their proper channels.
    // We need a 2 dimensional array, that is: an array of channels each
    // containing all the measure values for that channel
    magnitudes = Object.values(channels).map(channel =>
      channel.map(measure => Math.abs(measure.measureValueDouble))
    );

    hoverTexts = Object.values(channels).map(channel =>
      channel.map(measure => generateHoverText(measure))
    );
  }

  // This gradient based on the "Jet (6-colour)" scale defined in Figma:
  // https://www.figma.com/file/pbjef2Y8JibzH8zz1p54rj/HIF003-Design-System?node-id=1085%3A315
  /* eslint-disable no-magic-numbers */
  const colorscale = [
    [0, color.black],
    [0.16, color.codGray],
    [0.332, color.catalinaBlue],
    [0.498, color.denim],
    [0.664, color.forestGreen],
    [0.83, color.christine],
    [1, color.alizarinCrimson]
  ];
  /* eslint-enable no-magic-numbers */

  const xAxis = {
    ...axisDefaults,
    autorange: true,
    range: timeRange
  };

  const yAxis = {
    ...axisDefaults,
    autorange: true,
    range: [postRange[0], postRange[1]]
  };

  const [minVal, setMinVal] = useState({});
  const [maxVal, setMaxVal] = useState({});

  const clamp = {
    zauto: minVal === undefined && maxVal === undefined,
    zmid: minVal === undefined && maxVal === undefined ? 0.0 : undefined,
    zmin: minVal,
    zmax: maxVal
  };

  const onShadingChange = (start: number, end: number, reset: boolean) => {
    clamp.zmin = start;
    clamp.zmax = end;
    clamp.zauto = reset;
    setMinVal(start);
    setMaxVal(end);
  };

  return (
    <BaseChart
      {...props}
      postRange={postRange}
      unit={unit}
      fiber={fiber}
      timeLine={timeAxis || []}
      xAxis={xAxis}
      yAxis={yAxis}
      onShadingChange={onShadingChange}
      data={[
        {
          type: "heatmap",
          hoverongaps: false,
          colorscale,
          hovertemplate: "%{text}<extra></extra>",
          text: hoverTexts,
          ...clamp,
          x: timeAxis,
          y: channelAxis,
          z: magnitudes
        }
      ]}
    />
  );
};

export default HeatMap;

/* cSpell:enable */
