import React from "react";
import cx from "classnames";
import styles from "./index.module.scss";

interface ITextProps<DomElementType>
  extends React.HTMLAttributes<DomElementType>,
    React.SVGAttributes<DomElementType> {
  component?: React.ElementType;
  size?: "small" | "regular" | "large";
  dark?: boolean;
  disambiguation?: boolean;
}

export function Text<DomElementType = "p">({
  component: Component = "p",
  size = "regular",
  dark,
  disambiguation,
  className,
  ...props
}: ITextProps<DomElementType>) {
  return (
    <Component
      {...props}
      className={cx(className, styles[`text-${size}`], {
        [styles.dark]: dark,
        [styles.disambiguation]: disambiguation
      })}
    />
  );
}

type HeadingSizes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
interface IHeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  component?: React.ElementType;
  size: HeadingSizes | "h7";
  dark?: boolean;
}

export const Heading = ({
  component,
  size,
  dark,
  className,
  ...props
}: IHeadingProps) => {
  let Component = component;
  if (!Component) {
    Component = size === "h7" ? "h6" : size;
  }
  return (
    <Component
      {...props}
      className={cx(className, styles[size], {
        [styles.dark]: dark
      })}
    />
  );
};

interface IMetaProps extends React.HTMLAttributes<HTMLHeadingElement> {
  component?: React.ElementType;
  size?: "small" | "regular";
  dark?: boolean;
}

export const Meta = ({
  component: Component = "span",
  size = "regular",
  dark,
  className,
  ...props
}: IMetaProps) => {
  return (
    <Component
      {...props}
      className={cx(className, styles[`meta-${size}`], {
        [styles.dark]: dark
      })}
    />
  );
};
