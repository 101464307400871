import React from "react";

const DEFAULT_SIZE = 24;

type PropsType = {
  size?: number;
};

const PigNav = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 4.74902H6.15315L7.2087 7.113H12.3056V4.74902H17.6135L19.7246 7.113H22.25V16.8869H19.7246L17.6135 19.2509H12.3056V16.8869H7.2087L6.15315 19.2509H1.75V4.74902ZM7.47222 15.3869H12.3056V8.613H7.47222V15.3869ZM5.97222 8.02283L5.18018 6.24902H3.25V17.7509H5.18018L5.97222 15.9771V8.02283ZM13.8056 6.24902V17.7509H16.942L19.0531 15.3869H20.75V8.613H19.0531L16.942 6.24902H13.8056Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PigNav;
