import React from "react";
import { useSelect } from "downshift";

import DropdownButton from "./DropdownButton";
import { Text } from "../Type";

import { OptionType } from "../../types/GeneralTypes";

import { color } from "../../styles/colors";
import styles from "./index.module.scss";
import cx from "classnames";

type Props = {
  label: string;
  items: Array<OptionType>;
  onChange: (selectedItem: OptionType) => void;
  initial: OptionType;
  disabled?: boolean;
  hideLabel?: boolean;
  maxWidth?: number;
  small?: boolean;
  forceSelectedItem?: OptionType;
};

const Select = ({
  label,
  items,
  onChange,
  initial,
  disabled,
  hideLabel,
  maxWidth,
  small,
  forceSelectedItem
}: Props) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    getLabelProps
  } = useSelect({
    items,
    onSelectedItemChange: changes =>
      changes.selectedItem && onChange(changes.selectedItem)
  });

  return (
    <div
      className={styles.DropdownWrapper}
      // maxWidth will need to be more than 150px to take effect
      style={{ maxWidth: `${maxWidth}px` }}
    >
      {!hideLabel && (
        <Text
          size="small"
          className={styles.Label}
          component="label"
          {...getLabelProps()}
        >
          {label}
        </Text>
      )}
      <DropdownButton
        buttonProps={{ ...getToggleButtonProps(), disabled }}
        selectedItem={forceSelectedItem || selectedItem || initial}
        open={isOpen}
        maxWidth={maxWidth}
        aria-label={hideLabel && label}
        small={small}
      />
      <ul
        {...getMenuProps()}
        className={cx(
          styles.Dropdown,
          isOpen && styles.DropdownOpen,
          hideLabel && styles.DropdownNoLabel,
          small && hideLabel && styles.DropdownNoLabelSmall
        )}
      >
        {isOpen &&
          items.map((item, index) => {
            let selected = false;

            if (forceSelectedItem) {
              if (forceSelectedItem.value === item.value) {
                selected = true;
              }
            } else if (selectedItem && selectedItem.value === item.value) {
              selected = true;
            }

            return (
              <li
                style={
                  highlightedIndex === index && !selected
                    ? { backgroundColor: color.mineShaft }
                    : {}
                }
                key={`${item}${index}`}
                className={cx(
                  styles.DropdownItem,
                  selected && styles.DropdownItemSelected,
                  small && styles.DropdownItemSmall
                )}
                {...getItemProps({ item, index })}
              >
                <div className={styles.DropdownMainContent}>
                  {item.icon && (
                    <div className={styles.IconWrapper}>{item.icon}</div>
                  )}
                  <Text
                    component="span"
                    className={cx(
                      styles.DropdownLabel,
                      item.altText && styles.DropdownLabelWithAltText
                    )}
                  >
                    {item.label}
                  </Text>
                </div>
                {item.altText && (
                  <div className={styles.AltTextWrapper}>
                    <Text
                      component="span"
                      className={styles.DropdownAltText}
                      dark
                    >
                      {item.altText}
                    </Text>
                  </div>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
};
export default Select;
