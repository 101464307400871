import React from "react";
import { TextLink } from "../Button";
import { Text } from "../Type";

import cx from "classnames";
import styles from "./index.module.scss";

type props = {
  connected: boolean;
  live: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const getStatus = (connected: boolean, live: boolean) => {
  if (connected) {
    if (live) {
      return "Live";
    }
    return "Paused";
  }
  return "Connecting...";
};

const StatusIndicator = ({ connected, live, onClick }: props) => {
  const indicatorClasses = cx(styles.Indicator, {
    [styles.Live]: connected && live,
    [styles.Paused]: connected && !live
  });

  return (
    <div className={styles.StatusIndicator}>
      <span className={indicatorClasses}></span>
      <Text component="span" size="small">
        {getStatus(connected, live)}
      </Text>
      {onClick && connected && (
        <TextLink
          buttonSize="small"
          className={styles.Toggle}
          onClick={onClick}
        >
          {live ? "Pause" : "Resume"}
        </TextLink>
      )}
    </div>
  );
};

export default StatusIndicator;
