// Plotly creates a ton of spelling issues so we're going to turn off the spell
// checker for the whole document.
/* cSpell:disable */

// Implementing Plotly makes typing things pretty tricky, well try our best to
// maintain best practices but we're going to globally allow `any` in this file
// to prevent headaches for now.
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect } from "react";
import { gqlType } from "@hifieng/common";
import "./plotly.scss";
import styles from "./index.module.scss";
import cx from "classnames";
import LoadingSpinnerWrapper from "../LoadingSpinnerWrapper";
import { Text, Meta, Heading } from "../Type";
import { color } from "../../styles/colors";
import { breakpoints } from "../../styles/variables";
import { Plot } from "@hifieng/plotly";
import StatusIndicator from "../StatusIndicator";
import TimeTicker from "../TimeTicker";
import { ChartTypes, IChartProps } from ".";
import { TextLink } from "../Button";
import ShadingSelector from "./ShadingSelector";
import { useAuth } from "../../contexts/AuthProvider";

// esling doesn't like this import path but it does exist and it works. So let's
// suppress the warning.
// eslint-disable-next-line import/no-unresolved
import { ModeBarDefaultButtons, Layout } from "plotly.js";
import { snap } from "../../helpers/math";
import { ONE_MINUTE_IN_MS } from "../../helpers/constants";
import { graphDate } from "../../helpers/formatDate";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";

// This is kind of a strange way of defining this components props because
// we're not really "extending" `IChartProps`, we're also removing a few props.
// I feel that even with all the modifications to the interface they're still
// similar enough that it's cleaner code to adapt `IChartProps` in this manner
// versus creating a whole new Props definition.
interface IBaseChartProps
  extends Omit<IChartProps, "data" | "rawData" | "timeRange"> {
  data: any;
  xAxis: any;
  yAxis: any;
  timeLine: Array<number>;
  height?: number;
}

const DEFAULT_CHART_HEIGHT = 340;

const precisionLimits = {
  triple: 100,
  double: 1000
};
const getPrecision = (value: number): number => {
  /* eslint-disable no-magic-numbers*/
  if (value < precisionLimits.triple) {
    return 3;
  } else if (value < precisionLimits.double) {
    return 2;
  }
  return 1;
};

export const kpMpRatio = 0.621371;
const mpPrecision = 3;
export const kpToMp = (kp: number, decimals = mpPrecision) => {
  const result = (kpMpRatio * kp).toFixed(decimals);
  return parseFloat(result);
};

const formatTickDates = (
  ticks: Array<number>,
  tickStep = 1,
  tickFormat: (tick: number) => {} | undefined
) => {
  const tickvals = [];

  // the minimum timestamp floored and then snapped to counts of tickStep
  const minTick = snap(Math.min(...ticks), tickStep);

  // Creates an xAxis entry for every unit of `tickstep`
  for (let i = minTick; i <= Math.max(...ticks); i += tickStep) {
    tickvals.push(i);
  }

  return {
    tickmode: "array",
    tickvals,
    // create the formatted date stamp for evert tickval
    ticktext: tickvals.map(tick => String(tickFormat ? tickFormat(tick) : tick))
  };
};

const BaseChart = ({
  type,
  windowSize,
  loading,
  title,
  subTitle,
  data,
  postRange,
  unit,
  fiber,
  xAxis,
  yAxis,
  timeLine,
  height = DEFAULT_CHART_HEIGHT,
  connected,
  live,
  onPauseToggle,
  onTypeChange,
  onShadingChange
}: IBaseChartProps) => {
  const { activeOrg } = useOrganizationContext();
  const [autoRange, setAutoRange] = useState<boolean | "reversed" | undefined>(
    false
  );

  // This will turn off the yAxis's `autoRange` when the chart goes into
  // "connecting" status. This fixes an issue where the autoRange gets
  // stuck in the "on" position when pausing and resuming the live feed.
  useEffect(() => {
    if (!connected && !live && autoRange) {
      setAutoRange(false);
    }
  }, [connected, live, autoRange]);

  const { permissions } = useAuth();
  const imperialFlag =
    (permissions.user.imperial ? true : false) ||
    fiber.id === "enbridge_line_3" ||
    fiber.id === "enbridge_line_4";

  // creates the yAxis ticks, takes the unit into account.
  const formatKPChRange = (
    unit: number,
    postRange: Array<number>,
    fiber: gqlType.ChannelKPostMap
  ) => {
    const tickvals: Array<number> = [];
    const ticktext: Array<string> = [];

    fiber.ch.forEach((ch: number, i: number) => {
      if (ch >= postRange[0] && ch <= postRange[1]) {
        tickvals.push(ch);
        const kp = fiber.kp[i];
        if (imperialFlag) {
          ticktext.push(String(!unit ? kpToMp(kp, getPrecision(kp)) : ch));
        } else {
          ticktext.push(String(!unit ? fiber.kp[i] : ch));
        }
      }
    });

    return {
      tickmode: "array",
      tickvals,
      ticktext
    };
  };

  // We are excluding everything except for the 6 tools we want/need.
  // This is likely overkill as some of these buttons aren't relevant to our
  // type of graph but it works. We can refine this list later if we need.
  const removeButtons: Array<ModeBarDefaultButtons> = [
    "lasso2d",
    "select2d",
    "sendDataToCloud",
    "hoverClosestCartesian",
    "hoverCompareCartesian",
    "zoom3d",
    "pan3d",
    "orbitRotation",
    "tableRotation",
    "resetCameraDefault3d",
    "resetCameraLastSave3d",
    "hoverClosest3d",
    "zoomInGeo",
    "zoomOutGeo",
    "resetGeo",
    "hoverClosestGeo",
    "hoverClosestGl2d",
    "hoverClosestPie",
    "toggleHover",
    "toImage",
    "resetViews",
    "toggleSpikelines"
  ];

  // mobile devices won't work with the graph controls
  // so we'll remove them all if the screen is to small.
  if (windowSize < breakpoints.large) {
    removeButtons.push(
      "zoom2d",
      "pan2d",
      "zoomIn2d",
      "zoomOut2d",
      "autoScale2d",
      "resetScale2d"
    );
  } else {
    // Hides all buttons that won't work when the data feed is live.
    if (live) {
      removeButtons.push("zoom2d", "pan2d", "zoomIn2d", "zoomOut2d");

      // The heat-map has forced auto range in both dimensions, these buttons won't do anything so we'll hide them
      if (type === ChartTypes.HeatMap) {
        removeButtons.push("autoScale2d", "resetScale2d");
      }
    }
  }

  const config = {
    displayModeBar: true,
    displaylogo: false,
    modeBarButtonsToRemove: removeButtons
  };

  fiber.kp.forEach((kp: number, i: number, kpArray: Array<number>) => {
    kpArray[i] = parseFloat(kp.toFixed(getPrecision(kp)));
  });

  /* eslint-disable @typescript-eslint/camelcase */
  /* eslint-disable no-magic-numbers*/
  const layout: any = {
    title: null,
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    autotypenumbers: "strict",
    autosize: true,
    height,
    showlegend: false,
    dragmode: false,
    hovermode: "closest",
    hoverlabel: {
      bgcolor: color.mineShaft,
      bordercolor: color.mineShaft,
      font: {
        color: color.silverChalice
      }
    },
    xaxis: {
      autorange: true,
      ...formatTickDates(timeLine, ONE_MINUTE_IN_MS, tick =>
        graphDate(
          tick,
          activeOrg && {
            timezoneAbbr: activeOrg.timezoneAbbr,
            utcOffset: activeOrg.utcOffset
          }
        )
      ),
      ...xAxis
    },
    yaxis: {
      autorange: autoRange,
      ...formatKPChRange(unit, postRange, fiber),
      ...yAxis
    },
    margin: {
      l: 50,
      r: 40,
      t: 35,
      b: 50
    },
    font: {
      color: color.silverChalice
    }
  };
  /* eslint-enable no-magic-numbers*/
  /* eslint-enable @typescript-eslint/camelcase */

  const [preservedLayout, setPreservedLayout] = useState<Partial<Layout>>(
    layout
  );

  const preservePlotLayout = (figure: any) => {
    // only run this if there is a change to the layout.
    if (JSON.stringify(figure.layout) !== JSON.stringify(preservedLayout)) {
      // preserve all the current layout but override the `yaxis`'s `autorange` with our own
      setPreservedLayout({
        ...figure.layout,
        yaxis: {
          ...figure.layout.yaxis,
          autorange: yAxis.autorange !== undefined ? yAxis.autorange : autoRange
        }
      });
    }
  };

  const onRelayout = (layout: any) => {
    if (!autoRange && layout["yaxis.autorange"]) {
      // `autoRange` is off and the user has clicked the "Autoscale" button
      setAutoRange(true);
    } else if (autoRange && layout["yaxis.range[0]"] !== undefined) {
      // `autoRange` is on and the user has clicked the "Reset Axes" button
      setAutoRange(false);
    }
  };

  const timeFrame = layout.xaxis.tickvals.length
    ? [Math.min(...layout.xaxis.tickvals), Math.max(...layout.xaxis.tickvals)]
    : [0, 0];

  const precision = 2;
  const rangeStart = 0;
  const getDataMax = () => {
    if (type === ChartTypes.HeatMap) {
      return Math.max(...[].concat(...data[0].z));
    } else {
      return 0;
    }
  };
  const rangeEnd = Number(getDataMax().toFixed(precision));
  const maxRange = rangeEnd - rangeStart;
  const stepCount = 50;
  const rangeStep = maxRange / stepCount;
  // const minRange = Number(rangeStep.toFixed(2));
  const minRange = rangeStep;
  const initialEndValue = rangeEnd;
  const [startValue, setStartValue] = useState(rangeStart);
  const [endValue, setEndValue] = useState(rangeEnd);
  const handleShadingChange = (
    sliderStart: number,
    sliderEnd: number,
    reset = false
  ) => {
    if (onShadingChange !== undefined) {
      let start: number, end: number;
      if (reset === true) {
        start = 0;
        end = Number(getDataMax().toFixed(precision));
      } else {
        start = sliderStart;
        end = sliderEnd;
      }
      setStartValue(start);
      setEndValue(end);
      onShadingChange(start, end, reset);
      setPreservedLayout({ ...layout });
    }
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.TitleWrapper}>
        <Heading size="h6" className={styles.Title}>
          {title}
        </Heading>
        <StatusIndicator
          live={live}
          connected={connected}
          onClick={onPauseToggle}
        />
      </div>
      {timeFrame !== undefined && (
        <TimeTicker
          startTimestamp={timeFrame[0]}
          endTimestamp={timeFrame[1]}
          isLive={connected && live}
        />
      )}
      {connected && live && onTypeChange !== undefined && (
        <div className={styles.ChartTypeWrapper}>
          <Text component="span" size="small">
            {type === ChartTypes.HeatMap ? "Color Map" : "Line Chart"}{" "}
            &bull;&nbsp;
          </Text>
          <TextLink
            buttonSize="small"
            onClick={() => {
              onTypeChange(
                type === ChartTypes.HeatMap
                  ? ChartTypes.ScatterLines
                  : ChartTypes.HeatMap
              );
            }}
          >
            View {type === ChartTypes.HeatMap ? "Line Chart" : "Color Map"}
          </TextLink>
        </div>
      )}
      <Meta component="span" className={styles.SubTitle}>
        {subTitle}
      </Meta>
      {connected && type === ChartTypes.HeatMap ? (
        <div className={styles.ShadingSelector}>
          <ShadingSelector
            key="range"
            precision={precision}
            minRange={minRange}
            maxRange={maxRange}
            rangeStep={rangeStep}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            startValue={startValue}
            endValue={endValue > 0 ? endValue : initialEndValue}
            onShadingChange={handleShadingChange}
          />
        </div>
      ) : (
        undefined
      )}
      <Plot
        useResizeHandler
        className={cx(styles.Plot, { [styles.Loading]: loading })}
        config={config}
        layout={live ? layout : preservedLayout}
        data={data}
        onInitialized={preservePlotLayout}
        onUpdate={preservePlotLayout}
        onRelayout={onRelayout}
      />
      <LoadingSpinnerWrapper
        className={styles.Loader}
        loading={loading ? true : false}
      />
    </div>
  );
};

export default BaseChart;

/* eslint-enable @typescript-eslint/no-explicit-any */
/* cSpell:enable */
