import { useCallback, useEffect, useState } from "react";
import { gqlType } from "@hifieng/common";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { ISummaryQueryResponse } from "../types/AnalysisTypes";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  appendSummaryData,
  digestSummaryData,
  timeRangeFromData
} from "../helpers/summaryData";
import { timeFromUnitToMs } from "../helpers/time";
import {
  MILLISECONDS_IN_SECONDS,
  SECONDS_PER_MINUTE
} from "../helpers/constants";
import { ChartTypes } from "../components/AnalysisCharts";

type Props = {
  organizationId: string;
  filters: gqlType.SummaryFiltersInput;
  pollInterval: number;
  gapToleranceMS?: number;
  queryBufferInSeconds?: number;
  initialState?: ISummaryQueryResponse;
  initialTimeRange: number;
  chartType: ChartTypes;
};

export const INITIAL_SUMMARY_STATE: ISummaryQueryResponse = {
  loading: true,
  data: []
};

const SUMMARY_QUERY = gql`
  query summaryData($organizationId: String!, $filters: SummaryFiltersInput!) {
    summaryData(organizationId: $organizationId, filters: $filters) {
      channel
      measureValueDouble
      time
    }
  }
`;

const useSummaryData = ({
  organizationId,
  filters,
  pollInterval,
  gapToleranceMS = 0,
  queryBufferInSeconds = SECONDS_PER_MINUTE,
  initialState,
  initialTimeRange
}: Props) => {
  const defaultMaxRange = 100;
  const [connected, setConnected] = useState(false);
  const [live, setLive] = useState(true);
  const [timeRange, setTimeRange] = useState([0, defaultMaxRange]);
  const [rawData, setRawData] = useState<Array<gqlType.SummaryData>>([]);
  const [summaryData, setSummaryData] = useState<ISummaryQueryResponse>(
    initialState || INITIAL_SUMMARY_STATE
  );

  // This initial query will be expensive but the subsequent subscription should be fairly cheap I think.
  const initialSummaryQuery = useQuery(SUMMARY_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      organizationId,
      filters: {
        ...filters,
        endTime: 1 // "now" (or 0) is too soon. Let's add a 1 second delay to
        // help prevent gaps due to "eventual consistency"
      }
    }
  });

  // The subsequent query, runs on an interval of `pollInterval` and fetches the last 1 min of data.
  const [startLivePolling, liveSummaryQuery] = useLazyQuery(SUMMARY_QUERY, {
    fetchPolicy: "cache-and-network",
    pollInterval,
    variables: {
      organizationId,
      filters: {
        ...filters,
        timeUnit: gqlType.TimeUnits.Seconds,
        startTime: queryBufferInSeconds,
        endTime: 1 // "now" (or 0) is too soon. Let's add a 1 second delay to
        // help prevent gaps due to "eventual consistency"
      }
    }
  });

  const graphMaxTimelineWidthInMs = timeFromUnitToMs(
    filters.startTime,
    filters.timeUnit
  );

  const { refetch } = initialSummaryQuery;
  const { stopPolling } = liveSummaryQuery;

  const reconnect = useCallback(() => {
    // Stop live updates if they're running.
    if (stopPolling) stopPolling();
    // set the "connected" flag to "false", it will get turned back on once the initial query finishes
    setConnected(false);
    // sets the "live" flag to "false", it will get turned back on once live polling starts again
    setLive(false);
    // clear out the existing summary data.
    setSummaryData({ data: [], loading: true, error: undefined });
    // refetch the data to initially populate the chart. This will subsequently trigger the live updates to resume.
    refetch();
  }, [refetch, stopPolling]);

  const togglePause = useCallback(() => {
    if (live) {
      // We're currently "live" and this action will result in the data being "paused"
      // This is a test to see if the live polling has started yet
      if (liveSummaryQuery.data || liveSummaryQuery.loading) {
        // stop live polling
        stopPolling();
      }
      // set the live flag to "false" so the UI updates to say "paused"
      setLive(false);
    } else {
      // we're coming from a "paused" state, `reconnect` will start everything back up again.
      reconnect();
    }
  }, [
    live,
    liveSummaryQuery.data,
    liveSummaryQuery.loading,
    reconnect,
    stopPolling
  ]);

  // If the filters change then initiate a reconnect.
  useDeepCompareEffect(() => {
    reconnect();
  }, [filters]);

  // Initial connection
  useEffect(() => {
    const { loading, data, error } = initialSummaryQuery;

    if (error) {
      setSummaryData({ error, loading: false, data: [] });
    } else if (!loading && data && data.summaryData) {
      const digestedData = digestSummaryData(data.summaryData);

      setRawData(data.summaryData);

      setSummaryData({
        loading,
        error,
        data: digestedData
      });

      if (!connected) {
        setConnected(true);
        setTimeRange(
          timeRangeFromData(digestedData, graphMaxTimelineWidthInMs)
        );
        startLivePolling();
        setLive(true);
      }
    }
  }, [
    graphMaxTimelineWidthInMs,
    initialSummaryQuery,
    connected,
    pollInterval,
    startLivePolling
  ]);

  const { data, loading, error } = liveSummaryQuery;

  // Live updates
  useEffect(() => {
    if (live && !error && !loading) {
      if (data && data.summaryData && data.summaryData.length) {
        const digestedData = digestSummaryData(data.summaryData);

        setTimeRange(
          timeRangeFromData(digestedData, graphMaxTimelineWidthInMs)
        );

        const newSummaryData = appendSummaryData(
          summaryData.data,
          digestedData,
          initialTimeRange,
          gapToleranceMS
        );

        if (newSummaryData) {
          setRawData(prev => [...prev, ...data.summaryData]);
          setSummaryData({
            loading,
            error,
            data: newSummaryData
          });
        } else {
          reconnect();
        }
      }
    }
  }, [
    live,
    graphMaxTimelineWidthInMs,
    loading,
    summaryData.data,
    data,
    error,
    initialTimeRange,
    gapToleranceMS,
    reconnect
  ]);

  // This will crop the raw data to an `initialTimeRange` window of time from
  // the latest timestamp.
  useEffect(() => {
    if (rawData.length) {
      let changed = false;
      const newData: Array<gqlType.SummaryData> = [];

      rawData.forEach(d => {
        if (
          d.time >=
          timeRange[1] / MILLISECONDS_IN_SECONDS - initialTimeRange
        ) {
          newData.push(d);
        } else {
          changed = true;
        }
      });

      if (changed) {
        setRawData(newData);
      }
    }
  }, [initialTimeRange, timeRange, rawData]);

  return {
    onPauseToggle: togglePause,
    connected,
    live,
    ...summaryData,
    rawData,
    timeRange
  };
};

export default useSummaryData;
