import React, { useContext, useState, useEffect } from "react";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { ApolloError } from "apollo-boost";

import {
  pigsQueryComposer,
  PIG_COUNT_SUBSCRIPTION
} from "../../helpers/pigQueryComposer";
import { logErrorToLogdna } from "../../helpers/logdna";

type PropsType = {
  children: React.ReactNode;
};

export type PigCount = {
  active: number;
  completed: number;
};

interface IPigHeaderContext {
  pigCount: PigCount;
  loading?: boolean;
}
const initialPigContext: IPigHeaderContext = {
  pigCount: {
    active: 0,
    completed: 0
  }
};

const fragment = gql`
  fragment PigsFragment on PaginatedPigsAndTotal {
    active
    completed
  }
`;

export const PigHeaderContext = React.createContext(initialPigContext);
export const usePigHeaderContext = () => useContext(PigHeaderContext);

const PIG_COUNT_QUERY = pigsQueryComposer(fragment);

export const PigHeaderProvider = ({ children }: PropsType) => {
  const [pigCount, setPigCount] = useState<PigCount>(
    initialPigContext.pigCount
  );
  const { activeOrg } = useOrganizationContext();

  const { data, loading, subscribeToMore, refetch } = useQuery(
    PIG_COUNT_QUERY,
    {
      variables: {
        organizationId: activeOrg ? activeOrg.id : undefined
      },
      skip: !activeOrg,
      onError: (err: ApolloError) => {
        logErrorToLogdna(
          "GETTING_ACTIVE_PIGS",
          "Apollo error while getting active pigs.",
          err
        );
        throw err;
      }
    }
  );

  useEffect(() => {
    if (data) {
      const { active, completed } = data.pigs;
      setPigCount({ active, completed });
    }
  }, [data]);

  useEffect(() => {
    if (!activeOrg) {
      return;
    }
    subscribeToMore({
      document: PIG_COUNT_SUBSCRIPTION,
      variables: {
        organizationId: activeOrg.id
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        refetch();
      }
    });
    //suppressing hook dependency because `subscribeToMore` changes on every render
    // and the documentation suggest to only call it on component mount
    // https://www.apollographql.com/docs/react/data/subscriptions/#subscribing-to-updates-for-a-query
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOrg]);

  return (
    <PigHeaderContext.Provider
      value={{ pigCount, loading: loading || !activeOrg }}
    >
      {children}
    </PigHeaderContext.Provider>
  );
};

export default PigHeaderProvider;
