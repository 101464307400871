import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { gqlType } from "@hifieng/common";
import ReportRow from "../ReportRow";
import AppError from "../../../errors/AppError";
import styles from "./index.module.scss";
import { canIframePreview, isImage } from "../previewTypes";

type PropsType = {
  reports: Array<gqlType.Report>;
  error?: string;
  loading: boolean;
};

const ReportDetailsPage = ({ reports, error, loading }: PropsType) => {
  const match = useRouteMatch<{ reportName: string }>();
  const decodedReportName = decodeURIComponent(match.params.reportName);

  const report = reports.find(report => report.name === decodedReportName);
  const [cachedReport, setCachedReport] = useState<gqlType.Report>();

  useEffect(() => {
    if (!report) {
      throw new AppError(
        `No report exists with the name "${decodedReportName}"`
      );
    }

    if (!cachedReport || report.createdAt !== cachedReport.createdAt) {
      setCachedReport(report);
    }
  }, [report, cachedReport, decodedReportName]);

  if (loading || !cachedReport) {
    return null;
  }

  if (error) {
    throw new AppError(error);
  }

  const renderImage = isImage(cachedReport.contentType);
  const renderIframe = canIframePreview(cachedReport.contentType);

  return (
    <div className={styles.Content}>
      <ReportRow
        report={cachedReport}
        showPreviewButton={false}
        showCloseButton
      />
      {renderImage ? (
        <div className={styles.Preview}>
          <img
            src={cachedReport.uri}
            alt={`A preview of ${cachedReport.title}`}
            className={styles.PreviewImage}
          />
        </div>
      ) : null}
      {renderIframe ? (
        <div className={styles.Preview}>
          <iframe
            src={cachedReport.uri}
            title={`A preview of ${cachedReport.title}`}
            className={styles.Iframe}
          />
        </div>
      ) : null}
    </div>
  );
};
export default ReportDetailsPage;
