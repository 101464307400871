import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Pig = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      fill="none"
      viewBox="0 0 14 10"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H2.93543L3.63914 1.57598H7.03704V0H10.5757L11.9831 1.57598H13.6667V8.09192H11.9831L10.5757 9.6679H7.03704V8.09192H3.63914L2.93543 9.6679H0V0Z"
        fill="white"
      />
    </svg>
  );
};

export default Pig;
