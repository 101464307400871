import React from "react";
import ScreenLayout from "../ScreenLayout";
import PipelineHeartbeatDetails from "./PipelineHeartbeatDetails";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import { useLiveStatusContext } from "../../contexts/LiveStatusProvider";
import styles from "./index.module.scss";
import { sortByKey } from "../../helpers/sortByKey";

const HeartbeatPage = () => {
  const { activeOrg } = useOrganizationContext();
  const { liveHeartbeat } = useLiveStatusContext();

  const pipelines = liveHeartbeat
    ? liveHeartbeat.data.sort(sortByKey("name"))
    : [];
  const title = activeOrg ? activeOrg.name : "";

  return (
    <ScreenLayout title="Connection Status" subtitle={title}>
      <div className={styles.HeartbeatsList}>
        {pipelines.map(pipeline => {
          // Pipelines with interfaceOptions.hideHeartbeat set to true are hidden
          if (pipeline.interfaceOptions.hideHeartbeat) return null;

          return (
            <PipelineHeartbeatDetails
              key={pipeline.id}
              pipeline={pipeline}
              heartbeatStatus={liveHeartbeat.status}
            />
          );
        })}
      </div>
    </ScreenLayout>
  );
};

export default HeartbeatPage;
