import React from "react";

const ResetAudio = () => {
  return (
    <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 .344)">
        <path
          fill="currentColor"
          d="M5.71428571,4.48979592 L2.51836735,4.48979592 C4.14457212,1.99056964 7.22059416,0.858170599 10.0791223,1.70639854 C12.9376505,2.55462649 14.898149,5.18154199 14.8979592,8.16326531 L16.1224532,8.16326531 C16.125903,4.80848341 14.0254414,1.81171844 10.87072,0.670532241 C7.71599851,-0.470653959 4.18442388,0.488783154 2.04081633,3.06938776 L2.04081633,0.816326531 L0.816326531,0.816326531 L0.816326531,5.71428571 L5.71428571,5.71428571 L5.71428571,4.48979592 Z M10.6122449,11.8367347 L13.8081633,11.8367347 C12.1819585,14.335961 9.10593645,15.46836 6.24740828,14.6201321 C3.3888801,13.7719041 1.42838166,11.1449886 1.42857143,8.16326531 L0.204077379,8.16326531 C0.200627642,11.5180472 2.30108917,14.5148122 5.45581063,15.6559984 C8.6105321,16.7971846 12.1421067,15.8377475 14.2857143,13.2571429 L14.2857143,15.5102041 L15.5102041,15.5102041 L15.5102041,10.6122449 L10.6122449,10.6122449 L10.6122449,11.8367347 Z"
        />
      </g>
    </svg>
  );
};

export default ResetAudio;
