import { gqlType } from "@hifieng/common";

export const getStatusText = (status: string) => {
  let statusText = "Travelling";

  switch (status) {
    case gqlType.TrainStatus.ApproachingStation:
      statusText = "Approaching Station";
      break;
    case gqlType.TrainStatus.ApproachingPoi:
      statusText = "Approaching POI";
      break;
    case gqlType.TrainStatus.Detected:
      statusText = "Detected";
      break;
    case gqlType.TrainStatus.Moving:
      statusText = "Moving";
      break;
    case gqlType.TrainStatus.NotDetected:
      statusText = "No Movement Detected";
      break;
    case gqlType.TrainStatus.Stopped:
      statusText = "Stopped";
      break;
    case gqlType.TrainStatus.Completed:
      statusText = "Completed";
      break;
  }
  return statusText;
};
