import React, { useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";

import ListDetailsSection from "../ListDetailsSection";
import MobileFilters from "./MobileFilters";
import EventsList from "../EventsList";
import HistoryFiltersProvider from "./HistoryFiltersProvider";
import DesktopFilters from "./DesktopFilters";
import { useEvents } from "../../hooks/useEvents";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import { useQueryParams } from "../../hooks/useQueryParams";
import { eventsLimit, offset } from "../../helpers/pagination";
import styles from "./index.module.scss";

import EventMap from "../EventMap";
import { useLiveStatusContext } from "../../contexts/LiveStatusProvider";
import { useMemoizedTimes } from "../../hooks/useMemorizedTimes";
import { ITabContent } from "../TabbedContent";

const HistoryPage = ({ onActivated }: ITabContent) => {
  const match = useRouteMatch();
  const slugMatch = useRouteMatch<{ referenceId?: string }>(
    `${match.url}/:referenceId`
  );
  const history = useHistory();
  const { appStatus } = useLiveStatusContext();

  const { queryParams } = useQueryParams();
  const { activeOrg } = useOrganizationContext();
  const activePipelines = (activeOrg && activeOrg.pipelines) || [];
  const activePipelineId =
    activePipelines &&
    activePipelines.map((pipeline: { id: string }) => pipeline.id);
  const numericPage = parseInt(queryParams.page, 10);
  const page = Number.isInteger(numericPage) ? numericPage : 1;
  const paginationOffset = offset(page, eventsLimit);

  const { start, end } = queryParams;
  const timeRange = useMemoizedTimes(
    start,
    end,
    activeOrg && activeOrg.utcOffset
  );

  const selectedEventTypes = queryParams.types
    ? queryParams.types.split(",")
    : undefined;

  const selectedPipelines = queryParams.pipelines
    ? queryParams.pipelines.split(",")
    : activePipelineId;

  const { currentLiveEvents: historicalEvents, reFetchQuery } = useEvents({
    queryName: "historyEvents",
    filters: {
      archived: true,
      sortBy: queryParams.sortBy,
      timeRange,
      types: selectedEventTypes,
      pipelines: selectedPipelines,
      geoBounds: undefined,
      limit: eventsLimit,
      offset: paginationOffset
    }
  });
  const isLoading = historicalEvents.status === "loading" || !activeOrg;

  useEffect(() => {
    if (appStatus.needUpdate) {
      reFetchQuery();
    }
  }, [appStatus.needUpdate, reFetchQuery]);

  const selectedEvent =
    slugMatch && slugMatch.params ? slugMatch.params.referenceId : undefined;

  const selectEvent = (referenceId: string) => {
    history.push(`${match.url}/${referenceId}${history.location.search}`);
  };

  if (onActivated) onActivated({ title: "historical" });

  return (
    <HistoryFiltersProvider>
      <div className={styles.ListMapWrapper}>
        <ListDetailsSection
          emptyStateText="No Alerts"
          totalEvents={historicalEvents.totalEvents}
          selectedEvent={selectedEvent}
          listComponent={
            <EventsList
              events={historicalEvents ? historicalEvents.events : []}
              selectedEvent={selectedEvent}
              setSelectedEvent={selectEvent}
            />
          }
          loading={isLoading}
          mapComponent={
            <EventMap
              canSearchOnMapMove={false}
              events={historicalEvents.events}
              isSearching={isLoading}
              scrollZoom
            />
          }
          headerComponent={
            <>
              <MobileFilters
                eventsCount={historicalEvents.events.length}
                totalEvents={historicalEvents.totalEvents}
              />
              <DesktopFilters
                eventsCount={historicalEvents.events.length}
                totalEvents={historicalEvents.totalEvents}
              />
            </>
          }
        />
      </div>
    </HistoryFiltersProvider>
  );
};

export default HistoryPage;
