import React from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { Switch, Route } from "react-router";
import { gqlType } from "@hifieng/common";
import ReportsPage from "./ReportsPage";
import ReportDetailsPage from "./ReportDetailsPage";
import { QUICK_POLL_INTERVAL_MS } from "../../helpers/constants";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import ScreenLayout from "../ScreenLayout";
import { useModalContext } from "../../contexts/ModalProvider";
import { useAuth } from "../../contexts/AuthProvider";
import styles from "./index.module.scss";
import { Tertiary } from "../Button";
import MobilePageTitle from "../MobilePageTitle";
import UploadModal from "./UploadModal";
import { sortByKey } from "../../helpers/sortByKey";
import { logErrorToLogdna } from "../../helpers/logdna";
import { ApolloError } from "apollo-boost";

const ERROR_CODES = {
  gettingReports: "GETTING_REPORTS"
};

export const GET_REPORTS = gql`
  query reports($organizationIds: [String!]!) {
    reports(organizationIds: $organizationIds) {
      id
      title
      name
      sizeInByte
      uri
      createdAt
      contentType
    }
  }
`;

const UploadButton = () => {
  const { permissions } = useAuth();
  const { setOpenModal } = useModalContext();

  if (permissions.user["write-reports"]) {
    return (
      <Tertiary
        onClick={() => setOpenModal("upload-report")}
        className={styles.UploadDesktop}
      >
        Upload Report
      </Tertiary>
    );
  } else return null;
};

const ReportsRoute = () => {
  const { activeOrg } = useOrganizationContext();

  const [getReports, { data, refetch, error, loading }] = useLazyQuery<{
    reports: Array<gqlType.Report>;
  }>(GET_REPORTS, {
    pollInterval: QUICK_POLL_INTERVAL_MS,
    onError: (error: ApolloError) => {
      logErrorToLogdna(
        ERROR_CODES.gettingReports,
        "Apollo error while getting reports.",
        error
      );
      return error;
    }
  });

  React.useEffect(() => {
    if (activeOrg) {
      getReports({
        variables: {
          organizationIds: [activeOrg.id]
        }
      });
    }
  }, [activeOrg, getReports]);

  let sortedReports;
  if (data) {
    sortedReports = data.reports.sort(sortByKey("createdAt", true));
  }

  const isLoading = !activeOrg || loading || !data;

  return (
    <ScreenLayout title="Reports" subtitle={<UploadButton />}>
      <MobilePageTitle
        page="Reports"
        subtitle={<div className={styles.ButtonWrap}>{<UploadButton />}</div>}
      />
      <Switch>
        <Route exact path="/reports">
          <ReportsPage
            reports={sortedReports ? sortedReports : []}
            error={error ? error.message : undefined}
            loading={isLoading}
          />
        </Route>
        <Route path="/reports/:reportName">
          {sortedReports ? (
            <ReportDetailsPage
              reports={sortedReports}
              error={error ? error.message : undefined}
              loading={isLoading}
            />
          ) : null}
        </Route>
      </Switch>
      <UploadModal onSuccess={refetch} />
    </ScreenLayout>
  );
};

export default ReportsRoute;
