import React from "react";
import { Arrow } from "../Icons";
import { Text } from "../Type";
import cx from "classnames";
import styles from "./index.module.scss";
import { OptionType } from "../../types/GeneralTypes";

type Props = {
  open: boolean;
  buttonProps?: { [key: string]: string };
  onClick?: () => void;
  labelProps?: { [key: string]: string };
  selectedItem: OptionType;
  maxWidth?: number;
  small?: boolean;
};

const DropdownButton = ({
  open,
  buttonProps,
  onClick,
  selectedItem,
  maxWidth,
  small
}: Props) => {
  return (
    <button
      type="button"
      className={cx(styles.DropdownButton, {
        [styles.DropdownButtonSelected]:
          selectedItem.value && selectedItem.value !== "All",
        [styles.DropdownButtonSmall]: small
      })}
      style={{ maxWidth: `${maxWidth}px` }}
      onClick={onClick}
      {...buttonProps}
    >
      <div className={styles.DropdownMainContent}>
        {selectedItem.icon && (
          <div className={styles.IconWrapper}>{selectedItem.icon}</div>
        )}
        <Text
          component="span"
          className={cx(
            styles.DropdownLabel,
            selectedItem.altText &&
              maxWidth === undefined &&
              styles.DropdownLabelWithAltText
          )}
        >
          {selectedItem.label}
        </Text>
      </div>
      <div className={styles.AltTextWrapper}>
        {selectedItem.altText && (
          <Text component="span" className={styles.DropdownAltText} dark>
            {selectedItem.altText}
          </Text>
        )}
        <span
          className={cx(styles.Icon, {
            [styles.IconOpen]: open,
            [styles.IconSmall]: small
          })}
        >
          <Arrow />
        </span>
      </div>
    </button>
  );
};

export default DropdownButton;
