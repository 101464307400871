import React from "react";

const Pause = () => {
  return (
    <svg width="10px" height="14px" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(0 .344)">
        <path
          fill="currentColor"
          d="M1.5,0.375 C1.81379815,0.375 2.07358314,0.606257877 2.1182234,0.907642081 L2.125,1 L2.125,14 C2.125,14.345178 1.84517797,14.625 1.5,14.625 C1.18620185,14.625 0.926416865,14.3937421 0.881776605,14.0923579 L0.875,14 L0.875,1 C0.875,0.654822031 1.15482203,0.375 1.5,0.375 Z"
        />
        <path
          fill="currentColor"
          d="M9,0.375 C9.31379815,0.375 9.57358314,0.606257877 9.6182234,0.907642081 L9.625,1 L9.625,14 C9.625,14.345178 9.34517797,14.625 9,14.625 C8.68620185,14.625 8.42641686,14.3937421 8.3817766,14.0923579 L8.375,14 L8.375,1 C8.375,0.654822031 8.65482203,0.375 9,0.375 Z"
        />
      </g>
    </svg>
  );
};

export default Pause;
