import React from "react";
import { EventPriorityType } from "../../../types/EventTypes";
import { priorityColorMap } from "../../../styles/colors";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
  priority?: EventPriorityType;
};

const MapCluster = ({ size = DEFAULT_SIZE, priority = "low" }: PropsType) => {
  let colorHex = "#FFF";
  if (priority !== "low") {
    colorHex = priorityColorMap[priority];
  }

  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 44 44`}
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="6"
      transform=""
    >
      <g
        stroke={colorHex}
        strokeWidth="6"
        transform="translate(6 6)"
        fill="none"
        fillRule="evenodd"
      >
        <circle strokeOpacity=".5" fill={colorHex} cx="16" cy="16" r="16" />
        <circle strokeOpacity=".25" cx="16" cy="16" r="19" />
      </g>
    </svg>
  );
};

export default MapCluster;
