import React from "react";
import { gqlType } from "@hifieng/common";

import { Meta, Text } from "../../Type";
import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";
import { coordWeldMap } from "../../../helpers/coordWeldMap";
import styles from "./index.module.scss";
import DateTime from "../../DateTime";
import { kpToMp } from "../../AnalysisCharts/BaseChart";
import { useAuth } from "../../../contexts/AuthProvider";

type Props = {
  pig: gqlType.PigRun;
  update: gqlType.PigRunUpdate;
  nextPoi?: string;
  estimatedArrival?: number;
};

const PigInfo = ({ pig, update, nextPoi, estimatedArrival }: Props) => {
  const { permissions } = useAuth();
  const COORDINATE_DIGITS = 6;
  const coordinatesString = `${update.coordinates.latitude.toFixed(
    COORDINATE_DIGITS
  )},${update.coordinates.longitude.toFixed(COORDINATE_DIGITS)}`;

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          RUN ID
        </Meta>
        <Text className={styles.Text} component="span" size="small">
          {pig.pipeline.name} - Run {pig.runId}
        </Text>
      </div>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          {(permissions.user.imperial ? true : false) ||
          pig.pipelineId === "enbridge_line_3" ||
          pig.pipelineId === "enbridge_line_4"
            ? "Mile Post"
            : "KM Post"}
        </Meta>
        <Text className={styles.Text} component="span" size="small">
          {(permissions.user.imperial ? true : false) ||
          pig.pipelineId === "enbridge_line_3" ||
          pig.pipelineId === "enbridge_line_4"
            ? `MP ${kpToMp(update.post)}`
            : `KP ${update.post}`}
        </Text>
      </div>
      {pig.pipeline.id === "suncor_icp" ? (
        <div className={styles.Row}>
          <Meta component="h5" size="small" dark>
            Weld ID
          </Meta>
          {event && (
            <Text className={styles.Text} component="span" size="small">
              {coordWeldMap(coordinatesString)}
            </Text>
          )}
        </div>
      ) : (
        undefined
      )}
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          Timestamp
        </Meta>
        <Text className={styles.Text} component="span" size="small">
          <DateTime
            timestamp={update.timestamp * MILLISECONDS_IN_SECONDS}
            length="long"
          />
        </Text>
      </div>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          Lat/Lng
        </Meta>
        <a
          href={`https://maps.google.com/maps?q=loc:${update.coordinates.latitude},${update.coordinates.longitude}`}
          className={styles.Link}
          target="__blank"
        >
          <Text className={styles.Text} component="span" size="small">
            {update.coordinates.latitude}, {update.coordinates.longitude}
          </Text>
        </a>
      </div>
      <hr className={styles.Divider} />
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          Speed
        </Meta>
        <Text className={styles.Text} component="span" size="small">
          {update.speed}m/second
        </Text>
      </div>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          Next POI
        </Meta>
        <Text className={styles.TextPoi} component="span" size="small">
          {nextPoi || "N/A"}
        </Text>
      </div>
      {estimatedArrival && (
        <div className={styles.Row}>
          <Meta component="h5" size="small" dark>
            Arrival (EST.)
          </Meta>
          <Text className={styles.Text} component="span" size="small">
            <DateTime
              timestamp={estimatedArrival * MILLISECONDS_IN_SECONDS}
              length="long"
            />
          </Text>
        </div>
      )}
    </div>
  );
};

export default PigInfo;
