import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Text } from "../Type";
import styles from "./index.module.scss";

type PropsType = {
  value?: string;
  onChange?: (value: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  errorMessage?: string;
  successMessage?: string;
  supportMessage?: string;
  label: string;
  hideLabel?: boolean;
  disabled?: boolean;
  size?: "regular" | "small";
  maxLength?: number;
};

const TextInput = ({
  value,
  placeholder,
  errorMessage,
  successMessage,
  supportMessage,
  onChange,
  label,
  hideLabel,
  disabled,
  size = "regular",
  maxLength,
  onFocus
}: PropsType) => {
  const [message, setMessage] = useState<{ text: string; type: string }>({
    text: "",
    type: ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage({
      text: supportMessage || "",
      type: ""
    });
    if (onChange) {
      onChange(e.target.value);
    }
  };

  useEffect(() => {
    const updatedMessage = {
      text: errorMessage || successMessage || supportMessage || "",
      type: errorMessage ? "error" : successMessage ? "success" : "support"
    };
    if (updatedMessage.text && updatedMessage.type) {
      setMessage(updatedMessage);
    }
  }, [errorMessage, successMessage, supportMessage]);

  return (
    <label className={styles.Wrapper}>
      {!hideLabel ? (
        <Text className={cx(styles.Label, { [styles.Disabled]: disabled })}>
          {label}
        </Text>
      ) : null}
      <input
        aria-label={hideLabel ? label : undefined}
        className={cx(styles.Input, {
          [styles.InputError]: message.type === "error",
          [styles.InputSuccess]: message.type === "success",
          [styles.InputSmall]: size === "small"
        })}
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        maxLength={maxLength}
        onFocus={onFocus}
      />
      {message.text && (
        <span
          className={cx(styles.Message, {
            [styles.MessageError]: message.type === "error",
            [styles.MessageSuccess]: message.type === "success"
          })}
        >
          {message.text}
        </span>
      )}
    </label>
  );
};

export default TextInput;
