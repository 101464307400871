import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Ellipses = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="2" fill="currentColor" />
      <circle cx="19" cy="12" r="2" fill="currentColor" />
      <circle cx="5" cy="12" r="2" fill="currentColor" />
    </svg>
  );
};

export default Ellipses;
