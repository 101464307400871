import React from "react";
import { gqlType } from "@hifieng/common";
import { Marker } from "react-google-maps";

import getEncodedIconByType from "./getEncodedIconByType";

type PropsType = {
  assets: Array<gqlType.Asset>;
  iconSize: number;
};

// eslint-disable-next-line no-magic-numbers
const half = (num: number) => num * 0.5;

const AssetMarkers = ({ assets, iconSize }: PropsType) => {
  return (
    <>
      {assets &&
        assets.map(asset => {
          const encodedIcon = getEncodedIconByType(
            iconSize,
            asset.type,
            "asset"
          );

          const icon = {
            url: encodedIcon,
            anchor: new window.google.maps.Point(half(iconSize), half(iconSize))
          };

          return (
            <Marker
              key={asset.id}
              position={{
                lat: asset.coordinates.latitude,
                lng: asset.coordinates.longitude
              }}
              icon={icon}
              zIndex={11}
              clickable={false}
            />
          );
        })}
    </>
  );
};

export default AssetMarkers;
