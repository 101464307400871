// Plotly creates a ton of spelling issues so we're going to turn off the spell
// checker for the whole document.
/* cSpell:disable */

import React from "react";
import { axisDefaults, IChartProps } from ".";
import BaseChart from "./BaseChart";
import { color } from "../../styles/colors";
import { useOrganizationContext } from "../../contexts/OrganizationProvider";
import { IPlotlyTrace } from "../../types/AnalysisTypes";
import { formatTime } from "../../helpers/formatDate";
import { channelToKPost } from "../../helpers/analysisFunctions";

const ScatterLines = ({
  data,
  timeRange,
  postRange,
  unit,
  fiber,
  ...props
}: IChartProps) => {
  const { activeOrg } = useOrganizationContext();

  const formatTraceName = (traceName: string) => {
    if (unit === 0) {
      return `KP: ${channelToKPost(fiber, parseInt(traceName, 10)) || 0}`;
    }
    return `Channel: ${traceName}`;
  };

  const formatTraceText = (trace: IPlotlyTrace) => {
    if (activeOrg && trace.text) {
      return trace.text.map(t => {
        const name = trace.name ? `${formatTraceName(trace.name)}<br>` : "";
        const values = t.split("|");
        const time = formatTime(parseInt(values[0], 10), {
          timezoneAbbr: activeOrg.timezoneAbbr,
          utcOffset: activeOrg.utcOffset
        });
        return `${name}Time: ${time}<br>Value: ${values[1]}`;
      });
    }
    return undefined;
  };

  const xAxis = {
    ...axisDefaults,
    range: timeRange
  };

  const yAxis = {
    ...axisDefaults,
    range: [postRange[0] - 1, postRange[1] + 1]
  };

  return (
    <BaseChart
      {...props}
      postRange={postRange}
      unit={unit}
      fiber={fiber}
      timeLine={data.length ? data[0].x : []}
      xAxis={xAxis}
      yAxis={yAxis}
      data={data.map((trace: IPlotlyTrace) => {
        return {
          type: "scatter",
          mode: "lines",
          hovertemplate: "%{text}<extra></extra>",
          marker: { color: trace.color || color.blueRibbon },
          ...trace,
          name: undefined,
          text: trace.text && formatTraceText(trace)
        };
      })}
    />
  );
};

export default ScatterLines;

/* cSpell:enable */
