import React from "react";

const DEFAULT_SIZE = 14;

type PropsType = {
  size?: number;
};

const Power = ({ size = DEFAULT_SIZE }: PropsType) => {
  return (
    <svg width={`${size}px`} height={`${size}px`} viewBox="0 0 16 16">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M11.00646 1.95954l.57709.33319c2.80927 1.62193 4.1789 4.92853 3.33933 8.06186-.83957 3.13334-3.67901 5.31212-6.92288 5.31212-3.24387 0-6.0833-2.17878-6.92288-5.31212-.81625-3.0463.45568-6.25636 3.10858-7.92284l.23075-.13902.5771-.33319 1.16732 2.01948-.57768.33353C3.68856 5.4064 2.76486 7.63645 3.33108 9.74962 3.8973 11.8628 5.81228 13.33221 8 13.33221s4.1027-1.46941 4.66892-3.58259c.5467-2.04031-.29555-4.18955-2.0596-5.31991l-.1925-.11716-.57769-.33353 1.16733-2.01948zM9.16674.3333v8.33383H6.83326V.3333h2.33348z"
      />
    </svg>
  );
};

export default Power;
