import React, { useState } from "react";
import cx from "classnames";

import { Close, Check, ChevronDown, ChevronUp } from "../Icons";
import { Text, Meta } from "../Type";

import { sortByKey } from "../../helpers/sortByKey";

import {
  IHeartbeatsPipeline,
  HeartbeatStatusType,
  HeartbeatSegmentType
} from "../../types/HeartbeatTypes";

import styles from "./pipelineHeartbeatDetails.module.scss";

import { kpToMp } from "../AnalysisCharts/BaseChart";
import { useAuth } from "../../contexts/AuthProvider";

type PropsType = {
  pipeline: IHeartbeatsPipeline;
  heartbeatStatus: HeartbeatStatusType;
};

const SegmentList = ({
  disconnectedSegments,
  isDisconnected,
  isPartiallyConnected,
  isError,
  open
}: {
  disconnectedSegments: Array<HeartbeatSegmentType>;
  isDisconnected: boolean;
  isPartiallyConnected: boolean;
  isError: boolean;
  open: boolean;
}) => {
  const { permissions } = useAuth();

  if (isError) {
    return null;
  }

  if (isDisconnected) {
    return (
      <div
        className={cx(styles.SegmentList, {
          [styles.SegmentListOpen]: open
        })}
      >
        <Text className={styles.SegmentItemDisconnectedText}>
          All segments disconnected
        </Text>
      </div>
    );
  }

  return (
    <div
      className={cx(styles.SegmentList, {
        [styles.SegmentListOpen]:
          open && (isDisconnected || isPartiallyConnected)
      })}
    >
      {disconnectedSegments.map(segment => {
        return (
          <Text
            key={segment.id}
            component="span"
            className={styles.SegmentItem}
            disambiguation
          >
            {(permissions.user.imperial ? true : false) ||
            segment.id === "enbridge_line_3_segment_1" ||
            segment.id === "enbridge_line_4_segment_1"
              ? `MP ${kpToMp(segment.post)}`
              : `KP ${segment.post}`}{" "}
            / {segment.id}
          </Text>
        );
      })}
    </div>
  );
};

const PipelineHeartbeatDetails = ({ pipeline, heartbeatStatus }: PropsType) => {
  const [open, setOpen] = useState(false);
  const disconnectedSegments = pipeline.segments
    .filter(segment => {
      return !segment.connected;
    })
    .sort(sortByKey("post"));

  const segmentsCount = pipeline.segments.length;
  const disconnectedCount = disconnectedSegments.length;

  const isError = heartbeatStatus === "error";
  const isPartiallyConnected =
    disconnectedCount > 0 && disconnectedCount < segmentsCount;
  const isDisconnected = disconnectedCount === segmentsCount;
  const showOpenButton = !isError && (isDisconnected || isPartiallyConnected);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={cx(styles.Pipeline)}>
      <div
        className={cx(styles.PipelineHeader, {
          [styles.PipelineHeaderPartiallyConnected]: isPartiallyConnected,
          [styles.PipelineHeaderDisconnected]: isDisconnected,
          [styles.PipelineHeaderError]: isError
        })}
      >
        {showOpenButton && (
          <button
            type="button"
            className={styles.PipelineButton}
            onClick={toggleOpen}
          />
        )}
        <Text
          className={cx(styles.CircleIcon, {
            [styles.CircleIconPartiallyConnected]: isPartiallyConnected,
            [styles.CircleIconDisconnected]: isDisconnected,
            [styles.CircleIconError]: isError
          })}
          component="span"
        >
          {!isDisconnected && !isPartiallyConnected && <Check />}
          {isPartiallyConnected && disconnectedCount}
          {isDisconnected && <Close size={12} />}
        </Text>
        <div className={styles.Info}>
          <Meta size="small" className={styles.InfoSegments}>
            {`${segmentsCount} ${segmentsCount === 1 ? "Segment" : "Segments"}`}
          </Meta>
          <Text size="large" component="span" className={styles.InfoName}>
            {pipeline.name}
          </Text>
        </div>
        {showOpenButton && (
          <span className={styles.Toggle}>
            {open ? <ChevronUp /> : <ChevronDown />}
          </span>
        )}
      </div>
      <SegmentList
        disconnectedSegments={disconnectedSegments}
        isDisconnected={isDisconnected}
        isPartiallyConnected={isPartiallyConnected}
        isError={isError}
        open={open}
      />
    </div>
  );
};

export default PipelineHeartbeatDetails;
