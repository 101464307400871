import React from "react";
import cx from "classnames";
import { OptionType } from "../../types/GeneralTypes";

import { Text, Heading } from "../Type";

import styles from "./index.module.scss";

type PropsType = {
  label?: string;
  name: string;
  options: Array<OptionType>;
  checkboxValue: Array<string>; // The current values selected for this group
  onChange: (val: string) => void;
  noPad?: boolean;
  small?: boolean;
  noCapitalize?: boolean;
};

/* CheckboxGroup is a visual component that renders out a list of `checkbox` type inputs within a group.
 * It is intentionally dumb and takes an `onChange` function that determines what to do when the user interacts.
 * This allows complex logic like triggering actions or updates to other inputs based on interaction with this component.
 * All logic for form state exists at the level of the component implementing this component.
 */

const CheckboxGroup = ({
  options,
  name,
  checkboxValue,
  onChange,
  label,
  noPad,
  small,
  noCapitalize
}: PropsType) => {
  return (
    <fieldset>
      {label ? (
        <Heading className={styles.GroupLabel} size="h6" component="legend">
          {label}
        </Heading>
      ) : null}
      <div className={styles.CheckboxWrapper}>
        {options.map(option => (
          <label
            className={cx(styles.Checkbox, {
              [styles.CheckboxNoPad]: noPad
            })}
            key={option.value.toString()}
          >
            {checkboxValue.includes(option.value) ? (
              <CheckboxSelected small={small} />
            ) : (
              <Checkbox small={small} />
            )}
            <input
              className={styles.CheckboxInput}
              name={name}
              type="checkbox"
              value={option.value.toString()}
              aria-checked={checkboxValue.includes(option.value)}
              checked={checkboxValue.includes(option.value)}
              onChange={e => onChange(e.target.value)}
            />
            <Text
              size={small ? "small" : "regular"}
              className={cx(
                styles.CheckboxLabel,
                {
                  [styles.CheckboxLabelSelected]: checkboxValue.includes(
                    option.value
                  )
                },
                { [styles.CheckboxLabelNoCapitalize]: noCapitalize }
              )}
            >
              {option.label}
            </Text>
          </label>
        ))}
      </div>
    </fieldset>
  );
};

export default CheckboxGroup;

const CheckboxSelected = ({ small }: { small?: boolean }) => {
  return (
    <svg
      width={small ? "15" : "18"}
      height={small ? "15" : "18"}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <rect
          stroke="#FFF"
          strokeWidth="2.5"
          fill="#FFF"
          x="1"
          y="1"
          width="16"
          height="16"
          rx="1"
        />
        <g transform="translate(3 5)">
          <path
            stroke="#161616"
            strokeWidth=".8"
            fill="#000"
            d="M4.667 6.6L1.133 3.067.4 3.733l3.533 3.534.734.733L11.733.933 11 .2z"
          />
        </g>
      </g>
    </svg>
  );
};

const Checkbox = ({ small }: { small?: boolean }) => {
  return (
    <svg
      width={small ? "15" : "18"}
      height={small ? "15" : "18"}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="16"
        height="16"
        rx="1"
        stroke="#FFF"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};
