import { gqlType } from "@hifieng/common";
import { IPlotlyTrace } from "../types/AnalysisTypes";
import { MILLISECONDS_IN_SECONDS, ONE_MINUTE_IN_MS } from "./constants";
import { snap } from "./math";

export const digestSummaryData = (
  summaryData: Array<gqlType.SummaryData>
): Array<IPlotlyTrace> => {
  const digested: Array<{
    name: string;
    x: Array<number>;
    y: Array<number>;
    text: Array<string>;
  }> = [];

  // group the new data by channel
  summaryData.forEach(dp => {
    const time = dp.time * MILLISECONDS_IN_SECONDS;

    // create the channel if it doesn't exist
    if (!digested[dp.channel]) {
      digested[dp.channel] = {
        name: `${dp.channel}`,
        x: [],
        y: [],
        text: []
      };
    }

    // Push a new point to this channel's "trace" line
    // Note: we add the channel to the value in order to ensure this "trace"
    // sits on it's own xAxis and doesn't overlap other channels
    digested[dp.channel].x.push(time);
    digested[dp.channel].y.push(dp.channel + dp.measureValueDouble);
    digested[dp.channel].text.push(`${time}|${dp.measureValueDouble}`);
  });

  // we need to reset the channel indexes, filter will handle this for us.
  return digested.filter(trace => {
    return trace;
  });
};

// Removes data older than `lifetimeInSeconds`
export const dropOldData = (data: IPlotlyTrace, lifetimeInSeconds: number) => {
  const cutOffTime =
    Math.max(...data.x) - lifetimeInSeconds * MILLISECONDS_IN_SECONDS;

  data.x = data.x.filter((time, index) => {
    if (time < cutOffTime) {
      data.y.splice(index, 1);
      data.text.splice(index, 1);
      return false;
    }
    return true;
  });

  return data;
};

export const appendSummaryData = (
  base: Array<IPlotlyTrace>,
  append: Array<IPlotlyTrace>,
  lifetimeInSeconds: number,
  gapToleranceMS = 0
) => {
  append.forEach((trace, i) => {
    if (base[i]) {
      // copy of the new y axis data
      const cleanY: Array<number> = [];
      const hoverText: Array<string> = [];

      // copy of the new x axis data
      // We're going to filter this to remove duplicates from both axes
      const cleanX = trace.x.filter((time, j) => {
        // Does this timestamp exist in the base data set?
        const index = base[i].x.indexOf(time);

        // if we didn't find this timestamp in the base data then carry on as normal.
        if (index < 0) {
          cleanY.push(trace.y[j]);
          hoverText.push(trace.text[j]);
          return true;
        } else {
          // if we already have this timestamp then we'll update it's value to the new data.
          base[i].y[index] = trace.y[j];
        }

        // If we get here then we already have this timestamp, we're assuming
        // that's because it was plotted in a previous fetch so we'll just
        // remove it from the x and y axis of this trace so it doesn't get
        // plotted again and mess up the graph

        // Remove it from the x axis, it won't be added to the y index at this
        // point so no need to remove it from there.
        return false;
      });

      // add our new, clean data to the original dataset.
      base[i].x = base[i].x.concat(cleanX);
      base[i].y = base[i].y.concat(cleanY);
      base[i].text = base[i].text.concat(hoverText);
    } else {
      // this trace doesn't exist yet, that is unexpected but we might as well handle it.
      base[i] = trace;
    }

    // trim off old data.
    base[i] = dropOldData(base[i], lifetimeInSeconds);
  });

  // the code below detects gaps in the data timeline.
  // if there are too many gaps it will return null which
  // should be caught and handled, ie: re-fetching the data or
  // re-connecting the data source.

  let gaps = 0;

  if (gapToleranceMS > 0) {
    base.forEach((trace: IPlotlyTrace) => {
      let lastTS = 0;

      trace.x.forEach((timestamp: number) => {
        if (lastTS > 0) {
          if (timestamp - lastTS >= gapToleranceMS) {
            gaps += 1;
          }
        }
        lastTS = timestamp;
      });
    });
  }

  return gaps && gaps >= base.length ? null : base;
};

export const timeRangeFromData = (
  data: Array<IPlotlyTrace>,
  length: number
) => {
  let times: Array<number> = [];
  let now = Date.now();

  // if there is no data then the timeFrame is `length` seconds ago until now.
  if (!data.length) return [now - length, now];

  // make a new array of the max time stamps from each trace's axis
  data.forEach(trace => {
    times = times.concat(Math.max(...trace.x));
  });

  // `now` is the max time from all of the max times
  now = Math.max(...times);

  // timeFrame is `length` seconds ago until now (closest timestamp).
  return [snap(now - length, ONE_MINUTE_IN_MS), now];
};
