import React from "react";

import EventMap from "../../EventMap";

import { useEventDetailContext } from "../../../contexts/eventDetailProvider";

import styles from "./index.module.scss";

const EventDetailMap = () => {
  const { event } = useEventDetailContext();

  return (
    <div className={styles.Wrapper}>
      {event && (
        <EventMap
          events={[event]}
          canSearchOnMapMove={false}
          isSearching={false}
          focusPipelines={[event.pipeline.id]}
          hideLegend
          disableEventIconSelection
        />
      )}
    </div>
  );
};

export default EventDetailMap;
