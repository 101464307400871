import React from "react";
import cx from "classnames";

import { EventPriorityType } from "../../../../types/EventTypes";
import styles from "./index.module.scss";

interface IEventCardProps extends React.HTMLProps<HTMLDivElement> {
  priority?: EventPriorityType;
  children?: React.ReactNode;
  onClick: () => void;
  selected: boolean;
}

const EventCard = ({
  priority = "low",
  children,
  onClick,
  selected
}: IEventCardProps) => {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className={cx(styles.Card, {
        [styles.CardSelected]: selected,
        [styles.CardMedium]: priority === "medium",
        [styles.CardHigh]: priority === "high"
      })}
    >
      {children}
    </button>
  );
};

export default EventCard;
