import React from "react";
import { gqlType } from "@hifieng/common";

import { Meta, Text } from "../../Type";
import { MILLISECONDS_IN_SECONDS } from "../../../helpers/constants";
import styles from "./index.module.scss";
import DateTime from "../../DateTime";
import { kpToMp } from "../../AnalysisCharts/BaseChart";
import { useAuth } from "../../../contexts/AuthProvider";

type Props = {
  train: gqlType.TrainRun;
  update: gqlType.TrainRunUpdate;
  estimatedArrival?: number;
};

const TrainInfo = ({ train, update, estimatedArrival }: Props) => {
  const { permissions } = useAuth();
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          RUN ID
        </Meta>
        <Text className={styles.Text} component="span" size="small">
          {train.railId} - Run {train.runId}
        </Text>
      </div>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          {(permissions.user.imperial ? true : false) ||
          train.pipelineId === "enbridge_line_3" ||
          train.pipelineId === "enbridge_line_4"
            ? "Mile Post"
            : "KM Post"}
        </Meta>
        <Text className={styles.Text} component="span" size="small">
          {(permissions.user.imperial ? true : false) ||
          train.pipelineId === "enbridge_line_3" ||
          train.pipelineId === "enbridge_line_4"
            ? `MP ${kpToMp(update.post)}`
            : `KP ${update.post}`}
        </Text>
      </div>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          Timestamp
        </Meta>
        <Text className={styles.Text} component="span" size="small">
          <DateTime
            timestamp={update.timestamp * MILLISECONDS_IN_SECONDS}
            length="long"
          />
        </Text>
      </div>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          Lat/Lng
        </Meta>
        <a
          href={`https://maps.google.com/maps?q=loc:${update.coordinates.latitude},${update.coordinates.longitude}`}
          className={styles.Link}
          target="__blank"
        >
          <Text className={styles.Text} component="span" size="small">
            {update.coordinates.latitude}, {update.coordinates.longitude}
          </Text>
        </a>
      </div>
      <hr className={styles.Divider} />
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          Speed
        </Meta>
        <Text className={styles.Text} component="span" size="small">
          {Math.abs(update.speed)}m/second
        </Text>
      </div>
      <div className={styles.Row}>
        <Meta component="h5" size="small" dark>
          ETA
        </Meta>
        <Text className={styles.TextPoi} component="span" size="small">
          {update.nextStationEta ? (
            <DateTime
              timestamp={update.nextStationEta * MILLISECONDS_IN_SECONDS}
              length="short"
            />
          ) : (
            "TBD"
          )}
        </Text>
      </div>
      {estimatedArrival && (
        <div className={styles.Row}>
          <Meta component="h5" size="small" dark>
            Arrival (EST.)
          </Meta>
          <Text className={styles.Text} component="span" size="small">
            <DateTime
              timestamp={estimatedArrival * MILLISECONDS_IN_SECONDS}
              length="long"
            />
          </Text>
        </div>
      )}
    </div>
  );
};

export default TrainInfo;
